import styled, { css } from 'styled-components'
import { colors } from '@common/Theme'

const labelColorFn = ({ labelColor }) =>
  labelColor &&
  css`
    color: ${labelColor || '#1d2a43'};
  `

const BaseFieldLabel = css`
  line-height: 16px;
  font-size: 14px;
  font-weight: 500;
  font-family: 'IBM Plex Sans', sans-serif;
  color: #a9aeb9;
  display: block;
  margin: 0 0 8px 0;
`

const FlexRow = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`

const FlexItem = styled.div`
  flex-grow: 1;
`

const FieldLabel = styled.span.attrs({ className: 'label' })`
  ${BaseFieldLabel};
  ${labelColorFn};
  ${(props) =>
    props.error &&
    css`
      color: ${colors.grapefruit};
    `}
`

const FieldContainer = styled.div`
  margin-top: 16px;
  width: 100%;
`

const FormikContainer = styled.div`
  height: 100%;
  form {
    height: 100%;
    position: relative;
  }
`

const FormScroll = styled.div`
  width: 100%;
  padding-right: 6px;
`

const ExerciseCreatorContainer = styled.div.attrs({
  className: 'ExerciseCreator',
})`
  margin-right: 6px;
  overflow: auto;
  margin: 0;
  height: calc(100% - 130px);
  max-height: calc(100% - 130px);
  &::-webkit-scrollbar-track {
    background: none;
    border: none;
    box-shadow: none;
    border-radius: 4px;
    width: 14px;
  }
  &::-webkit-scrollbar-thumb {
    background: #eeeff1;
    border: none;
    box-shadow: none;
    border-radius: 6px;
  }
`

export {
  FlexRow,
  FlexItem,
  ExerciseCreatorContainer,
  FieldLabel,
  FormScroll,
  FieldContainer,
  FormikContainer,
}
