import React, { useEffect, useContext } from 'react'
import { useSelector } from 'react-redux'
import { prop } from 'ramda'
// STATE
import { exerciseSelector } from '@state/exercise'
// CONTEXT
import { LayoutContext, LayoutTypes } from '@features/Layout'
// LOCAL
import { ExerciseEditorDescription } from './ExerciseEditorDescription'
import { ExerciseEditorName } from './ExerciseEditorName'
import { ExerciseEditorMuscles } from './ExerciseEditorMuscles'
import { ExerciseEditorEquipment } from './ExerciseEditorEquipment'
import { ExerciseUploader } from '../ExerciseUploader'

import { FormScroll } from './ExerciseEditor.styled'

const ExerciseEditor = () => {
  const [_, localDispatch] = useContext(LayoutContext)

  // SELECTORS
  const activeExercise = useSelector(exerciseSelector.activeExercise)
  const exerciseName = prop('exerciseName')(activeExercise)

  useEffect(() => {
    if (exerciseName && exerciseName.length > 0) {
      localDispatch({
        type: LayoutTypes.SET_BLADE_TITLE,
        data: exerciseName,
      })
    }
  }, [exerciseName])

  return (
    <>
      <FormScroll>
        <ExerciseUploader type="UPDATE" />
        <ExerciseEditorName />
        <ExerciseEditorDescription />
        <ExerciseEditorMuscles />
        <ExerciseEditorEquipment />
      </FormScroll>
    </>
  )
}

export { ExerciseEditor }
