import styled, { css } from 'styled-components'
import { colors, media, mbFn, mlFn, mtFn } from '@common/Theme'

const Container = styled.div`
  padding: 0;
  display: flex;
  flex-direction: column;
  ${mlFn};
  ${mtFn};
  ${mbFn};
  ${media.sm`
  flex-direction: row;
    `};
  button.BackButton {
    display: flex;
    ${media.sm`
      display: none;
    `};
  }
`

const Block = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 48px;
  width: 100%;
  flex-basis: 100%;
  ${mbFn};
  ${media.sm`
    flex-basis: 300px;
  `};
`

const IconContainer = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Label = styled.h3`
  /* color: black; */
  color: rgb(18, 27, 45);
  font-size: 12px;
  font-weight: 500;
  margin: 0;
  padding: 0;
`

const Content = styled.div`
  padding: 0 0 0 0px;
  display: flex;
  width: 100%;
  flex-direction: column;
  border-radius: 6px;
  max-width: 800px;
  transition: all 0.3s ease-in-out;
`

const MenuContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0px;
  width: 100%;
`

const Item = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 8px 8px 8px;
  cursor: pointer;
  border-radius: 6px;
  position: relative;
  width: 100%;
  border: 1px solid transparent;
  transition: all 0.3s ease-in-out;
  .IconContainer {
    margin-left: auto;
    opacity: 0;
    transition: all 0.3s ease-in-out;
  }
  span {
    color: #778092;
    font-size: 14px;
    font-weight: 400;
  }
  &:hover {
    background: transparent;
  }

  ${(props) =>
    props.isActive &&
    css`
      &:before {
        content: '';
        width: 4px;
        height: 100%;
        position: absolute;
        left: 0;
        ${(props) =>
          props.isActive &&
          css`
            background: transparent;
          `};
      }
      span {
        color: ${colors.charcoal};
      }
      background: #f9fafc;
      border: 1px solid #e3e5eb;
      box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1);
      .IconContainer {
        opacity: 1;
      }
    `};
`

const Group = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 6px;
  background: transparent;
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  margin-top: 16px;
`

export {
  Container,
  Block,
  Content,
  MenuContent,
  Item,
  Group,
  Label,
  IconContainer,
}
