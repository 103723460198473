const ADD_IMAGE = 'ADD_IMAGE';
const TOGGLE_MODAL = 'TOGGLE_MODAL';
const SET_IMAGE_READER = 'SET_IMAGE_READER';
const CLEAR_IMAGES = 'CLEAR_IMAGES';
const REMOVE_IMAGE = 'REMOVE_IMAGE';

export default {
  ADD_IMAGE,
  TOGGLE_MODAL,
  SET_IMAGE_READER,
  CLEAR_IMAGES,
  REMOVE_IMAGE,
};
