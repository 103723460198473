import styled, { css } from 'styled-components';

const Container = styled.button`
  box-sizing: border-box;
  border-radius: 8px;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  background: #fafafb;
  border: 1.5px solid #e3e5eb;
  box-sizing: border-box;
  border-radius: 6px;
  ${props =>
    props.isActive &&
    css`
      background: white;
      border: 1.5px solid #ff5a45;
    `}
  &:active {
    border: 1.5px solid #e3e5eb;
    ${props =>
      props.isActive &&
      css`
        background: white;
        border: 1.5px solid #ff5a45;
      `}
  }
  &:hover {
    color: #40a9ff;
  }
  &:focus {
    color: #e3e5eb;
    outline: none;
    border: 1.5px solid #e3e5eb;
    ${props =>
      props.isActive &&
      css`
        background: white;
        border: 1.5px solid #ff5a45;
      `}
  }
`;

export { Container };
