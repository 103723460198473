import React from 'react';
import { Icon } from '@common/Icon';
import { Container } from './NotesControl.styled';

const NotesControl = ({ onClick = () => {}, isActive = false }) => (
  <Container onClick={onClick} isActive={isActive}>
    <Icon name="DETAILS" size={20} stroke={isActive ? '#ff5a45' : '#A9AEB9'} />
  </Container>
);

export { NotesControl };
