import React from 'react'
import PropTypes from 'prop-types'
import { prop } from 'ramda'
import { Text } from '@common/Text'
import { Heading } from '@common/Heading'

import { Container, Footer } from '../Modal.styled'

const TokenContent = ({ config = {}, dev, level, children }) => {
  const layoutStyle = prop('layout')(config) || {}
  return (
    <Container style={layoutStyle} dev={dev} level={level}>
      <Heading
        config={{
          text: {
            color: '#000000',
            fontSize: '24px',
            lineHeight: '32px',
            marginBottom: '16px',
          },
        }}
        variant="SECONDARY"
      >
        Copy Token
      </Heading>
      <Text color="#121B2D" weight="300">
        Press the button below to copy the current authentication token.
      </Text>
      <Footer>{children}</Footer>
    </Container>
  )
}

TokenContent.defaultProps = {
  style: {},
  dev: false,
  level: 2,
  data: {
    message: `Copy Token?`,
  },
  onClick: () => {},
}

TokenContent.propTypes = {
  data: PropTypes.object,
  style: PropTypes.object,
  dev: PropTypes.bool,
  level: PropTypes.number,
  onClick: PropTypes.func,
  children: PropTypes.any,
}

export default TokenContent
