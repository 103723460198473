import styled, { css } from 'styled-components'
import {
  basicFunctions,
  basicStyles,
  basicText,
  pseudoStyles,
} from './buttonStyles'
import { mtFn, mbFn, mrFn, mlFn } from '@common/Theme'

const Transparent = styled.button`
  ${basicStyles};
  ${basicText};
  ${pseudoStyles}
  ${basicFunctions};
  ${mtFn};
  ${mbFn};
  ${mrFn};
  ${mlFn};
  padding: 12px 16px;
  background: transparent;
  border-radius: 8px;
  font-weight: 500;
  transition: all 0.2s ease-in-out;
  justify-content: center;
  align-items: center;
  span {
    font-size: 16px;
    color: black;
    line-height: 21px;
    font-weight: 300;
  }
  &:hover {
    background: transparent;
    box-shadow: none;
  }

  ${(props) => props.extend};
`

export { Transparent }
