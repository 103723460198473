import { green } from '@common/Theme'
import { mtFn, media, mbFn, mlFn, mrFn } from '@common/Theme'
import styled, { css } from 'styled-components'

const BuilderControlsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${mbFn};
`

const ControlRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  &.ControlsRow {
    .Controls {
      width: 100%;
      margin-bottom: 16px;
      margin-left: none;
      justify-content: flex-start;
      margin-top: 16px;
    }
    ${media.sm`
    flex-direction: row;
    .Controls {
      justify-content: flex-end;
      margin-left: auto;
    } 
    `}
  }
`

const Controls = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: auto;
  button {
    margin-left: 12px;
    &:first-child {
      margin-left: 0px;
    }
  }

  ${media.sm`
    width: 100%;
  `}
`
const orange = `#FF5A45`
const success = 'transparent'

const SaveButton = styled.button`
  display: inline-flex;
  ${mbFn};
  ${mtFn};
  ${mrFn};
  ${mlFn};
  // FONT
  font-weight: 500;
  color: white;
  border: 1.5px solid ${success};
  background: ${success};
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  max-height: 48px;
  padding: 10px 12px;
  transition: all 0.2s ease-in-out;
  box-sizing: border-box;
  cursor: pointer;
  &:hover {
    background: ${success};
  }
  ${(props) =>
    props.isActive &&
    css`
      background: ${success};
      border: 1.5px solid rgb(179 216 189);
      color: #a9aeb9;
      color: rgb(25 178 68);
      &:hover {
        background: ${success};
      }
    `}
`

export { SaveButton, BuilderControlsContainer, ControlRow, Controls }
