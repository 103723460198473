import styled, { css } from 'styled-components'
import {
  basicFunctions,
  basicStyles,
  basicText,
  pseudoStyles,
} from './buttonStyles'
import { mtFn, mbFn, mrFn, mlFn, widthFn } from '@common/Theme'

const White = styled.button`
  ${basicStyles};
  ${basicText};
  ${pseudoStyles}
  ${basicFunctions};
  ${mtFn};
  ${mbFn};
  ${mrFn};
  ${mlFn};
  padding: 12px 16px;
  width: 100%;
  background: transparent;
  border-radius: 8px;
  transition: all 0.2s ease-in-out;
  color: white;
  justify-content: center;
  ${widthFn};
  span {
    font-size: 16px;
    font-weight: 500;
    line-height: 21px;
    width: 100%;
  }
  &:hover {
    background: transparent;
  }
`

export { White }
