import React, { useEffect } from 'react'
import { Grid, Row, Col } from '@common/Grid'
import { CopyProvider, CopyValue } from '@common/CopyValue'
import { Section, SectionContent } from '@common/Section'
import { useBackgroundColor } from '@hooks/useBackgroundColor'
import { NoteProvider, Note } from '@common/Note'
// Build a page that copies the local environment code
const SetupPage = () => {
  const setBackgroundColor = useBackgroundColor('white')

  useEffect(() => {
    setBackgroundColor('rgb(31, 36, 42)')
  }, [])

  return (
    <Grid marginX={24} mt={24} fluid>
      <Row>
        <Col xs={12} md={12}>
          <Section title="Workout Image" path="src/state/workout">
            <SectionContent>
              <CopyProvider>
                <CopyValue target value={`src/state/workout`} label="Path" />
                <NoteProvider>
                  <Note type="note" text="state/workout/actionTypes" />
                  <Note type="note" text="state/workout/reducer" />
                  <Note
                    type="note"
                    text="state/workout/action/pending/  createWorkoutImage() "
                  />
                </NoteProvider>
              </CopyProvider>
            </SectionContent>
          </Section>
          <Section title="Board">
            <SectionContent>
              <CopyProvider>
                <CopyValue
                  target
                  value={`https://vercel.com/aimalife/aimacs`}
                  label="Vercel"
                />

                <CopyValue
                  target
                  value={`https://dashboard.stripe.com/test/products/prod_LmgYTVY0kxAP71`}
                  label="Stripe Products"
                />
                <CopyValue
                  target
                  value={`https://github.com/aima-fit/aimacs`}
                  label="Github"
                />
              </CopyProvider>
            </SectionContent>
          </Section>

          <Section title="Documentation">
            <SectionContent>
              <CopyProvider>
                <CopyValue target value={`aimafit`} label="Postman Team Name" />
                <CopyValue
                  target
                  value={`https://ramdajs.com/docs/`}
                  label="Ramda Docs"
                />
                <CopyValue
                  target
                  value={`https://styled-components.com/`}
                  label="Styled Components"
                />
              </CopyProvider>
            </SectionContent>
          </Section>
        </Col>
      </Row>
    </Grid>
  )
}

export default SetupPage
