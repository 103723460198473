import React, { useState, useRef, forwardRef, useEffect } from 'react'
import { Spacer } from '@common/Spacer'
import { Spinner } from '@common/Spinner'
import { Back } from '@components/Back'
import { useNavigate } from 'react-router-dom'
import { Icon } from '@common/Icon'
import { useSelector } from 'react-redux'
import { ImageControl } from './ImageControl'
import { NotesControl } from './NotesControl'
import { Notes } from './Notes'
import { ControlName } from './ControlName'
import { prop } from 'ramda'
import { ImageUploader } from '@components/ImageUploader'
import { ImageRenderer } from '@components/ImageUploader/ImageRenderer'
import { deleteWorkout, updateWorkout, workoutSelector } from '@state/workout'
import {
  BuilderControlsContainer,
  ControlRow,
  SaveButton,
  Controls,
} from './AdvancedControls.styled'
import { isEmptyObject } from '@utils/ramda'
import { useDispatch } from 'react-redux'
import { Button } from '@common/Button'
import { createWorkoutImage } from '@state/workout'

const spacerStyle = {
  display: 'flex',
  justifyContent: 'space-between',
}

import styled from 'styled-components'
import { Line } from '@common/Line'
import { Modal } from '@common/Modal'
import { IconContainer } from '@common/IconContainer'

const SettingsContainer = styled.div`
  border: 1px solid #e3e5eb;
  padding: 16px;
  background-color: white;
  border-radius: 6px;
  margin-bottom: 8px;
  h2 {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 16px;
  }
`

const ControlsModal = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`
const Settings = () => {
  const activeWorkout = useSelector(workoutSelector.activeWorkout)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [deleteConfirmation, setDeleteConfirmation] = useState(false)

  if (!prop('workoutId')(activeWorkout)) return null

  const handleDelete = () => {
    const id = prop('workoutId')(activeWorkout)
    dispatch(deleteWorkout(id))
    navigate('/library/workout')
  }

  const handleInitialDelete = () => {
    setDeleteConfirmation(true)
  }

  const handleCancel = () => {
    setDeleteConfirmation(false)
  }

  return (
    <SettingsContainer>
      <h2>Workout Settings</h2>
      <Button
        variant="orange"
        config={{
          layout: {
            width: 'unset',
          },
        }}
        onClick={() => handleInitialDelete()}
        label="Delete Workout"
      ></Button>

      {deleteConfirmation && (
        <Modal
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          callback={() => handleCancel()}
          variant="DELETE_WORKOUT"
        >
          <p style={{ color: '#4f4f4f', marginBottom: '0' }}>
            Are you sure you want to delete this workout? This is a permanent
            action and cannot be undone.{' '}
          </p>
          <Line bgColor="#d4d4d4" mb={16} mt={24} />
          <ControlsModal>
            <Button
              variant="outline"
              config={{
                layout: {
                  width: 'unset',
                },
              }}
              onClick={() => handleCancel()}
              label="Cancel"
            >
              <IconContainer ml={5}>
                <Icon name="CANCEL" />
              </IconContainer>
            </Button>
            <Button
              variant="orange"
              config={{
                layout: {
                  width: 'unset',
                },
              }}
              onClick={() => handleDelete()}
              label="Delete Workout"
            ></Button>
          </ControlsModal>
        </Modal>
      )}
    </SettingsContainer>
  )
}

const getFilteredItems =
  (id) =>
  (items = []) =>
    items.filter((k) => k.id !== id)

const AdvancedControls = forwardRef(({ width, callback = () => {} }, ref) => {
  const targetRef = useRef(null)
  const [showNotes, setShowNotes] = useState(false)
  const [showDelete, setShowDelete] = useState(false)
  const activeWorkout = useSelector(workoutSelector.activeWorkout)
  const [, setDimensions] = useState({ width: 0, height: 0 })
  const dispatch = useDispatch()
  const isLoading = useSelector(workoutSelector.loading)
  const isEqual = true

  const loading = useSelector(workoutSelector.loading)
  const images = useSelector(workoutSelector.images)

  const navigate = useNavigate()
  const renderSave = (isLoading) => {
    if (isLoading)
      return (
        <Spinner variant="SUCCESS" mr={4} width={20} height={20} size={20} />
      )
    return isEqual ? (
      <Icon mr={4} name="CHECKMARK" size={20} />
    ) : (
      <Icon mr={4} name="SAVE" stroke="white" />
    )
  }

  useEffect(() => {
    if (targetRef.current) {
      setDimensions({
        width: targetRef.current.offsetWidth,
      })
    }
  }, [width])

  const onNavigate = () => navigate('/library/workout')

  const onToggleNotes = () => {
    setShowNotes(!showNotes)
  }
  const onToggleDelete = () => {
    setShowDelete(!showDelete)
  }

  const handleSave = () => {
    callback({ type: 'SAVE' })
  }

  const handleImageCallback = ({ action, value }) => {
    if (action === 'DELETE_IMAGE') {
      const updatedWorkout = {
        ...activeWorkout,
        images: getFilteredItems(prop('id')(value))(activeWorkout.images),
      }

      if (!prop('workoutId')(activeWorkout)) {
        console.warn('No workoutId found in [AdvancedControls.js]')
        return
      }
      dispatch(updateWorkout(updatedWorkout))
    }
  }

  const handleUpload = ({ value }) => {
    dispatch(createWorkoutImage(value))
  }

  return (
    <BuilderControlsContainer mb={16} ref={ref}>
      <Spacer style={spacerStyle} mb={24}>
        <Back onPress={onNavigate} />
        <SaveButton isActive={!isLoading} onClick={handleSave}>
          {renderSave(isLoading)}
          {isLoading ? 'Saving...' : isEqual ? 'Saved' : 'Save'}
        </SaveButton>
      </Spacer>

      <ControlRow className="ControlsRow" ref={targetRef}>
        <ControlName />
        <Controls className="Controls">
          <ImageControl onClick={onToggleDelete} isActive={showDelete} />
          <NotesControl onClick={onToggleNotes} isActive={showNotes} />
        </Controls>
      </ControlRow>
      {showDelete && <Settings />}
      {showNotes && <Notes />}
      {!isEmptyObject(activeWorkout) && prop('workoutId')(activeWorkout) && (
        <ImageUploader
          variant="banner"
          loading={loading}
          images={images}
          callback={handleUpload}
        >
          <ImageRenderer
            parentId={activeWorkout?.workoutId}
            images={activeWorkout?.images}
            variant="banner"
            config={{
              controlType: 'default',
            }}
            kind="workout"
            callback={handleImageCallback}
          />
        </ImageUploader>
      )}
    </BuilderControlsContainer>
  )
})

export { AdvancedControls }
