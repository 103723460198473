import React, { useEffect, useRef, useState } from 'react';
import { PropTypes } from 'prop-types';
import { prop } from 'ramda';
import {
  RangeSliderContainer,
  RangeSliderItem,
  RangeValue,
} from './RangeSlider.styled';

const RangeSliderView = ({
  value: valueProp = 0,
  onChange,
  max = 100,
  min = 1,
  disabled,
  showTag,
  label,
  width,
  config,
}) => {
  const ref = useRef(null);
  const [value, setValue] = useState(0);
  const [percentage, setPercentage] = useState(0);

  const layoutStyle = prop('layout')(config);

  const onLocalChange = e => {
    onChange(e);
    if (disabled) {
      return;
    }
    const {
      target: { min, max, value: currentValue },
    } = e;

    setValue(currentValue);
    const percentage = (100 * (currentValue - min)) / (max - min);
    setPercentage(percentage);
  };

  useEffect(() => {
    if (ref) {
      const { current } = ref;
      const percentage =
        (100 * (valueProp - current.min)) / (current.max - current.min);
      setPercentage(percentage);
      setValue(valueProp);
    }
  }, [valueProp]);

  return (
    <RangeSliderContainer style={layoutStyle}>
      <RangeSliderItem
        onChange={onLocalChange}
        type="range"
        ref={ref}
        min={min}
        max={max}
        value={value}
        disabled={disabled}
        percentage={percentage}
        width={width}
        label={label}
        className={'range-slider'}
      />
      {showTag && <RangeValue class="range-slider__value">{value}</RangeValue>}
    </RangeSliderContainer>
  );
};

RangeSliderView.defaultProps = {
  disabled: false,
  showTag: false,
  width: '100%',
  onChange: () => {},
  config: {},
  label: '',
};

RangeSliderView.propTypes = {
  dispabled: PropTypes.bool,
  showTag: PropTypes.bool,
  width: PropTypes.string,
  onChange: PropTypes.func,
  config: PropTypes.object,
  label: PropTypes.string,
};

export { RangeSliderView };
