import React, { useState, useEffect } from 'react'
import { Formik, Form, Field } from 'formik'
import { string, object, ref } from 'yup'

import { InputField } from '@common/InputField'
import { Icon } from '@common/Icon'
import { Button } from '@common/Button'
import { Grid, Row, Col } from '@common/Grid'
import { Text } from '@common/Text'
import { Block } from '@common/Block'
import { Heading } from '@common/Heading'
import { ErrorList } from '@common/ErrorList'
import { Loading } from '@common/Loading'
import { useDispatch } from 'react-redux'
import { useSelector } from '@hooks/useSelector'
import { useNavigate } from 'react-router-dom'
import { authSelector, authSignUpPending } from '@state/auth'

// import { Service } from '../Service'
import { Or, Center, Group, Wrap } from './SignUp.styled'

// const auth = Service()

const Schema = object().shape({
  firstName: string()
    .max(20, `Your first name is quite long, try shortening it for us`)
    .required('Please enter your first name'),
  lastName: string()
    .max(20, `Your last name is quite long, try shortening it for us`)
    .required('Please enter your last name'),
  email: string()
    .email('Please enter a valid email')
    .required('Please enter a valid email'),
  password: string()
    .max(25, 'Your password cannot be more than 25 characters in length')
    .min(7, 'Your password must have a minumum of 7 characters')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/,
      'Your password must contain at least one number and one special character',
    )
    .required('Please enter a password'),
  confirm: string()
    .oneOf([ref('password'), null], 'Passwords must match')
    .required('Please re-enter your password'),
})

const haystack = [
  {
    name: 'invalid_signup',
    variant: 'error',
    message: 'Invalid sign up, you might already have an account with us.',
  },
  {
    name: 'generic',
    variant: 'error',
    message: 'There was a problem',
  },
  {
    name: 'success',
    variant: 'success',
    message: 'You have successfully sign up',
  },
]

const isNext = true
const baseUrl = isNext ? '' : './public/'
const imagePath = `${baseUrl}assets/banners/login.svg`

const signInWithGoogle = () => {
  authInstance.authorize(
    {
      audience: AUTH_CONFIG.audience,
      connection: 'google-oauth2',
      responseType: 'token id_token',
    },
    (err, res) => {
      if (err) {
        return createStatus('failure')(err)
      }
      if (res) {
        return createStatus('success')(res)
      }
    },
  )
}

const SignUp = () => {
  const [errors, setErrors] = useState([])
  const authSignup = useSelector(authSelector.authSignup)
  const isLoading = useSelector(authSelector.loading)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const handleSubmit = async (value) => {
    const { email, password } = value

    dispatch(
      authSignUpPending({
        email,
        password,
      }),
    )
  }

  // const onGoogleSignIn = () => {
  // auth.signInWithGoogle()
  // }

  const onNavigateLogin = () => navigate('/login')

  const closeError = (error) => {
    setErrors((state) => state.filter((item) => item !== error))
  }

  useEffect(() => {
    if (authSignup) {
      navigate('/validate')
    }
  }, [authSignup])

  return (
    <>
      <Center>
        <Block.Container mb={64} shadow column>
          <Heading mb={16} variant="AUTH">
            Sign up for AIMA
          </Heading>
          <ErrorList
            hasClose
            haystack={haystack}
            needles={errors}
            mb={16}
            callback={closeError}
          />

          <>
            {/* <Button variant="signup" onClick={onGoogleSignIn}>
              <Icon
                name="GOOGLESIGNUP"
                size={20}
                fill="white"
                viewBox="-1 -2 20 20"
              />
              <span style={{ marginLeft: '16px' }}>Sign up with Google</span>
            </Button> */}
            {/* <Or /> */}
            <Formik
              initialValues={{
                firstName: '',
                lastName: '',
                email: '',
                password: '',
                confirm: '',
              }}
              validateOnChange
              validationSchema={Schema}
              onSubmit={(values) => handleSubmit(values)}
            >
              {() => (
                <Group>
                  <Form>
                    <Grid marginX={0}>
                      <Row>
                        <Col xs={12} sm={6}>
                          <Field
                            name="firstName"
                            component={InputField}
                            label="First Name"
                            mb={16}
                            placeholder="First Name"
                          />
                        </Col>
                        <Col xs={12} sm={6}>
                          <Field
                            name="lastName"
                            component={InputField}
                            mb={16}
                            label="Last Name"
                            placeholder="Last Name"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12}>
                          <Field
                            name="email"
                            component={InputField}
                            mb={16}
                            label="Email"
                            placeholder="Email"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} sm={6}>
                          <Field
                            name="password"
                            component={InputField}
                            label="Password"
                            mb={16}
                            placeholder="+6 Characters"
                          />
                        </Col>
                        <Col xs={12} sm={6}>
                          <Field
                            name="confirm"
                            component={InputField}
                            mb={16}
                            label="Confirm Password"
                            ml={8}
                            placeholder="Confirm Password"
                          />
                        </Col>
                      </Row>
                      <br />
                      <Button variant="orange" mt={16} type="submit" alone>
                        Create Account
                      </Button>
                    </Grid>
                  </Form>
                </Group>
              )}
            </Formik>
          </>
          <Block.Flex mt={16} style={{ justifyContent: 'center' }}>
            <Text
              as="p"
              color="#1D2A43"
              weight={600}
              config={{
                layout: {
                  textAlign: 'left',
                  fontWeight: '600',
                  lineHeight: '20px',
                },
              }}
            >
              Already have an account?
            </Text>
            <Text
              as="link"
              color="#FF5A45"
              weight={600}
              onClick={onNavigateLogin}
              ml={8}
              style={{
                textAlign: 'left',
                lineHeight: '20px',
              }}
            >
              Login
            </Text>
          </Block.Flex>
        </Block.Container>
      </Center>
      <Wrap imageSrc={imagePath} />
      {isLoading && <Loading message="Loading..." />}
    </>
  )
}

export { SignUp }
