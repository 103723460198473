import {
  PREFERENCES_FETCH_PENDING,
  PREFERENCES_FETCH_FAILURE,
  PREFERENCES_FETCH_SUCCESS,
  PREFERENCES_SAVE_BOOKMARK_PENDING,
  PREFERENCES_SAVE_BOOKMARK_SUCCESS,
  PREFERENCES_SAVE_BOOKMARK_FAILURE,
  PREFERENCES_REMOVE_BOOKMARK_PENDING,
  PREFERENCES_REMOVE_BOOKMARK_SUCCESS,
  PREFERENCES_REMOVE_BOOKMARK_FAILURE,
  USER_FETCH_ME_PENDING,
  USER_FETCH_ME_SUCCESS,
  USER_FETCH_ME_FAILURE,
  USER_CREATE_IMAGE_PENDING,
  USER_UPDATE_PENDING,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAILURE,
  USER_CREATE_IMAGE_SUCCESS,
  USER_VALIDATE_CHANNEL_PENDING,
  USER_VALIDATE_CHANNEL_SUCCESS,
  USER_VALIDATE_CHANNEL_FAILURE,
  USER_RESET_VALIDATE_CHANNEL_PENDING,
} from './actionTypes'

const initialState = {
  loading: false,
  favourites: [],
  error: '',
  user: null,
  roles: [],
  validating: false,
  isValidChannel: null,
}

export default function preferencesReducer(state = initialState, action) {
  switch (action.type) {
    case PREFERENCES_FETCH_PENDING:
      return {
        ...state,
        loading: true,
      }
    case PREFERENCES_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case USER_FETCH_ME_PENDING:
      return {
        ...state,
        loading: true,
      }
    case USER_UPDATE_PENDING:
      return {
        ...state,
        loading: true,
      }
    case USER_CREATE_IMAGE_PENDING:
      return {
        ...state,
        loading: true,
      }
    case USER_VALIDATE_CHANNEL_PENDING:
      return {
        ...state,
        validating: true,
      }
    case USER_RESET_VALIDATE_CHANNEL_PENDING:
      return {
        ...state,
        isValidChannel: null,
      }
    case USER_VALIDATE_CHANNEL_SUCCESS:
      return {
        ...state,
        validating: false,
        isValidChannel: true,
      }
    case USER_VALIDATE_CHANNEL_FAILURE:
      return {
        ...state,
        validating: false,
        isValidChannel: false,
        error: action.payload,
      }
    case USER_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
      }
    case USER_CREATE_IMAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
      }
    case USER_FETCH_ME_SUCCESS:
      return {
        ...state,
        user: action.payload,
        roles: action.payload.roles,
        loading: false,
      }
    case PREFERENCES_SAVE_BOOKMARK_PENDING:
      return {
        ...state,
        loading: true,
      }
    case PREFERENCES_SAVE_BOOKMARK_SUCCESS:
      return {
        ...state,
        loading: false,
        favourites: action.payload,
      }
    case PREFERENCES_SAVE_BOOKMARK_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    case PREFERENCES_REMOVE_BOOKMARK_PENDING:
      return {
        ...state,
        loading: true,
      }
    case PREFERENCES_REMOVE_BOOKMARK_SUCCESS:
      return {
        ...state,
        loading: false,
        favourites: action.payload,
      }
    case PREFERENCES_REMOVE_BOOKMARK_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    case PREFERENCES_FETCH_FAILURE:
      return { ...state, loading: false, error: action.error }

    case PREFERENCES_FETCH_FAILURE:
    case PREFERENCES_SAVE_BOOKMARK_FAILURE:
    case PREFERENCES_REMOVE_BOOKMARK_FAILURE:
    case USER_UPDATE_FAILURE:
    case USER_FETCH_ME_FAILURE:
      return { ...state, loading: false, error: action.payload }

    default:
      return state
  }
}
