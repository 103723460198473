import { css } from 'styled-components'

const hiddenFn = ({ isHidden }) =>
  isHidden &&
  css`
    display: none;
  `

const widthFnPx = ({ width }) =>
  width &&
  css`
    max-width: ${width}px;
  `

const maxWidthFn = ({ maxWidth }) =>
  (maxWidth || maxWidth === 0) &&
  css`
    max-width: ${maxWidth}px;
  `

const mtFn = ({ mt }) =>
  (mt || mt === 0) &&
  css`
    margin-top: ${mt}px;
  `

const mbFn = ({ mb }) =>
  (mb || mb === 0) &&
  css`
    margin-bottom: ${mb}px;
  `

const pbFn = ({ pb }) =>
  pb &&
  css`
    padding-bottom: ${pb}px;
  `

const ptFn = ({ pt }) =>
  pt &&
  css`
    padding-top: ${pt}px;
  `

const mlFn = ({ ml }) =>
  ml &&
  css`
    margin-left: ${ml}px;
  `

const mrFn = ({ mr }) =>
  mr &&
  css`
    margin-right: ${mr}px;
  `

const bgColorFn = ({ bgColor }) =>
  bgColor &&
  css`
    background: ${bgColor};
  `

const preventSelectFn = ({ preventSelect }) =>
  preventSelect &&
  css`
    user-select: none;
  `

const borderColorFn = ({ color }) =>
  color &&
  css`
    border-color: color;
  `

const plFn = ({ pl }) =>
  (pl || pl === 0) &&
  css`
    padding-left: ${pl}px;
  `

const prFn = ({ pr }) =>
  (pr || pr === 0) &&
  css`
    padding-right: ${pr}px;
  `

const lineHeightFn = ({ lineHeight }) =>
  lineHeight &&
  css`
    line-height: ${lineHeight}px;
  `

const heightFn = ({ height }) =>
  height &&
  css`
    height: ${height};
  `

const fontSizeFn = ({ size }) =>
  size &&
  css`
    font-size: ${size}px;
  `

const weightFn = ({ weight = 500 }) =>
  weight &&
  css`
    font-weight: ${weight};
  `

const widthFn = ({ width }) => {
  if (typeof width === 'string') {
    return css`
      width: ${width};
    `
  } else {
    return css`
      width: ${width}px;
    `
  }
}
const linkFn = ({ link }) =>
  link &&
  css`
    text-decoration: underline;
    cursor: pointer;
  `

const flipFn = ({ flip }) =>
  flip &&
  css`
    -moz-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    filter: FlipH;
    -ms-filter: 'FlipH';
  `

const rotateFn = ({ rotate }) =>
  rotate &&
  css`
    transform: rotate(${rotate}deg);
  `

const centerFn = ({ center }) =>
  center &&
  css`
    text-align: center;
  `

const alignFn = ({ align }) =>
  align &&
  css`
    text-align: ${align};
  `

const colorFn = ({ color }) =>
  color &&
  css`
    color: ${color};
  `

const paddingFn = ({ padding }) =>
  padding &&
  css`
    padding: ${padding};
  `

const maxHeightFn = ({ maxHeight }) => {
  const checkPx = maxHeight && maxHeight.toString().includes('px')
  if (checkPx) {
    return css`
      max-height: ${maxHeight};
    `
  }

  return css`
    max-height: ${maxHeight}px;
  `
}

const flexDirectionFn = ({ direction }) => css`
  flex-direction: ${direction};
`

const justifyContentFn = ({ justifyContent }) => css`
  justify-content: ${justifyContent};
`

export {
  weightFn,
  linkFn,
  widthFn,
  centerFn,
  colorFn,
  heightFn,
  flexDirectionFn,
  justifyContentFn,
  maxHeightFn,
  bgColorFn,
  paddingFn,
  lineHeightFn,
  preventSelectFn,
  maxWidthFn,
  fontSizeFn,
  mtFn,
  mbFn,
  mlFn,
  alignFn,
  mrFn,
  pbFn,
  ptFn,
  plFn,
  prFn,
  flipFn,
  rotateFn,
  hiddenFn,
  borderColorFn,
  widthFnPx,
}
