import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import { Icon } from '@common/Icon'
import { Button } from '@common/Button'
import {
  Container,
  Content,
  Item,
  IconContainer,
  Block,
  Label,
  Group,
} from './BladeMenu.styled'
import { loadState, saveState } from '@utils/storage'
import { useDelayResize } from '@hooks/useDelayResize'

const MenuItem = ({ id, label, callback, isComplete, className, isActive }) => {
  return (
    <Item
      className={className}
      isActive={isActive}
      onClick={() => callback({ action: 'SET', value: id })}
    >
      {isComplete && <Icon name="CHECK_ACTIVE" size={20} mr={5} />}
      <span>{label}</span>
      <IconContainer className="IconContainer">
        <Icon name="CHEVRON" stroke="#000" size={16} />
      </IconContainer>
    </Item>
  )
}

const BladeMenu = ({
  data = [],
  name,
  activeId,
  callback = () => {},
  label = '',
  ml,
  mb,
  mt,
  isDevMode,
  children,
}) => {
  const localState = loadState(name)
  const [localActiveId, setLocalActiveId] = useState(localState)
  const width = useDelayResize(300)

  useEffect(() => {
    if (localState !== undefined) {
      callback({ action: 'SET', value: localState })
      setLocalActiveId(localState)
    }
  }, [])

  useEffect(() => {
    // if (width < 480) {
    //   if (!!activeId && activeId === 'hidden') {
    //     setLocalActiveId(data[0].id)
    //     callback({ action: 'SET', value: data[0].id })
    //     return
    //   }
    // }
    if (!!activeId) {
      setLocalActiveId(activeId)
    }
  }, [activeId, width])

  const handleCallback = ({ action, value }) => {
    setLocalActiveId(value)
    saveState({ [name]: value })
    callback({ action, value })
  }

  const renderItems = (items) => {
    if (!items || !items.length) return null
    const result = items.map((item) => (
      <MenuItem
        key={item.id}
        id={item.id}
        label={item.label}
        isComplete={item.isComplete}
        callback={handleCallback}
        className={
          localActiveId && localActiveId !== item.id ? 'in-active' : ''
        }
        isActive={localActiveId === item.id}
      />
    ))
    return <Group>{result}</Group>
  }

  const renderOptions = () => {
    return (
      <Block className={`BladeBlock hidden`} mb={24}>
        {label && <Label>{label}</Label>}
        {renderItems(data)}
      </Block>
    )
  }

  return (
    <Container ml={ml} mt={mt} mb={mb} className="BladeMenu">
      {isDevMode && (
        <span>
          {activeId === 'hidden'
            ? 'Is back button hidden'
            : 'Is back button active'}
        </span>
      )}
      {renderOptions()}
      {children && <Content>{children}</Content>}
    </Container>
  )
}

BladeMenu.defaultProps = {
  data: [],
  activeId: null,
  ml: 0,
  isDevMode: false,
  mt: 0,
  callback: () => {},
}

BladeMenu.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      value: PropTypes.string,
      index: PropTypes.number,
      sequence: PropTypes.number,
      active: PropTypes.bool,
      isComplete: PropTypes.bool,
    }),
  ),
  activeId: PropTypes.string,
  callback: PropTypes.func,
}

export { BladeMenu }
