export const WORKOUTS_REQUEST_PENDING = 'WORKOUTS_REQUEST_PENDING'
export const WORKOUTS_REQUEST_SUCCESS = 'WORKOUTS_REQUEST_SUCCESS'
export const WORKOUTS_REQUEST_FAILURE = 'WORKOUTS_REQUEST_FAILURE' // Handle failure --> hide loader

export const WORKOUTS_SET_FILTERS = 'WORKOUTS_SET_FILTERS'
export const UPDATE_ACTIVE_WORKOUT = 'UPDATE_ACTIVE_WORKOUT'

export const WORKOUTS_FETCH_PENDING = 'WORKOUTS_FETCH_PENDING'
export const WORKOUTS_FETCH_SUCCESS = 'WORKOUTS_FETCH_SUCCESS'
export const WORKOUTS_FETCH_FAILURE = 'WORKOUTS_FETCH_FAILURE'

export const WORKOUTS_UPDATE_PENDING = 'WORKOUTS_UPDATE_PENDING'
export const WORKOUTS_UPDATE_SUCCESS = 'WORKOUTS_UPDATE_SUCCESS'
export const WORKOUTS_UPDATE_FAILURE = 'WORKOUTS_UPDATE_FAILURE'

export const WORKOUTS_UPDATE_LISTEN_PENDING = 'WORKOUTS_UPDATE_LISTEN_PENDING'
export const WORKOUTS_UPDATE_LISTEN_SUCCESS = 'WORKOUTS_UPDATE_LISTEN_SUCCESS'
export const WORKOUTS_UPDATE_LISTEN_FAILURE = 'WORKOUTS_UPDATE_LISTEN_FAILURE'

export const WORKOUTS_ADD_IMAGE_PENDING = 'WORKOUTS_ADD_IMAGE_PENDING'
export const WORKOUTS_ADD_IMAGE_SUCCESS = 'WORKOUTS_ADD_IMAGE_SUCCESS'
export const WORKOUTS_ADD_IMAGE_FAILURE = 'WORKOUTS_ADD_IMAGE_FAILURE'

// export const WORKOUTS_DELETE_UPDATE_IMAGE_PENDING =
//   'WORKOUTS_DELETE_UPDATE_IMAGE_PENDING'
// export const WORKOUTS_DELETE_UPDATE_IMAGE_SUCCESS =
//   'WORKOUTS_DELETE_UPDATE_IMAGE_SUCCESS'
// export const WORKOUTS_DELETE_UPDATE_IMAGE_FAILURE =
//   'WORKOUTS_DELETE_UPDATE_IMAGE_FAILURE'

export const WORKOUTS_SET_ACTIVE_PENDING = 'WORKOUTS_SET_ACTIVE_PENDING'
export const WORKOUTS_SET_ACTIVE_SUCCESS = 'WORKOUTS_SET_ACTIVE_SUCCESS'
export const WORKOUTS_SET_ACTIVE_FAILURE = 'WORKOUTS_SET_ACTIVE_FAILURE'

export const WORKOUTS_CREATE_PENDING = 'WORKOUTS_CREATE_PENDING'
export const WORKOUTS_CREATE_SUCCESS = 'WORKOUTS_CREATE_SUCCESS'
export const WORKOUTS_CREATE_FAILURE = 'WORKOUTS_CREATE_FAILURE'

export const WORKOUTS_BY_ID_PENDING = 'WORKOUTS_BY_ID_PENDING'
export const WORKOUTS_BY_ID_SUCCESS = 'WORKOUTS_BY_ID_SUCCESS'
export const WORKOUTS_BY_ID_FAILURE = 'WORKOUTS_BY_ID_FAILURE'

export const WORKOUTS_CREATE_IMAGE_PENDING = 'WORKOUTS_CREATE_IMAGE_PENDING'
export const WORKOUTS_CREATE_IMAGE_SUCCESS = 'WORKOUTS_CREATE_IMAGE_SUCCESS'
export const WORKOUTS_CREATE_IMAGE_FAILURE = 'WORKOUTS_CREATE_IMAGE_FAILURE'

export const WORKOUTS_UPDATE_IMAGE_PENDING = 'WORKOUTS_UPDATE_IMAGE_PENDING'
export const WORKOUTS_UPDATE_IMAGE_SUCCESS = 'WORKOUTS_UPDATE_IMAGE_SUCCESS'
export const WORKOUTS_UPDATE_IMAGE_FAILURE = 'WORKOUTS_UPDATE_IMAGE_FAILURE'

export const WORKOUTS_DELETE_IMAGE_PENDING = 'WORKOUTS_DELETE_IMAGE_PENDING'
export const WORKOUTS_DELETE_IMAGE_SUCCESS = 'WORKOUTS_DELETE_IMAGE_SUCCESS'
export const WORKOUTS_DELETE_IMAGE_FAILURE = 'WORKOUTS_DELETE_IMAGE_FAILURE'

export const EXERCISES_CREATE_IMAGE_SUCCESS = 'EXERCISES_CREATE_IMAGE_SUCCESS'
export const EXERCISES_CREATE_IMAGE_FAILURE = 'EXERCISES_CREATE_IMAGE_FAILURE'

export const WORKOUTS_UPDATE_BY_ID_SUCCESS = 'WORKOUTS_UPDATE_BY_ID_SUCCESS'
export const WORKOUTS_ADD_WORKOUT_PROTOCOL_PENDING =
  'WORKOUTS_ADD_WORKOUT_PROTOCOL_PENDING'
export const WORKOUTS_ADD_WORKOUT_PROTOCOL_SUCCESS =
  'WORKOUTS_ADD_WORKOUT_PROTOCOL_SUCCESS'
export const WORKOUTS_ADD_WORKOUT_PROTOCOL_FAILURE =
  'WORKOUTS_ADD_WORKOUT_PROTOCOL_FAILURE'

export const WORKOUTS_UPDATE_WORKOUT_PROTOCOL_PENDING =
  'WORKOUTS_UPDATE_WORKOUT_PROTOCOL_PENDING'
export const WORKOUTS_UPDATE_WORKOUT_PROTOCOL_SUCCESS =
  'WORKOUTS_UPDATE_WORKOUT_PROTOCOL_SUCCESS'
export const WORKOUTS_UPDATE_WORKOUT_PROTOCOL_FAILURE =
  'WORKOUTS_UPDATE_WORKOUT_PROTOCOL_FAILURE'

export const WORKOUTS_CLEAR_IMAGE = 'WORKOUTS_CLEAR_IMAGE'

// WIP
export const WORKOUTS_DELETE_PENDING = 'WORKOUTS_DELETE_PENDING'
export const WORKOUTS_DELETE_SUCCESS = 'WORKOUTS_DELETE_SUCCESS'
export const WORKOUTS_DELETE_FAILURE = 'WORKOUTS_DELETE_FAILURE'
