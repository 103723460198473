import SETTINGS_GET_ACTIVE_SUBSCRIPTIONS_SUCCESS from './SETTINGS_GET_ACTIVE_SUBSCRIPTIONS_SUCCESS';
import SETTINGS_GENERATE_DRAFT_QUOTE_SUCCESS from './SETTINGS_GENERATE_DRAFT_QUOTE_SUCCESS';
import SETTINGS_GET_ACTIVE_PRICES_SUCCESS from './SETTINGS_GET_ACTIVE_PRICES_SUCCESS';
import SETTINGS_UPDATE_QUOTE_PRICE_SUCCESS from './SETTINGS_UPDATE_QUOTE_PRICE_SUCCESS';
import SETTINGS_GET_ALL_PAYMENT_METHODS_SUCCESS from './SETTINGS_GET_ALL_PAYMENT_METHODS_SUCCESS';
import SETTINGS_CREATE_PAYMENT_METHOD_SUCCESS from './SETTINGS_CREATE_PAYMENT_METHOD_SUCCESS';
import SETTINGS_UPDATE_QUOTE_QUANTITY_SUCCESS from './SETTINGS_UPDATE_QUOTE_QUANTITY_SUCCESS';

export default {
  SETTINGS_GET_ACTIVE_SUBSCRIPTIONS_SUCCESS,
  SETTINGS_GENERATE_DRAFT_QUOTE_SUCCESS,
  SETTINGS_GET_ACTIVE_PRICES_SUCCESS,
  SETTINGS_UPDATE_QUOTE_PRICE_SUCCESS,
  SETTINGS_GET_ALL_PAYMENT_METHODS_SUCCESS,
  SETTINGS_CREATE_PAYMENT_METHOD_SUCCESS,
  SETTINGS_UPDATE_QUOTE_QUANTITY_SUCCESS,
};
