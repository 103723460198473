export const authResponse = (state) => state.auth.authResponse
export const loading = (state) => state.auth.loading
export const authSignup = (state) => state.auth.authSignup
export const isAuthenticated = (state) => state.auth.isAuthenticated

export const authSelector = {
  loading,
  authResponse,
  authSignup,
  isAuthenticated,
}
