import React from 'react'
import { Icon } from '@common/Icon'
import styled from 'styled-components'

const BladeIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
`

const config = (type) =>
  ({
    CONTAINER: 'SECTION',
    SECTION: 'LIST',
    GROUP: 'REST',
    REST: 'REST',
  }[type.toUpperCase()] || 'SECTION')

const BladeIcon = ({ type }) => {
  const value = type ? type : 'GROUP'

  const matchedIcon = config(value)

  return (
    <BladeIconContainer>
      <Icon name={matchedIcon} size={20} />
    </BladeIconContainer>
  )
}

export { BladeIcon }
