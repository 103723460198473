import React, { useContext, useState, useEffect } from 'react'
import { Icon } from '@common/Icon'
import { values } from 'ramda'
import { LayoutContext, LayoutTypes } from '@features/Layout'
import { useNavigate, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { userSelector } from '@state/user'
import {
  MenuBodyContainer,
  MenuCustomIcon,
  MenuCloseContainer,
  MenuGroup,
  MenuItem,
  MenuIcon,
  MenuText,
  MenuClose,
} from './MenuBody.styled'
import options from '../options'

import { filterOptionsByRole } from '@utils/user'
import { loadState, saveState } from '@utils/storage'

const MenuBody = ({ options = {} }) => {
  const [state, localDispatch] = useContext(LayoutContext)
  const [selectedRoute, setSelectedRoute] = useState('')

  const userRoles = useSelector(userSelector.roles)
  // filter options based on user role

  const match = useLocation()
  const navigate = useNavigate()

  const { menuExpanded: isExpanded } = state

  useEffect(() => {
    if (!!loadState('MENU')) {
      localDispatch({ type: LayoutTypes.OPEN_MENU })
    }
    // eslint-disable-next-line
  }, [])

  const onToggle = () => {
    if (isExpanded) {
      localDispatch({ type: LayoutTypes.CLOSE_MENU })
      saveState({ MENU: false })
    } else {
      localDispatch({ type: LayoutTypes.OPEN_MENU })
      saveState({ MENU: true })
      // remove active exercise ??
    }
  }

  const onSelect = (item) => {
    navigate(item.route)
  }

  const renderBodyClose = () => {
    if (isExpanded) return null
    return (
      <MenuCloseContainer>
        <MenuClose onClick={onToggle}>
          <Icon rotate={isExpanded ? 180 : 0} name="ARROW_RIGHT" size={20} />
        </MenuClose>
      </MenuCloseContainer>
    )
  }

  const getSelectedRoute = (route) => {
    if (route === selectedRoute) return true
  }

  useEffect(() => {
    const matchedPath = match.pathname
    if (matchedPath) setSelectedRoute(matchedPath)
  }, [match])

  const renderBodyOptions = () => {
    const initialOptions = values(options)
    const list = filterOptionsByRole(initialOptions, userRoles)
    if (!list || !list.length) return null

    return (
      <MenuGroup>
        {list.map((item) => (
          <MenuItem
            key={item.id}
            onClick={() => onSelect(item)}
            className="MenuItem"
            isActive={getSelectedRoute(item.route)}
          >
            <MenuIcon>
              <Icon name={item.icon} size={20} />
            </MenuIcon>
            {isExpanded && (
              <MenuText className="MenuText">{item.label}</MenuText>
            )}
            {item.id === 'settings' && isExpanded && (
              <MenuCustomIcon className={item.icon}>
                <Icon name={item.icon} size={20} />
              </MenuCustomIcon>
            )}
          </MenuItem>
        ))}
      </MenuGroup>
    )
  }

  return (
    <MenuBodyContainer
      isExpanded={isExpanded}
      className={isExpanded ? 'isExpanded' : ''}
    >
      {renderBodyClose()}
      {renderBodyOptions()}
    </MenuBodyContainer>
  )
}

MenuBody.defaultProps = {
  options: options,
}

export { MenuBody }
