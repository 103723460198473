import styled from 'styled-components'

const Container = styled.div`
  background: white;
  border-radius: 10px;
  box-shadow: 0px 4px 54px 0px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  width: 100%;
  min-width: 320px;
  max-width: 640px;
  min-height: 450px;
  height: 100%;
  max-height: 450px;
  left: 50%;
  top: 50%;
  position: fixed;
  transition: all 0.2s ease-in-out;
  transform: translate(-50%, -50%);
  z-index: 999;
  display: flex;
  flex-direction: column;
`

const Controls = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-bottom: 16px;
  justify-content: space-between;
`

const FooterControls = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
  input {
    width: 100%;
    padding-top: 8px;
    padding-bottom: 8px;
    margin-top: 16px;
  }
`

const Content = styled.div`
  box-sizing: border-box;
  position: relative;
  height: 100%;
  img.cropped-image {
    width: 100%;
    height: 300px;
    object-fit: cover;
    max-height: 300px;
  }
`

const Header = styled.div`
  width: 100%;
  display: flex;
  padding: 16px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  h2 {
    font-size: 18px;
    margin: 0;
    padding: 0;
    font-weight: 600;
  }
`

export { Container, Controls, FooterControls, Content, Header }
