import { api } from '@state/utils'
import { API } from './api'
import { localVars } from '@features/Auth/Service/config'
import {
  EXERCISES_FETCH_SUCCESS,
  EXERCISES_UPDATE_SUCCESS,
  EXERCISES_CREATE_IMAGE_SUCCESS,
  EXERCISES_CREATE_SUCCESS,
  EXERCISES_DELETE_SUCCESS,
  EXERCISES_BUILDER_FETCH_SUCCESS,
} from './actionTypes'

import {
  exerciseCreateSuccess,
  exerciseUpdateImageSuccess,
  exerciseDeleteSuccess,
} from './data'

import EXERCISES_FETCH_SUCCESS_DATA from './data/EXERCISES_FETCH_SUCCESS.json'
import EXERCISES_BUILDER_FETCH_SUCCESS_DATA from './data/EXERCISES_BUILDER_FETCH_SUCCESS.json'

const mockApi = ({ type, params, body }) => {
  const response = (type) =>
    ({
      [EXERCISES_FETCH_SUCCESS]: EXERCISES_FETCH_SUCCESS_DATA,
      [EXERCISES_BUILDER_FETCH_SUCCESS]: EXERCISES_BUILDER_FETCH_SUCCESS_DATA,
      [EXERCISES_UPDATE_SUCCESS]: { body, params },
      [EXERCISES_DELETE_SUCCESS]: exerciseDeleteSuccess,
      [EXERCISES_CREATE_IMAGE_SUCCESS]: exerciseUpdateImageSuccess,
      [EXERCISES_CREATE_SUCCESS]: exerciseCreateSuccess,
    }[type])

  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(response(type))
    }, 1000)
  })
}

const apiInterceptor = (props) => {
  if (localVars.mock === 'enabled') {
    return mockApi(props)
  } else {
    return api(props)(API)
  }
}

export const fetchExercises = () =>
  apiInterceptor({ type: EXERCISES_FETCH_SUCCESS })

export const fetchBuilderExercises = () =>
  apiInterceptor({ type: EXERCISES_BUILDER_FETCH_SUCCESS })

export const createExercise = (payload) =>
  apiInterceptor({ type: EXERCISES_CREATE_SUCCESS, body: payload })

export const updateExercises = (payload) =>
  apiInterceptor({
    type: EXERCISES_UPDATE_SUCCESS,
    body: payload.body,
    params: payload.params,
  })

export const addImage = (payload) => {
  const form = new FormData()
  form.append('files', payload)
  return apiInterceptor({ type: EXERCISES_CREATE_IMAGE_SUCCESS, body: form })
}

export const removeExercise = (payload) =>
  apiInterceptor({ type: EXERCISES_DELETE_SUCCESS, params: payload })
