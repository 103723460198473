import styled, { css } from 'styled-components'
import { mbFn, mtFn } from '@common/Theme'

const ErrorContainer = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  width: 100%;
  flex-direction: column;
  ${mtFn};
  ${mbFn};
`

const ErrorWrapper = styled.li`
  list-style: none;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 10px 12px 10px 16px;
  min-height: 48px;
  background: #ffe4e6;
  border-radius: 6px;
  margin-bottom: 16px;
  box-sizing: border-box;
  ${(props) =>
    props.error &&
    css`
      background: #ffe4e6;
    `}
  ${(props) =>
    props.success &&
    css`
      background: #19b244;
      .ErrorClose {
        background: transparent;
      }
    `}
`

const ErrorContent = styled.div.attrs({ className: 'ErrorContent' })`
  line-height: 28px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  p {
    font-size: 14px;
    line-height: 21px;
    margin: 0;
    padding: 0;
    ${(props) =>
      props.error &&
      css`
        color: #f72e44;
      `}
    ${(props) =>
      props.success &&
      css`
        color: white;
      `}
  }
`

const ErrorClose = styled.button.attrs({ className: 'ErrorClose' })`
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  padding: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  background: #ffd9dc;
  border-radius: 6px;
  margin-left: auto;
  padding: 6px;
  &:focus {
    outline: none;
  }
`

const ErrorIcon = styled.div`
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export { ErrorContainer, ErrorWrapper, ErrorContent, ErrorClose, ErrorIcon }
