import React, { useEffect, useState } from 'react'
import { string, object } from 'yup'
import { Formik, Field, useFormikContext } from 'formik'
import { InputField } from '@common/InputField'
import { useSelector } from 'react-redux'
import { prop, omit } from 'ramda'
import { plFn, ptFn, mtFn, mbFn, colors } from '@common/Theme'
import { Text } from '@common/Text'
import {
  FlexRow,
  FlexItem,
  FormFeature,
  FormGroup,
  FormControl,
} from './Profile.styled'

import { ImageUploader, ImageRenderer } from '@components/ImageUploader'

import { useDispatch } from 'react-redux'
import {
  resetChannelValidation,
  updateUser,
  validateChannel,
  validateChannelFailure,
} from '@state/user/actions'
import { alter, isEmptyObject } from '@utils/ramda'
import { userSelector } from '@state/user'

import { createUserImage } from '@state/user/actions'
import { Channel } from './Channel'
import { settingsSelector } from '@state/settings'

import { StateView } from '@common/StateView'
import { Block } from '@common/Block'
import { Loading } from '@common/Loading'
import { Button } from '@common/Button'
import { Heading } from '@common/Heading'
import { Icon } from '@common/Icon'

import { Control } from './Control/Control'
import { usePortal as Portal } from '@hooks/usePortal'
import { ErrorList } from './Channel'

import styled from 'styled-components'

const ProfileRow = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;

  ${plFn};
  ${ptFn};
  ${mtFn};
  ${mbFn};
`

const ProfileColumn = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding-left: 24px;
  gap: 16px;
`

const Header = () => (
  <Block.Column mb={32}>
    <Heading size={20} color="#323232" variant="TERTIARY" mb={12}>
      Profile Settings
    </Heading>
    <Text size={16} color="#778092" weight="400">
      Setup your profile information. Some of this information will be what your
      clients see on your custom landing page.
    </Text>
  </Block.Column>
)

const CHANNEL_URL = {
  MIN_LENGTH: 8,
  MAX_LENGTH: 20,
  PATTERN: /^(?=.{8,20}$)(?![_.])(?!.*[_.]{2})[a-zA-Z0-9._]+(?<![_.])$/,
}

const initialProfileDetails = () => ({
  firstName: '',
  lastName: '',
  title: '',
  userStory: '',
  instaHandler: '',
  youtubeHandler: '',
})

const initialProfileURL = () => ({
  uniqueChannelName: '',
})

const SchemaProfileDetails = object().shape({
  firstName: string()
    .max(30, `Your first name is required`)
    .required('Please enter your first name'),
  lastName: string()
    .max(30, `Your last name is required`)
    .required('Please enter your last name'),
  title: string()
    .max(30, `Your title is required`)
    .required('Please enter your title'),
  userStory: string().max(250, `Your story is too long`),
  instaHandler: string().max(20, `Your insta handle is too long`),
  youtubeHandler: string().max(100, `Your youtube handle is too long`),
})

const SchemaProfileURL = object().shape({
  uniqueChannelName: string()
    .required('Please enter your channel page URL')
    .max(CHANNEL_URL.MAX_LENGTH, 'Your channel URL is too long')
    .min(CHANNEL_URL.MIN_LENGTH, 'Your channel URL is too short')
    .matches(CHANNEL_URL.PATTERN, 'Please enter a valid channel URL'),
})

const localValidate = (value) => {
  if (!value || !value.length) return false
  if (value.length < CHANNEL_URL.MIN_LENGTH) return false
  if (value.length > CHANNEL_URL.MAX_LENGTH) return false
  if (!CHANNEL_URL.PATTERN.test(value)) return false
  return true
}

const getFilteredItems =
  (id) =>
  (items = []) =>
    items.filter((k) => k.id !== id)

const ProfileURL = () => {
  const {
    submitForm,
    values: { uniqueChannelName: formikUniqueChannelName },
  } = useFormikContext()
  const loading = useSelector(userSelector.validating)
  const isValidChannel = useSelector(userSelector.isValidChannel)
  const user = useSelector(userSelector.user) || {}
  const firstName = prop('firstName')(user) || ''
  const lastName = prop('lastName')(user) || ''
  const uniqueChannelName = prop('uniqueChannelName')(user) || ''

  const dispatch = useDispatch()

  useEffect(() => {
    if (formikUniqueChannelName === uniqueChannelName) {
      dispatch(resetChannelValidation())
    }
  }, [formikUniqueChannelName])

  const onSave = () => {
    if (!formikUniqueChannelName) {
      return
    }
    if (formikUniqueChannelName === uniqueChannelName) {
      return
    }
    dispatch(
      updateUser({
        uniqueChannelName: formikUniqueChannelName,
      }),
      dispatch(resetChannelValidation()),
    )
  }

  const handleChannelValidation = (e) => {
    e.preventDefault()
    dispatch(validateChannel(formikUniqueChannelName))
  }

  return (
    <>
      {loading && <Loading />}
      <FormGroup>
        <FlexRow>
          <FlexItem>
            <Channel
              prefix="https://aima.fit/"
              name="uniqueChannelName"
              firstName={firstName}
              lastName={lastName}
              value={uniqueChannelName}
              label="Channel URL"
            >
              {!!isValidChannel &&
                uniqueChannelName !== formikUniqueChannelName && (
                  <Text size={14} color="#778092" weight={300}>
                    {!!isValidChannel
                      ? 'This Channel URL is valid and not used by anyone else'
                      : ''}
                  </Text>
                )}
              <ErrorList
                error={
                  !isValidChannel && isValidChannel !== null
                    ? 'Looks like that channel URL is already taken, please try again.'
                    : ''
                }
              />
              <Note
                mt={16}
                text="Use this URL to share on any of your social media platforms.
                This is a direct link to your custom landing page. Use this link to help your clients find you and sign up for your services. "
              ></Note>
            </Channel>
          </FlexItem>
        </FlexRow>
      </FormGroup>
      <FormControl>
        {formikUniqueChannelName !== uniqueChannelName ? (
          <Button
            mt={16}
            type="button"
            disabled={!localValidate(formikUniqueChannelName)}
            onClick={handleChannelValidation}
            label="Validate Channel"
            width="unset"
            variant="black"
          >
            <Icon
              name="SAVE"
              ml={5}
              stroke={
                !localValidate(formikUniqueChannelName)
                  ? 'rgb(169, 174, 185)'
                  : 'white'
              }
            />
          </Button>
        ) : null}
        {!loading &&
          !!isValidChannel &&
          formikUniqueChannelName !== uniqueChannelName && (
            <Button
              mt={16}
              type="button"
              onClick={onSave}
              label="Save"
              width="unset"
              variant="orange"
            >
              <Icon name="SAVE" ml={5} stroke="white" />
            </Button>
          )}
      </FormControl>
    </>
  )
}

const ProfileDetails = () => {
  const user = useSelector(userSelector.user) || {}
  const loading = useSelector(userSelector.loading)
  const { setFieldValue, submitForm } = useFormikContext()

  const firstName = prop('firstName')(user) || ''
  const lastName = prop('lastName')(user) || ''
  const title = prop('title')(user) || ''
  const youtubeHandler = prop('youtubeHandler')(user) || ''
  const instaHandler = prop('instaHandler')(user) || ''
  const userStory = prop('userStory')(user) || ''

  const handleCancel = () => {
    setFieldValue('firstName', firstName)
    setFieldValue('lastName', lastName)
    setFieldValue('title', title)
    setFieldValue('youtubeHandler', youtubeHandler)
    setFieldValue('instaHandler', instaHandler)
    setFieldValue('userStory', userStory)
  }

  useEffect(() => {
    if (!isEmptyObject(user)) {
      setFieldValue('firstName', firstName)
      setFieldValue('lastName', lastName)
      setFieldValue('title', title)
      setFieldValue('youtubeHandler', youtubeHandler)
      setFieldValue('instaHandler', instaHandler)
      setFieldValue('userStory', userStory)
    }
  }, [firstName, lastName, title, youtubeHandler, instaHandler, userStory])

  return (
    <FormFeature>
      <FormGroup>
        <FlexRow>
          <FlexItem>
            <Field
              name="firstName"
              variant="OUTLINE"
              label="First name"
              mb={16}
              placeholder="Enter your first name"
              component={InputField}
            />
            <Field
              name="lastName"
              variant="OUTLINE"
              label="Last name"
              mb={16}
              placeholder="Enter last name"
              component={InputField}
            />
          </FlexItem>
        </FlexRow>
        <FlexRow>
          <FlexItem>
            <Field
              name="title"
              variant="OUTLINE"
              label="Title*"
              mb={16}
              placeholder="Mr. Mrs, Dr., etc."
              component={InputField}
            />
          </FlexItem>
        </FlexRow>
        <FlexRow>
          <FlexItem>
            <Field
              name="instaHandler"
              variant="OUTLINE"
              label="Instagram"
              mb={16}
              placeholder="https://www.instagram.com"
              component={InputField}
            />
            <Field
              name="youtubeHandler"
              variant="OUTLINE"
              label="Youtube"
              mb={16}
              placeholder="https://www.youtube.com"
              component={InputField}
            />
          </FlexItem>
        </FlexRow>
        <FlexRow>
          <FlexItem>
            <Field
              name="userStory"
              label="Your Story*"
              placeholder="A little about you.."
              component={InputField}
              mb={16}
              variant="textarea"
            />
          </FlexItem>
        </FlexRow>
      </FormGroup>
      <FormControl>
        <Button
          mt={16}
          type="button"
          alone
          width="unset"
          onClick={handleCancel}
          label="Cancel"
          variant="outline"
        >
          <Icon name="CANCEL" ml={5} stroke="#868b95" />
        </Button>
        <Button
          mt={16}
          type="button"
          config={{
            layout: {
              alignItems: 'center',
            },
          }}
          onClick={submitForm}
          label="Save"
          alone
          width="unset"
          variant="rgb(119, 128, 146)"
        >
          <Icon name="SAVE" ml={5} stroke="white" />
        </Button>
      </FormControl>
    </FormFeature>
  )
}

const options = [
  {
    id: 'profileDetails',
    label: 'Profile Details',
    value: 'profileDetails',
    index: 0,
    sequence: 0,
    active: true,
    isComplete: false,
  },
  {
    id: 'profileUrl',
    label: 'Channel URL',
    value: 'profileUrl',
    index: 1,
    sequence: 1,
    active: false,
    isComplete: false,
  },
  {
    id: 'profileImage',
    label: 'Profile Image',
    value: 'profileImage',
    index: 2,
    sequence: 2,
    active: false,
    isComplete: false,
  },
  {
    id: 'profileBanner',
    label: 'Profile Banner',
    value: 'profileBanner',
    index: 3,
    sequence: 3,
    active: false,
    isComplete: false,
  },
]

import { BladeMenu } from '@common/BladeMenu'
import { Note } from '@common/Note'

const getOptions = (
  options,
  { email, firstName, lastName, uniqueChannelName },
) => {
  const profileDetails = options.find((k) => k.id === 'profileDetails')
  if (!email || !firstName || !lastName) {
    alter('isComplete', false)(options)
  }
  console.log({ profileDetails })
  return options
}

const Profile = () => {
  const isDevMode = useSelector(settingsSelector.isDevMode)
  const user = useSelector(userSelector.user)
  const isPublished = useSelector(userSelector.isPublished)
  const loading = useSelector(userSelector.loading)
  const images = useSelector(userSelector.images)
  const email = prop('email')(user)
  const firstName = prop('firstName')(user)
  const lastName = prop('lastName')(user)
  const uniqueChannelName = prop('uniqueChannelName')(user)
  const [controlledOptions, setOptions] = useState(options)
  const dispatch = useDispatch()

  const [state, setState] = useState({
    activeId: 'profileDetails',
  })

  useEffect(() => {
    // const options = getOptions(options, { email, firstName, lastName })
    setOptions(options)
  }, [email, firstName, lastName, uniqueChannelName])

  const handleUploadBanner = ({ value }) => {
    dispatch(createUserImage(value))
  }

  const handleUploadProfile = ({ value }) => {
    dispatch(createUserImage(value))
  }

  const handleImageCallback = ({ action, value }) => {
    if (action === 'DELETE_IMAGE') {
      const payload = {
        ...user,
        images: getFilteredItems(prop('id')(value))(images),
      }

      if (isEmptyObject(user) || isEmptyObject(user)) {
        console.warn('No user found in [Profile.js]')
        return
      }

      dispatch(updateUser(omit(['uniqueChannelName'])(payload)))
    }
  }

  const submitProfileDetails = (values) => {
    console.log(values)
    if (!values.firstName || !values.lastName) {
      return
    }
    dispatch(updateUser(values))
  }

  const submitProfileURL = (values) => {
    console.log(values)
  }

  const handleCallback = ({ value }) => {
    setState((state) => ({ ...state, activeId: value }))
  }

  return (
    <Block.Account>
      <Portal selector="#header-portal">
        <Control />
      </Portal>

      <Header />
      <BladeMenu
        data={controlledOptions}
        name="profile"
        callback={handleCallback}
        activeId={state.activeId}
        label="PERSONAL SETTINGS"
      >
        {state.activeId === 'profileBanner' && (
          <>
            <ImageUploader
              variant="banner"
              loading={loading}
              label="Add Banner Image"
              images={!!images ? images : []}
              callback={handleUploadBanner}
            >
              <ImageRenderer
                variant="banner"
                parentId={email}
                config={{
                  controlType: 'default',
                }}
                images={!!images ? images : []}
                kind="user"
                callback={handleImageCallback}
              />
            </ImageUploader>
          </>
        )}
        {state.activeId === 'profileImage' && (
          <>
            <FormGroup>
              <ProfileRow>
                <ImageUploader
                  variant="thumbnail"
                  loading={loading}
                  images={!!images ? images : []}
                  callback={handleUploadProfile}
                >
                  <ImageRenderer
                    variant="thumbnail"
                    parentId={email}
                    config={{
                      controlType: 'swap',
                    }}
                    images={!!images ? images : []}
                    kind="user"
                    callback={handleImageCallback}
                  />
                </ImageUploader>
                <ProfileColumn>
                  <ProfileRow>
                    {firstName && (
                      <>
                        <Text
                          size={14}
                          weight={300}
                          mr={5}
                          color="rgb(119, 128, 146)"
                        >
                          First Name:
                        </Text>
                        <Text size={14} weight={500}>
                          {firstName}
                        </Text>
                      </>
                    )}
                  </ProfileRow>
                  <ProfileRow>
                    {lastName && (
                      <>
                        <Text
                          size={14}
                          weight={300}
                          mr={5}
                          color="rgb(119, 128, 146)"
                        >
                          Last Name:
                        </Text>
                        <Text ml={5} size={14} weight={500}>
                          {lastName}
                        </Text>
                      </>
                    )}
                  </ProfileRow>
                  <ProfileRow>
                    {email && (
                      <>
                        <Text
                          size={14}
                          weight={300}
                          color="rgb(119, 128, 146)"
                          mr={5}
                        >
                          Email:
                        </Text>
                        <Text size={14} weight={500}>
                          {email}
                        </Text>
                      </>
                    )}
                  </ProfileRow>
                  <ProfileRow>
                    <Text size={14} weight={300} color="rgb(119, 128, 146)">
                      Published:{' '}
                    </Text>
                    <Text size={14} ml={5} weight={500} color={colors.black}>
                      {isPublished ? 'Yes' : 'No'}
                    </Text>
                  </ProfileRow>
                </ProfileColumn>
              </ProfileRow>
            </FormGroup>
          </>
        )}
        {state.activeId === 'profileDetails' && (
          <Formik
            initialValues={initialProfileDetails()}
            validateOnChange
            validationSchema={SchemaProfileDetails}
            onSubmit={submitProfileDetails}
          >
            {() => <ProfileDetails />}
          </Formik>
        )}
        {state.activeId === 'profileUrl' && (
          <Formik
            initialValues={initialProfileURL()}
            validateOnChange
            validationSchema={SchemaProfileURL}
            onSubmit={submitProfileURL}
          >
            {() => <ProfileURL />}
          </Formik>
        )}
      </BladeMenu>

      {isDevMode && (
        <StateView
          mt={16}
          state={{
            ...state,
          }}
        />
      )}
    </Block.Account>
  )
}

export { Profile }
