import React, { useEffect } from 'react'
import { Grid, Row, Col } from '@common/Grid'
import { StateView } from '@common/StateView'
import { useSelector } from '@hooks/useSelector'
import { settingsSelector } from '@state/settings'
import { fetchMe, userSelector } from '@state/user'
// import ClientTable from './ClientTable'
// import { ClientTable } from './ClientTable'
import styled from 'styled-components'
import { media } from '@common/Theme'

const Clients = () => {
  const isDevMode = useSelector(settingsSelector.isDevMode)
  const user = useSelector(userSelector.user)
  return (
    <>
      <Grid marginX={0} fluid>
        <Row>
          <Col xs={12} sm={12} md={12}></Col>
          {/* <ClientTable /> */}
          <Col xs={12} sm={8} md={8}></Col>
        </Row>
      </Grid>
      {isDevMode && <StateView state={{ user }} />}
    </>
  )
}

export { Clients }
