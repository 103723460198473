import { call, put } from 'redux-saga/effects'
import {
  requestGetActiveSubscriptions,
  requestGetActivePrices,
  requestUpdateQuoteQuantity,
  requestUpdateQuotePrice,
  requestGenerateDraftQuote,
  requestCreatePaymentMethod,
  requestGetAllPaymentMethods,
  requestQuoteApplyCoupon,
} from './requests'
import {
  getActiveSubscriptionsSuccess,
  getActivePricesSuccess,
  updateQuoteQuantitySuccess,
  updateQuotePriceSuccess,
  generateDraftQuoteSuccess,
  createPaymentMethodSuccess,
  getAllPaymentMethodsSuccess,
  updateQuoteApplyCouponSuccess,
} from './actions/success'
import {
  getActiveSubscriptionsFailure,
  getActivePricesFailure,
  updateQuoteQuantityFailure,
  updateQuotePriceFailure,
  generateDraftQuoteFailure,
  createPaymentMethodFailure,
  getAllPaymentMethodsFailure,
  updateQuoteApplyCouponFailure,
} from './actions/failure'

export function* onFetchActiveSubscriptions() {
  try {
    const response = yield call(requestGetActiveSubscriptions)
    if (response && response.data)
      yield put(getActiveSubscriptionsSuccess(response.data))
  } catch (error) {
    yield put(getActiveSubscriptionsFailure({ error }))
  }
}

export function* onFetchActivePrices() {
  try {
    const response = yield call(requestGetActivePrices)
    if (response && response.data)
      yield put(getActivePricesSuccess(response.data))
  } catch (error) {
    yield put(getActivePricesFailure({ error }))
  }
}

export function* onUpdateQuoteQuantity({ payload }) {
  try {
    const response = yield call(requestUpdateQuoteQuantity, {
      body: payload,
    })
    if (response && response.data)
      yield put(updateQuoteQuantitySuccess(response.data))
  } catch (error) {
    yield put(updateQuoteQuantityFailure({ error }))
  }
}
export function* onUpdateQuotePrice({ payload }) {
  try {
    const response = yield call(requestUpdateQuotePrice, {
      body: payload,
    })
    if (response && response.data)
      yield put(updateQuotePriceSuccess(response.data))
  } catch (error) {
    yield put(updateQuotePriceFailure({ error }))
  }
}
export function* onGenerateDraftQuote({ payload }) {
  try {
    const response = yield call(requestGenerateDraftQuote, {
      body: payload,
    })
    if (response && response.data)
      yield put(generateDraftQuoteSuccess(response.data))
  } catch (error) {
    yield put(generateDraftQuoteFailure({ error }))
  }
}
export function* onCreatePaymentMethod({ payload }) {
  try {
    const response = yield call(requestCreatePaymentMethod, {
      body: payload,
    })
    if (response && response.data)
      yield put(createPaymentMethodSuccess(response.data))
  } catch (error) {
    yield put(createPaymentMethodFailure({ error }))
  }
}

export function* onFetchAllPaymentMethods() {
  try {
    const response = yield call(requestGetAllPaymentMethods)
    if (response && response.data)
      yield put(getAllPaymentMethodsSuccess(response.data))
  } catch (error) {
    yield put(getAllPaymentMethodsFailure({ error }))
  }
}

export function* onUpdateQuoteApplyCoupon({ payload }) {
  try {
    const response = yield call(requestQuoteApplyCoupon, {
      body: payload,
    })
    if (response && response.data)
      yield put(updateQuoteApplyCouponSuccess(response.data))
  } catch (error) {
    yield put(updateQuoteApplyCouponFailure({ error }))
  }
}
