import React from 'react'
import styled, { css } from 'styled-components'
import {
  basicStyles,
  basicFunctions,
  basicText,
  pseudoStyles,
} from './buttonStyles'
import { mtFn, mbFn, mrFn, mlFn } from '@common/Theme'

const disabledFn = ({ disabled }) =>
  disabled &&
  css`
    border: solid 1px transparent;
    background: #f5f5f8;
    cursor: not-allowed;
    color: #d6d5e4;
    &:focus {
      border: solid 1px transparent;
    }
    &:hover {
      background: #f5f5f8;
    }
  `

const PrimaryStyle = styled.button`
  ${basicStyles};
  ${basicText};
  ${pseudoStyles}
  ${basicFunctions};
  ${mtFn};
  ${mbFn};
  ${mrFn};
  ${mlFn};
  ${disabledFn};
  background-color: red;
  padding: 12px 16px;
  background: #ff5a45;
  border-radius: 8px;
  font-weight: 500;
  transition: all 0.2s ease-in-out;
  color: white;
  &:hover {
    background: #ff5a45;
  }
`

const Primary = ({ style, children, ...props }) => (
  <PrimaryStyle style={style} {...props}>
    {children}
  </PrimaryStyle>
)

export { Primary }
