import React from 'react'
import PropTypes from 'prop-types'
import { prop } from 'ramda'
import { Spinner } from '@common/Spinner'

import { Container, Content } from '../Modal.styled'

const LoadingContent = ({ style = {}, dev, level, data }) => {
  const message = prop('message')(data) || {}
  return (
    <Container style={style || {}} dev={dev} level={level}>
      <Spinner circle={`#A9AEB9`} path={`#FF5A45`} />
      {message && <Content>{message}</Content>}
    </Container>
  )
}

LoadingContent.defaultProps = {
  data: {
    message: `Are you sure you want to skip this workout? Skipping workouts affects your program and overall workout adherance score`,
  },
  style: {},
  dev: false,
  level: 2,
}

LoadingContent.propTypes = {
  data: PropTypes.object,
  style: PropTypes.object,
  dev: PropTypes.bool,
  level: PropTypes.number,
}

export default LoadingContent
