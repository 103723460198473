import styled from 'styled-components'
const GroupLower = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  background: transparent;
  border-radius: 6px;
  gap: 24px;
  flex-direction: row;
  flex-wrap: wrap;
`

export { GroupLower }
