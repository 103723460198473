import { reject } from 'ramda';
import Types from './Types';
import { prop } from 'ramda';

const initialState = {
  items: [
    {
      id: 'exercise1',
      type: 'WARMUP',
      weight: 20,
      reps: 50,
      rest: 45,
      creationDate: new Date(),
    },
  ],
};

const editSet =
  (data = {}) =>
  state => {
    const id = prop('id')(data);
    const { name, value } = prop('payload')(data);

    const result = state.reduce((prev, curr) => {
      if (curr.id === id) {
        const updatedItem = {
          ...curr,
          [name]: value,
        };
        return [...prev, updatedItem];
      }
      return [...prev, curr];
    }, []);

    return result;
  };

const Reducer = (state = {}, action) => {
  const { type, data } = action;
  switch (type) {
    case Types.ADD_SET:
      return {
        ...state,
        items: [...state.items, data],
      };
    case Types.REORDER_SET:
      return {
        ...state,
        items: data,
      };
    case Types.EDIT_SET:
      const foundSet = state.items.find(f => f.id === data.id);
      if (!foundSet) return state;

      return {
        ...state,
        items: editSet(data)(state.items),
      };
    case Types.REMOVE_SET:
      return {
        ...state,
        items: reject(o => o.id === data, state.items),
      };

    default:
      return state;
  }
};

export { initialState };

export default Reducer;
