import React, { useContext, useEffect } from 'react'
import { pipe, insert, remove } from 'ramda'
import { DraggableRow } from './DraggableRow'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { DraggableContainer } from './DraggableExercise.styled'
// import { DraggableHeader } from './DraggableHeader';
import styled, { css } from 'styled-components'
import { Types, Context } from '../Provider'

const DragItem = styled.div`
  border-top: 1px solid transparent;
  ${(props) =>
    props.isDragging &&
    css`
      border-top: 1px solid transparent;
    `}
  &:last-of-type {
    border-bottom: 1px solid transparent;
  }
`

const reorder = ({ list, start, end }) => {
  const movedItem = list[start.index]
  const result = pipe(
    remove(start.index, 1),
    insert(end.index, movedItem),
  )(list)

  return result
}

const getStyles = (style = {}) => ({
  ...style,
})

const DraggableExercise = () => {
  const [localState, localDispatch] = useContext(Context)

  const { items = [] } = localState

  const onRemove = (id) => {
    localDispatch({
      type: Types.REMOVE_SET,
      data: id,
    })
  }

  const onDragEnd = (result) => {
    const source = result.source
    const destination = result.destination

    const currentCollection = items

    const nextCollection = reorder({
      list: currentCollection,
      start: source,
      end: destination,
    })

    localDispatch({
      type: Types.REORDER_SET,
      data: nextCollection,
    })
  }

  const handleCallback = ({ id, name, value }) => {
    localDispatch({
      type: Types.EDIT_SET,
      data: {
        id,
        payload: { name, value },
      },
    })
  }

  useEffect(() => {
    localDispatch({
      type: Types.REORDER_SET,
      data: items,
    })
  }, [items])

  if (!items || items.length <= 0) return null

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(droppableProvided, droppableSnapshot) => (
          <DraggableContainer
            ref={droppableProvided.innerRef}
            isDraggingOver={droppableSnapshot.isDraggingOver}
          >
            {items.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id} index={index}>
                {(draggableProvided, draggableSnapshot) => (
                  <DragItem
                    ref={draggableProvided.innerRef}
                    {...draggableProvided.draggableProps}
                    {...draggableProvided.dragHandleProps}
                    style={getStyles(draggableProvided.draggableProps.style)}
                    isDragging={draggableSnapshot.isDragging}
                  >
                    <DraggableRow
                      data={item}
                      onRemove={onRemove}
                      callback={handleCallback}
                      index={index + 1}
                      isDragging={draggableSnapshot.isDragging}
                    />
                  </DragItem>
                )}
              </Draggable>
            ))}
            {droppableProvided.placeholder}
          </DraggableContainer>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export { DraggableExercise }
