import React from 'react'
import { mtFn, mbFn } from '@common/Theme'
import styled, { css } from 'styled-components'

const NoteGroup = styled.div`
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  ${mtFn};
  ${mbFn};
  gap: 16px;
  .NoteItem {
    border-radius: 4px;
    ${(props) =>
      props.hasLength &&
      css`
        &:first-child {
          border-radius: 4px;
        }
      `}
    &:not(:first-child) {
      margin-bottom: 0;
      margin-top: 8px;
    }
    &:last-of-type {
      border-bottom: 1px solid rgb(51, 59, 68);
    }
  }
`

const NoteProvider = ({ children, ...props }) => {
  const hasLength = children && children.length && children.length > 1

  return (
    <NoteGroup hasLength={hasLength} {...props}>
      {children}
    </NoteGroup>
  )
}

export { NoteProvider }
