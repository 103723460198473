import React from 'react'

const LogoFull = ({ width = 38, height = 38 }) => (
  <div className="logo-full">
    <svg width="97" height="34" viewBox="0 0 97 34" fill="none">
      <path
        d="M12.8767 6.33842C13.2625 5.55725 14.0582 5.06274 14.9295 5.06274C16.6214 5.06274 17.7288 6.8348 16.9872 8.35556L9.43147 23.8495C9.04061 24.651 8.22693 25.1595 7.3352 25.1595C5.6065 25.1595 4.47862 23.3446 5.24405 21.7946L12.8767 6.33842Z"
        fill="#FF5A45"
      />
      <path
        d="M17.7042 11.3491C18.0958 10.5394 18.916 10.0249 19.8155 10.0249C21.5455 10.0249 22.68 11.8339 21.9269 13.3913L16.876 23.8353C16.4844 24.6451 15.6642 25.1595 14.7647 25.1595C13.0348 25.1595 11.9002 23.3506 12.6534 21.7932L17.7042 11.3491Z"
        fill="#FF5A45"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.3247 20.1975C21.3792 20.1975 20.5103 20.742 20.0931 21.5836C19.2824 23.2188 20.4676 25.1597 22.3045 25.1597C23.25 25.1597 24.1189 24.6152 24.5361 23.7735C25.3468 22.1384 24.1616 20.1975 22.3247 20.1975Z"
        fill="#FF5A45"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49.8869 5C48.9865 5 48.159 5.51857 47.7616 6.32013C46.9895 7.87744 48.1183 9.72588 49.8678 9.72588C50.7682 9.72588 51.5957 9.2073 51.9931 8.40575C52.7651 6.84844 51.6364 5 49.8869 5Z"
        fill="#121B2D"
      />
      <path
        d="M40.9232 13.8767V13.6304C40.9414 13.1743 41.1331 12.782 41.4981 12.4536C41.8814 12.107 42.3286 11.9337 42.8397 11.9337C43.369 11.9337 43.8162 12.0979 44.1813 12.4263C44.5646 12.7547 44.7563 13.156 44.7563 13.6304V23.2907C44.7563 23.765 44.5646 24.1664 44.1813 24.4948C43.8162 24.8232 43.369 24.9874 42.8397 24.9874C42.3286 24.9874 41.8814 24.8232 41.4981 24.4948C41.1331 24.1482 40.9414 23.7468 40.9232 23.2907V23.0444C40.5216 23.7012 39.9649 24.212 39.253 24.5769C38.5594 24.9235 37.8384 25.0969 37.0901 25.0969C35.3743 25.0969 33.905 24.5039 32.682 23.3181C31.4773 22.1139 30.875 20.4902 30.875 18.4469C30.875 16.4218 31.4773 14.8163 32.682 13.6304C33.905 12.4263 35.3743 11.8242 37.0901 11.8242C37.6742 11.8242 38.3404 11.9793 39.0888 12.2894C39.8371 12.5996 40.4486 13.1287 40.9232 13.8767ZM37.8293 21.7034C38.6872 21.7034 39.4173 21.3842 40.0197 20.7456C40.6403 20.0888 40.9506 19.3317 40.9506 18.4742C40.9506 17.6168 40.6403 16.8687 40.0197 16.2302C39.4173 15.5734 38.6872 15.245 37.8293 15.245C36.9167 15.245 36.1866 15.5643 35.639 16.2028C35.0914 16.8231 34.8176 17.5803 34.8176 18.4742C34.8176 19.3682 35.0914 20.1345 35.639 20.773C36.1866 21.3933 36.9167 21.7034 37.8293 21.7034Z"
        fill="#121B2D"
      />
      <path
        d="M51.8075 13.6305V23.2908C51.8075 23.7651 51.6158 24.1665 51.2325 24.4949C50.8675 24.8233 50.4203 24.9875 49.8909 24.9875C49.3799 24.9875 48.9327 24.8233 48.5494 24.4949C48.1843 24.1483 47.9926 23.7469 47.9744 23.2908V13.6305C47.9926 13.1744 48.1843 12.7822 48.5494 12.4538C48.9327 12.1072 49.3799 11.9338 49.8909 11.9338C50.4203 11.9338 50.8675 12.098 51.2325 12.4264C51.6158 12.7548 51.8075 13.1562 51.8075 13.6305Z"
        fill="#121B2D"
      />
      <path
        d="M75.1568 17.5985V23.2907C75.1568 23.765 74.9652 24.1664 74.5818 24.4948C74.2168 24.8232 73.7696 24.9874 73.2403 24.9874C72.7292 24.9874 72.282 24.8232 71.8987 24.4948C71.5336 24.1482 71.342 23.7468 71.3237 23.2907V18.0364C71.3237 17.2336 71.1138 16.6133 70.694 16.1755C70.2924 15.7194 69.7905 15.4913 69.1881 15.4913C68.5493 15.4913 68.0291 15.7102 67.6275 16.1481C67.226 16.5677 67.0252 17.2154 67.0252 18.0911V23.2907C67.0252 23.765 66.8335 24.1664 66.4502 24.4948C66.0852 24.8232 65.638 24.9874 65.1086 24.9874C64.5976 24.9874 64.1504 24.8232 63.7671 24.4948C63.402 24.1482 63.2103 23.7468 63.1921 23.2907V17.9543C63.1921 17.188 62.9822 16.586 62.5624 16.1481C62.1608 15.7102 61.668 15.4913 61.0839 15.4913C60.445 15.4913 59.9157 15.7011 59.4959 16.1207C59.0761 16.5221 58.8662 17.1789 58.8662 18.0911V23.2907C58.8662 23.765 58.6745 24.1664 58.2912 24.4948C57.9262 24.8232 57.479 24.9874 56.9496 24.9874C56.4386 24.9874 55.9914 24.8232 55.6081 24.4948C55.243 24.1482 55.0513 23.7468 55.0331 23.2907V13.6304C55.0513 13.1743 55.243 12.7821 55.6081 12.4537C55.9914 12.107 56.4386 11.9337 56.9496 11.9337C57.479 11.9337 57.9262 12.0979 58.2912 12.4263C58.6745 12.7547 58.8662 13.1561 58.8662 13.6304V13.9314C59.1582 13.2746 59.6054 12.7638 60.2078 12.3989C60.8284 12.0158 61.4946 11.8242 62.2064 11.8242C63.2469 11.8242 64.1047 12.0158 64.7801 12.3989C65.4554 12.7638 66.003 13.375 66.4228 14.2325C66.8427 13.3385 67.4085 12.7091 68.1204 12.3442C68.8322 11.9793 69.5076 11.7969 70.1464 11.7969C71.6979 11.7969 72.9117 12.2895 73.7878 13.2747C74.6822 14.2598 75.1386 15.7011 75.1568 17.5985Z"
        fill="#121B2D"
      />
      <path
        d="M87.7138 13.8767V13.6304C87.7321 13.1743 87.9237 12.782 88.2888 12.4536C88.6721 12.107 89.1193 11.9337 89.6304 11.9337C90.1597 11.9337 90.6069 12.0979 90.9719 12.4263C91.3552 12.7547 91.5469 13.156 91.5469 13.6304V23.2907C91.5469 23.765 91.3552 24.1664 90.9719 24.4948C90.6069 24.8232 90.1597 24.9874 89.6304 24.9874C89.1193 24.9874 88.6721 24.8232 88.2888 24.4948C87.9237 24.1482 87.7321 23.7468 87.7138 23.2907V23.0444C87.3123 23.7012 86.7555 24.212 86.0437 24.5769C85.3501 24.9235 84.6291 25.0969 83.8807 25.0969C82.165 25.0969 80.6956 24.5039 79.4727 23.3181C78.268 22.1139 77.6656 20.4902 77.6656 18.4469C77.6656 16.4218 78.268 14.8163 79.4727 13.6304C80.6956 12.4263 82.165 11.8242 83.8807 11.8242C84.4648 11.8242 85.131 11.9793 85.8794 12.2894C86.6278 12.5996 87.2392 13.1287 87.7138 13.8767ZM84.62 21.7034C85.4778 21.7034 86.208 21.3842 86.8103 20.7456C87.4309 20.0888 87.7412 19.3317 87.7412 18.4742C87.7412 17.6168 87.4309 16.8687 86.8103 16.2302C86.208 15.5734 85.4778 15.245 84.62 15.245C83.7073 15.245 82.9772 15.5643 82.4296 16.2028C81.882 16.8231 81.6082 17.5803 81.6082 18.4742C81.6082 19.3682 81.882 20.1345 82.4296 20.773C82.9772 21.3933 83.7073 21.7034 84.62 21.7034Z"
        fill="#121B2D"
      />
    </svg>
  </div>
)

export default LogoFull
