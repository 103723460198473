import { takeLatest } from 'redux-saga/effects'

import {
  EXERCISES_FETCH_PENDING,
  EXERCISES_UPDATE_PENDING,
  EXERCISES_CREATE_PENDING,
  EXERCISES_CREATE_IMAGE_PENDING,
  EXERCISES_UPDATE_IMAGE_PENDING,
  EXERCISE_DELETE_IMAGE_PENDING,
  EXERCISE_DELETE_UPDATE_IMAGE_PENDING,
  // EXERCISES_DELETE_SUCCESS,
  EXERCISES_DELETE_PENDING,
  EXERCISES_BUILDER_FETCH_PENDING,
} from './exercise/actionTypes'

import {
  WORKOUTS_CREATE_PENDING,
  WORKOUTS_FETCH_PENDING,
  WORKOUTS_UPDATE_PENDING,
  WORKOUTS_DELETE_PENDING,
  WORKOUTS_BY_ID_PENDING,
  WORKOUTS_CREATE_IMAGE_PENDING,
  WORKOUTS_UPDATE_LISTEN_PENDING,
  WORKOUTS_UPDATE_IMAGE_PENDING,
  // WORKOUTS_DELETE_UPDATE_IMAGE_PENDING,
} from './workout/actionTypes'

import {
  MUSCLES_FETCH_PENDING,
  EQUIPMENT_FETCH_PENDING,
} from './master/actionTypes'

import {
  PREFERENCES_FETCH_PENDING,
  PREFERENCES_SAVE_BOOKMARK_PENDING,
  PREFERENCES_REMOVE_BOOKMARK_PENDING,
  USER_FETCH_ME_PENDING,
  USER_UPDATE_PENDING,
  USER_UPDATE_SUCCESS,
  USER_CREATE_IMAGE_PENDING,
  USER_FETCH_ME_SUCCESS,
  USER_VALIDATE_CHANNEL_PENDING,
} from './user/actionTypes'

import {
  SETTINGS_GET_ACTIVE_SUBSCRIPTIONS_PENDING,
  SETTINGS_GET_ACTIVE_PRICES_PENDING,
  SETTINGS_UPDATE_QUOTE_QUANTITY_PENDING,
  SETTINGS_UPDATE_QUOTE_PRICE_PENDING,
  SETTINGS_GENERATE_DRAFT_QUOTE_PENDING,
  SETTINGS_CREATE_PAYMENT_METHOD_PENDING,
  SETTINGS_CREATE_PAYMENT_METHOD_SUCCESS,
  SETTINGS_GET_ALL_PAYMENT_METHODS_PENDING,
  SETTINGS_UPDATE_QUOTE_APPLY_COUPON_PENDING,
} from './settings/actionTypes'

import {
  onFetchExercises,
  onFetchBuilderExercises,
  onUpdateExercise,
  onUpdateExerciseImage,
  onCreateExercise,
  onCreateExerciseImage,
  onDeleteExerciseImage,
  onDeleteUpdateExerciseImage,
  onDeleteExercise,
} from './exercise/saga'

import {
  onFetchWorkouts,
  onDeleteWorkout,
  onCreateWorkout,
  onCreateWorkoutImage,
  onUpdateWorkout,
  onUpdateWorkoutImage,
  onGetWorkoutById,
  onWorkoutUpdateListen,
} from './workout/saga'

import {
  onFetchPreferences,
  onSaveExerciseBookmark,
  onRemoveExerciseBookmark,
  onFetchMe,
  onUpdateUser,
  onCreateUserImage,
  onValidateChannel,
} from './user/saga'

import {
  onFetchActiveSubscriptions,
  onFetchActivePrices,
  onUpdateQuotePrice,
  onUpdateQuoteQuantity,
  onGenerateDraftQuote,
  onCreatePaymentMethod,
  onFetchAllPaymentMethods,
  onUpdateQuoteApplyCoupon,
} from './settings/saga'

import {
  AUTH_PENDING,
  AUTH_SIGNIN_PENDING,
  AUTH_SIGNIN_SUCCESS,
  AUTH_SIGNUP_PENDING,
  AUTH_SUCCESS,
} from './auth/actionTypes'

import {
  onAuthenticate,
  onAuthenticateSignin,
  onAuthenticateSignup,
} from './auth/saga'

import { onFetchMuscles, onFetchEquipment } from './master/saga'

// watcher saga: watches for actions dispatched to the store, starts worker saga
export function* watcherSaga() {
  // watches for exercise action dispatch
  yield takeLatest(EXERCISES_FETCH_PENDING, onFetchExercises)
  yield takeLatest(EXERCISES_BUILDER_FETCH_PENDING, onFetchBuilderExercises)
  yield takeLatest(EXERCISES_UPDATE_PENDING, onUpdateExercise)
  yield takeLatest(EXERCISES_CREATE_PENDING, onCreateExercise)
  yield takeLatest(EXERCISES_DELETE_PENDING, onDeleteExercise)
  // watches for exercise image action dispatches
  yield takeLatest(EXERCISES_CREATE_IMAGE_PENDING, onCreateExerciseImage) // ----- IMAGE
  yield takeLatest(EXERCISES_UPDATE_IMAGE_PENDING, onUpdateExerciseImage)
  yield takeLatest(EXERCISE_DELETE_IMAGE_PENDING, onDeleteExerciseImage)
  yield takeLatest(
    EXERCISE_DELETE_UPDATE_IMAGE_PENDING,
    onDeleteUpdateExerciseImage,
  )
  // watches for master action dispatches
  yield takeLatest(MUSCLES_FETCH_PENDING, onFetchMuscles)
  yield takeLatest(EQUIPMENT_FETCH_PENDING, onFetchEquipment)

  // watches for workout action dispatches
  yield takeLatest(WORKOUTS_FETCH_PENDING, onFetchWorkouts)
  yield takeLatest(WORKOUTS_CREATE_PENDING, onCreateWorkout)
  yield takeLatest(WORKOUTS_UPDATE_PENDING, onUpdateWorkout)
  yield takeLatest(WORKOUTS_UPDATE_IMAGE_PENDING, onUpdateWorkoutImage)
  yield takeLatest(WORKOUTS_UPDATE_LISTEN_PENDING, onWorkoutUpdateListen)

  yield takeLatest(WORKOUTS_CREATE_IMAGE_PENDING, onCreateWorkoutImage)
  yield takeLatest(USER_CREATE_IMAGE_PENDING, onCreateUserImage)
  yield takeLatest(WORKOUTS_DELETE_PENDING, onDeleteWorkout)
  yield takeLatest(WORKOUTS_BY_ID_PENDING, onGetWorkoutById)

  // watches for user action dispatches
  yield takeLatest(USER_UPDATE_PENDING, onUpdateUser)
  // yield takeLatest(USER_FETCH_ME_SUCCESS, onUpdateUser)
  yield takeLatest(USER_FETCH_ME_PENDING, onFetchMe)
  yield takeLatest(USER_VALIDATE_CHANNEL_PENDING, onValidateChannel)
  yield takeLatest(PREFERENCES_FETCH_PENDING, onFetchPreferences)
  yield takeLatest(PREFERENCES_SAVE_BOOKMARK_PENDING, onSaveExerciseBookmark)
  yield takeLatest(
    PREFERENCES_REMOVE_BOOKMARK_PENDING,
    onRemoveExerciseBookmark,
  )
  yield takeLatest(
    SETTINGS_GET_ACTIVE_SUBSCRIPTIONS_PENDING,
    onFetchActiveSubscriptions,
  )
  yield takeLatest(SETTINGS_GET_ACTIVE_PRICES_PENDING, onFetchActivePrices)
  yield takeLatest(
    SETTINGS_UPDATE_QUOTE_QUANTITY_PENDING,
    onUpdateQuoteQuantity,
  )
  yield takeLatest(SETTINGS_UPDATE_QUOTE_PRICE_PENDING, onUpdateQuotePrice)
  yield takeLatest(SETTINGS_GENERATE_DRAFT_QUOTE_PENDING, onGenerateDraftQuote)
  yield takeLatest(
    SETTINGS_CREATE_PAYMENT_METHOD_PENDING,
    onCreatePaymentMethod,
  )
  yield takeLatest(
    SETTINGS_CREATE_PAYMENT_METHOD_SUCCESS,
    onFetchAllPaymentMethods,
  )

  yield takeLatest(
    SETTINGS_GET_ALL_PAYMENT_METHODS_PENDING,
    onFetchAllPaymentMethods,
  )
  yield takeLatest(
    SETTINGS_UPDATE_QUOTE_APPLY_COUPON_PENDING,
    onUpdateQuoteApplyCoupon,
  )
  yield takeLatest(AUTH_PENDING, onAuthenticate)
  yield takeLatest(AUTH_SIGNIN_PENDING, onAuthenticateSignin)
  // yield takeLatest(USER_UPDATE_SUCCESS, onFetchMe)
  // yield takeLatest(AUTH_SUCCESS, onFetchMe)
  // yield takeLatest(AUTH_SIGNIN_SUCCESS, onFetchMe)
  yield takeLatest(AUTH_SIGNUP_PENDING, onAuthenticateSignup)
}
