import {
  WORKOUTS_FETCH_PENDING,
  WORKOUTS_FETCH_SUCCESS,
  WORKOUTS_FETCH_FAILURE,
  WORKOUTS_UPDATE_SUCCESS,
  WORKOUTS_REQUEST_PENDING,
  WORKOUTS_UPDATE_FAILURE,
  WORKOUTS_UPDATE_PENDING,
  WORKOUTS_SET_FILTERS,
  WORKOUTS_SET_ACTIVE_SUCCESS,
  WORKOUTS_CREATE_SUCCESS,
  WORKOUTS_CREATE_FAILURE,
  WORKOUTS_DELETE_FAILURE,
  WORKOUTS_DELETE_SUCCESS,
  WORKOUTS_DELETE_PENDING,
  WORKOUTS_BY_ID_SUCCESS,
  WORKOUTS_UPDATE_IMAGE_SUCCESS,
  WORKOUTS_DELETE_IMAGE_SUCCESS,
  WORKOUTS_CREATE_IMAGE_PENDING,
  WORKOUTS_CLEAR_IMAGE,
  UPDATE_ACTIVE_WORKOUT,
  WORKOUTS_UPDATE_LISTEN_FAILURE,
} from './actionTypes'
import { activeWorkout } from './selectors'

const initialState = {
  workouts: [],
  filteredWorkouts: [],
  activeWorkout: null, // in original structure
  loading: false,
  error: '',
  images: [],
}

export default function workoutReducer(state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case WORKOUTS_REQUEST_PENDING:
      return { ...state, loading: true, error: null }

    case WORKOUTS_FETCH_PENDING:
      return {
        ...state,
        loading: true,
      }

    case WORKOUTS_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        workouts: action.payload,
      }

    case WORKOUTS_SET_FILTERS:
      return {
        ...state,
        filteredWorkouts: action.payload,
      }

    case WORKOUTS_SET_ACTIVE_SUCCESS:
      return { ...state, activeWorkout: action.payload }

    case WORKOUTS_UPDATE_PENDING:
      return { ...state, loading: true }

    case WORKOUTS_UPDATE_SUCCESS:
      return {
        ...state,
        activeWorkout: action.payload,
        loading: false,
      }

    case WORKOUTS_CREATE_SUCCESS:
      return {
        ...state,
        workouts: [...state.workouts, action.payload],
      }

    case WORKOUTS_DELETE_PENDING:
      return {
        ...state,
        loading: true,
        workouts: state.workouts,
      }

    case WORKOUTS_DELETE_SUCCESS:
      return {
        ...state,
        workouts: action.payload,
      }

    case WORKOUTS_BY_ID_SUCCESS:
      return {
        ...state,
        activeWorkout: action.payload,
      }

    case UPDATE_ACTIVE_WORKOUT:
      return {
        ...state,
        activeWorkout: action.payload || state.activeWorkout,
      }

    case WORKOUTS_CREATE_IMAGE_PENDING:
      return {
        ...state,
        loading: true,
      }

    case WORKOUTS_UPDATE_IMAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        activeWorkout: action.payload.activeWorkout,
        images: [...state.images, action.payload.imageId],
      }

    case WORKOUTS_DELETE_IMAGE_SUCCESS:
      return {
        ...state,
        images: action.payload,
      }
    case WORKOUTS_CLEAR_IMAGE:
      return {
        ...state,
        images: [],
      }
    case WORKOUTS_FETCH_FAILURE:
    case WORKOUTS_UPDATE_FAILURE:
    case WORKOUTS_CREATE_FAILURE:
    case WORKOUTS_DELETE_FAILURE:
    case WORKOUTS_UPDATE_LISTEN_FAILURE:
      return { ...state, loading: false, error: action.payload }

    default:
      return state
  }
}
