/* eslint-disable react/prop-types */
import React from 'react';

const IconPath = (props) => {
  const { fill = '#A9AEB9' } = props;
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.8564 6L9.92819 2L3 6V14L9.92819 18L16.8564 14V6ZM9.92819 13.0769C11.6274 13.0769 13.0051 11.6994 13.0051 10C13.0051 8.30063 11.6274 6.92308 9.92819 6.92308C8.22897 6.92308 6.85126 8.30063 6.85126 10C6.85126 11.6994 8.22897 13.0769 9.92819 13.0769Z"
        fill={fill}
      />
    </svg>
  );
};

export default IconPath;
