import { schema } from 'normalizr';

// Define a protocolDefinition schema
export const protocolDefinitionSchema = new schema.Entity('protocolDefinition');

// Define your protocol schema having list of protocol_definition
export const protocolSchema = new schema.Entity('protocol', {
  protocol_definition: [protocolDefinitionSchema],
});

// Defining workoutProtocol schema having list of protocol
export const workoutProtocolSchema = new schema.Entity(
  'workoutProtocol',
  {
    protocol: [protocolSchema],
  },
  { idAttribute: 'id' }
);

// Defining workout schema having list of workoutProtocol
export const workoutSchema = new schema.Entity(
  'workout',
  {
    workoutProtocol: [workoutProtocolSchema],
  },
  { idAttribute: 'workoutId' }
);
