// /* eslint-disable react/prop-types */
// import React from 'react';

// const IconPath = () => {
//   return (
//     <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
//       <path
//         fillRule="evenodd"
//         clipRule="evenodd"
//         d="M9.94993 4.00003L17.4999 4C17.7761 4 18 4.22391 17.9999 4.50009C17.9999 4.7762 17.776 5.00001 17.4999 5L9.94993 4.99998C9.7183 6.14111 8.70942 7 7.49993 7C6.29044 7 5.28155 6.1411 5.04993 4.99996L2.49994 4.99995C2.22383 4.99995 2 4.77612 2 4.50001C2 4.2239 2.22383 4.00006 2.49994 4.00006L5.04993 4.00005C5.28155 2.8589 6.29044 2 7.49993 2C8.70942 2 9.71831 2.8589 9.94993 4.00003Z"
//         fill="#a9aeb9"
//       />
//       <path
//         className="ADJUST_CIRCLE"
//         d="M9 4.5C9 5.32843 8.32843 6 7.5 6C6.67157 6 6 5.32843 6 4.5C6 3.67157 6.67157 3 7.5 3C8.32843 3 9 3.67157 9 4.5Z"
//         fill="white"
//       />
//       <path
//         fillRule="evenodd"
//         clipRule="evenodd"
//         d="M10.05 9.99997L2.50003 10C2.22385 10 1.99998 9.77609 2.00003 9.49992C2.00007 9.2238 2.22392 9 2.50003 9L10.05 9.00002C10.2816 7.85889 11.2905 7 12.5 7C13.7095 7 14.7184 7.8589 14.95 9.00004L17.5 9.00005C17.7761 9.00005 17.9999 9.22388 17.9999 9.49999C17.9999 9.7761 17.7761 9.99994 17.5 9.99994L14.95 9.99995C14.7184 11.1411 13.7095 12 12.5 12C11.2905 12 10.2816 11.1411 10.05 9.99997Z"
//         fill="#a9aeb9"
//       />
//       <path
//         className="ADJUST_CIRCLE"
//         d="M10.9999 9.5C10.9999 8.67157 11.6715 8 12.4999 8C13.3284 8 13.9999 8.67157 13.9999 9.5C13.9999 10.3284 13.3284 11 12.4999 11C11.6715 11 10.9999 10.3284 10.9999 9.5Z"
//         fill="white"
//       />
//       <path
//         fillRule="evenodd"
//         clipRule="evenodd"
//         d="M9.94993 14L17.4999 14C17.7761 14 18 14.2239 17.9999 14.5001C17.9999 14.7762 17.776 15 17.4999 15L9.94993 15C9.7183 16.1411 8.70942 17 7.49993 17C6.29044 17 5.28155 16.1411 5.04993 15L2.49994 15C2.22383 14.9999 2 14.7761 2 14.5C2 14.2239 2.22383 14.0001 2.49994 14.0001L5.04993 14C5.28155 12.8589 6.29044 12 7.49993 12C8.70942 12 9.71831 12.8589 9.94993 14Z"
//         fill="#a9aeb9"
//       />
//       <path
//         className="ADJUST_CIRCLE"
//         d="M9 14.5C9 15.3284 8.32843 16 7.5 16C6.67157 16 6 15.3284 6 14.5C6 13.6716 6.67157 13 7.5 13C8.32843 13 9 13.6716 9 14.5Z"
//         fill="white"
//       />
//     </svg>
//   );
// };

// export default IconPath;

/* eslint-disable react/prop-types */
// import React from 'react';

// const IconPath = () => {
//   return (
//     <svg
//       width="20"
//       height="20"
//       viewBox="0 0 20 20"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//         <path
//           fillRule="evenodd"
//           clipRule="evenodd"
//           d="M7.19998 6.5C7.19998 5.78203 7.78201 5.2 8.49998 5.2C9.21795 5.2 9.79998 5.78203 9.79998 6.5C9.79998 7.21797 9.21795 7.8 8.49998 7.8C7.78201 7.8 7.19998 7.21797 7.19998 6.5ZM6.11443 5.75C6.433 4.73572 7.38057 4 8.49998 4C9.61939 4 10.567 4.73572 10.8855 5.75H17C17.4142 5.75 17.75 6.08579 17.75 6.5C17.75 6.91421 17.4142 7.25 17 7.25H10.8855C10.567 8.26428 9.61939 9 8.49998 9C7.38057 9 6.433 8.26428 6.11443 7.25H3.5C3.08579 7.25 2.75 6.91421 2.75 6.5C2.75 6.08579 3.08579 5.75 3.5 5.75H6.11443Z"
//           fill="#a9aeb9"
//         />
//         <path
//           fillRule="evenodd"
//           clipRule="evenodd"
//           d="M13.3 12.5C13.3 13.218 12.718 13.8 12 13.8C11.282 13.8 10.7 13.218 10.7 12.5C10.7 11.782 11.282 11.2 12 11.2C12.718 11.2 13.3 11.782 13.3 12.5ZM14.3856 13.25C14.067 14.2643 13.1194 15 12 15C10.8806 15 9.93303 14.2643 9.61447 13.25L3.49998 13.25C3.08577 13.25 2.74998 12.9142 2.74998 12.5C2.74998 12.0858 3.08577 11.75 3.49998 11.75L9.61447 11.75C9.93303 10.7357 10.8806 10 12 10C13.1194 10 14.067 10.7357 14.3856 11.75L17 11.75C17.4142 11.75 17.75 12.0858 17.75 12.5C17.75 12.9142 17.4142 13.25 17 13.25L14.3856 13.25Z"
//           fill="#a9aeb9"
//         />
//         <circle
//           cx="8.5"
//           cy="6.5"
//           r="1.9"
//           transform="rotate(180 8.5 6.5)"
//           stroke="#a9aeb9"
//           stroke-width="1.2"
//         />
//       </g>

//     </svg>
//   );
// };

// export default IconPath;

/* eslint-disable react/prop-types */
import React from 'react';

const IconPath = props => {
  const { stroke = '#A9AEB9' } = props;
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.7479 6.75006L9.4053 6.72436L9.40992 5.22437L17.7525 5.25006C18.1667 5.25134 18.5015 5.58816 18.5002 6.00237C18.4989 6.41658 18.1621 6.75133 17.7479 6.75006ZM1.74999 5.21049L4.88906 5.21045L4.88908 6.71045L1.75001 6.71049C1.3358 6.71049 1 6.37471 1 5.9605C0.999995 5.54628 1.33578 5.21049 1.74999 5.21049Z"
        fill="#a9aeb9"
      />
      <circle
        cx="6.75"
        cy="6"
        r="2.4"
        className="circle"
        transform="rotate(180 6.75 6)"
        stroke="#a9aeb9"
        strokeWidth="1.2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.75 13.0001C18.75 13.4143 18.4142 13.7501 18 13.7501L16 13.7501L16 12.2501L18 12.2501C18.4142 12.2501 18.75 12.5859 18.75 13.0001ZM2.00013 12.9571C2.002 12.5429 2.3393 12.2086 2.75351 12.2105L11.5034 12.25L11.4966 13.75L2.74673 13.7105C2.33253 13.7086 1.99826 13.3713 2.00013 12.9571Z"
        fill="#a9aeb9"
      />
      <circle
        cx="14"
        cy="13"
        className="circle"
        r="2.4"
        transform="rotate(180 14 13)"
        stroke="#a9aeb9"
        strokeWidth="1.2"
      />
    </svg>
  );
};

export default IconPath;
