import React from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { Heading } from '@common/Heading'
import { Button } from '@common/Button'
import { Icon } from '@common/Icon'
import { Container } from '../Modal.styled'
import { Controls } from '@common/Controls'
// import Auth from '@features/Auth'
import { useAuth0 } from '@auth0/auth0-react'
// const auth = Auth.Service()
import { AUTH_CONFIG } from '@features/Auth/Service'
import { clearState } from '@utils/storage'

const ErrorContent = ({ style, dev, level, children }) => {
  const navigate = useNavigate()
  const { isLoading, isAuthenticated, error, user, loginWithRedirect, logout } =
    useAuth0()

  const onLogout = () => {
    clearState('token')
    logout({
      logoutParams: {
        returnTo: AUTH_CONFIG.returnTo,
      },
    })
  }

  const onLogin = () => {
    loginWithRedirect()
  }
  const onBack = () => {
    navigate(-1)
  }

  return (
    <Container style={style || {}} dev={dev} level={level}>
      <Heading mt={20} variant="PAGE">
        Whoops, that's not right
      </Heading>
      <Controls mt={32} withLine>
        {!isAuthenticated && (
          <>
            <Button onClick={() => onBack()} label="Back" variant="OUTLINE">
              <Icon name="RETURN" size={20} ml={5} />
            </Button>
            <Button
              onClick={() => onLogin()}
              label="login"
              alone
              variant="orange"
            >
              <Icon name="CLIENT" size={20} ml={5} stroke="white" />
            </Button>
          </>
        )}
        {isAuthenticated && (
          <>
            <Button
              onClick={() => navigate(-1)}
              label="Go back"
              alone
              variant="OUTLINE"
            >
              <Icon name="RETURN" size={20} ml={5} />
            </Button>
            <Button
              onClick={() => navigate('/dashboard')}
              label="Go to dashboard"
              variant="OUTLINE"
              alone
            >
              <Icon name="RETURN" size={20} ml={5} />
            </Button>
            <Button
              onClick={() => onLogout()}
              label="Logout"
              variant="orange"
              alone
            >
              <Icon name="LEAVE" size={20} ml={5} stroke="white" />
            </Button>
          </>
        )}
      </Controls>
    </Container>
  )
}

ErrorContent.defaultProps = {
  data: {
    message:
      'Seems you have landed in the wrong place.  Try pressing the back button.',
  },
  style: {},
  dev: false,
  level: 2,
}

ErrorContent.propTypes = {
  data: PropTypes.object,
  style: PropTypes.object,
  dev: PropTypes.bool,
  level: PropTypes.number,
}

export default ErrorContent
