export default {
  stripeQuoteId: 'qt_1L5663CTOd88lFo5LP3NoUXN',
  currency: 'aud',
  collectionMethod: 'charge_automatically',
  status: 'draft',
  amountTotal: 24000,
  amountSubTotal: 24000,
  quantity: 1,
  stripeCreatedAt: '2022-05-30T16:15:15',
  expiresAt: '2022-06-29T16:15:15',
  computed: {
    lastResponse: null,
    rawJsonObject: null,
    recurring: {
      lastResponse: null,
      rawJsonObject: null,
      amountSubtotal: 24000,
      amountTotal: 24000,
      interval: 'year',
      intervalCount: 1,
      totalDetails: {
        lastResponse: null,
        rawJsonObject: null,
        amountDiscount: 0,
        amountShipping: 0,
        amountTax: 0,
        breakdown: null,
      },
    },
    upfront: {
      lastResponse: null,
      rawJsonObject: null,
      amountSubtotal: 0,
      amountTotal: 0,
      lineItems: null,
      totalDetails: {
        lastResponse: null,
        rawJsonObject: null,
        amountDiscount: 0,
        amountShipping: 0,
        amountTax: 0,
        breakdown: null,
      },
    },
  },
  statusTransition: {
    lastResponse: null,
    rawJsonObject: null,
    acceptedAt: null,
    canceledAt: null,
    finalizedAt: null,
  },
  subscriptionData: {
    lastResponse: null,
    rawJsonObject: null,
    effectiveDate: null,
    trialPeriodDays: 8,
  },
  totalDetails: {
    lastResponse: null,
    rawJsonObject: null,
    amountDiscount: 0,
    amountShipping: 0,
    amountTax: 0,
    breakdown: null,
  },
  priceInfo: {
    stripePriceId: 'price_1L3GW1CTOd88lFo5pRLAKFTb',
    stripeProductId: 'prod_Ljx4bgjysAPjTg',
    amount: 0,
    label: {
      interval: 'year',
      usageType: 'licensed',
      lastResponse: null,
      intervalCount: 1,
      rawJsonObject: null,
      aggregateUsage: null,
      trialPeriodDays: null,
    },
    active: true,
    tiers: [
      {
        upTo: 3,
        flatAmount: 24000,
        unitAmount: null,
        lastResponse: null,
        rawJsonObject: null,
        flatAmountDecimal: 24000,
        unitAmountDecimal: null,
      },
      {
        upTo: null,
        flatAmount: null,
        unitAmount: 3600,
        lastResponse: null,
        rawJsonObject: null,
        flatAmountDecimal: null,
        unitAmountDecimal: 3600,
      },
    ],
    taxBehavior: 'inclusive',
    tiersMode: 'graduated',
    type: 'recurring',
    currency: 'aud',
    isDefault: false,
    createdAt: '2022-05-25T14:58:31.112046',
    updatedAt: '2022-05-25T14:58:31.112046',
  },
  createdBy: {
    email: 'support@prostaid.health',
    stripeCustomerId: 'cus_LlA9RYOvQPQDq1',
    roles: ['PersonalTrainer'],
    createdAt: '2022-05-26T15:51:27.579485',
    updatedAt: '2022-05-30T15:18:02.58769',
    isActive: true,
    isDeleted: false,
    stripeSetupIntentObject: {
      setupIntentId: 'seti_1L55CgCTOd88lFo5ojK65nsj',
      paymentMethodId: 'pm_1L55CdCTOd88lFo5CQcA02MO',
      setupIntentSecret:
        'seti_1L55CgCTOd88lFo5ojK65nsj_secret_LmeVNyHo0jMpysDpkM6bwCWKGjKZdAL',
    },
  },
};
