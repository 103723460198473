import React, { useEffect, useContext, useState, useRef } from 'react'
import { fetchMe, userSelector } from '@state/user'
import { media } from '@common/Theme'
import { prop } from 'ramda'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import { useDispatch } from 'react-redux'
import { ImageRenderer } from '@components/ImageUploader'
import { Text } from '@common/Text'
import { IconContainer } from '@common/IconContainer'
import { Icon } from '@common/Icon'

import { getHighestRole } from '@utils/user'
import { useClickOutside as ClickOutside } from '@hooks/useClickOutside'
import { colors } from '@common/Theme'
import { useAuth0 } from '@auth0/auth0-react'
import { useNavigate } from 'react-router-dom'

const HeaderContainer = styled.div`
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 24px;
  padding-left: 16px;
  padding-right: 24px;
  display: none;

  .TabContainer {
    flex: 1;
  }
  #header-portal {
    display: flex;
    margin-right: 16px;
  }

  ${media.sm`
    display: flex;
  `};
`

const ProfileContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 8px 4px 12px;
  border: 1px solid rgb(227, 229, 235);
  background: white;
  gap: 8px;
  border-radius: 8px;
  transition: all 0.3s ease;

  &:hover {
    cursor: pointer;
    background: #f3f3f3;
    border: 1px solid rgb(227, 229, 235);
  }
  .ImageGroup {
    margin-left: 0px;
    min-height: 40px;
    height: 32px;
  }
  .ImageItem.Thumnail {
    height: 32px;
    top: 4px;
    position: relative;
    .ImageElement {
      transition: all 0.3s ease;
      width: 32px;
      height: 32px;
      border: 1px solid transparent;
      border-radius: 50%;
    }
  }
  ${(props) =>
    props.isExpanded &&
    css`
      background: #f3f3f3;
      .ImageItem.Thumnail .ImageElement {
        transform: scale(1.05);
        border: 1px solid #cbcdd2;
        box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.15);
      }
    `};
`

const DefaultImage = styled.div`
  width: 40px;
  height: 40px;
  max-width: 40px;
  max-height: 40px;
  min-width: 40px;
  min-height: 40px;
  border-radius: 50%;
  background: rgb(18 27 45);
  transition: all 0.3s ease;
  border: 1px solid rgb(227, 229, 235);
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    color: white;
    font-size: 14px;
  }
  &:hover {
    cursor: pointer;
    background: rgb(12 17 29);
    border: 1px solid rgb(227, 229, 235);
  }
`

const RowItem = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2px;
`

const ColItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: 100%;
  transition: all 0.3s ease-in-out;
  .isExpanded & {
    transform: translateX(-3px);
  }
`

const ProfileWrapper = styled.div`
  position: relative;
`

const ProfileMenu = styled.div`
  border-radius: 6px;
  background: white;
  overflow: hidden;
  border: 1px solid rgb(227, 229, 235);
  position: absolute;
  top: calc(100% + 6px);
  right: 0;
  z-index: 100;
  display: flex;
  flex-direction: column;
  width: 100%;
  transition: all 0.3s ease;
  justify-content: flex-end;
  align-items: flex-end;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.15);
  ul,
  li {
    margin: 0;
    padding: 0;
    width: 100%;
    list-style: none;
  }
  li {
    padding: 10px 16px;
    background: white;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: all 0.3s ease-in-out;
    &:hover {
      background: #f3f3f3;
      cursor: pointer;
      svg.SVG.LEAVE {
        path {
          stroke: ${colors.orange};
        }
      }
      svg.SVG.CLIENT {
        path {
          stroke: rgb(18 27 45);
        }
      }
    }
    span {
      font-size: 14px;
      font-weight: 500;
      color: black;
    }
  }
`

const ProfileFooter = styled.div`
  border-top: 1px solid rgb(227, 229, 235);
  background: white;
  width: 100%;
  ul,
  li {
    margin: 0;
    padding: 0;
    width: 100%;
    list-style: none;
  }
  li {
    padding: 8px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      font-size: 14px;
      font-weight: 500;
      color: black;
    }
  }
`
const Profile = () => {
  const dispatch = useDispatch()
  const user = useSelector(userSelector.user) || {}
  const images = useSelector(userSelector.images) || []
  const userRoles = useSelector(userSelector.roles)
  const role = getHighestRole(userRoles)
  const email = prop('email')(user)
  const firstName = prop('firstName', user)
  const lastName = prop('lastName', user)
  const { logout } = useAuth0()
  const navigate = useNavigate()

  const [state, setState] = useState({
    isExpanded: false,
  })

  useEffect(() => {
    if (!!user && !!firstName) return
    dispatch(fetchMe())
  }, [user])

  const onExpand = () => {
    console.log('Expand')
    setState((state) => ({
      ...state,
      isExpanded: !state.isExpanded,
    }))
  }

  const handleCallback = () => {
    setState((state) => ({
      ...state,
      isExpanded: false,
    }))
  }

  return (
    <ClickOutside callback={handleCallback}>
      <ProfileWrapper>
        <ProfileContainer
          className={state.isExpanded ? 'Profile isExpanded' : 'Profile'}
          isExpanded={state.isExpanded}
          onClick={onExpand}
        >
          <IconContainer mr={5}>
            <Icon
              name="CHEVRON"
              size={16}
              rotate={state.isExpanded ? 270 : 90}
            />
          </IconContainer>
          {!!user && (
            <ColItem>
              {role && (
                <Text
                  size={12}
                  lineHeight={14}
                  align="right"
                  weight={600}
                  color="black"
                >
                  {role.roleLabel}
                </Text>
              )}
              <RowItem>
                <Text
                  size={12}
                  lineHeight={14}
                  align="right"
                  weight={500}
                  color="black"
                >
                  {firstName}
                </Text>
                <Text
                  size={12}
                  lineHeight={14}
                  weight={500}
                  align="right"
                  color="black"
                >
                  {lastName}
                </Text>
              </RowItem>
            </ColItem>
          )}
          {!!email && !!images && !!images.length ? (
            <ImageRenderer
              variant="thumbnail"
              parentId={email}
              images={images}
              config={{
                controlType: 'thumnail',
              }}
              kind="user"
            />
          ) : (
            <DefaultImage>
              {!!firstName && (
                <span>
                  {firstName[0]}
                  {lastName[0]}
                </span>
              )}
            </DefaultImage>
          )}
        </ProfileContainer>
        {state.isExpanded && (
          <ProfileMenu>
            <ul>
              <li onClick={() => navigate('/profile')}>
                <span>View Profile</span>
                <Icon name="CLIENT" size={20} />
              </li>
            </ul>
            <ProfileFooter>
              <ul>
                <li onClick={() => logout()}>
                  <span>Log out</span>
                  <Icon name="LEAVE" size={20} />
                </li>
              </ul>
            </ProfileFooter>
          </ProfileMenu>
        )}
      </ProfileWrapper>
    </ClickOutside>
  )
}

const Header = ({ children, config = {} }) => {
  const { isProfileEnabled = false } = config
  return (
    <HeaderContainer className="Header">
      {children}
      <div id="header-portal"></div>
      {isProfileEnabled && <Profile />}
    </HeaderContainer>
  )
}
export { Header }
