import React from 'react'
import PropTypes from 'prop-types'
import { includes, join, toLower, keys, prop, toUpper } from 'ramda'
import {
  Group,
  Primary,
  Secondary,
  Tertiary,
  Quaternary,
  PageHeading,
  AuthHeading,
  RowHeading,
  PaymentHeading,
} from './Heading.styled'

const randomId = () => '_' + Math.random().toString(36).substr(2, 9)

const LOOKUP = {
  PRIMARY: ['font-size: 42px', 'font-weight: 700', 'margin-bottom: 20px'],
  SECONDARY: [' font-size: 32px', 'font-weight: 700', 'margin-bottom: 20px'],
  TERTIARY: ['font-size: 26px', 'font-weight: 500', 'margin-bottom: 20px'],
  PAGE: ['font-size: 32px', 'font-weight: 700', 'margin-bottom: 20px'],
  ROW: ['font-size: 32px', 'font-weight: 700', 'margin-bottom: 20px'],
  AUTH: ['font-size: 26px', 'font-weight: 600', 'margin-bottom: 0px'],
  PAYMENT: ['font-size: 20px', 'font-weight: 500', 'margin-bottom: 0px'],
}

const match = {
  PRIMARY: Primary,
  SECONDARY: Secondary,
  TERTIARY: Tertiary,
  QUATERNARY: Quaternary,
  PAGE: PageHeading,
  ROW: RowHeading,
  AUTH: AuthHeading,
  PAYMENT: PaymentHeading,
}

const Heading = ({
  variant,
  style = {},
  config = {},
  weight,
  size,
  mb,
  mt,
  children,
  ...props
}) => {
  const matchedTextStyles = prop('text')(config) || {}

  if (!variant || !variant.length) {
    return <p>{`Looks like you forgot to pass the variant`}</p>
  }

  if (!includes(toUpper(variant), keys(match))) {
    const joinList = join(', ')(keys(match))
    return (
      <p>{`Please provide an appropriate key for the <Heading /> component.  It currently supports: ${joinList}`}</p>
    )
  }
  const Component = match[toUpper(variant)]

  return (
    <Component
      mb={mb}
      mt={mt}
      weight={weight}
      size={size}
      className={`heading ${toLower(variant)}`}
      style={{ ...style, ...matchedTextStyles }}
      {...props}
    >
      {children}
    </Component>
  )
}

const withWrapper =
  (Component) =>
  ({ dev, variant, ...props }) => {
    return (
      <>
        <Component variant={variant} {...props} />
        {dev && (
          <Group className="group">
            {LOOKUP[toUpper(variant)]?.map((item, index) => (
              <li key={`${index}-prop-${item}-${randomId()}`}>{item}</li>
            ))}
          </Group>
        )}
      </>
    )
  }

Heading.defaultProps = {
  variant: 'PRIMARY',
  style: {},
  config: {},
  children: <></>,
}

Heading.propTypes = {
  variant: PropTypes.string,
  style: PropTypes.object,
  config: PropTypes.object,
  children: PropTypes.node,
}

const withHeadingWrapper = withWrapper(Heading)

export default withHeadingWrapper
