import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { prop } from 'ramda'
import { useDebounce } from '@hooks/useDebounce'
import { useSelector } from '@hooks/useSelector'
import { TextArea } from '@common/TextArea'
import { workoutSelector, updateWorkout } from '@state/workout'

const Notes = () => {
  const [localState, setLocalState] = useState('')
  const dispatch = useDispatch()

  const actualNotes = useDebounce(localState, 1500)
  const activeWorkout = useSelector(workoutSelector.activeWorkout)
  const workoutNotes = prop('workoutNotes')(activeWorkout)

  const onChange = (e) => {
    const { value } = e.target
    setLocalState(value)
  }

  useEffect(() => {
    if (!workoutNotes || !workoutNotes.length) return
    if (workoutNotes === localState) return
    setLocalState(workoutNotes)
  }, [workoutNotes])

  useEffect(() => {
    if (!actualNotes || !actualNotes.length) return
    const payload = {
      ...activeWorkout,
      workoutNotes: localState,
    }
    dispatch(updateWorkout(payload))
  }, [actualNotes])

  return (
    <>
      <TextArea
        onChange={onChange}
        placeholder="Enter Notes"
        value={localState}
        mb={16}
      />
    </>
  )
}

export { Notes }
