import React from 'react'
import PropTypes from 'prop-types'
import { prop } from 'ramda'

import { Spinner } from '@common/Spinner'
import { Button } from '@common/Button'

import { Container, Push } from '../Modal.styled'

const SavingContent = ({ style, dev, level, data }) => {
  const message = prop('message')(data) || {}
  return (
    <Container style={style || {}} dev={dev} level={level}>
      <Button
        type="button"
        config={{
          layout: { display: 'flex', alignItems: 'center' },
        }}
      >
        <Spinner variant="SUCCESS" />
        {message && <Push>{message}</Push>}
      </Button>
    </Container>
  )
}

SavingContent.defaultProps = {
  data: {
    message: 'Processing...',
  },
  style: {},
  dev: false,
  level: 2,
}

SavingContent.propTypes = {
  data: PropTypes.object,
  style: PropTypes.object,
  dev: PropTypes.bool,
  level: PropTypes.number,
}

export default SavingContent
