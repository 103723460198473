import styled, { css } from 'styled-components'

const ImageContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  box-sizing: border-box;
  width: 30px;
  height: 30px;
  ${(props) =>
    props.bgColor &&
    css`
      background-color: ${props.bgColor};
    `}
  ${(props) =>
    props.radius &&
    css`
      border-radius: ${props.radius};
    `}
  ${(props) =>
    props.width &&
    css`
      width: ${props.width};
    `}
${(props) =>
    props.height &&
    css`
      height: ${props.height};
    `}
    ${(props) =>
    props.minWidth &&
    css`
      min-width: ${props.minWidth};
    `}
    ${(props) =>
    props.minHeight &&
    css`
      min-height: ${props.minHeight};
    `}
`

const ImageItem = styled.div`
  background-color: transparent;
  background-size: 30px 30px;
  background-repeat: no-repeat;
  object-fit: cover;
  background-position: center center;
  ${(props) =>
    props.objectFit &&
    css`
      object-fit: ${props.objectFit};
    `}
  ${(props) =>
    props.bgPosition &&
    css`
      background-position: ${props.bgPosition};
    `}
  ${(props) =>
    props.bgSize &&
    css`
      background-size: ${props.bgSize};
    `}
    ${(props) =>
    props.width &&
    css`
      width: ${props.width};
    `}
${(props) =>
    props.height &&
    css`
      height: ${props.height};
    `}
    ${(props) =>
    props.minWidth &&
    css`
      min-width: ${props.minWidth};
    `}
    ${(props) =>
    props.minHeight &&
    css`
      min-height: ${props.minHeight};
    `}
  ${(props) =>
    props.$imageSrc &&
    css`
      background-image: ${`url(${props.$imageSrc})`};
    `}
`

export { ImageContainer, ImageItem }
