import styled, { css } from 'styled-components'
import { colors, mbFn, mtFn } from '@common/Theme'

const Group = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0;
  margin: 0;
  gap: 16px;
  margin-left: 14px;
  list-style: none;
  ${mbFn};
  ${mtFn};
  ${(props) =>
    props.gap &&
    css`
      gap: ${props.gap};
    `}
`

const List = styled.li`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;

  ${mbFn};
  ${mtFn};

  .SVG {
    min-width: 20px;
  }

  ${(props) =>
    props.dot &&
    css`
      list-style: none;
      &:before {
        content: '';
        margin-left: -14px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 5px;
        height: 5px;
        background: ${colors.black};
        border-radius: 50%;
      }
    `};
`

export { Group, List }
