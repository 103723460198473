const CONFIG = {
  PRODUCTION: {
    api: 'https://stagek.api.aima.fit',
  },
  DEVELOPMENT: {
    api: 'https://stagek.api.aima.fit',
  },
};

const getEnvironment = (env = 'DEVELOPMENT') => {
  const isProd = true;
  const baseURL = isProd ? CONFIG[env].api : 'localhost:3000';
  return { baseURL };
};

export { getEnvironment };
