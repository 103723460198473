import LayoutTypes from './LayoutTypes';

const initialState = {
  menuExpanded: false,
  bladeExpanded: false,
  documentHeight: 100,
  bladeTitle: '',
  bladeName: '',
};

const Reducer = (state = {}, action) => {
  const { type, data } = action;
  switch (type) {
    case LayoutTypes.OPEN_MENU:
      return {
        ...state,
        menuExpanded: true,
      };
    case LayoutTypes.CLOSE_MENU:
      return {
        ...state,
        menuExpanded: false,
      };
    case LayoutTypes.CLOSE_BLADE:
      return {
        ...state,
        bladeExpanded: false,
      };
    case LayoutTypes.OPEN_BLADE:
      return {
        ...state,
        bladeExpanded: true,
      };
    case LayoutTypes.SET_HEIGHT:
      return {
        ...state,
        documentHeight: data,
      };
    case LayoutTypes.SET_BLADE_TITLE:
      return {
        ...state,
        bladeTitle: data,
      };
    case LayoutTypes.SET_BLADE_NAME:
      return {
        ...state,
        bladeName: data,
      };
    default:
      return state;
  }
};

export { initialState };

export default Reducer;
