import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { keys, toUpper } from 'ramda';

import {
  TypeLabelContainer,
  TypeLabelItem,
  TypeLabelBlock,
} from './TypeLabel.styled';

const typesConfig = {
  WARMUP: { id: 'WARMUP', label: 'WARMUP' },
  NORMAL: { id: 'NORMAL', label: 'NORMAL' },
  DROP_SET: { id: 'DROP_SET', label: 'DROP SET' },
  COOL_DOWN: { id: 'COOL_DOWN', label: 'COOL DOWN' },
};

const betterIndexOf = (needle, haystack, defaultIndex) => {
  const result = haystack.indexOf(needle);

  if (result !== -1) return result;
  if (typeof defaultIndex !== 'undefined') return defaultIndex;
};

const idsInOrder = keys(typesConfig);

const TypeLabel = ({ callback = () => {}, activeId }) => {
  const [selectedIndex, setSelectedIndex] = useState(
    betterIndexOf(toUpper(activeId), idsInOrder, 0)
  );

  const currentType = idsInOrder[selectedIndex];
  const currentItem = typesConfig[currentType];

  const onSelect = e => {
    const nextStep = selectedIndex + 1;

    if (nextStep < idsInOrder.length) {
      callback(keys(typesConfig)[nextStep]);
      setSelectedIndex(nextStep);
      return;
    }

    setSelectedIndex(0);
    callback(keys(typesConfig)[0]);
    return;
  };

  return (
    <TypeLabelContainer>
      <TypeLabelItem onClick={onSelect}>
        <TypeLabelBlock type={currentItem.id}>
          {currentItem.label}
        </TypeLabelBlock>
      </TypeLabelItem>
    </TypeLabelContainer>
  );
};

PropTypes.defaultProps = {
  callback: () => {},
  activeId: 'NORMAL',
};

PropTypes.propTypes = {
  callback: PropTypes.func,
  activeId: PropTypes.string,
};

export { TypeLabel };
