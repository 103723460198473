import React, { useContext, useEffect } from 'react'
import { uuid } from '@utils/uuid'
import { usePrevious } from '@hooks/usePrevious'
import { DraggableExercise } from './DraggableExercise'
import { Icon } from '@common/Icon'
import { Provider, Types, Context } from './Provider'
import {
  AddContainer,
  AddButtonIcon,
  SetContainer,
  SaveButton,
  SetButton,
  SetDragContainer,
} from './ActivitySet.styled'
import { useDebounce } from '@hooks/useDebounce'
import { Button } from '@common/Button'

const ActivitySet = ({ callback = () => {}, data = [], onClose }) => {
  const [localState, localDispatch] = useContext(Context)
  const { items = [] } = localState
  const prevItems = usePrevious(items)
  const actualItems = useDebounce(items, 1500)

  const onCreate = () => {
    const creationDate = new Date()

    const payload = {
      id: uuid(),
      creationDate,
      reps: 0,
      weight: 0,
      rest: 0,
      type: 'NORMAL',
    }

    localDispatch({
      type: Types.ADD_SET,
      data: payload,
    })
  }

  useEffect(() => {
    if (!actualItems) return
    if (prevItems !== items) {
      localDispatch({
        type: Types.REORDER_SET,
        data: items,
      })
    }
  }, [actualItems])

  useEffect(() => {
    if (prevItems !== items) callback(items)
  }, [items])

  useEffect(() => {
    if (data !== actualItems) {
      localDispatch({
        type: Types.REORDER_SET,
        data: data,
      })
    }
  }, [data])

  return (
    <SetContainer>
      <SetDragContainer>
        <DraggableExercise />
      </SetDragContainer>
      <AddContainer>
        <Button
          variant="orange"
          onClick={onCreate}
          config={{
            layout: {
              padding: '12px',
              width: 'unset',
            },
            text: {
              lineHeight: '21px',
            },
          }}
          label="Add Set"
        >
          <Icon name="PLUS" size={20} ml={5} stroke="#fff" />
        </Button>
      </AddContainer>
    </SetContainer>
  )
}

const ActivitySetWrapper = (props) => (
  <Provider>
    <ActivitySet {...props} />
  </Provider>
)

export { ActivitySetWrapper }
