import React, { useState, useEffect } from 'react'
import { PropTypes } from 'prop-types'

import { RangeSliderContainer, RangeInput } from './RangeSlider.styled'

const RangeSlider = ({
  onChange,
  defaultValue,
  label,
  min,
  max,
  step,
  mt,
  mb,
}) => {
  const [sliderVal, setValue] = useState(0)
  const [mouseState, setMouseState] = useState(null)

  useEffect(() => {
    if (defaultValue && defaultValue > 0) {
      setValue(defaultValue)
    }
  }, [defaultValue])

  const changeCallback = (e) => {
    const { value } = e.target
    setValue(value)
    onChange(value)
  }

  useEffect(() => {
    if (mouseState === 'up') {
      onChange(sliderVal)
    }
  }, [mouseState])

  return (
    <RangeSliderContainer mb={mb} mt={mt}>
      <label htmlFor="rangeInput">{label}</label>
      <RangeInput
        id="rangeInput"
        min={min}
        max={max}
        step={step}
        minVal={min}
        maxVal={max}
        type="range"
        onChange={changeCallback}
        onMouseDown={() => setMouseState('down')}
        onMouseUp={() => setMouseState('up')}
        value={sliderVal}
      />
    </RangeSliderContainer>
  )
}

RangeSlider.defaultProps = {
  onChange: () => {},
  min: 3,
  max: 50,
  step: 1,
  value: 3,
}

RangeSlider.propTypes = {
  onChange: PropTypes.func,
  min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  step: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export { RangeSlider }
