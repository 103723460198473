import {
  EXERCISES_FETCH_PENDING,
  EXERCISES_BUILDER_FETCH_PENDING,
  EXERCISES_BUILDER_FETCH_SUCCESS,
  EXERCISES_BUILDER_FETCH_FAILURE,
  EXERCISES_FETCH_SUCCESS,
  EXERCISES_FETCH_FAILURE,
  EXERCISES_UPDATE_PENDING,
  EXERCISES_UPDATE_SUCCESS,
  EXERCISES_UPDATE_FAILURE,
  EXERCISE_SET_ACTIVE_SUCCESS,
  EXERCISES_UPDATE_IMAGE_FAILURE,
  EXERCISES_CREATE_SUCCESS,
  EXERCISES_CREATE_PENDING,
  EXERCISES_CREATE_FAILURE,
  EXERCISES_CREATE_IMAGE_SUCCESS,
  EXERCISES_UPDATE_IMAGE_SUCCESS,
  EXERCISES_CREATE_IMAGE_FAILURE,
  EXERCISES_SET_FILTERS,
  EXERCISE_DELETE_IMAGE_SUCCESS,
  EXERCISE_CLEAR_IMAGE,
  EXERCISES_DELETE_SUCCESS,
  EXERCISES_DELETE_FAILURE,
  EXERCISES_DELETE_PENDING,
} from './actionTypes'
// import activities from './data/exercises'
// reducer with initial state
const initialState = {
  exercises: [],
  builderExercises: [],
  filteredExercises: [],
  activeExercise: null,
  loading: false,
  error: '',
  images: [],
}

export default function exerciseReducer(state = initialState, action) {
  switch (action.type) {
    case EXERCISES_FETCH_PENDING:
      return {
        ...state,
        loading: true,
      }
    case EXERCISES_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        exercises: action.payload,
      }
    case EXERCISES_BUILDER_FETCH_PENDING:
      return {
        ...state,
        loading: true,
      }
    case EXERCISES_BUILDER_FETCH_SUCCESS:
      return {
        ...state,
        loading: true,
        builderExercises: action.payload,
      }
    case EXERCISES_UPDATE_PENDING:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case EXERCISES_CREATE_PENDING:
      return {
        ...state,
        loading: true,
      }
    case EXERCISES_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        exercises: action.payload,
      }

    case EXERCISE_SET_ACTIVE_SUCCESS:
      return { ...state, activeExercise: action.payload }

    case EXERCISES_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        activeExercise: action.payload.activeExercise,
        exercises: action.payload.exercises,
      }
    /* When an exercise is removed we need to find a way to check to see that if the user had selected 
      the same exercise in which to remove is reset (ie the activeExercise needs to be set to empty 
      object literals), however, we cannot simply set this every time we remove an exercise because 
      the user may be editing a different exercise at the time of deletion and as a result the "currently"
      active excercise they are working on will disappear from view and they will have to find it again
      in order to continue editing it and this user experiences is not ideal.
    */
    case EXERCISES_DELETE_PENDING:
      return {
        ...state,
        loading: true,
      }
    case EXERCISES_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        exercises: action.payload,
      }
    case EXERCISES_CREATE_IMAGE_SUCCESS:
      return {
        ...state,
        images: [...state.images, action.payload],
      }
    case EXERCISES_UPDATE_IMAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        activeExercise: action.payload.activeExercise,
        images: [...state.images, action.payload.imageId],
      }
    case EXERCISE_DELETE_IMAGE_SUCCESS:
      return {
        ...state,
        images: action.payload,
      }
    case EXERCISE_CLEAR_IMAGE:
      return {
        ...state,
        images: [],
      }
    case EXERCISES_SET_FILTERS:
      return {
        ...state,
        filteredExercises: action.payload,
      }

    case EXERCISES_CREATE_FAILURE:
    case EXERCISES_FETCH_FAILURE:
    case EXERCISES_BUILDER_FETCH_FAILURE:
    case EXERCISES_UPDATE_FAILURE:
    case EXERCISES_CREATE_IMAGE_FAILURE:
    case EXERCISES_UPDATE_IMAGE_FAILURE:
    case EXERCISES_DELETE_FAILURE:
      return { ...state, loading: false, error: action.payload }
    default:
      return state
  }
}
