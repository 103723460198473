import {
  AUTH_SUCCESS,
  AUTH_SIGNIN_SUCCESS,
  AUTH_SIGNUP_SUCCESS,
} from '../actionTypes'

export const authSuccess = (payload) => ({
  type: AUTH_SUCCESS,
  payload,
})

export const authSigninSuccess = (payload) => ({
  type: AUTH_SIGNIN_SUCCESS,
  payload,
})

export const authSignupSuccess = (payload) => ({
  type: AUTH_SIGNUP_SUCCESS,
  payload,
})
