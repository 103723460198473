import styled, { css } from 'styled-components'
import { media } from '@common/Theme'

const config = {
  minWidth: '70px',
  maxWidth: '282px',
}

const MenuContainer = styled.div`
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  position: relative;
  top: 0;
  left: 0;
  z-index: 999;
  min-height: 100vh;
  transition: all 0.3s ease-in-out;
  display: none;
  ${media.sm`
    position: relative;
    z-index: 1;
    max-width: ${config.minWidth};
    display: block;
  `};
  ${(props) =>
    props.isExpanded &&
    css`
      max-width: ${config.maxWidth};
      ${media.md`
        position: relative;
        z-index: 1;
        max-width: ${config.maxWidth};
        display: block;
      `}
    `}
`

const MenuStyled = styled.div`
  position: fixed;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  max-width: ${config.minWidth};
  transition: all 0.3s ease-in-out;
  .isExpanded & {
    max-width: ${config.maxWidth};
  }
`

const MenuContent = styled.div`
  transition: all 0.3s ease-in-out;
  padding: 24px 13px;
  position: relative;
  overflow: hidden;
  display: flex;
  width: 100%;
  flex-direction: column;
  .isExpanded & {
    padding: 24px;
  }
`

export { MenuContainer, MenuStyled, MenuContent }
