/* eslint-disable react/prop-types */
import React from 'react'

const IconPath = (props) => {
  const { fill = '#A9AEB9', stroke = '#A9AEB9' } = props
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <mask id="path-1-inside-1_5704_46880" fill={fill}>
        <path d="M2.15063 13.985L11.111 5.02461L12.5731 3.56254C13.3541 2.78148 14.6204 2.78148 15.4015 3.56253L16.5858 4.74681C17.3668 5.52786 17.3668 6.79419 16.5858 7.57524L15.1237 9.03731L6.1295 18.0315L2.18648 18.0146L2.15063 13.985Z" />
      </mask>
      <path
        d="M15.1237 9.03731L14.5934 9.56764L15.1237 9.03731ZM15.6541 8.50706L15.654 8.50698L14.5934 9.56764L14.5934 9.56772L15.6541 8.50706ZM15.654 8.50698L11.6413 4.49428L10.5807 5.55494L14.5934 9.56764L15.654 8.50698ZM5.59917 17.5012L5.58979 17.5106L6.65045 18.5712L6.65983 18.5618L5.59917 17.5012ZM15.1237 9.03731L16.1844 10.098L15.1237 9.03731ZM11.111 5.02461L12.1717 6.08526L12.1717 6.08526L11.111 5.02461ZM2.15063 13.985L1.08997 12.9244L0.645098 13.3692L0.650694 13.9984L2.15063 13.985ZM12.5731 3.56254L11.5124 2.50188L11.5124 2.50188L12.5731 3.56254ZM16.5858 4.74681L17.6464 3.68615L16.5858 4.74681ZM15.4015 3.56253L14.3408 4.62319V4.62319L15.4015 3.56253ZM2.18648 18.0146L0.686538 18.0279L0.699705 19.5082L2.18003 19.5145L2.18648 18.0146ZM6.1295 18.0315L6.12305 19.5315L6.74815 19.5342L7.19016 19.0922L6.1295 18.0315ZM3.2113 15.0457L12.1717 6.08526L10.0503 3.96395L1.08997 12.9244L3.2113 15.0457ZM12.1717 6.08526L13.6337 4.62319L11.5124 2.50188L10.0503 3.96395L12.1717 6.08526ZM17.6464 3.68615L16.4621 2.50186L14.3408 4.62319L15.5251 5.80747L17.6464 3.68615ZM0.650694 13.9984L0.686538 18.0279L3.68642 18.0012L3.65058 13.9717L0.650694 13.9984ZM15.5251 6.51458L14.063 7.97665L16.1844 10.098L17.6464 8.6359L15.5251 6.51458ZM14.063 7.97665L5.06884 16.9709L7.19016 19.0922L16.1844 10.098L14.063 7.97665ZM2.18003 19.5145L6.12305 19.5315L6.13595 16.5315L2.19293 16.5146L2.18003 19.5145ZM15.5251 5.80747C15.7204 6.00273 15.7204 6.31932 15.5251 6.51458L17.6464 8.6359C19.0133 7.26906 19.0133 5.05298 17.6464 3.68615L15.5251 5.80747ZM13.6337 4.62319C13.829 4.42793 14.1456 4.42793 14.3408 4.62319L16.4621 2.50186C15.0953 1.13503 12.8792 1.13504 11.5124 2.50188L13.6337 4.62319Z"
        fill={fill}
        mask="url(#path-1-inside-1_5704_46880)"
      />
    </svg>
  )
}

export default IconPath
