import { useContext, useEffect } from 'react';
import { usePrevious } from '@hooks/usePrevious';

import { ThemeContext } from './ThemeProvider';

const Background = () => {
  const [state] = useContext(ThemeContext);

  const { background } = state;

  const previousState = usePrevious(background);

  useEffect(() => {
    if (document && previousState !== background) {
      document.body.style.backgroundColor = state.background;
    }
    return () => null;
  }, [state.background]);

  return null;
};

export { Background };
