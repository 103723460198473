const SET_BLADE_TITLE = 'SET_BLADE_TITLE'
const OPEN_MENU = 'OPEN_MENU'
const CLOSE_MENU = 'CLOSE_MENU'
const OPEN_BLADE = 'OPEN_BLADE'
const CLOSE_BLADE = 'CLOSE_BLADE'
const SET_HEIGHT = 'SET_HEIGHT'
const SET_BLADE_NAME = 'SET_BLADE_NAME'

export default {
  SET_BLADE_TITLE,
  OPEN_MENU,
  CLOSE_MENU,
  CLOSE_BLADE,
  OPEN_BLADE,
  SET_HEIGHT,
  SET_BLADE_NAME,
}
