import React, { useContext } from 'react'
import { LayoutContext, LayoutTypes } from '../../LayoutProvider'
import { Icon } from '@common/Icon'
import {
  LogoContainer,
  MenuHeaderContainer,
  MenuClose,
} from './MenuHeader.styled'

import LogoIcon from './assets/Logo'
import { useNavigate } from 'react-router-dom'

const MenuHeader = () => {
  const [state, dispatch] = useContext(LayoutContext)
  const navigate = useNavigate()
  const { menuExpanded: isExpanded } = state

  const onToggle = () => {
    if (isExpanded) {
      dispatch({ type: LayoutTypes.CLOSE_MENU })
    } else {
      dispatch({ type: LayoutTypes.OPEN_MENU })
    }
  }

  return (
    <MenuHeaderContainer
      isExpanded={isExpanded}
      className={isExpanded ? 'isExpanded' : ''}
    >
      <LogoContainer onClick={() => navigate('/')}>
        <LogoIcon />
      </LogoContainer>
      {isExpanded && (
        <MenuClose onClick={onToggle}>
          <Icon rotate={isExpanded ? 180 : 0} name="ARROW_RIGHT" size={20} />
        </MenuClose>
      )}
    </MenuHeaderContainer>
  )
}

export { MenuHeader, LogoIcon }
