export const PREFERENCES_FETCH_PENDING = 'PREFERENCES_FETCH_PENDING'
export const PREFERENCES_FETCH_SUCCESS = 'PREFERENCES_FETCH_SUCCESS'
export const PREFERENCES_FETCH_FAILURE = 'PREFERENCES_FETCH_FAILURE'

export const PREFERENCES_SAVE_BOOKMARK_PENDING =
  'PREFERENCES_SAVE_BOOKMARK_PENDING'
export const PREFERENCES_SAVE_BOOKMARK_SUCCESS =
  'PREFERENCES_SAVE_BOOKMARK_SUCCESS'
export const PREFERENCES_SAVE_BOOKMARK_FAILURE =
  'PREFERENCES_SAVE_BOOKMARK_FAILURE'

export const PREFERENCES_REMOVE_BOOKMARK_PENDING =
  'PREFERENCES_REMOVE_BOOKMARK_PENDING'
export const PREFERENCES_REMOVE_BOOKMARK_SUCCESS =
  'PREFERENCES_REMOVE_BOOKMARK_SUCCESS'
export const PREFERENCES_REMOVE_BOOKMARK_FAILURE =
  'PREFERENCES_REMOVE_BOOKMARK_FAILURE'

export const USER_FETCH_ME_PENDING = 'USER_FETCH_ME_PENDING'
export const USER_FETCH_ME_SUCCESS = 'USER_FETCH_ME_SUCCESS'
export const USER_FETCH_ME_FAILURE = 'USER_FETCH_ME_FAILURE'

export const USER_UPDATE_PENDING = 'USER_UPDATE_PENDING'
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS'
export const USER_UPDATE_FAILURE = 'USER_UPDATE_FAILURE'

export const USER_CREATE_IMAGE_PENDING = 'USER_CREATE_IMAGE_PENDING'
export const USER_CREATE_IMAGE_SUCCESS = 'USER_CREATE_IMAGE_SUCCESS'
export const USER_CREATE_IMAGE_FAILURE = 'USER_CREATE_IMAGE_FAILURE'

export const USER_VALIDATE_CHANNEL_PENDING = 'USER_VALIDATE_CHANNEL_PENDING'
export const USER_VALIDATE_CHANNEL_SUCCESS = 'USER_VALIDATE_CHANNEL_SUCCESS'
export const USER_VALIDATE_CHANNEL_FAILURE = 'USER_VALIDATE_CHANNEL_FAILURE'
export const USER_RESET_VALIDATE_CHANNEL_PENDING =
  'USER_RESET_VALIDATE_CHANNEL_PENDING'
