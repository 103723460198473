import React, { useState, useEffect, useRef, useContext } from 'react'
import { useClickOutside as ClickOutside } from '@hooks/useClickOutside'
import { useDispatch } from 'react-redux'
import { prop } from 'ramda'
import { useSelector } from '@hooks/useSelector'
import { Icon } from '@common/Icon'
import { Button } from '@common/Button'
// STATE
import {
  exerciseSelector,
  setExerciseFilters,
  setActiveExercise,
} from '@state/exercise'
import { useSleep } from '@hooks/useSleep'
import { LayoutContext, LayoutTypes } from '@features/Layout'
import {
  SearchWrapper,
  SearchContainer,
  ExerciseSearchContainer,
  SearchIcon,
  SearchInput,
} from './ExerciseSearch.styled'

const ExerciseSearch = () => {
  const [value, setValue] = useState('')
  const [focus, setFocus] = useState(false)
  const ref = useRef()
  const [_, localDispatch] = useContext(LayoutContext)

  const dispatch = useDispatch()

  const exercises = useSelector(exerciseSelector.exercises)
  const filteredExercises = useSelector(exerciseSelector.filteredExercises)

  const onChange = (e) => {
    const { value } = e.target
    setValue(value)
  }

  const onExpandBlade = () => {
    localDispatch({ type: LayoutTypes.OPEN_BLADE })
    dispatch(setActiveExercise({}))
    if (value && value.length > 0) {
      if (!filteredExercises || !filteredExercises.length) {
        localDispatch({ type: LayoutTypes.SET_BLADE_NAME, data: value })
      }
    }
  }

  const filterResults = ({ searchValue = '', results = [] }) => {
    if (searchValue) {
      const foundResults = results.filter((item) => {
        const name = prop('exerciseId')(item)
        if (!name) {
          return
        } else {
          return prop('exerciseId')(item)
            .toLowerCase()
            .includes(searchValue.toLowerCase())
        }
      })
      console.log({ foundResults })
      return foundResults
    }
    return results
  }

  const onFocus = () => {
    if (ref.current) {
      setFocus(true)
      ref.current.focus()
    }
  }
  const onCancelFocus = () => {
    if (ref.current && !!focus) {
      setFocus(false)
    }
  }

  useEffect(() => {
    // let currentQuery = true

    // const loadResults = async () => {
    //   if (!value) {
    //     dispatch(setExerciseFilters(exercises))
    //     return
    //   }
    //   await useSleep(350)
    // if (currentQuery) {
    //   const results = filterResults({
    //     searchValue: value,
    //     results: exercises,
    //   })
    dispatch(setExerciseFilters(exercises))
    //   }
    // }
    // loadResults()

    // return () => {
    //   currentQuery = false
    // }
    // eslint-disable-next-line
  }, [value, exercises])

  return (
    <ClickOutside callback={onCancelFocus}>
      <ExerciseSearchContainer className="ExerciseSearchContainer">
        <SearchWrapper>
          <SearchContainer onClick={onFocus} isActive={focus}>
            <SearchIcon>
              <Icon name="SEARCH" size={20} />
            </SearchIcon>
            <SearchInput
              ref={ref}
              focus={focus}
              value={value}
              placeholder="Search"
              onChange={onChange}
            />
          </SearchContainer>
        </SearchWrapper>
        <Button
          variant="orange"
          config={{
            layout: {
              padding: '10px 10px',
              display: 'flex',
              marginLeft: '8px',
              width: 'unset',
              justifyContent: 'center',
              alignCenter: 'center',
            },
          }}
          onClick={onExpandBlade}
        >
          <Icon name="PLUS" stroke="white" size={20} />
        </Button>
      </ExerciseSearchContainer>
    </ClickOutside>
  )
}

export { ExerciseSearch }
