import React, { useState, useRef, useEffect, useContext } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { Icon } from '@common/Icon'
import { useParams } from 'react-router-dom'
import { AdvancedSection } from './AdvancedSection'
import { useSelector, useDispatch } from 'react-redux'
import { LayoutContext, LayoutTypes } from '@features/Layout'
import {
  SECTION,
  CONTAINER,
  GROUP,
  ACTIVITY,
  ADD_WORKOUT,
  ADD_SECTION,
  ADD_GROUP,
  ADD_ACTIVITY,
} from './constants/AdvancedConstants'
import {
  IconContainer,
  SectionContainer,
  SectionControl,
  SectionDraggingOver,
  SectionHighest,
  SectionDroppable,
} from './AdvancedSection/AdvancedSection.styled'
import { AdvancedLabel } from './AdvancedLabel'
import { workoutSelector, updateWorkout, getWorkoutById } from '@state/workout'
// import { generateTemplates } from './AdvancedBlade/templateData';
import {
  comprehendPath,
  getActivity,
  // getRandomWord,
  getControlActivities,
  // updateLabelById,
  applyIndexByMap,
} from './utils/generateData'

import { exerciseSelector, fetchBuilderExercises } from '@state/exercise'
import {
  lensPath,
  set,
  propEq,
  dissoc,
  toPairs,
  find,
  pipe,
  insert,
  nth,
  prop,
  toUpper,
  split,
} from 'ramda'
import { Button } from '@common/Button'
import { AdvancedBlade } from './AdvancedBlade'
import { AdvancedHelper } from './AdvancedHelper'
import { uuid } from '@utils/uuid'
import { Main } from '@features/Layout'
import { AdvancedControls } from './AdvancedControls'
import {
  AdvancedSectionHeader,
  SectionDroppableContainer,
} from './AdvancedDrag.styled'

const getStepStyle = (isDragging, draggableStyles) => ({
  // change background colour if dragging
  background: isDragging ? '' : '',
  transition: isDragging
    ? 'all .3s ease-in-out !important'
    : 'all .3s ease-in-out',
  // styles we need to apply on draggables
  ...draggableStyles,
})

const generateTypes = (options = []) => options.join('_')

const isAddType =
  (id = '') =>
  (options = []) => {
    return id.includes(generateTypes(options))
  }

const getContainerStyle = (isDraggingOver, width) => {
  // const getWidth = width > 0 ? { maxWidth: `${width}px` } : {};
  const dragging = isDraggingOver
    ? {
        borderRadius: '8px',
      }
    : {}
  return {
    position: 'relative',
    ...dragging,
  }
}

/**
 * Returns an array after sorting by property
 *
 * @param key The key argument which is used to destructure against during each iteration
 * @returns {array} The sorted array
 */

const sortyByKey =
  (key = 'sequence') =>
  (list = []) =>
    list.sort((a, b) => (prop(key)(a) > prop(key)(b) ? 1 : -1))

const getTypeFromId = (id) => {
  const typeOptions = [SECTION, CONTAINER, ADD_WORKOUT, GROUP, ACTIVITY]

  const foundType = typeOptions.find((curr) => toUpper(id).includes(curr))

  return foundType
}

/**
 * Returns an object after removing a key and the value it was assigned to
 *
 * @param id The id argument which is used to look up on the obj
 * @param obj The object in which to look up on
 * @returns {object} The result object
 */

const removeKeyById = (id) => (obj) => dissoc(id, obj)

/**
 * Returns an array after accepting an object and converting it to an array
 *
 * @param data The object in which to convert
 * @returns {array} The result array
 */

const makeArray = (data = {}) => {
  const nowPairs = toPairs(data)
  const result = nowPairs.reduce((prev, [key, value]) => {
    const item = {
      id: key,
      ...value,
    }
    return [...prev, item]
  }, [])
  return result
}

const mapChildrenToId = (items = []) => {
  return items.reduce((acc, item) => {
    acc[item.id] = item.children
    return acc
  }, {})
}

// a little function to help us with reordering the result after dropping
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const firstItem = nth(0) || ''

/**
 * Returns a string if present of the parent id
 *
 * @param needle The string in which to lookup upon the following args
 * @param haystack The objectt literal in which to lookup through (notice the usage of the toPairs)
 * @returns {string} The result string if found
 */
const getParentByNestedId = (needle) => (haystack) => {
  return pipe(
    toPairs,
    find(([key, value]) => find(propEq('id', needle), value)),
    firstItem,
  )(haystack)
}

/**
 * Returns an object literal
 *
 * @param items The array in which to iterate through
 * @returns {object} The result object with the keys being the found id and the value being children which is an array
 */
const sectionItemsByKey = (items = []) =>
  items.reduce((acc, item) => {
    acc[item.id] = item.children
    return acc
  }, {})

/**
 * Returns an object literal matching the prop passed into this function
 *
 * @param id The id in which to use to lookup on and match against withing the following argument
 * @param items The array in which to use to lookup
 * @returns {object} The result object with the key of id matching the found object id key
 */
const getById =
  (id) =>
  (items = []) =>
    find(propEq('id', id))(items)

/**
 * Returns an array after destructuring the children key from data
 *
 * @param data The data object in which should have a children key
 * @returns {array} The key of children which should be an array
 */

const validate = (data) => {
  if (!data || !data.children || data.children.length <= 0) return []
  return data.children
}

/**
 * Returns an array having filtered based on the predicate
 *
 * @param id The id in which to use to lookup on and match against within the following argument
 * @param items The array in which to use to lookup
 * @returns {array} The result array
 */

const getFilteredItems =
  (id) =>
  (items = []) =>
    items.filter((k) => k.id !== id)

/**
 * Returns an object literal having filtered based on the predicate
 *
 * @param id The id in which to use to lookup on and match against within the following argument
 * @param items The array in which to use to lookup
 * @returns {object} The result object having matched the predicate
 */
const findGroupItemById =
  (id) =>
  (items = []) =>
    items.find((k) => k.id === id)

/**
 * Returns an object literal after performing a reduce and assigning the value of each iterated item to the id
 *
 * @param items The array argument which is used to iterate
 * @returns {object} The key-value pair of argument
 */

const makeObject = (items = []) => {
  const result = items.reduce((prev, curr) => {
    const item = { [curr.id]: curr }
    return { ...prev, ...item }
  }, {})
  return result
}

const getFoundDestActivity =
  (activityId) =>
  (items = []) =>
    items.find((k) => k.id === activityId)

const replaceSectionGroupLabel = (sequence) => (item) => (data) => {
  if (sequence >= data.length) {
    return console.error('replaceSectionGroup sequence is out of bounds', {
      sequence,
      item,
      data,
    })
  }
  return set(lensPath([sequence, 'children']), item)(data)
}

const replaceSectionGroup = (sequence) => (item) => (data) => {
  if (sequence >= data.length) {
    return console.error('replaceSectionGroup sequence is out of bounds', {
      sequence,
      item,
      data,
    })
  }
  return set(lensPath([sequence, 'children']), item)(data)
}

const replaceGroupActivity = (sequenceA, sequenceB) => (item) => (data) => {
  if (sequenceA >= data.length) {
    return console.error(
      'replaceSectionGroup sequenceA & sequenceB is out of bounds',
      {
        sequenceA,
        sequenceB,
        item,
        data,
      },
    )
  }
  return set(
    lensPath([sequenceA, 'children', sequenceB, 'children']),
    item,
  )(data)
}

const createSectionAtSequence =
  (index) =>
  (items = []) => {
    const section = {
      id: `${`Section`}_${uuid()} SECTION`,
      label: `${`Section`}`,
      sequence: index,
      type: 'section',
      children: [],
    }
    const withUpdatedSection = insert(index, section, items)
    const indexedWorkoutItems = applyIndexByMap(withUpdatedSection)

    return indexedWorkoutItems
  }

const createGroupAtSequence =
  ({ destParentId, destSequence }) =>
  (items = []) => {
    const group = {
      id: `${`Group`}_${uuid()} GROUP`,
      label: `${`Group`}`,
      sequence: destSequence,
      variant: 'GROUP',
      type: 'group',
      children: [],
    }

    const result = createGroupAtDestination({
      destId: destParentId,
      destSequence: destSequence,
      replaceWith: group,
    })(items)

    return result
  }

/* GROUP */

const removeGroupFromSection =
  ({ parentId, groupId }) =>
  (items = []) => {
    const sectionItem = getById(parentId)(items)
    const sectionChildren = prop('children')(sectionItem)
    const foundSectionSequence = prop('sequence')(sectionItem)

    const filteredChildrenByGroupId = getFilteredItems(groupId)(sectionChildren)
    const group = findGroupItemById(groupId)(sectionChildren)
    const indexedGroupItems = applyIndexByMap(filteredChildrenByGroupId)

    const data =
      replaceSectionGroup(foundSectionSequence)(indexedGroupItems)(items)

    return {
      data,
      group,
    }
  }

const createGroupAtDestination =
  ({ destId, destSequence, replaceWith }) =>
  (items = []) => {
    const destSectionItem = getById(destId)(items)
    const sourceSectionChildren = validate(destSectionItem)

    const destGroupChildren = insert(
      destSequence,
      replaceWith,
      sourceSectionChildren,
    )

    const indexedGroupItems = applyIndexByMap(destGroupChildren)

    const data = replaceSectionGroup(destSectionItem.sequence)(
      indexedGroupItems,
    )(items)

    return data
  }

/* ACTIVITY */

const removeActivityFromGroup =
  ({ parentId, activityId }) =>
  (items = []) => {
    const sourceSectionId = getParentByNestedId(parentId)(
      sectionItemsByKey(items),
    )
    const sourceSectionItem = getById(sourceSectionId)(items)
    const sourceSectionSequence = prop('sequence')(sourceSectionItem)
    const sourceSectionChildren = validate(sourceSectionItem)
    // Need groupItem for the sequence
    const sourceGroupItem = getById(parentId)(sourceSectionChildren)
    const sourceGroupChildren = validate(sourceGroupItem)
    const activityChildren = getFilteredItems(activityId)(sourceGroupChildren)
    const activity = getFoundDestActivity(activityId)(sourceGroupChildren)
    const indexedActivityItems = applyIndexByMap(activityChildren)

    const data = replaceGroupActivity(
      sourceSectionSequence,
      sourceGroupItem.sequence,
    )(indexedActivityItems)(items)

    return {
      data,
      activity,
    }
  }

const createActivityAtSequence =
  ({ destId, replaceWith }) =>
  (destSequence) =>
  (items = []) => {
    const destSectionId = getParentByNestedId(destId)(sectionItemsByKey(items))

    const destSectionItem = getById(destSectionId)(items)
    const destSectionSequence = prop('sequence')(destSectionItem)
    const destSectionChildren = validate(destSectionItem)
    const destGroupItem = getById(destId)(destSectionChildren)
    const destGroupChildren = validate(destGroupItem)

    const destActivityChildren = insert(
      destSequence,
      replaceWith,
      destGroupChildren,
    )

    const indexedActivityItems = applyIndexByMap(destActivityChildren)

    const result = replaceGroupActivity(
      destSectionSequence,
      destGroupItem.sequence,
    )(indexedActivityItems)(items)
    return result
  }

/**
 * Returns an object literal after performing a reduce and assigning the value of each iterated item to the id
 *
 * @param items The array argument which is used to iterate
 * @returns {object} If a match is found during the iteration it will return the object found
 */

const amendSectionLabel =
  ({ sectionId, data = null }) =>
  (items = []) => {
    const reduceOver = items.reduce((prev, curr) => {
      if (curr.id === sectionId) {
        const newItem = {
          ...curr,
          label: data,
        }
        return [...prev, newItem]
      }
      return [...prev, curr]
    }, [])

    const result = reduceOver

    return result
  }

const amendGroupLabel =
  ({ groupId, data = null }) =>
  (items = []) => {
    // Section id from groupId
    const destSectionId = getParentByNestedId(groupId)(sectionItemsByKey(items))
    // Section item {}
    const destSectionItem = getById(destSectionId)(items)
    // Section sequence
    const destSectionSequence = prop('sequence')(destSectionItem)
    // Section children
    const destSectionChildren = validate(destSectionItem)
    // Collected group outer object
    const destGroupItem = getById(groupId)(destSectionChildren)
    // Sequence of group
    const destGroupSequence = prop('sequence')(destGroupItem)

    const reduceOver = destSectionChildren.reduce((prev, curr) => {
      if (curr.sequence === destGroupSequence) {
        const newItem = {
          ...curr,
          label: data,
        }
        return [...prev, newItem]
      }
      return [...prev, curr]
    }, [])

    const result =
      replaceSectionGroupLabel(destSectionSequence)(reduceOver)(items)

    return result
  }

const amendActivityAtSequence =
  ({ groupId, data = {} }) =>
  (activitySequence) =>
  (items = []) => {
    const destSectionId = getParentByNestedId(groupId)(sectionItemsByKey(items))
    const destSectionItem = getById(destSectionId)(items)
    // used later below
    const destSectionChildren = validate(destSectionItem)
    const destGroupItem = getById(groupId)(destSectionChildren)
    const destGroupChildren = validate(destGroupItem)
    const destSectionSequence = prop('sequence')(destSectionItem)

    const reduceOver = destGroupChildren.reduce((prev, curr) => {
      if (curr.sequence === activitySequence) {
        const newItem = {
          ...curr,
          data,
        }
        return [...prev, newItem]
      }
      return [...prev, curr]
    }, [])

    const result = replaceGroupActivity(
      destSectionSequence,
      destGroupItem.sequence,
    )(reduceOver)(items)

    return result
  }

const isDevMode = false

const AdvancedDrag = () => {
  const [width, setWidth] = useState()

  const dispatch = useDispatch()
  const containerRef = useRef()
  // useParam hook is only working on a force refresh which will be helpful for external users navigating here, however for users that click the item
  // from the list it will not work as expected
  const { workoutId } = useParams()

  const activeWorkout = useSelector(workoutSelector.activeWorkout)
  const builderExercises = useSelector(exerciseSelector.builderExercises)
  const [, localDispatch] = useContext(LayoutContext)
  const activities = [...getControlActivities(), ...builderExercises]

  const [state, setState] = useState([])

  useEffect(() => {
    if (!!workoutId) {
      dispatch(getWorkoutById(workoutId))
      dispatch(fetchBuilderExercises())
    }
  }, [workoutId])

  useEffect(() => {
    localDispatch({ type: LayoutTypes.OPEN_BLADE })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (
      activeWorkout !== null &&
      activeWorkout !== undefined &&
      !!activeWorkout.workoutProtocol
    ) {
      setState(activeWorkout.workoutProtocol)
    }
  }, [activeWorkout])

  useEffect(() => {
    if (containerRef.current) {
      setWidth(containerRef.current.clientWidth - 10)
    }
  }, [])

  const [disabledItems, setDisabledItems] = useState({
    CONTAINER: false,
    SECTION: false,
    GROUP: false,
    ACTIVITY: false,
  })

  const onDragStart = (result) => {
    const { source } = result
    const droppableType = getTypeFromId(source.droppableId)

    if (source.index > 0) {
      switch (droppableType) {
        // Setting to true is setting the key to become disabled
        case 'CONTAINER':
          setDisabledItems({
            CONTAINER: false,
            SECTION: true,
            GROUP: true,
            ACTIVITY: true,
          })
          break
        case 'SECTION':
          setDisabledItems({
            CONTAINER: true,
            SECTION: false,
            GROUP: true,
            ACTIVITY: true,
          })
          break
        case 'GROUP':
        default:
          setDisabledItems({
            CONTAINER: false,
            SECTION: false,
            GROUP: false,
            ACTIVITY: false,
          })
      }
    }
  }

  const resetDisabledStates = () =>
    setDisabledItems({
      CONTAINER: false,
      SECTION: false,
      GROUP: false,
      ACTIVITY: false,
    })

  const onRemoveSection = (e, { id }) => {
    e.stopPropagation()

    const itemsAsKeyValuePair = makeObject(state)
    const updatedKeyValuePairs = removeKeyById(id)(itemsAsKeyValuePair)
    const updatedSections = makeArray(updatedKeyValuePairs)
    const indexedSections = applyIndexByMap(updatedSections)
    const sortedSections = sortyByKey('sequence')(indexedSections)

    setState(sortedSections)
  }

  const handleBladeCallback = (value) => {
    const { type } = value
    if (type === 'SAVE') {
      const updatedWorkout = {
        ...activeWorkout,
        workoutId: workoutId,
        workoutProtocol: state,
      }

      if (!prop('workoutId')(activeWorkout)) {
        console.error('No workoutId found [AdvancedDrag.js]')
        return
      }

      dispatch(updateWorkout(updatedWorkout))
    }
  }

  const handleLabelCallback = (cb) => {
    const { id, data = null } = cb

    const groupsWithAmendment = amendGroupLabel({
      groupId: id,
      data,
    })(state)

    setState(groupsWithAmendment)
  }

  const sectionLabelCallback = (cb) => {
    const { id, data = null } = cb

    const sectionWithAmendment = amendSectionLabel({
      sectionId: id,
      data,
    })(state)

    setState(sectionWithAmendment)
  }

  const handleCallback = (cb) => {
    const { data = [], groupId, activitySequence } = cb

    const itemsWithAmendment = amendActivityAtSequence({
      groupId: groupId,
      data,
    })(activitySequence)(state)

    setState(itemsWithAmendment)
  }

  const onRemoveGroup = (e, { groupId, parentId }) => {
    e.stopPropagation()

    const { data } = removeGroupFromSection({
      parentId,
      groupId,
    })(state)

    setState(data)
  }

  const onRemoveActivity = (e, { activityId, parentId }) => {
    e.stopPropagation()

    const { data } = removeActivityFromGroup({
      parentId: parentId,
      activityId: activityId,
    })(state)

    setState(data)
  }

  const onDragEnd = (result) => {
    const { destination, source, type, draggableId } = result

    // Dropped outside the list
    if (!destination) {
      resetDisabledStates()
      return
    }

    const { index: sourceIndex } = source
    const { index: destIndex } = destination

    if (type === CONTAINER) {
      if (isAddType(draggableId)([ADD_SECTION])) {
        const itemsWithInsert = createSectionAtSequence(destIndex)(state)

        setState(itemsWithInsert)

        resetDisabledStates()

        return
      } else {
        const items = reorder(state, sourceIndex, destIndex)
        const mapOverSequence = applyIndexByMap(items)
        setState(mapOverSequence)

        resetDisabledStates()
        return
      }
    }

    if (type === SECTION) {
      const itemSubItemMap = mapChildrenToId(state)

      const sourceParentId = prop('droppableId')(source)
      const destParentId = prop('droppableId')(destination)

      const sourceSubItems = itemSubItemMap[sourceParentId]

      if (isAddType(draggableId)([ADD_GROUP])) {
        const itemsWithInsert = createGroupAtSequence({
          destParentId,
          destSequence: destIndex,
        })(state)

        setState(itemsWithInsert)

        resetDisabledStates()

        return
      }

      let newItems = [...state]

      /** In this case subItems are reOrdered inside same Parent */
      if (sourceParentId === destParentId) {
        const preReorderedGroups = reorder(
          sourceSubItems,
          sourceIndex,
          destIndex,
        )

        const mapOverSequence = applyIndexByMap(preReorderedGroups)

        const groupItems = newItems.map((item) => {
          if (item.id === sourceParentId) {
            item.children = mapOverSequence
          }
          return item
        })

        setState(groupItems)

        resetDisabledStates()
        return
      } else {
        const { data, group } = removeGroupFromSection({
          parentId: source.droppableId,
          groupId: draggableId,
        })(state)

        if (data && data.length > 0) {
          const result = createGroupAtDestination({
            destId: destination.droppableId,
            destSequence: destination.index,
            replaceWith: group,
          })(data)

          setState(result)
          resetDisabledStates()
          return
        }
      }
    }

    if (type === GROUP) {
      const destParentId = prop('droppableId')(destination)
      // lookup here before adding item...
      const { destination: destId, sourceId, id } = comprehendPath(draggableId)

      if (isAddType(draggableId)([ADD_ACTIVITY])) {
        if (!id) {
          const foundActivity = getActivity(sourceId, [
            'droppableId',
            'draggableId',
            'droppableType',
            'type',
            'id',
          ])(activities)

          const activity = {
            ...foundActivity,
            id: `${destId}/${sourceId}/${uuid()}`,
            sequence: destIndex,
          }

          const itemsWithInsertion = createActivityAtSequence({
            destId: destParentId,
            replaceWith: activity,
          })(destIndex)(state)

          setState(itemsWithInsertion)
          // callback({ action: 'ADD_ACTIVITY', value: itemsWithInsertion })
          resetDisabledStates()
          return
        }
        if (!!id) {
          const { data, activity } = removeActivityFromGroup({
            parentId: source.droppableId,
            activityId: draggableId,
          })(state)

          const itemsWithInsert = createActivityAtSequence({
            destId: destParentId,
            replaceWith: activity,
          })(destIndex)(data)

          setState(itemsWithInsert)
          resetDisabledStates()
          return
        }
      }
    }
  }

  return (
    <>
      <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
        <Droppable droppableId={CONTAINER} type={CONTAINER}>
          {(provided, snapshot) => (
            <>
              <Main>
                <AdvancedControls
                  state={state}
                  callback={handleBladeCallback}
                />
                <SectionHighest
                  ref={containerRef}
                  hasChildren={!!state && !!state.length}
                >
                  <SectionDroppableContainer
                    ref={provided.innerRef}
                    isDraggingOver={snapshot.isDraggingOver}
                    style={getContainerStyle(snapshot.isDraggingOver, width)}
                  >
                    {state.map((section, index) => (
                      <Draggable
                        key={section.id}
                        draggableId={section.id}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <SectionDroppable>
                            <SectionContainer
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              key={index}
                              style={getStepStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style,
                              )}
                            >
                              <AdvancedSectionHeader>
                                <IconContainer {...provided.dragHandleProps}>
                                  <Icon name="DRAG" fill="#A9AEB9" size={20} />
                                </IconContainer>

                                <AdvancedLabel
                                  label={section.label}
                                  defaultValue="Section"
                                  id={section.id}
                                  callback={sectionLabelCallback}
                                />

                                <SectionControl>
                                  <Button
                                    center
                                    variant="white"
                                    onClick={(e) => onRemoveSection(e, section)}
                                  >
                                    <Icon name="TRASH" size={20} />
                                  </Button>
                                </SectionControl>
                              </AdvancedSectionHeader>
                              <AdvancedSection
                                disabledItems={disabledItems}
                                section={section}
                                callback={handleCallback}
                                groupLabelCallback={handleLabelCallback}
                                onRemoveGroup={onRemoveGroup}
                                onRemoveActivity={onRemoveActivity}
                              />
                              {provided.placeholder}
                            </SectionContainer>
                          </SectionDroppable>
                        )}
                      </Draggable>
                    ))}
                    {snapshot.isDraggingOver && <SectionDraggingOver />}
                  </SectionDroppableContainer>
                  {provided.placeholder}
                </SectionHighest>
                {isDevMode && <AdvancedHelper state={state} />}
              </Main>
              <AdvancedBlade activities={activities} state={state} />
            </>
          )}
        </Droppable>
      </DragDropContext>
    </>
  )
}

export { AdvancedDrag }
