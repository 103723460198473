import React, { useState, useEffect, useCallback } from 'react'
import { Formik, Form, Field } from 'formik'
import { string, object } from 'yup'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useSelector } from '@hooks/useSelector'
import { localVars } from '@features/Auth/Service/config'
import { Snackbar } from '@common/Snackbar'
import { ErrorList } from '@common/ErrorList'
import { Block } from '@common/Block'
import { Divider } from '@common/Divider'
import { InputField } from '@common/InputField'
import { Icon } from '@common/Icon'
import { Button } from '@common/Button'
import { Text } from '@common/Text'
import { Heading } from '@common/Heading'
import { Loading } from '@common/Loading'
import { authPending, authSelector, authSigninPending } from '@state/auth'

import { useKeyboardShortcut } from '@hooks/useKeyboardShortcut'
import { Center, Wrap, Group } from './Login.styled'
// import { Service } from '../Service'

// const auth = Service()

const isNext = true
const baseUrl = isNext ? '' : './public/'
const imagePath = `${baseUrl}assets/banners/login.svg`

const LoginSchema = object().shape({
  password: string()
    .max(25, 'Your password cannot be more than 25 characters in length')
    .min(7, 'Your password does not meet our length requirements')
    .required('Please enter your password'),
  email: string()
    .email('You have entered an invalid email')
    .required('Please enter your email'),
})

const Login = () => {
  const [showSnack, setSnack] = useState(localVars.mock === 'enabled')
  const authResponse = useSelector(authSelector.authResponse)
  const isLoading = useSelector(authSelector.loading)
  const authResponseSuccess = authResponse === 'SUCCESS'
  const authResponseFailure = authResponse === 'FAILURE'
  const [errors, setErrors] = useState([])
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const initialValues = {
    email: '',
    password: '',
  }

  useEffect(() => {
    if (!authResponse || !authResponseSuccess || authResponseFailure) return
    dispatch(authPending())
  }, [authResponse])

  const onCloseSnack = () => {
    setSnack(false)
  }

  const handleSubmit = (value) => {
    const { email, password } = value
    if (!email || !password) return
    dispatch(
      authSigninPending({
        email,
        password,
      }),
    )
  }

  const keysPhilip = ['Shift', 'P']
  const handleKeyboardShortcut = useCallback((keys) => {
    handleSubmit({
      email: 'philiphultgren7@gmail.com',
      password: 'Password1!',
    })
  }, [])

  useKeyboardShortcut(keysPhilip, handleKeyboardShortcut)

  const onGoogleSignIn = () => {
    //  auth.signInWithGoogle()
  }

  const onNavigateSignUp = () => navigate('/signup')

  const closeError = (error) => {
    setErrors((state) => state.filter((item) => item !== error))
  }

  const onSecret = () => {
    handleSubmit({
      email: 'philiphultgren7@gmail.com',
      password: 'Password1!',
    })
  }

  return (
    <>
      <Center>
        <Block.Container shadow mb={64} column>
          <Heading mb={8} variant="AUTH" onClick={onSecret}>
            Log In
          </Heading>
          <ErrorList
            hasClose
            haystack={[
              {
                name: 'invalid_signup',
                variant: 'error',
                message: 'Invalid credentials',
              },
              {
                name: 'generic',
                variant: 'error',
                message: 'There was a problem',
              },
              {
                name: 'failure',
                variant: 'error',
                message: 'There was a problem',
              },
              {
                name: 'success',
                variant: 'success',
                message: 'You have successfully authenticated',
              },
            ]}
            needles={errors}
            callback={closeError}
          />
          <Formik
            initialValues={initialValues}
            validationSchema={LoginSchema}
            onSubmit={(values) => handleSubmit(values)}
            validateOnChange
          >
            {() => (
              <Group>
                <Form>
                  <Field
                    name="email"
                    component={InputField}
                    label="Email"
                    type="email"
                    mb={16}
                    placeholder="Email"
                  />
                  <Field
                    name="password"
                    label="Password"
                    type="password"
                    component={InputField}
                    mb={32}
                    placeholder="Password"
                  />
                  <Button variant="orange" type="submit" mb={16}>
                    <span>Login</span>
                  </Button>
                </Form>
              </Group>
            )}
          </Formik>
          <Divider text="or" />
          <Button variant="signin" onClick={onGoogleSignIn}>
            <Icon name="GOOGLE" size={20} fill="white" viewBox="-1 -2 20 20" />
            <span style={{ marginLeft: '16px' }}>Sign in with Google</span>
          </Button>

          <Block.Flex mt={16} style={{ justifyContent: 'center' }}>
            <Text
              type="link"
              color="#1D2A43"
              config={{
                layout: {
                  textAlign: 'left',
                  fontWeight: '600',
                  lineHeight: '20px',
                },
              }}
            >
              Don't have an account?
            </Text>
            <Text
              as="link"
              color="#FF5A45"
              weight={600}
              onClick={onNavigateSignUp}
              ml={8}
              style={{
                textAlign: 'left',
                lineHeight: '20px',
              }}
            >
              Sign Up
            </Text>
          </Block.Flex>
        </Block.Container>
      </Center>
      <Wrap $imageSrc={imagePath} />
      {isLoading && <Loading message="Loading..." />}
      {showSnack && (
        <Snackbar
          descriptor="Mocks"
          label="You're using mocks!"
          variant="success"
          onClose={onCloseSnack}
          actions={[
            {
              label: 'mocks',
            },
          ]}
        />
      )}
    </>
  )
}

export { Login }
