import React, { useState, useEffect, useCallback } from 'react'
import { prop } from 'ramda'
import { useClickOutside as ClickOutside } from '@hooks/useClickOutside'
import { Icon } from '@common/Icon'
import {
  EntryWrapper,
  EntryContainer,
  EntryControl,
  EntryGroup,
  // EntryInput,
  EntryContent,
  EntryNote,
  // EntryContentRow,
  EntryContentGroup,
  EntryHeaderContainer,
  EntryImage,
  EntryHeaderDetail,
  // EntryViewGroup,
  // EntryViewItem,
  // EntryHeaderGroup,
  EntryTitle,
  // EntryHeaderItem,
} from './ActivityEntry.styled'
import { Button } from '@common/Button'
import { ActivitySetWrapper as ActivitySet } from '../ActivitySet'
import { IconContainer } from '@common/IconContainer'
import { useEscape } from '@hooks/useEscape'

const imageConfig = (type) =>
  ({
    THUMB: process.env.PUBLIC_URL + `/assets/exercises/thumb.jpg`,
  }[type.toUpperCase() || 'THUMB'])

const EntryHeader = ({
  onEdit = () => {},
  onRemove = () => {},
  label = '',
  image = '',
  isRest,
  ...props
}) => {
  return (
    <EntryHeaderContainer className="EntryHeader" {...props}>
      <EntryHeaderDetail>
        {image && !isRest && <EntryImage imageSrc={image} />}
        {!!isRest && (
          <IconContainer>
            <Icon mr={4} size={24} name="REST" />
          </IconContainer>
        )}
        {label && <EntryTitle>{label}</EntryTitle>}
      </EntryHeaderDetail>
      <EntryControl className="EntryControl">
        {!isRest && (
          <Button variant="white" className="EDIT" onClick={onEdit}>
            <Icon name="EXPAND" size={20} />
          </Button>
        )}
        <Button className="TRASH" variant="white" onClick={onRemove}>
          <Icon name="TRASH" size={20} />
        </Button>
      </EntryControl>
    </EntryHeaderContainer>
  )
}

const getLabel = (exerciseName, label) => {
  if (exerciseName) {
    return exerciseName
  }
  return label
}

const ActivityEntry = ({
  onRemove = () => {},
  callback = () => {},
  dragProps = {},
  sectionId,
  groupId,
  activityId,
  activitySequence,
  data = [],
  exerciseName,
  variant,
  ...props
}) => {
  const [isExpanded, setExpanded] = useState(false)
  const id = prop('id')(props) || ''
  const notes = prop('notes')(props) || ''
  const label = getLabel(exerciseName, prop('label')(props))
  const image = prop('image')(props) || imageConfig('THUMB')

  const getVariant = (variant) => {
    if (!!variant && variant.toUpperCase() === 'REST') {
      return true
    }
    return false
  }

  const isRest = getVariant(variant)

  const onToggle = () => {
    if (isExpanded) {
      setExpanded(false)
    } else {
      setExpanded(true)
    }
  }

  const handleClose = () => {
    setExpanded(false)
  }

  const handleRemove = (e) => {
    e.stopPropagation()
    onRemove(e, id)
  }

  const handleSetCallback = (data) => {
    callback({
      activityId,
      groupId,
      sectionId,
      activitySequence,
      data,
    })
  }

  useEscape(() => setExpanded(false))

  const customDragProps = !isExpanded ? dragProps : {}

  return (
    <EntryWrapper {...customDragProps} isRest={isRest}>
      <ClickOutside callback={handleClose}>
        <EntryContainer className="EntryContainer">
          <EntryHeader
            onEdit={onToggle}
            onRemove={handleRemove}
            label={label}
            image={image}
            isRest={isRest}
          />
          {!isRest && (
            <React.Fragment>
              <EntryContent variant="EXERCISE">
                {isExpanded && (
                  <EntryGroup className="EntryGroup">
                    <ActivitySet
                      onClose={handleClose}
                      data={data}
                      callback={handleSetCallback}
                    />
                  </EntryGroup>
                )}
              </EntryContent>
              {notes && (
                <EntryContentGroup>
                  <EntryNote>{notes}</EntryNote>
                </EntryContentGroup>
              )}
            </React.Fragment>
          )}
        </EntryContainer>
      </ClickOutside>
    </EntryWrapper>
  )
}

export { ActivityEntry }
