import styled from 'styled-components'

import { flexDirectionFn, justifyContentFn, media } from '@common/Theme'

const ModalBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  ${flexDirectionFn};
  ${justifyContentFn};
`

const ModalContent = styled.div`
  padding: 24px 24px 0px 24px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`

const ModalClose = styled.button`
  width: 24px;
  height: 24px;
  padding: 0;
  margin: 0;
  border: none;
  display: flex;
  background: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  svg {
    path {
      transition: all 0.2s ease-in-out;
    }
  }
  &:hover {
    svg {
      path {
        stroke: #e0e0e0;
      }
    }
  }
`

const ModalContainer = styled.div.attrs({ className: 'ModalContainer' })`
  background: white;
  border-radius: 10px;
  box-shadow: 0px 4px 54px 0px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  width: 100%;
  min-width: 320px;
  max-width: 340px;
  left: 50%;
  top: 50%;
  position: fixed;
  transition: all 0.2s ease-in-out;
  transform: translate(-50%, -50%);
  .confirm-button {
    span {
      display: none;
    }
  }
  ${media.xs`
  max-width: 472px;
  min-width: 400px;
  .confirm-button {
    span {
      display: inline-block;
    }
  }
`}
  z-index: 200;
`

export { ModalContainer, ModalContent, ModalBlock, ModalClose }
