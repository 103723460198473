/**
 * Handles all the api requests
 */
import { MUSCLES_FETCH_SUCCESS, EQUIPMENT_FETCH_SUCCESS } from './actionTypes';

import { equipmentFetchSuccess, musclesFetchSuccess } from './data';

// function that makes the api request and returns a Promise for response
const mockApi = (type, payload) => {
  const returnType = (type) =>
    ({
      [MUSCLES_FETCH_SUCCESS]: musclesFetchSuccess,
      [EQUIPMENT_FETCH_SUCCESS]: equipmentFetchSuccess
    }[type]);

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(returnType(type));
    }, 1000);
  });
};

export const fetchMuscles = () => {
  return mockApi(MUSCLES_FETCH_SUCCESS);
};

export const fetchEquipment = () => {
  return mockApi(EQUIPMENT_FETCH_SUCCESS);
};
