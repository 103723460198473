import styled, { css } from 'styled-components'

const ExtendInput = css`
  margin: 0;
  input {
    height: unset;
    border: 1px solid transparent;
    border-radius: 4px;
    padding: 8px 8px 8px 4px;
    width: 100%;
    font-size: 16px;
    line-height: 24px;
    color: #333;
    font-weight: 600;
    background-color: #fff;
    box-shadow: none;
    outline: none;
    padding: 0;
    transition: all 0.3s ease-in-out;
    &:hover {
      outline: none;
      background: white;
      border: 1px solid transparent;
    }
    &:focus {
      border: 1px solid transparent;
      outline: none;
      background: white;
      font-weight: 600;
      box-shadow: none;
    }
    &:active {
      box-shadow: none;
      font-weight: 600;
      outline: none;
      background: white;
      border: 1px solid transparent;
    }
  }
`

const ChanelRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: white;

  margin-bottom: 16px;
  height: 48px;
  border-radius: 4px;
  border: 1px solid #e3e5eb;
  background: white;
  font-size: 16px;
  font-weight: normal;
  line-height: 20px;
  padding: 0px 12px;
  width: 100%;

  box-shadow: none;
  color: #778092;
`

const ChanelContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  label {
    line-height: 16px;
    font-size: 14px;
    font-weight: 500;
    font-family: 'IBM Plex Sans', sans-serif;
    color: #a9aeb9;
    display: block;
    margin: 0 0 8px 0;
    color: #121b2d;
  }
`

export { ExtendInput, ChanelRow, ChanelContainer }
