import React, { useContext, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { saveState, loadState } from '@utils/storage'
import { Icon } from '@common/Icon'
// CONTEXT
import { LayoutContext, LayoutTypes } from '../LayoutProvider'
// STATE
import { setActiveExercise, exerciseSelector } from '@state/exercise'

import {
  BladeContainer,
  BladeHidden,
  BladeStyled,
  BladeKnob,
  BladeClickable,
  BladeHeader,
  BladeControls,
  BladeButton,
  BladeContent,
  BladeTitle,
} from './Blade.styled'

const Blade = ({ children, variant }) => {
  const [state, localDispatch] = useContext(LayoutContext)
  const { bladeExpanded, bladeTitle } = state
  const isLoading = useSelector(exerciseSelector.loading)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!!loadState('BLADE')) {
      localDispatch({ type: LayoutTypes.OPEN_BLADE })
    }
    // eslint-disable-next-line
  }, [])

  const onToggle = () => {
    if (bladeExpanded) {
      localDispatch({ type: LayoutTypes.CLOSE_BLADE })
      saveState({ BLADE: false })
      dispatch(setActiveExercise({ payload: {} }))
    } else {
      localDispatch({ type: LayoutTypes.OPEN_BLADE })
      saveState({ BLADE: true })
      dispatch(setActiveExercise({ payload: {} }))
    }
  }

  return (
    <BladeContainer isExpanded={bladeExpanded}>
      <BladeClickable isOnTop={!bladeExpanded} onClick={onToggle}>
        <BladeKnob className="knob" />
      </BladeClickable>
      <BladeHidden bladeExpanded={bladeExpanded} isLoading={isLoading}>
        <BladeStyled>
          <BladeHeader>
            <BladeControls>
              <BladeTitle>{bladeTitle}</BladeTitle>
              <BladeButton onClick={onToggle}>
                <Icon name="ARROW_RIGHT" size={20} />
              </BladeButton>
            </BladeControls>
          </BladeHeader>
          <BladeContent variant={variant}>{children}</BladeContent>
        </BladeStyled>
      </BladeHidden>
    </BladeContainer>
  )
}

export { Blade }
