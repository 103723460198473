import React from 'react';
import styled from 'styled-components';
import { colors, widthFn, mtFn, mbFn } from '@common/Theme';

const TextAreaElement = styled.textarea`
  padding: 12px;
  height: 150px;
  background: #ffffff;
  border: 1px solid #e3e5eb;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(28, 17, 44, 0.0488);
  border-radius: 6px;
  margin-top: 8px;
  margin-bottom: 12px;
  width: 100%;
  position: relative;
  resize: vertical;
  font-size: 16px;
  color: #778092;
  font-weight: 400;
  line-height: 21px;
  min-height: 150px;
  ${mtFn};
  ${mbFn};
  ${widthFn};
  &::-webkit-resizer {
    display: none;
  }
  &:focus {
    font-weight: normal;
    outline: none;
    border: 1px solid ${colors.orange};
  }
  &::placeholder {
    color: #778092;
  }
`;

const TextAreaContainer = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
  &:after {
    content: '';
    border-top: 2px solid #a9aeb9;
    width: 16px;
    transform: rotate(-45deg);
    background: transparent;
    position: absolute;
    right: 4px;
    bottom: 28px;
    pointer-events: none;
    border-radius: 25%;
  }
`;

const TextAreaTab = styled.div`
  border-top: 2px solid #a9aeb9;
  width: 8px;
  transform: rotate(-45deg);
  position: absolute;
  bottom: 24px;
  z-index: 1;
  right: 5px;
  border-radius: 2px;
  pointer-events: none;
`;

const TextArea = ({ ...props }) => {
  return (
    <TextAreaContainer>
      <TextAreaTab />
      <TextAreaElement {...props} />
    </TextAreaContainer>
  );
};

export { TextArea };
