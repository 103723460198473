import { prop } from 'ramda'
import {
  PREFERENCES_FETCH_SUCCESS,
  PREFERENCES_SAVE_BOOKMARK_SUCCESS,
  PREFERENCES_REMOVE_BOOKMARK_SUCCESS,
  USER_FETCH_ME_SUCCESS,
  USER_UPDATE_SUCCESS,
  USER_CREATE_IMAGE_SUCCESS,
  USER_VALIDATE_CHANNEL_SUCCESS,
} from '../actionTypes'

export const fetchMeSuccess = (payload) => ({
  type: USER_FETCH_ME_SUCCESS,
  payload,
})

export const createUserImageSuccess = (payload) => ({
  type: USER_CREATE_IMAGE_SUCCESS,
  payload,
})

export const validateChannelSuccess = () => ({
  type: USER_VALIDATE_CHANNEL_SUCCESS,
})

export const fetchPreferencesSuccess = ({ payload }) => ({
  type: PREFERENCES_FETCH_SUCCESS,
  payload,
})

export const updateUserSuccess = (payload) => ({
  type: USER_UPDATE_SUCCESS,
  payload,
})

export const saveExerciseBookmarkSuccess = ({ payload }) => {
  const favouriteExercises = prop('favouriteExercises')(payload)
  return {
    type: PREFERENCES_SAVE_BOOKMARK_SUCCESS,
    payload: favouriteExercises,
  }
}

export const removeExerciseBookmarkSuccess = ({ payload }) => {
  const favouriteExercises = prop('favouriteExercises')(payload)
  return {
    type: PREFERENCES_REMOVE_BOOKMARK_SUCCESS,
    payload: favouriteExercises,
  }
}
