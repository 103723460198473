import React, { useEffect } from 'react'
import { Play } from '@features/Play'

import { Tabs } from '@common/Tabs'
import { Main, Header, Content, Layout, Menu } from '@features/Layout'
import { useNavigate } from 'react-router-dom'
import { Back } from '@components/Back'
import { useParams } from 'react-router-dom'
import { format } from 'date-fns/fp'
import { prop } from 'ramda'
import { Loading } from '@common/Loading'
import { useDispatch } from 'react-redux'
import { getWorkoutById, workoutSelector } from '@state/workout'
import { useSelector } from 'react-redux'

const options = [
  {
    label: 'Playing',
    value: 'playing',
    content: null,
    route: '/playing',
    roles: ['PERSONALTRAINER', 'CLIENT'],
  },
]

const filterItemsByVariant = (items) => {
  // Define the variants that should be included
  const allowedVariants = ['EXERCISE', 'REST', 'SURVEY']

  // Filter the items array based on the variant property
  const filteredItems = items.filter((item) => {
    // Check if the item has the 'variant' property and if its value is included in allowedVariants
    return (
      item.hasOwnProperty('variant') && allowedVariants.includes(item.variant)
    )
  })

  return filteredItems
}

const applyPlayProperties = (data) => {
  if (!data || !data.length) return []
  // const result = filterItemsByVariant(data)
  // only return items that have variant that is either "EXERCISE" or "REST" or "SURVEY"

  return data.map((item) => {
    return {
      ...item,
      date: format('yyyy-MM-dd')(new Date()),
      status: 'IS_PENDING',
      timerStatus: 'IS_PAUSED',
      remainingSeconds: null,
      minutes: 0,
      variant: 'EXERCISE',
      seconds: item.rest,
    }
  })
}

const PlayPage = () => {
  const { startId } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const loading = useSelector(workoutSelector.loading)
  const activeWorkout = useSelector(workoutSelector.activeWorkout)
  const workoutProtocol = useSelector(workoutSelector.workoutProtocol)

  const onNavigate = () => {
    navigate('/start')
  }

  useEffect(() => {
    if (!startId) return
    console.log({ startId })
    dispatch(getWorkoutById(startId))
  }, [startId])

  const workoutName = prop('workoutName')(activeWorkout) || ''

  const data = applyPlayProperties(workoutProtocol)

  const handleCallback = ({ action, value }) => {
    console.log({ action, value })
  }
  return (
    <Layout variant="CUSTOM">
      <Menu />
      <Main className="Content">
        <Header config={{ isProfileEnabled: true }}>
          <Tabs
            options={options}
            active={'playing'}
            loading={false}
            showPanel={false}
          />
        </Header>
        <Content>
          <Back mb={32} onPress={onNavigate} />
          {loading ? (
            <Loading />
          ) : (
            <Play data={data} label={workoutName} callback={handleCallback} />
          )}
        </Content>
      </Main>
    </Layout>
  )
}

export default PlayPage
