import React, { useState, useEffect } from 'react'

// DEPENDENCIES
import { Icon } from '@common/Icon'
import styled, { css } from 'styled-components'
import axios from 'axios'
import { getHeaders } from '@state/utils'

import {
  CardContainer,
  CardHeader,
  CardDetail,
  CardText,
  CardControls,
  CardTitle,
  CardButton,
  CardFooter,
} from './Card.styled'

const API = {
  path: `https://stagek.api.aima.fit/aima`,
}

const ImageElement = styled.div`
  width: 168px;
  height: 220px;
  background-image: none;
  background-position: 50% 50%;
  background: #f4f5f8;
  ${(props) =>
    props.imageSrc &&
    css`
      background-size: cover;
      background-repeat: no-repeat;
      background-image: ${`url(${props.imageSrc})`};
    `};
`

const ImageDefault = styled.div`
  width: 100%;
  height: 220px;
  border-radius: 8px;
  background: #f4f5f8;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgb(227, 229, 235);
`

const getBlobFromImageId = async ({ parentId, imageId, kind }) => {
  const config = {
    url: `${API.path}/${kind}/download-image/${parentId}/${imageId}`,
    method: 'GET',
    headers: getHeaders(['AUTH']),
    responseType: 'blob',
  }
  const blob = await axios(config).then((response) => response.data)
  if (!blob) return null
  return blob
}

const Image = ({ imageId, parentId, variant, kind, className = '' }) => {
  const [blob, setBlob] = useState(null)
  useEffect(() => {
    const fetchBlob = async () => {
      try {
        let result = await getBlobFromImageId({ parentId, imageId, kind })
        setBlob(result)
      } catch (e) {
        setBlob(null)
      }
    }
    fetchBlob()
  }, [imageId, parentId, variant])

  if (!blob) return null
  return (
    <ImageElement className={className} imageSrc={URL.createObjectURL(blob)} />
  )
}

const Card = ({
  isActive = false,
  callback = () => {},
  id,
  images = [],
  name = '',
  description = '',
  footer = '',
  children,
}) => {
  const renderImages = (images) => {
    if (!images || !images.length)
      return (
        <ImageDefault>
          <Icon name="IMAGE" size={48} />
        </ImageDefault>
      )

    return (
      <Image
        imageId={images[0].id}
        variant={'banner'}
        parentId={id}
        kind={'workout'}
        className="card"
      />
    )
  }
  return (
    <CardContainer
      onClick={() => callback({ action: 'SELECT', value: id })}
      isActive={isActive}
      asRow={false}
    >
      <CardHeader>
        {name && <CardTitle>{name}</CardTitle>}
        <CardControls>
          <CardButton onClick={() => callback({ action: 'PLAY' })}>
            <span className="label">Start</span>
            <Icon name="PLAY_FILLED" stroke="black" size={20} />
          </CardButton>
        </CardControls>
      </CardHeader>
      {renderImages(images)}
      {description && (
        <CardDetail>
          <CardText>{description}</CardText>
        </CardDetail>
      )}

      {children}
      {footer && <CardFooter>{footer}</CardFooter>}
    </CardContainer>
  )
}

export { Card }
