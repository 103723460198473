import React from 'react'

const TaskRemaining = ({ seconds }) => {
  const minutes = Math.floor(seconds / 60)
  const remainingSeconds = seconds % 60
  return (
    <span className="remaining">
      {minutes}:{remainingSeconds < 10 ? '0' : ''}
      {remainingSeconds}
    </span>
  )
}

export { TaskRemaining }
