import React from 'react'
import { useClickOutside as ClickOutside } from '@hooks/useClickOutside'
import { keys, toUpper, includes } from 'ramda'
import { Overlay } from './Modal.styled'

import SavingContent from './Variants/SavingContent'
import LoadingContent from './Variants/LoadingContent'
import LogoutContent from './Variants/LogoutContent'
import TokenContent from './Variants/TokenContent'
import ErrorContent from './Variants/ErrorContent'
import ConfirmContent from './Variants/ConfirmContent'
import DeleteWorkout from './Variants/DeleteWorkout'

import { useEscape } from '@hooks/useEscape'

const withBase =
  (Component) =>
  ({ callback = () => {}, ...props }) => {
    const handleCallback = () => {
      callback({ action: 'CLOSE' })
    }

    useEscape(() => {
      callback({ action: 'CLOSE' })
    })

    return (
      <>
        <ClickOutside callback={handleCallback}>
          <Component callback={handleCallback} {...props} />
        </ClickOutside>
        <Overlay level={1} isActive onClick={handleCallback} />
      </>
    )
  }

const config = {
  LOADING: LoadingContent,
  SAVING: SavingContent,
  LOGOUT: LogoutContent,
  DELETE_WORKOUT: DeleteWorkout,
  CONFIRM: ConfirmContent,
  TOKEN: TokenContent,
  ERROR: ErrorContent,
}

const Modal = ({ variant, children, ...props }) => {
  if (!includes(toUpper(variant), keys(config))) {
    return (
      <div>
        {`Please provide an appropriate key for the <Modal /> component.`}
      </div>
    )
  }

  const foundConfig = config[toUpper(variant)]
  const Component = withBase(foundConfig)

  return <Component {...props}>{children}</Component>
}

export { Modal }
