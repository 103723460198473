import styled from 'styled-components'
import { paddingFn } from '@common/Theme'

const SectionContent = styled.div`
  padding: 0 16px;
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  border-radius: 4px;
  ${paddingFn};
  h2 {
    font-weight: 600;
    font-size: 20px;
    color: white;
  }
  ${(props) => props.extend};
`

export { SectionContent }
