import { colors } from '@common/Theme'
import styled, { css } from 'styled-components'

const BladeItem = styled.li`
  list-style: none;
  margin: 0;
  display: flex;
  width: 100%;
  border-radius: 8px;
  padding: 12px 16px;
  display: flex;
  border: 1px solid #e3e5eb;
  border-radius: 6px;
  background-color: white;
  position: relative;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
  ${(props) =>
    props.isDisabled &&
    css`
      background: #f2f2f2;
      pointer-events: none;
      opacity: 0.7;
      user-select: none;
      border: 1px solid #eaeaea;
      cursor: not-allowed;
      &:hover {
        cursor: not-allowed;
      }
    `};
`

const BladeType = styled.span`
  padding: 3px;
  background: #53b253;
  border-radius: 3px;
  color: white;
  display: inline;
  position: absolute;
  font-size: 13px;
  top: 50%;
  transform: translate(-10px, calc(-50%));
  right: 0px;
  span {
    font-weight: 600;
    color: black;
  }
`

const BladeGroupContainer = styled.div`
  border-radius: 8px;
`

const BladeGroup = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
  ${(props) =>
    props.variant === 'exercises' &&
    css`
      max-height: 80px;
      height: 72px;
      overflow: hidden;
      .BladeItem {
        border: 1.5px solid ${colors.purple};
        background: rgb(176 59 238 / 8%);
      }
    `};
  ${(props) =>
    props.variant === 'surveys' &&
    css`
      .BladeItem {
        border: 1.5px solid ${colors.gold};
        background: rgb(247 192 58 / 15%);
      }
    `};
  ${(props) =>
    props.variant === 'controls' &&
    css`
      max-height: 80px;
      height: 72px;
      overflow: hidden;
      .BladeItem {
        border: 1.5px solid ${colors.blue};
        background: rgb(58 163 238 / 7%);
        margin: 0;
      }
    `};

  &::-webkit-scrollbar-track {
    background: none;
    border: none;
    box-shadow: none;
    border-radius: 4px;
    width: 14px;
  }
  &::-webkit-scrollbar-thumb {
    background: #eeeff1;
    border: none;
    box-shadow: none;
    border-radius: 4px;
  }
  ${(props) =>
    props.maxHeight &&
    css`
      max-height: ${props.maxHeight}px;
    `};
`

const BladeItemContent = styled.div`
  display: flex;
  margin-left: 8px;
  flex-direction: column;
  padding-top: 8px;
  padding-bottom: 8px;
  .BladeType {
    display: none;
  }
`

const BladeItemLabel = styled.span`
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0px;
  color: #b5b5b5;
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 21px;
  max-width: 200px;
`

const BladeHeading = styled.h4`
  color: white;
  font-size: 14px;
  font-weight: 600;
  margin-top: 0;
  color: rgb(181 181 181);
  margin-bottom: 8px;
`

const BladeSearchContainer = styled.div``

const BladeControls = styled.div`
  display: flex;
  flex-direction: column;

  &.controls {
    gap: 16px;
  }
  &.exercises {
    gap: 16px;
    max-height: 400px;
    overflow: auto;
  }
  &.surveys {
    gap: 16px;
  }
  &.exercises {
    padding-right: 8px;
  }
`

export {
  BladeItem,
  BladeGroupContainer,
  BladeControls,
  BladeGroup,
  BladeItemContent,
  BladeItemLabel,
  BladeType,
  BladeHeading,
  BladeSearchContainer,
}
