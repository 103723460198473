import {
  WORKOUTS_FETCH_FAILURE,
  WORKOUTS_UPDATE_FAILURE,
  WORKOUTS_CREATE_FAILURE,
  WORKOUTS_DELETE_FAILURE,
  WORKOUTS_BY_ID_FAILURE,
  WORKOUTS_CREATE_IMAGE_FAILURE,
  WORKOUTS_UPDATE_IMAGE_FAILURE,
  WORKOUTS_UPDATE_LISTEN_FAILURE,
} from '../actionTypes';

export const fetchWorkoutsFailure = payload => ({
  type: WORKOUTS_FETCH_FAILURE,
  payload,
});

export const createWorkoutFailure = payload => ({
  type: WORKOUTS_CREATE_FAILURE,
  payload,
});

export const updateWorkoutFailure = payload => ({
  type: WORKOUTS_UPDATE_FAILURE,
  payload,
});

export const updateWorkoutListenFailure = payload => ({
  type: WORKOUTS_UPDATE_LISTEN_FAILURE,
  payload,
});
export const deleteWorkoutFailure = payload => ({
  type: WORKOUTS_DELETE_FAILURE,
  payload,
});

export const getWorkoutByIdFailure = payload => ({
  type: WORKOUTS_BY_ID_FAILURE,
  payload,
});

export const createWorkoutImageFailure = error => ({
  type: WORKOUTS_CREATE_IMAGE_FAILURE,
  payload: error,
});

export const updateWorkoutImageFailure = error => ({
  type: WORKOUTS_UPDATE_IMAGE_FAILURE,
  payload: error,
});
