import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { values, prop } from 'ramda'

import { Icon } from '@common/Icon'
import { Modal } from '@common/Modal'
import { loadState } from '@utils/storage'

import Auth from '@features/Auth'

import { usePortal as Portal } from '@hooks/usePortal'
import { LayoutContext } from '@features/Layout'

import { System } from '@components/System'
import { Token } from '@components/Token'
import { useSelector } from '@hooks/useSelector'
import { authSelector } from '@state/auth'

// import { prop } from 'ramda';
// import { loadState } from '@utils/storage';

import {
  MenuFooterContainer,
  FooterGroup,
  FooterItem,
  FooterIcon,
  FooterText,
  UserImage,
  UserContent,
  UserContainer,
  UserName,
  UserEmail,
} from './MenuFooter.styled'

// const auth = Auth.Service()

const isNext = true
const baseUrl = isNext ? '' : './public/'

const imageConfig = (type) =>
  ({
    THUMB: `${baseUrl}assets/exercises/thumb.jpg`,
  }[type || 'THUMB'])

const modalConfig = {
  layout: {
    position: 'fixed',
  },
}

const MenuFooterUser = ({ user = {} }) => {
  const userName = prop('name')(user) || 'User'
  const userEmail = prop('email')(user) || ''
  const userAvatar = prop('picture')(user) || ''

  return (
    <UserContainer>
      <UserImage imageSrc={userAvatar || imageConfig('THUMB')} />
      <UserContent>
        {userName && <UserName>{userName}</UserName>}
        {userEmail && <UserEmail>{userEmail}</UserEmail>}
      </UserContent>
    </UserContainer>
  )
}

const LogoutButton = ({ item, isExpanded = false, onNavigate = () => {} }) => {
  return (
    <FooterItem
      key={item.id}
      onClick={() => onNavigate(item)}
      className="FooterItem"
    >
      <FooterIcon>
        <Icon name={item.icon} size={20} />
      </FooterIcon>
      {isExpanded && (
        <FooterText className="FooterText">{item.label}</FooterText>
      )}
    </FooterItem>
  )
}

const MenuFooter = ({ options = {} }) => {
  const [state] = useContext(LayoutContext)
  const [modal, setModal] = useState(false)
  const [userDetails, setUserDetails] = useState({
    name: '',
    email: '',
  })

  const { menuExpanded: isExpanded } = state
  const navigate = useNavigate()

  // const getUserDetails = () => {
  //   const user = loadState('user')
  //   if (user) {
  //     setUserDetails(user)
  //   }
  // }

  const onNavigate = (item) => {
    if (item.id === 'logout') {
      setModal(true)
    } else {
      navigate(item.route)
    }
  }

  const handleCallback = ({ action }) => {
    if (action === 'CLOSE') {
      setModal(false)
    }
  }

  const renderModal = () => {
    if (!!modal) {
      return (
        <Portal selector="#modal">
          <Modal
            callback={handleCallback}
            variant="LOGOUT"
            config={modalConfig}
          />
        </Portal>
      )
    }
  }

  const renderFooterOptions = () => {
    const list = values(options) || []
    if (!list || list.length <= 0) return null
    return (
      <FooterGroup>
        {list.map((item, i) => (
          <LogoutButton
            key={i}
            onNavigate={onNavigate}
            isExpanded={isExpanded}
            item={item}
          />
        ))}
      </FooterGroup>
    )
  }

  const renderHelper = () => {
    const token = loadState('token')
    return (
      token && (
        <>
          <Token />
          <System />
        </>
      )
    )
  }

  return (
    <>
      {renderHelper()}
      <MenuFooterContainer
        isExpanded={isExpanded}
        className={isExpanded ? 'isExpanded' : ''}
      >
        {renderFooterOptions()}
        {renderModal()}

        {/* <MenuFooterUser /> */}
      </MenuFooterContainer>
    </>
  )
}

MenuFooter.defaultProps = {
  options: {
    logout: {
      id: 'logout',
      route: '/logout',
      label: 'Log Out',
      icon: 'LEAVE',
    },
  },
}

export { MenuFooter }
