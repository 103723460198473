import React, { useState } from 'react'
import {
  LocationWrapper,
  LocationHeader,
  LocationExpander,
  LocationExpandButton,
} from './FileLocation.styled'

import { CopyValue } from '@common/CopyValue'
import { Toggle } from '@common/Toggle'
import { Icon } from '@common/Icon'
import { Button } from '@common/Button'

const FileLocation = ({
  id,
  title,
  path,
  children,
  resetPadding,
  isExpanded,
  extend,
  callback = () => {},
}) => {
  const [state, setState] = useState({
    [`isEditMode-${id}`]: false,
  })

  const onChangeToggle = ({ name, value }) => {
    setState((state) => ({
      ...state,
      [name]: value,
    }))
  }

  const varMode = `isEditMode-${id}`
  const isEditVar = state[varMode]

  return (
    <>
      <LocationHeader extend={extend} className="LocationHeader">
        {title && <h2>{title || 'File Path'}</h2>}
        {!!path && (
          <>
            <Toggle
              className="Toggle"
              isActive={isEditVar}
              onChange={onChangeToggle}
              name={varMode}
            />
            <Button
              ml={16}
              variant="Grey"
              extend={LocationExpandButton}
              onClick={() => callback({ action: 'EXPAND' })}
            >
              <Icon
                name={isExpanded ? 'COLLAPSE' : 'EXPAND'}
                size={16}
                stroke={`rgb(56 63 69)`}
              />
            </Button>
          </>
        )}
      </LocationHeader>
      {!!path && isEditVar && (
        <LocationWrapper
          className={resetPadding ? 'LocationWrapper reset' : 'LocationWrapper'}
        >
          <LocationExpander isExpanded={isEditVar}>
            <CopyValue
              label="Source"
              value={path}
              config={{ isValueVisible: true, isLabelVisible: true }}
            />
            {children}
          </LocationExpander>
        </LocationWrapper>
      )}
    </>
  )
}

export { FileLocation }
