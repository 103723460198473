import styled, { css } from 'styled-components'
import { mtFn, mbFn, mrFn, mlFn, colors, maxWidthFn } from '@common/Theme'

const disabledFn = css`
  ${(props) =>
    props.disabled === true &&
    css`
      background: #a9aeb9;
      pointer-events: none;
      opacity: 0.5;
      span {
        user-select: none;
      }
    `}
`

const centerFn = css`
  ${(props) =>
    props.center &&
    css`
      justify-content: center;
    `}
`

const elementLabel = css`
  ${(props) =>
    props.buttonLabel &&
    css`
      justify-content: center;
      span {
        width: unset;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        color: black;
        margin-right: 4px;
      }
    `};
`

const basicStyles = css`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  padding: 12px;
  border-radius: 4px;
  border: 1.5px solid transparent;
  transition: all 0.2s ease-in-out;
`

const basicText = css`
  line-height: 14px;
  font-size: 14px;
  font-weight: 300;
  color: white;
`

const pseudoStyles = css`
  &:focus {
    outline: none;
  }
  &:hover {
    cursor: pointer;
  }
`

const allDefaultStyles = css`
  ${basicStyles};
  ${basicText};
  ${pseudoStyles}
  ${disabledFn};
  ${elementLabel};
  ${centerFn};
  ${maxWidthFn};
  ${mtFn};
  ${mbFn};
  ${mrFn};
  ${mlFn};
`

export { allDefaultStyles }
