import styled from 'styled-components'
import { allDefaultStyles } from './utils'
import { colors } from '@common/Theme'

const WhiteBasic = styled.button`
  ${allDefaultStyles};
  background: transparent;
  border: 1.5px solid white;
  span {
    color: white;
  }
  svg.SETTINGS {
    path {
      stroke: white;
    }
  }
  svg.RESEND {
    path {
      fill: #ee3a3a;
    }
    line {
      fill: #ee3a3a;
    }
  }
  svg.HISTORY {
    path {
      stroke: white;
    }
    line {
      fill: white;
    }
  }
  svg.TRASH {
    path {
      stroke: ${colors.red};
    }
    line {
      stroke: ${colors.red};
    }
  }
  &:hover {
    background: rgba(255, 255, 255, 0.1);
    span {
      font-size: 16px;
      color: white;
    }
    svg.SETTINGS {
      path {
        stroke: white;
      }
    }
    svg.TRASH {
      path {
        stroke: rgb(252, 115, 115);
      }
      line {
        stroke: rgb(252, 115, 115);
      }
    }
    svg.RESEND {
      path {
        fill: #fc7373;
      }
      line {
        fill: #fc7373;
      }
    }
  }
  ${(props) => props.extend};
`

export { WhiteBasic }
