import React, { useReducer, useMemo } from 'react';
import LayoutReducer, { initialState } from './LayoutReducer';
import LayoutContext from './LayoutContext';

const LayoutProvider = ({ children }) => {
  const [state, dispatch] = useReducer(LayoutReducer, initialState);

  const context = useMemo(() => [state, dispatch], [state, dispatch]);

  return (
    <LayoutContext.Provider value={context}>{children}</LayoutContext.Provider>
  );
};

export default LayoutProvider;
