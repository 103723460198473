import { call, put, select } from 'redux-saga/effects'
import {
  fetchExercises,
  updateExercises,
  addImage,
  removeExercise,
  createExercise,
  fetchBuilderExercises,
} from './requests'
import {
  fetchExercisesSuccess,
  updateExerciseSuccess,
  updateExerciseImageSuccess,
  createExerciseImageFailure,
  updateExerciseFailure,
  fetchExercisesFailure,
  createExerciseSuccess,
  createExerciseImageSuccess,
  deleteExerciseImageSuccess,
  updateExerciseImageFailure,
  deleteExerciseSuccess,
  deleteExerciseFailure,
  setActiveExercise,
  fetchBuilderExercisesSuccess,
  fetchBuilderExercisesFailure,
  createExerciseFailure,
  deleteExerciseImageFailure,
} from './actions'
import {
  exercises,
  activeExercise,
  images as imagesSelector,
  exerciseSelector,
} from './selectors'
import { props } from 'ramda'

export function* onCreateExercise({ payload: exercise }) {
  try {
    const response = yield call(createExercise, exercise)
    if (response && response.data) {
      const exercisesList = yield select(exercises)
      yield put(
        createExerciseSuccess({ payload: response.data, state: exercisesList }),
      )
    }
  } catch (error) {
    yield put(createExerciseFailure(error))
  }
}

export function* onFetchExercises() {
  try {
    const response = yield call(fetchExercises)
    if (response && response.data)
      yield put(fetchExercisesSuccess(response.data.content))
  } catch (error) {
    yield put(fetchExercisesFailure(error))
  }
}

export function* onFetchBuilderExercises() {
  try {
    const response = yield call(fetchBuilderExercises)
    if (response && response.data)
      yield put(fetchBuilderExercisesSuccess(response.data.content))
  } catch (error) {
    yield put(fetchBuilderExercisesFailure(error))
  }
}

// ADJUST NAME TO DELETE
export function* onDeleteExercise({ payload }) {
  try {
    const response = yield call(removeExercise, payload)
    if (response && response.data) {
      const { successMessage, errorMessage } = response.data
      if (successMessage) {
        const exercisesList = yield select(exercises)
        yield put(
          deleteExerciseSuccess({
            payload: payload,
            state: exercisesList,
          }),
        )
      } else if (errorMessage) {
        yield put(deleteExerciseFailure(error))
      }
    }
  } catch (error) {
    yield put(deleteExerciseFailure(error))
  }
}

export function* onUpdateExercise({ payload: exercise }) {
  try {
    const response = yield call(updateExercises, {
      body: exercise,
      params: exercise.exerciseId,
    })
    const exercisesList = yield select(exercises)
    yield put(
      updateExerciseSuccess({ payload: response.data, state: exercisesList }),
    )
  } catch (error) {
    yield put(updateExerciseFailure(error))
  }
}

export function* onCreateExerciseImage({ payload: { uid, rawImage } }) {
  try {
    const response = yield call(addImage, rawImage)
    if (response && response.data)
      yield put(
        createExerciseImageSuccess({
          payload: { uid, imageId: response.data.imageid },
        }),
      )
  } catch (error) {
    yield put(createExerciseImageFailure(error))
  }
}

/**
 *step1: addImage(api call) => uploads the rawImage in server and returns "imageId" of the uploaded image
 step2 : updateActiveExercise(local call) => updates the activeExercise with the uploadedImageIds in images key(check actions.js for logic)
 step3 : updateExercises(api call) => updates the exercise on server with the updated active exercise
 * @param {*} payload : {uid : "", rawImage : blob} accepts the uid and blob of the selected file
 */
export function* onUpdateExerciseImage({ payload }) {
  try {
    const { uid, rawImage } = payload
    const imageResponse = yield call(addImage, rawImage) // returns imageId
    if (imageResponse.data) {
      const activeExerciseObject = yield select(activeExercise) // gets activeExerciseObject from selector
      const updatedActiveExercise = yield put(
        updateExerciseImageSuccess({
          payload: { uid, imageId: imageResponse.data.imageid },
          state: activeExerciseObject,
        }),
      )
      const { payload: activeExercisePayload } = updatedActiveExercise

      const exercisesList = yield select(exercises)
      const updateResponse = yield call(updateExercises, {
        body: activeExercisePayload.activeExercise,
        params: activeExercisePayload.activeExercise.id,
      }) // returns imageId
      if (updateResponse.data) {
        yield put(
          updateExerciseSuccess({
            payload: activeExercisePayload.activeExercise,
            state: exercisesList,
          }),
        )
      }
    } else {
      yield put(
        updateExerciseImageFailure(
          'Something went wrong while updating the image.',
        ),
      )
    }
  } catch (error) {
    yield put(
      updateExerciseImageFailure(
        error,
        'Something went wrong while updating the image.',
      ),
    )
  }
}

// ADJUST NAME to DELETE
export function* onDeleteExerciseImage({ payload: uid }) {
  try {
    const imageIdsObject = yield select(imagesSelector)
    const filteredImageIdObject = imageIdsObject.find(
      (imageId) => imageId.uid == uid,
    )
    const activeExercise = select(exerciseSelector.activeExercise)
    const images = activeExercise.images
    const updatedActiveExercise = {
      ...activeExercise,
      images:
        images &&
        images.length > 0 &&
        images.filter((id) => id !== props('imageId')(filteredImageIdObject)),
    }
    const updateExerciseResponse = yield call(updateExercises, {
      body: updatedActiveExercise,
      params: updatedActiveExercise.id,
    })
    if (updateExerciseResponse.data) {
      const exerciseList = yield select(exerciseSelector.exercises)

      yield put(
        updateExerciseSuccess(updateExerciseResponse.data, exerciseList),
      )
      // gets imagesSelector from selector
      yield put(
        deleteExerciseImageSuccess({
          payload: uid,
          state: imageIdsObject,
        }),
      )
    } else {
    }
  } catch (error) {}
}

export function* onDeleteUpdateExerciseImage({ payload: uid }) {
  try {
    const imageIdsObject = yield select(imagesSelector) // gets images from selector
    const { payload: updateImageIds } = yield put(
      deleteExerciseImageSuccess({
        payload: uid,
        state: imageIdsObject,
      }),
    )
    // updating activeExerciseObject with updated list of imagesSelector
    const imageIds =
      updateImageIds && updateImageIds.length > 0
        ? updateImageIds.map((item) => item.imageId)
        : []
    const activeExerciseObject = yield select(activeExercise) // gets activeExerciseObject from selector
    const updatedActiveExercise = { ...activeExerciseObject, images: imageIds }
    yield put(setActiveExercise({ payload: updatedActiveExercise }))
    // update the exercise on server
    const updateExerciseResponse = yield call(updateExercises, {
      body: updatedActiveExercise,
      params: updatedActiveExercise.id,
    })
    if (updateExerciseResponse && updateExerciseResponse.data) {
      // updating exerciseList in reducer
      const exercisesList = yield select(exercises)
      yield put(
        updateExerciseSuccess({
          payload: updatedActiveExercise,
          state: exercisesList,
        }),
      )
    }
  } catch (error) {
    yield put(deleteExerciseImageFailure(error))
  }
}
