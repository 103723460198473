import React from 'react';
import { Modal } from '@common/Modal';
import { useNavigate } from 'react-router-dom';

const Error = () => {
  const navigate = useNavigate();

  const handleCallback = () => {
    navigate('/login');
  };

  return (
    <Modal
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      data={{
        message: 'Go back',
      }}
      variant="ERROR"
      onClick={handleCallback}
    />
  );
};

export default Error;
