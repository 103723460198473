import React from 'react'
import styled, { css } from 'styled-components'

const EntryWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-left: 8px;
  background: white;
  border-radius: 6px;
  padding: 8px;
  border: 1px solid #eaeaea;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  .EntryHeader {
    .EntryControl {
      transition: all 0.2s ease-in-out;
      opacity: 0;
    }
  }
  &:hover {
    .EntryHeader {
      .EntryControl {
        opacity: 1;
      }
    }
  }
  ${(props) =>
    props.isRest &&
    css`
      /* background-color: #f8f9fa; */
      background: white;
      border: 1px solid #dbdfe7;
    `}
  &:active {
    box-shadow: -5px 2px 9px 2px #eaeaea;
  }
  > div:first-of-type {
    background: transparent;
    width: 100%;
  }
`

const EntryContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1.5px solid transparent;
  box-sizing: border-box;
  border-radius: 6px;
  transition: all 0.3s ease-in-out;
  width: 100%;

  ${(props) =>
    props.isFocused &&
    css`
      border: none;
    `}
`

const EntryControl = styled.div`
  display: flex;
`

const EntryGroup = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`

const EntryInput = styled.input`
  border: none;
  outline: none;
  border: 1px solid #e9ebf1;
  width: 100%;
  max-width: 72px;
  min-width: 72px;
  width: 72px;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 9px 12px;
  color: #121b2d;
  margin-left: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 12px;
  &:first-child {
    margin-left: 12px;
  }
`

const EntryRemove = styled.button`
  width: 20px;
  margin: 0 0 0 12px;
  padding: 0;
  border: none;
  display: flex;
  background: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    svg {
      path {
        stroke: #e0e0e0;
      }
      line {
        stroke: #e0e0e0;
      }
    }
  }
`

const RestContent = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  button.Entry {
    opacity: 0;
    transition: all 0.3s ease-in-out;
  }
  &:hover {
    button.Entry {
      opacity: 1;
    }
  }
`

const ExerciseContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  button.Entry {
    opacity: 0;
    transition: all 0.3s ease-in-out;
  }
  &:hover {
    button.Entry {
      opacity: 1;
    }
  }
`

const EntryContent = ({ variant = 'EXERCISE', children }) =>
  ({
    REST: <RestContent>{children}</RestContent>,
    EXERCISE: <ExerciseContent>{children}</ExerciseContent>,
  }[variant.toUpperCase()])

// style={{ flexDirection: !isEditMode && !isRest ? 'row' : 'column' }}

const EntryNote = styled.div`
  background: rgba(108, 32, 204, 0.08);
  mix-blend-mode: multiply;
  border-radius: 7px;
  padding: 10px;
  color: #894cd6;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  display: flex;
  align-items: center;
`

const EntryContentGroup = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
`

const EntryContentRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
`

const EntryHeaderContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
`

const EntryHeaderDetail = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
`

const EntryImage = styled.div`
  width: 38px;
  height: 38px;
  min-width: 38px;
  min-height: 38px;
  border-radius: 6px;
  background-image: none;
  margin-right: 10px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background: #a9aeb9;
  ${(props) =>
    props.imageSrc &&
    css`
      background-color: transparent;
      background-image: ${`url(${props.imageSrc})`};
    `};
`

const EntryViewGroup = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
`

const EntryHeaderGroup = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 4px;
  margin-top: 12px;
`

const EntryHeaderItem = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  border: 1px solid transparent;
  padding: 0;
  margin-left: 8px;
  max-width: 72px;
  &:first-child {
    width: 100%;
    max-width: 100%;
    flex-grow: 1;
    margin-left: 0;
  }
  &.dev-mode {
    &:nth-child(1) {
      background: red;
    }
    &:nth-child(2) {
      background: green;
    }
    &:nth-child(3) {
      background: orange;
    }
    &:nth-child(4) {
      background: purple;
    }
  }
  span {
    text-align: left;
    line-height: 10px;
    font-size: 10px;
    font-weight: 500;
    color: #a9aeb9;
    text-transform: uppercase;
  }
`

const EntryHeaderSpacer = styled.div`
  margin-left: 12px;
  padding-right: 8px;
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    font-size: 10px;
    width: 20px;
    max-width: 20px;
    line-height: 10px;
    opacity: 0;
  }
`

const EntryViewItem = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid #e9ebf1;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 11px 12px;
  margin-left: 8px;
  max-width: 72px;
  &:first-child {
    margin-left: 0;
  }
  span {
    text-align: left;
    line-height: 14px;
    font-size: 14px;
    font-weight: 500;
    color: #121b2d;
  }
`

const EntryTitle = styled.h3`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  display: flex;
  align-items: center;
  color: #1d2a43;
  width: 100%;
  margin: 0;
  ${(props) =>
    props.isRest &&
    css`
      color: #898fa0;
    `}
`

export {
  EntryWrapper,
  EntryContainer,
  EntryControl,
  EntryGroup,
  EntryInput,
  EntryHeaderDetail,
  EntryContentRow,
  EntryHeaderContainer,
  EntryContent,
  EntryNote,
  EntryImage,
  EntryViewGroup,
  EntryContentGroup,
  EntryRemove,
  EntryTitle,
  EntryViewItem,
  EntryHeaderGroup,
  EntryHeaderItem,
  EntryHeaderSpacer,
}
