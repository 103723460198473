/* eslint-disable react/prop-types */
import React from 'react'

const IconPath = (props) => {
  const { stroke = '#A9AEB9' } = props
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M13.25 6C13.25 7.79493 11.7949 9.25 10 9.25C8.20507 9.25 6.75 7.79493 6.75 6C6.75 4.20507 8.20507 2.75 10 2.75C11.7949 2.75 13.25 4.20507 13.25 6Z"
        stroke={stroke}
        strokeWidth="1.5"
      />
      <path
        d="M6 12.7088H14C15.7949 12.7088 17.25 14.1639 17.25 15.9588V17C17.25 17.1381 17.1381 17.25 17 17.25H3C2.86193 17.25 2.75 17.1381 2.75 17V15.9588C2.75 14.1639 4.20507 12.7088 6 12.7088Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default IconPath
