import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from '@common/Icon'
import { Text } from '@common/Text'

import {
  ModalContainer,
  ModalBlock,
  ModalContent,
  ModalClose,
} from './ExerciseUploaderModal.styled'

const ExerciseUploaderModal = ({ callback, children }) => (
  <ModalContainer>
    <ModalContent>
      <ModalBlock direction="row" $justifyContent="space-between">
        <Text
          size="18"
          lineHeight="20px"
          fontWeight={500}
          style={{ textAlign: 'left' }}
        >
          Image Cropper
        </Text>
        <ModalClose onClick={() => callback('CLOSED')}>
          <Icon name="CLOSE" size={20} />
        </ModalClose>
      </ModalBlock>
    </ModalContent>
    {children}
  </ModalContainer>
)

ExerciseUploaderModal.propTypes = {
  callback: PropTypes.func,
}

export { ExerciseUploaderModal }
