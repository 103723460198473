import styled, { css } from 'styled-components';

const Hr = styled.hr``;

const DividerItem = styled.div`
  width: 100%;
  position: relative;
  height: 24px;
  ${props =>
    props.height &&
    css`
      height: ${props.height};
    `}
  margin-top: 16px;
  margin-bottom: 16px;
  width: 100%;
  z-index: 1;
  &:after {
    width: 100%;
    position: absolute;
    z-index: -1;
    top: 50%;
    content: '';
    border-bottom: 1px solid ${props => props.color};
  }
  &:before {
    position: absolute;
    ${props =>
      props.text &&
      css`
        content: 'or';
      `};
    left: 50%;
    top: 50%;
    ${props =>
      props.bgColor &&
      css`
        background: ${props.bgColor};
      `}
    padding-left: 16px;
    padding-right: 16px;
    text-align: center;
    transform: translate(-50%, -50%);
  }
  ${props =>
    props.verticalPush &&
    css`
      margin-top: ${props.verticalPush};
    `}
  ${props =>
    props.horizontalPush &&
    css`
      margin-left: ${props.horizontalPush};
    `}
`;

export { Hr, DividerItem };
