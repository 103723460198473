export const black = 'rgb(18 27 45 / 90%)'
export const liquorice = '#222222'
export const steel = '#666666'
export const platinum = '#cccccc'
export const aluminium = '#dddddd'
export const aluminiumLight = '#eeeeee'
export const offwhite = '#f5f5f5'
export const white = '#ffffff'
export const maroon = '#870000'
export const darkred = '#cd0000'
export const lightred = '#fef2f2'
export const pink = '#f5eaea'
export const blueBay = '#8de2e0'
export const lightBlue = '#e7f7f7'
export const success = '#35A509'
export const error = '#ed710b'
export const warning = '#ed710b'
export const forestgreen = '#35A509'
export const lightgreen = '#f3fbf1'
export const tan = '#fcebcd'
export const lightTan = '#fef9f0'
export const bloodOrange = '#ed710b'
export const checkinGreen = '#009100'
export const checkinOrange = '#ed710b'
export const paleGray = '#f4f5f6'
export const warmGray = '#888888'
export const whiteGray = '#dadada'
export const bronze = '#cd7f32'
export const suvaGray = '#8c8c8c'
export const purple = '#b03aee'
export const yellow = '#EEBC3A'
export const blue = 'rgb(58 163 238)'
export const gold = '#f7c03a'
export const red = `#EE3A3A`
export const green = '#3AEEB8'
export const transparent = 'transparent'
export const sharkGray = '#343a40'

export const orange = '#ff5a45'
export const grapefruit = '#EB3D2B'
export const blueberry = '#5186EC'
export const passionfruit = '#6C20CC'
export const grape = '#BE8BFF'
export const charcoal = '#1B1A29'
export const ocean = '#1D2A43'
export const greyXXXDark = '#5d646f'
export const greyXXDark = '#8B8E94'
export const greyXDark = '#B3B6BC'
export const grey = '#BEC2CF'
export const greyLight = '#D7DAE4'
export const greyXLight = '#EDEEEF'
export const greyXXLight = '#F6F5FC'

export const colors = {
  black,
  liquorice,
  transparent,
  charcoal,
  steel,
  sharkGray,
  platinum,
  aluminium,
  aluminiumLight,
  offwhite,
  gold,
  white,
  maroon,
  darkred,
  lightred,
  pink,
  blueBay,
  lightBlue,
  success,
  error,
  warning,
  forestgreen,
  lightgreen,
  tan,
  lightTan,
  orange,
  bloodOrange,
  checkinGreen,
  checkinOrange,
  paleGray,
  warmGray,
  whiteGray,
  bronze,
  suvaGray,
  purple,
  yellow,
  blue,
  red,
  green,
  orange,
  grapefruit,
  blueberry,
  passionfruit,
  grape,
  charcoal,
  ocean,
  greyXXXDark,
  greyXXDark,
  greyXDark,
  grey,
  greyLight,
  greyXLight,
  greyXXLight,
}
