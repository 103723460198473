import React from 'react'
import styled from 'styled-components'
import { mtFn } from '@common/Theme'

import {
  basicFunctions,
  basicStyles,
  basicText,
  pseudoStyles,
  basicIconStyles,
} from './buttonStyles'

const DisabledStyle = styled.button`
  ${basicStyles};
  ${basicText};
  ${pseudoStyles}
  ${basicFunctions};
  ${basicIconStyles};

  ${mtFn};
  padding: 12px 16px;
  background: #f4f6f9;
  border: 1px solid #e3e5eb;
  cursor: not-allowed;
  box-shadow: 0px 1px 2px rgba(28, 17, 44, 0.0488);
  border-radius: 6px;
  border-radius: 8px;
  font-weight: 500;
  transition: all 0.2s ease-in-out;
  color: #a9aeb9;
  &:hover {
    cursor: not-allowed;
    box-shadow: 0px 1px 2px rgba(28, 17, 44, 0.0488);
  }
`

const Disabled = ({ style, children, ...props }) => (
  <DisabledStyle style={style} {...props}>
    {children}
  </DisabledStyle>
)

export { Disabled }
