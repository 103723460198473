import React, { useEffect, useState } from 'react'

import { PaymentUpdate } from './PaymentUpdate'
import { PaymentDetails } from './PaymentDetails'
import { Block } from '@common/Block'
import { prop } from 'ramda'
import { settingsSelector, getAllPaymentMethodsPending } from '@state/settings'
import { useSelector } from '@hooks/useSelector'
import { useDispatch } from 'react-redux'
import { Heading } from '@common/Heading'

const { Box } = Block

const textStyle = {
  layout: {
    weight: 500,
    size: '16px',
    lineHeight: '100%',
    color: '#121b2d',
  },
}

const Payment = () => {
  const dispatch = useDispatch()
  const methods = useSelector(settingsSelector.methods)
  const [isEditMode, setIsEditMode] = useState(false)

  const toggleEditMode = () => {
    setIsEditMode(!isEditMode)
  }

  const onCancelEditMode = () => {
    setIsEditMode(false)
  }

  const getLastFour = () => {
    if (!methods || methods.length <= 0) {
      return ''
    }
    const methodCard = prop('card')(methods && methods[0])
    return methodCard && prop('last4')(methodCard)
  }

  const getBrand = () => {
    if (!methods || methods.length <= 0) {
      return ''
    }
    const methodCard = prop('card')(methods && methods[0])
    return methodCard && prop('brand')(methodCard)
  }

  const brand = getBrand()
  const lastFour = getLastFour()

  useEffect(() => {
    dispatch(getAllPaymentMethodsPending())
  }, [])

  return (
    <>
      <Heading mt={32} mb={16} variant="PAYMENT">
        Payment Methods
      </Heading>
      {!isEditMode && (
        <Box mt={16} mb={16}>
          <PaymentDetails
            onEdit={toggleEditMode}
            cardNumber={lastFour}
            brand={brand}
          />
        </Box>
      )}
      {!lastFour ||
        (isEditMode && (
          <Box mt={16} mb={16}>
            <PaymentUpdate
              onCancel={onCancelEditMode}
              title="Payment Details"
              isEdit={isEditMode}
            />
          </Box>
        ))}
    </>
  )
}

export { Payment }
