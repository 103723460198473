const musclesFetchSuccess = {
  data: {
    content: [
      { muscleId: 1, muscleName: 'Calves', isActive: true, isDeleted: false },
      {
        muscleId: 2,
        muscleName: 'Hamstring',
        isActive: true,
        isDeleted: false
      },
      {
        muscleId: 3,
        muscleName: 'Quadriceps',
        isActive: true,
        isDeleted: false
      }
    ],
    pageable: {
      sort: { empty: false, unsorted: false, sorted: true },
      offset: 0,
      pageNumber: 0,
      pageSize: 2147483647,
      paged: true,
      unpaged: false
    },
    totalPages: 1,
    totalElements: 3,
    last: true,
    size: 2147483647,
    number: 0,
    sort: { empty: false, unsorted: false, sorted: true },
    numberOfElements: 3,
    first: true,
    empty: false
  }
};

export { musclesFetchSuccess };
