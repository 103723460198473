import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useSleep } from '@hooks/useSleep'

import { Overlay } from '@common/Overlay'
import { Spinner } from '@common/Spinner'

const LoadingContainer = styled.div.attrs({ className: 'LoadingContainer' })`
  background: transparent;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  max-width: 110px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
`

const LoadingContent = styled.div`
  color: #a9aeb9;
  font-weight: 400;
  font-size: 16px;
  display: flex;
  margin-top: 10px;
  text-align: center;
  color: #1d2a43;
  font-weight: 500;
  font-size: 16px;
`

const Loading = ({
  delay,
  method,
  message = '',
  position,
  hasOverlay = false,
}) => {
  useSleep(method, delay)

  return (
    <>
      <LoadingContainer>
        <Spinner circle={`#A9AEB9`} path={`#FF5A45`} />
        {message && <LoadingContent>{message}</LoadingContent>}
      </LoadingContainer>

      {hasOverlay && <Overlay position={position} />}
    </>
  )
}

Loading.defaultProps = {
  position: 'fixed',
  hasOverlay: false,
  method: () => {},
  delay: 500,
}

export { Loading }
