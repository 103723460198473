import styled from 'styled-components'
import { allDefaultStyles } from './utils'
import { colors } from '@common/Theme'

const Red = styled.button`
  ${allDefaultStyles};
  background: rgb(255 87 87 / 8%);
  border: 1.5px solid ${colors.red};
  span {
    color: #ee3a3a;
  }
  svg.LEAVE {
    path {
      stroke: #ee3a3a;
    }
  }
  svg.RESEND {
    path {
      fill: #ee3a3a;
    }
    line {
      fill: #ee3a3a;
    }
  }
  svg.CLOSE {
    path {
      stroke: #ee3a3a;
    }
  }
  svg.CANCEL {
    path {
      stroke: #ee3a3a;
    }
    line {
      fill: #ee3a3a;
    }
    circle {
      stroke: #ee3a3a;
    }
  }
  svg.TRASH {
    path {
      stroke: ${colors.red};
    }
    line {
      stroke: ${colors.red};
    }
  }
  svg.RETURN {
    path {
      stroke: ${colors.red};
    }
    line {
      stroke: ${colors.red};
    }
  }
  &:hover {
    background: rgb(255 87 87 / 16%);
    span {
      font-size: 16px;
      color: #fc7373;
    }
    svg.TRASH {
      path {
        stroke: rgb(252, 115, 115);
      }
      line {
        stroke: rgb(252, 115, 115);
      }
    }
    svg.RETURN {
      path {
        stroke: #fc7373;
      }
      line {
        stroke: #fc7373;
      }
    }
    svg.RESEND {
      path {
        fill: #fc7373;
      }
      line {
        fill: #fc7373;
      }
    }
    svg.CANCEL {
      path {
        stroke: #fc7373;
      }
      line {
        fill: #fc7373;
      }
      circle {
        stroke: #fc7373;
      }
    }
  }
  ${(props) => props.extend};
`

export { Red }
