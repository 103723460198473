export default [
  {
    lastResponse: null,
    rawJsonObject: null,
    acssDebit: null,
    afterpayClearpay: null,
    alipay: null,
    auBecsDebit: null,
    bacsDebit: null,
    bancontact: null,
    billingDetails: {
      lastResponse: null,
      rawJsonObject: null,
      address: {
        lastResponse: null,
        rawJsonObject: null,
        city: null,
        country: 'AU',
        line1: null,
        line2: null,
        postalCode: '3333',
        state: null,
      },
      email: 'philiphultgren7@gmail.com',
      name: 'James Philip Hultgren',
      phone: null,
    },
    boleto: null,
    card: {
      lastResponse: null,
      rawJsonObject: null,
      brand: 'visa',
      checks: {
        lastResponse: null,
        rawJsonObject: null,
        addressLine1Check: null,
        addressPostalCodeCheck: 'pass',
        cvcCheck: 'pass',
      },
      country: 'US',
      description: null,
      expMonth: 9,
      expYear: 2033,
      fingerprint: 'mQ4eLItx2Urh4EIC',
      funding: 'credit',
      iin: null,
      issuer: null,
      last4: '4242',
      networks: {
        lastResponse: null,
        rawJsonObject: null,
        available: ['visa'],
        preferred: null,
      },
      threeDSecureUsage: {
        lastResponse: null,
        rawJsonObject: null,
        supported: true,
      },
      wallet: null,
    },
    cardPresent: null,
    created: 1654576869,
    customer: 'cus_LfumKzA6MLt3Og',
    customerBalance: null,
    eps: null,
    fpx: null,
    giropay: null,
    grabpay: null,
    id: 'pm_1L7uE4CTOd88lFo5cfWe2kyX',
    ideal: null,
    interacPresent: null,
    klarna: null,
    konbini: null,
    livemode: false,
    metadata: {},
    object: 'payment_method',
    oxxo: null,
    p24: null,
    paynow: null,
    sepaDebit: null,
    sofort: null,
    type: 'card',
    usBankAccount: null,
    wechatPay: null,
    customerObject: null,
  },
];
