import React from 'react';
import { WorkoutCreator } from './WorkoutCreator';
import { WorkoutBuilderContainer } from './WorkoutBuilderForm.styled';

const WorkoutBuilderForm = () => (
  <>
    <WorkoutBuilderContainer>
      <WorkoutCreator />
    </WorkoutBuilderContainer>
    <div id="workout-controls" />
  </>
);

export { WorkoutBuilderForm };
