import React from 'react';
import { prop } from 'ramda';
import { RangeSliderView } from '@common/RangeSliderView';
import {
  StorageContainer,
  RemainingContainer,
  RemainingTitle,
  RangeContainer,
} from './ExerciseUploaderSize.styled';

const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

const StorageRemaining = ({ value = 0 }) => (
  <StorageContainer>
    <span>{value}</span>
    <span>{`/ 5MB`}</span>
  </StorageContainer>
);

const getPercentage = (images = []) => {
  const totalBytes = images.reduce((prev, curr) => {
    const preview = prop('preview')(curr);
    const size = preview ? preview.size : 0;
    return prev + size;
  }, 0);
  return totalBytes;
};

const ExerciseUploaderSize = ({ images = [] }) => {
  const totalBytes = getPercentage(images);
  const calculatedValue = Math.ceil((totalBytes / 1024 / 5000) * 100);

  return (
    <RemainingContainer>
      <RemainingTitle>{images && images.length}/2 Photos</RemainingTitle>
      <RangeContainer>
        <RangeSliderView
          disabled={true}
          value={calculatedValue}
          max={100}
          min={0}
          config={{ layout: { padding: '0px' } }}
        />
        <StorageRemaining value={formatBytes(totalBytes)} />
      </RangeContainer>
    </RemainingContainer>
  );
};

export { ExerciseUploaderSize };
