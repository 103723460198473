import styled from 'styled-components'
import { colors } from '@common/Theme'
import { allDefaultStyles } from './utils'

const Gold = styled.button`
  ${allDefaultStyles};
  background: transparent;
  border: 1.5px solid #f7c03a;
  &:hover {
    background: rgb(247 192 58 / 14%);
  }
  span {
    color: #ffd161;
  }

  svg.WEIGHT {
    path {
      stroke: ${colors.gold};
      fill: ${colors.gold};
    }
    rect {
      stroke: ${colors.gold};
      fill: ${colors.gold};
    }
  }
  svg.PLAY {
    path {
      stroke: ${colors.gold};
      fill: ${colors.gold};
    }
  }
  svg.CHEVRON {
    path {
      fill: ${colors.gold};
    }
  }
  svg.HISTORY {
    path {
      stroke: ${colors.gold};
    }
  }
  svg.REFRESH {
    path {
      stroke: none;
      fill: ${colors.gold};
    }
    rect {
      stroke: none;
      fill: ${colors.gold};
    }
  }
  ${(props) => props.extend};
`

export { Gold }
