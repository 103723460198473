import styled, { css } from 'styled-components'
import { colors } from '@common/Theme'

const labelColorFn = ({ labelColor }) =>
  labelColor &&
  css`
    color: ${labelColor || '#1d2a43'};
  `

const BaseFieldLabel = css`
  line-height: 16px;
  font-size: 14px;
  font-weight: 500;
  font-family: 'IBM Plex Sans', sans-serif;
  color: #a9aeb9;
  display: block;
  margin: 0 0 8px 0;
`

const FormFeature = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const FormGroup = styled.div`
  box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.08);
  padding: 16px 16px 32px 16px;
  border: 1px solid rgb(227, 229, 235);
  border-radius: 8px;
`

const FormControl = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  width: 100%;
  justify-content: flex-end;
`

const FlexRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`

const FlexItem = styled.div`
  flex-grow: 1;
  width: 100%;
  display: flex;
  gap: 16px;
`

const FieldLabel = styled.span.attrs({ className: 'label' })`
  ${BaseFieldLabel};
  ${labelColorFn};
  ${(props) =>
    props.error &&
    css`
      color: ${colors.grapefruit};
    `}
`

const FieldContainer = styled.div`
  margin-top: 16px;
`

const FormikContainer = styled.div`
  height: 100%;
  form {
    height: 100%;
    position: relative;
  }
`

const FormScroll = styled.div`
  width: 100%;
  padding-right: 6px;
`

const ExerciseCreatorContainer = styled.div.attrs({
  className: 'ExerciseCreator',
})`
  margin-right: 6px;
  overflow: auto;
  margin: 0;
  height: calc(100% - 130px);
  max-height: calc(100% - 130px);
  &::-webkit-scrollbar-track {
    background: none;
    border: none;
    box-shadow: none;
    border-radius: 4px;
    width: 14px;
  }
  &::-webkit-scrollbar-thumb {
    background: #eeeff1;
    border: none;
    box-shadow: none;
    border-radius: 6px;
  }
`

export {
  FlexRow,
  FlexItem,
  ExerciseCreatorContainer,
  FieldLabel,
  FormScroll,
  FieldContainer,
  FormFeature,
  FormControl,
  FormikContainer,
  FormGroup,
}
