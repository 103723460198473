import styled, { css } from 'styled-components';

const FormScroll = styled.div.attrs({
  className: 'ExerciseCreator',
})`
  margin-right: 6px;
  overflow: auto;
  margin: 0;
  padding-right: 8px;
  height: calc(100% - 64px);
  max-height: calc(100% - 64px);
  &::-webkit-scrollbar-track {
    background: none;
    border: none;
    box-shadow: none;
    border-radius: 4px;
    width: 14px;
  }
  &::-webkit-scrollbar-thumb {
    background: #eeeff1;
    border: none;
    box-shadow: none;
    border-radius: 6px;
  }
`;

export { FormScroll };
