import styled, { css } from 'styled-components';

const ImageContainer = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 24px;
`;

const ImageGroup = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  width: 100%;
  flex-direction: row;
`;

const ImageItem = styled.li`
  list-style: none;
  margin: 0;
  padding: 0;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  margin-right: 10px;
  &:nth-child(2) {
    margin-right: 0px;
  }
`;

const ImageButton = styled.button`
  width: 32px;
  height: 32px;
  padding: 0;
  margin: 0;
  border: none;
  display: flex;
  background: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: white;
  border-radius: 50%;
  border: 1px solid transparent;
`;

const ImageControls = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
`;

const Image = styled.div`
  ${props =>
    props.imageSrc &&
    css`
      width: 168px;
      height: 160px;
      background-image: none;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: cover;
      background-image: ${`url(${props.imageSrc})`};
    `};
`;

const Placeholder = styled.div`
  width: 168px;
  height: 160px;
  margin-right: 10px;
  background: #f4f5f8;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const UploaderIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  background: #f7f8fa;
  margin: 0px 0px 8px 0px;
`;

const UploaderContent = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: #838a9b;
  padding: 0;
  margin: 0;
`;

const UploaderLabel = styled.label`
  input {
    display: none;
  }
  &.custom-file-upload {
    box-sizing: border-box;
    border-radius: 8px;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    flex-direction: column;
    outline: none;
    background: #f4f5f8;
    width: 100%;
    transition: all 0.2s ease-in-out;
    border: 1px solid transparent;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    width: 168px;
    height: 160px;
  }

  .file-preview {
    margin: 0 10px;
  }
`;

export {
  ImageContainer,
  ImageGroup,
  ImageItem,
  ImageButton,
  ImageControls,
  Image,
  Placeholder,
  UploaderIcon,
  UploaderContent,
  UploaderLabel,
};
