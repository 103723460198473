import { Toggle as DefaultToggle } from './Toggle'
import styled from 'styled-components'
import { colors } from '@common/Theme'

const RestyleToggle = styled.div`
  .ToggleWrapper {
    .ToggleContent {
      input.ToggleCheckbox {
      }
      .ToggleLabel {
        background: #f7f8fa;
        &.checked {
          background: ${colors.orange};
          background: rgb(255 91 69 / 47%);
        }
      }
    }
  }
`

const Toggle = (props) => (
  <RestyleToggle>
    <DefaultToggle {...props} />
  </RestyleToggle>
)

export { Toggle }
