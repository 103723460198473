import styled, { css } from 'styled-components'
import { media } from '@common/Theme'

const ControlContainer = styled.div`
  width: 100%;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  border-bottom: 1px solid #e3e5eb;
  ${media.sm`
    flex-direction: row;
    ${(props) =>
      props.isExpanded &&
      css`
        flex-direction: column;
        .WorkoutSearchContainer {
          margin-top: 16px;
        }
      `}
  `}
`

export { ControlContainer }
