import styled, { css, keyframes } from 'styled-components';

const fadeIn = keyframes`
    0% { opacity: 0; }
    100% { opacity: 1; }
`;

const MenuFooterContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  bottom: 16px;
  margin-top: auto;
  bottom: 0;
  ${props => props.isExpanded && css``}
`;

const FooterGroup = styled.ul`
  width: 100%;
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
`;

const FooterItem = styled.li`
  margin: 0;
  padding: 0;
  list-style: none;
  padding: 13px;
  display: flex;
  width: 100%;
  border-radius: 6px;
  cursor: pointer;
  &:hover {
    background: linear-gradient(
      0deg,
      rgba(255, 90, 69, 0.1),
      rgba(255, 90, 69, 0.1)
    );
    .FooterText {
      color: #ff5a45;
    }
    svg {
      path {
        stroke: #ff5a45;
      }
    }
  }
`;

const FooterText = styled.span`
  opacity: 0;
  animation-name: ${fadeIn};
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  animation-duration: 0.3s;
  animation-delay: 0.1s;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  color: #121b2d;
`;

const FooterIcon = styled.div`
  width: 20px;
  height: 20px;
  transition: all 0.1s ease-in-out;
  align-items: center;
  justify-content: center;
  display: flex;
  .isExpanded & {
    display: none;
  }
`;

const UserContainer = styled.div`
  margin-top: 16px;
  border-top: 1px solid #e3e5eb;
  box-sizing: border-box;
  display: none;
  flex-direction: row;
  justify-content: flex-start;
  padding-top: 16px;
  width: 100%;
  opacity: 0;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  animation-duration: 0.3s;
  animation-delay: 0.1s;
  .isExpanded & {
    display: flex;
    animation-name: ${fadeIn};
  }
`;

const UserImage = styled.div`
  width: 32px;
  height: 32px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  display: flex;
  border-radius: 4px;
  overflow: hidden;
  min-width: 32px;
  ${props =>
    props.imageSrc &&
    css`
      background-image: ${`url(${props.imageSrc})`};
    `}
`;

const UserContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding-left: 8px;
`;

const UserName = styled.span`
  font-size: 14px;
  line-height: 18px;
  color: #121b2d;
  font-weight: 500;
  margin: 0;
  padding: 0;
`;

const UserEmail = styled.span`
  font-size: 12px;
  line-height: 14px;
  color: #a9aeb9;
  font-weight: 400;
  margin: 0;
  padding: 0;
`;

export {
  MenuFooterContainer,
  FooterText,
  FooterGroup,
  FooterIcon,
  FooterItem,
  UserImage,
  UserContent,
  UserContainer,
  UserName,
  UserEmail,
};
