import { getEnvironment } from '../utils';

const { baseURL } = getEnvironment();

import {
  WORKOUTS_FETCH_SUCCESS,
  WORKOUTS_CREATE_SUCCESS,
  WORKOUTS_UPDATE_SUCCESS,
  WORKOUTS_DELETE_SUCCESS,
  WORKOUTS_BY_ID_SUCCESS,
  WORKOUTS_CREATE_IMAGE_SUCCESS,
} from './actionTypes';

const API = {
  [WORKOUTS_FETCH_SUCCESS]: {
    endpoint: `${baseURL}/aima/workout/v2?&pageNo=0&pageSize=5&sortBy=workoutId&sortOrder=ASC&fetchForCurrentUser=true`,
    method: 'GET',
    headers: ['AUTH', 'JSON'],
  },
  [WORKOUTS_CREATE_SUCCESS]: {
    endpoint: `${baseURL}/aima/workout`,
    method: 'POST',
    headers: ['AUTH', 'JSON'],
  },
  [WORKOUTS_UPDATE_SUCCESS]: {
    endpoint: `${baseURL}/aima/workout`,
    method: 'PUT',
    headers: ['AUTH', 'JSON'],
  },
  [WORKOUTS_DELETE_SUCCESS]: {
    endpoint: `${baseURL}/aima/workout`,
    method: 'DELETE',
    headers: ['AUTH', 'JSON'],
  },
  [WORKOUTS_BY_ID_SUCCESS]: {
    endpoint: `${baseURL}/aima/workout`,
    method: 'GET',
    headers: ['AUTH', 'JSON'],
  },
  [WORKOUTS_CREATE_IMAGE_SUCCESS]: {
    endpoint: `${baseURL}/aima/workout/file-upload`,
    method: 'POST',
    headers: ['AUTH', 'IMAGE'],
  },
};

export { API };
