import ThemeTypes from './ThemeTypes';

const InitialState = {
  background: '#FAFAFB',
};

const Reducer = (state = {}, action) => {
  const { type, data } = action;
  switch (type) {
    case ThemeTypes.SET_BACKGROUND:
      return {
        ...state,
        background: data,
      };
    default:
      return state;
  }
};

export { InitialState };

export default Reducer;
