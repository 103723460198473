import styled from 'styled-components'
import { colors } from '@common/Theme'
import { allDefaultStyles } from './utils'

const Small = styled.button`
  ${allDefaultStyles};
  background: ${colors.transparent};
  border: 1.5px solid ${colors.black};
  span {
    color: ${colors.black};
  }
  &:hover {
    border: 1.5px solid ${colors.white};
    background: #50e5bb;
  }
  ${(props) => props.extend};
`

export { Small }
