import React, { forwardRef } from 'react'
import { Input } from './Input'
import PropTypes from 'prop-types'
import { ErrorList } from '../ErrorList'
import { path, pipe, replace, toLower, isNil, complement } from 'ramda'
import { FieldLabel, FieldContainer, TextArea } from './InputField.styled'

const getIn = (original, fieldName) => path(fieldName.split('.'), original)

const htmlId = pipe(replace(/\s+/g, '-'), toLower)

const isPresent = complement(isNil)

const InputField = forwardRef((props, ref) => {
  const {
    id,
    field,
    form,
    label,
    placeholder,
    disabled,
    labelColor,
    closeError,
    variant,
    as,
    extend,
    type,
    isErrorEnabled,
    ...rest
  } = props

  const { errors, touched, submitCount } = form
  const fieldError = getIn(errors, field.name)
  const fieldTouched = getIn(touched, field.name)

  return (
    <FieldContainer extend={extend}>
      {label && (
        <FieldLabel
          labelColor={labelColor}
          error={submitCount > 0 && fieldTouched && fieldError}
        >
          {label}
        </FieldLabel>
      )}

      {variant === 'textarea' ? (
        <TextArea
          id={id || htmlId(field.name)}
          type="text"
          autocomplete="off"
          rows={8}
          error={submitCount > 0 && fieldTouched && isPresent(fieldError)}
          disabled={disabled}
          placeholder={placeholder}
          ref={ref}
          {...field}
          {...rest}
        />
      ) : (
        <Input
          id={id || htmlId(field.name)}
          type={type}
          variant={variant}
          autocomplete="off"
          error={submitCount > 0 && fieldTouched && isPresent(fieldError)}
          disabled={disabled}
          isPassword={type === 'password'}
          placeholder={placeholder}
          ref={ref}
          {...field}
          {...rest}
        />
      )}
      {submitCount > 0 && fieldTouched && fieldError && isErrorEnabled && (
        <ErrorList
          haystack={[{ name: 'error', message: fieldError }]}
          needles={['error']}
          mb={16}
          callback={closeError}
        />
      )}
    </FieldContainer>
  )
})

InputField.defaultProps = {
  isErrorEnabled: true,
  labelColor: '#121B2D',
}

InputField.propTypes = {
  isErrorEnabled: PropTypes.bool,
  id: PropTypes.string,
  field: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    name: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  label: PropTypes.string.isRequired,
  form: PropTypes.shape({
    errors: PropTypes.object,
    touched: PropTypes.object,
    submitCount: PropTypes.number,
  }).isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
}

export { InputField }
