import {
  mtFn,
  mbFn,
  fontSizeFn,
  colorFn,
  lineHeightFn,
  weightFn,
} from '@common/Theme'
import styled, { css } from 'styled-components'

const Group = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  background: #f0f0f0;
  border-radius: 6px;
  padding: 8px;
  flex-direction: column;
  li {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-top: 4px;
    font-size: 8px;
    font-weight: 500;
  }
`

const resetStyles = css`
  padding: 0;
  margin: 0;
  font-style: normal;
  color: #29323d;
`

const baseStyles = css`
  ${resetStyles};
`

const Primary = styled.h1`
  ${baseStyles};
  font-size: 28px;
  font-weight: 600;
  line-height: 32px;
  ${fontSizeFn};
  ${lineHeightFn};
  ${weightFn};
  ${mbFn};
  ${mtFn};
  ${colorFn};
  ${(props) =>
    props.fontFamily &&
    css`
      font-family: 'Montserrat', sans-serif;
      font-optical-sizing: auto;
      font-weight: 500;
      font-style: normal;
    `};
`

const Secondary = styled.h2`
  ${baseStyles};
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 20px;
  ${fontSizeFn};
  ${lineHeightFn};
  ${mbFn};
  ${mtFn};
  ${colorFn};
`

const Tertiary = styled.h3`
  ${baseStyles};
  font-size: 26px;
  font-weight: 500;
  line-height: 30px;
  ${fontSizeFn};
  ${lineHeightFn};
  ${mbFn};
  ${mtFn};
  ${colorFn};
`

const Quaternary = styled.h4`
  ${baseStyles};
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
  ${fontSizeFn};
  ${lineHeightFn};
  ${mbFn};
  ${mtFn};
  ${colorFn};
`

const PageHeading = styled.h3`
  ${baseStyles};
  font-size: 26px;
  font-weight: 500;
  line-height: calc(26px + 8px);
  ${fontSizeFn};
  ${lineHeightFn};
  ${mbFn};
  ${mtFn};
  ${colorFn};
`

const AuthHeading = styled.h3`
  ${baseStyles};
  font-size: 26px;
  font-weight: 600;
  line-height: calc(26px + 8px);
  ${fontSizeFn};
  ${lineHeightFn};
  ${mbFn};
  ${mtFn};
  ${colorFn};
`

const RowHeading = styled.h3`
  ${baseStyles};
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  ${fontSizeFn};
  ${lineHeightFn};
  ${mbFn};
  ${mtFn};
  ${colorFn};
`

const PaymentHeading = styled.h4`
  ${baseStyles};
  font-size: 20px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  ${fontSizeFn};
  ${lineHeightFn};
  ${mbFn};
  ${mtFn};
  ${colorFn};
`

export {
  Group,
  Primary,
  Secondary,
  Tertiary,
  Quaternary,
  PageHeading,
  AuthHeading,
  RowHeading,
  PaymentHeading,
}
