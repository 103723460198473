import React, { useState, useRef, useEffect } from 'react'
import {
  alterTimerStatus,
  alterStatus,
  alterAllStatus,
  alterAllInProgress,
  alterAllIsPending,
  alterRemainingTime,
  getById,
} from './utils'

import {
  TimerGroupContainer,
  Wrapper,
  Group,
  LogContainer,
} from './Play.styled'
import { PlayItem } from './PlayItem'
import { Button } from '@common/Button'
import { Icon } from '@common/Icon'
import { prop } from 'ramda'
import { allItemsActive } from './utils'

const Play = ({ callback = () => {}, label = 'Default', data = [] }) => {
  const [state, setState] = useState({
    items: data,
    isLogged: false,
    start: new Date(),
  })
  const [, setMenuHeight] = useState(442)

  const menuRef = useRef(null)

  const onMount = (data) => {
    if (!data || !data.length) return
    const id = data[0].id
    const updatedAllItems = alterAllStatus('IS_PENDING', data)
    const updatedStatusItems = alterStatus(id, 'IN_PROGRESS', updatedAllItems)
    setState((state) => ({
      ...state,
      start: new Date(),
      items: updatedStatusItems,
    }))
  }

  useEffect(() => {
    if (!!data) onMount(data)
  }, [data])

  // if the user iteracts with the last item in the playlist then prompt with a panel suggesting to log their progress

  const handlePlayCallback = ({ action, value }) => {
    if (action === 'PLAY') {
      // only need to update the active item
      const { id } = value
      const updatedPlayStatus = alterTimerStatus(id, 'IS_PLAYING', state.items)
      setState((state) => ({
        ...state,
        items: updatedPlayStatus,
      }))
    }
    if (action === 'FINISH') {
      // will need to update the active item and the next item
      const { id } = value
      const updatedStatusCompletedItems = alterStatus(
        id,
        'COMPLETED',
        state.items,
      )

      setState((state) => ({
        ...state,
        items: updatedStatusCompletedItems,
      }))
      return
    }
    if (action === 'SKIP') {
      // will need to update the active item and the next item
      const { id, index } = value

      if (data && data.length - 1 >= index + 1) {
        const nextItemId = data[index + 1].id
        const updatedStatusSkippedItems = alterStatus(
          id,
          'SKIPPED',
          state.items,
        )
        const updatedTimeRemaining = alterRemainingTime(
          id,
          0,
          updatedStatusSkippedItems,
        )
        const updatedStatusItems = alterStatus(
          nextItemId,
          'IN_PROGRESS',
          updatedTimeRemaining,
        )
        setState((state) => ({
          ...state,
          items: updatedStatusItems,
        }))
        return
      }

      const updatedStatusSkippedItems = alterStatus(id, 'SKIPPED', state.items)
      const updatedTimeRemaining = alterRemainingTime(
        id,
        null,
        updatedStatusSkippedItems,
      )
      setState((state) => ({
        ...state,
        items: updatedTimeRemaining,
      }))
      return
    }

    if (action === 'NEXT') {
      // will need to update the active item and the next item
      const { id, index } = value
      const nextItemId = data[index + 1].id

      const updatedStatusCompletedItems = alterStatus(
        id,
        'COMPLETED',
        state.items,
      )
      const updatedStatusItems = alterStatus(
        nextItemId,
        'IN_PROGRESS',
        updatedStatusCompletedItems,
      )
      setState((state) => ({
        ...state,
        items: updatedStatusItems,
      }))
      return
    }
    if (action === 'SET_IN_PROGRESS') {
      // // will need to update the active item and the next item
      const { id } = value

      // first lookup the id in question - check to see it's current status
      // then use a conditional statement to determine the next status
      if (getById(id)(state.items).status === 'IN_PROGRESS') {
        const updatedAllInProgress = alterAllInProgress(state.items)
        setState((state) => ({
          ...state,
          items: updatedAllInProgress,
        }))
        return
      }
      const updatedAllInProgress = alterAllInProgress(state.items)
      const updatedStatusItems = alterStatus(
        id,
        'IN_PROGRESS',
        updatedAllInProgress,
      )

      const itemSeconds = state.items.find((item) => item.id === id).seconds
      const updatedTimeRemaining = alterRemainingTime(
        id,
        itemSeconds,
        updatedStatusItems,
      )

      setState((state) => ({
        ...state,
        items: updatedTimeRemaining,
      }))
      return
    }
    if (action === 'PAUSE') {
      const { id, remainingTime } = value
      const updatedActiveItems = alterTimerStatus(id, 'IS_PAUSED', state.items)
      const updatedTimeRemaining = alterRemainingTime(
        id,
        remainingTime,
        updatedActiveItems,
      )
      setState((state) => ({
        ...state,
        items: updatedTimeRemaining,
      }))
      return
    }
    if (action === 'SUBMIT_WORKOUT') {
      // do we know the count of the items?
      // ready to be used somewhere
      // will need to update the active item and the next item
      const { id } = value
      const updatedAllInProgress = alterAllInProgress(state.items)
      const updatedAllIsPending = alterAllIsPending(updatedAllInProgress)
      const updatedStatusCompletedItems = alterStatus(
        id,
        'COMPLETED',
        updatedAllIsPending,
      )

      setState((state) => ({
        ...state,
        items: updatedStatusCompletedItems,
      }))
      return
    }
    if (action === 'SUBMIT_SURVEY') {
      const { index, id } = value
      const nextItemId = data[index + 1].id
      const updatedAllInProgress = alterAllInProgress(state.items) // reset all items that are 'IN_PROGRESS' to 'IS_PENDING'
      const updatedStatusCompletedItems = alterStatus(
        id,
        'COMPLETED',
        updatedAllInProgress,
      )

      const updatedStatusItems = alterStatus(
        nextItemId,
        'IN_PROGRESS',
        updatedStatusCompletedItems,
      )
      setState((state) => ({
        ...state,
        items: updatedStatusItems,
      }))
      return
    }
  }

  const handleLog = () => {
    setState((state) => ({
      ...state,
      isLogged: true,
      items: state.items.map((item) => ({
        ...item,
        remainingSeconds: item.seconds,
        timerStatus: 'IS_PAUSED',
        status: 'IS_PENDING',
      })),
    }))

    callback({
      action: 'LOG',
      value: {
        children: state.items,
        label: label,
        start: state.start,
        end: new Date(),
      },
    })
  }

  const handleRestart = () => {
    const restartedItems = state.items.map((item) => ({
      ...item,
      remainingSeconds: item.seconds,
      timerStatus: 'IS_PAUSED',
      status: 'IS_PENDING',
    }))

    const id = data[0].id
    const updatedAllItems = alterAllStatus('IS_PENDING', restartedItems)
    const updatedStatusItems = alterStatus(id, 'IN_PROGRESS', updatedAllItems)

    setState((state) => ({
      ...state,
      isLogged: false,
      items: updatedStatusItems,
      date: new Date(),
    }))
    callback({ action: 'RESTART', value: updatedStatusItems })
  }

  useEffect(() => {
    if (menuRef.current) {
      setMenuHeight(menuRef.current.clientHeight)
    }
  }, [])

  const renderItems = () => {
    const { items } = state
    if (!items || !items.length)
      return (
        <TimerGroupContainer className="empty">
          <Group className="group">
            <h2>No Exercises</h2>
          </Group>
        </TimerGroupContainer>
      )

    const result = items.map((item, index) => {
      const variant = prop('variant', item)

      if (!!variant && variant.toUpperCase() === 'EXERCISE') {
        return (
          <PlayItem
            key={item.id}
            id={item.id}
            maxLength={items.length}
            isEdit={false}
            reps={item.reps}
            minutes={item.minutes}
            sound={item.sound}
            sets={item.sets}
            image={item.image}
            weight={item.weight}
            seconds={item.rest}
            label={item.label}
            date={item.date}
            notes={item.notes}
            resources={[]}
            index={index}
            link={item.link}
            remainingSeconds={item.remainingSeconds}
            status={item.status}
            timerStatus={item.timerStatus}
            callback={handlePlayCallback}
            config={{ enableMuteControls: false }}
          />
        )
      }
      if (!!variant && variant.toUpperCase() === 'REST') {
        return (
          <PlayItem
            key={item.id}
            id={item.id}
            variant={variant}
            maxLength={items.length}
            isEdit={false}
            reps={item.reps}
            minutes={item.minutes}
            sound={item.sound}
            sets={item.sets}
            image={item.image}
            weight={item.weight}
            seconds={item.rest}
            label={item.label}
            date={item.date}
            notes={item.notes}
            resources={[]}
            index={index}
            link={item.link}
            remainingSeconds={item.remainingSeconds}
            status={item.status}
            timerStatus={item.timerStatus}
            callback={handlePlayCallback}
            config={{ enableMuteControls: false }}
          />
        )
      }
      return null
    })

    return (
      <TimerGroupContainer>
        <Group>{result}</Group>
      </TimerGroupContainer>
    )
  }

  const renderLog = () => {
    const showLog = allItemsActive(state.items)

    if (showLog) {
      return (
        <LogContainer className="log">
          {!state.isLogged && (
            <Button variant="gold" onClick={handleLog}>
              <span>Log Progress</span>
              <Icon ml={5} name="HISTORY" />
            </Button>
          )}
        </LogContainer>
      )
    }
    if (state.isLogged) {
      return (
        <LogContainer className="log">
          <Button variant="purple" onClick={handleRestart}>
            <span>Restart</span>
            <Icon ml={5} name="REFRESH" />
          </Button>
        </LogContainer>
      )
    }
  }

  return (
    <Wrapper>
      {renderItems()}
      {renderLog()}
    </Wrapper>
  )
}

export { Play }
