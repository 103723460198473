// REDUCERS
import { combineReducers } from 'redux';
import exerciseReducer from './exercise/reducer';
import workoutReducer from './workout/reducer';
import masterReducer from './master/reducer';
import userReducer from './user/reducer';
import settingsReducer from './settings/reducer';
import authReducer from './auth/reducer';

const rootReducer = combineReducers({
  exercise: exerciseReducer,
  workout: workoutReducer,
  master: masterReducer,
  user: userReducer,
  settings: settingsReducer,
  auth: authReducer,
});

export default rootReducer;
