import styled, { css } from 'styled-components'
import { media } from '@common/Theme'

const Center = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  width: 100%;
  box-sizing: border-box;
  padding: 24px;
  position: relative;
`

const Wrap = styled.div`
  max-width: 1000px;
  width: 100%;
  position: fixed;
  top: 50%;
  left: 50%;
  height: 100%;
  z-index: -1;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  display: none;
  ${media.md`
        display: block;
    `}
  .login-image {
    display: none;
    ${media.lg`
        display: block;
    `}
  }

  ${(props) =>
    props.imageSrc &&
    css`
      background-image: none;
      background-repeat: no-repeat;
      background-position: center center;
      ${media.md`
          background-image: ${`url(${props.imageSrc})`};
        `}
    `};
  ${(props) =>
    props.custom &&
    css`
      ${media.sm`
        position: relative;
        top: unset;
        left: unset;
      `}
    `}
`

const Or = styled.div`
  width: 100%;
  position: relative;
  height: 24px;
  margin-top: 16px;
  margin-bottom: 16px;
  color: #1d2a43;
  font-weight: 500;
  &:after {
    width: 100%;
    position: absolute;
    z-index: 1;
    top: 50%;
    content: '';
    border-bottom: 1px solid #e3e5eb;
  }
  &:before {
    position: absolute;
    content: 'or';
    left: 50%;
    top: 50%;
    background: white;
    padding-left: 24px;
    padding-right: 24px;
    text-align: center;
    z-index: 2;
    transform: translate(-50%, -50%);
  }
`

const Group = styled.div`
  width: 100%;
`

export { Center, Or, Wrap, Group }
