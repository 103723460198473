import styled from 'styled-components'
import { colors } from '@common/Theme'

const RoleGroup = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
  background: white;
  border-radius: 6px;
  border: 1px solid #e3e5eb;
  width: 100%;
`

const RoleItem = styled.li`
  display: flex;
  flex-direction: column;
  padding: 16px;
  width: 100%;
  p {
    font-size: 14px;
    margin: 0;
  }
  div.status {
    display: inline-flex;
    align-items: center;
    margin-top: 12px;
  }
  h3 {
    font-size: 16px;
    font-weight: 600;
    color: #1b1a29;
    margin: 0;
  }
  ul,
  li {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  ul {
    display: inline-flex;
    flex-direction: row;
    gap: 5px;
    margin-left: 4px;
  }
  li {
    margin: 0;
    padding: 0;
    &.pt {
      background: #1b1a29;
    }
  }
  span.role {
    font-size: 14px;
    color: white;
    background: ${colors.orange};
    border-radius: 4px;
    padding: 3px 6px;
  }
`

export { RoleGroup, RoleItem }
