import React, { memo, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Icon } from '@common/Icon'
import { Loading } from '@common/Loading'
import axios from 'axios'
import {
  ImageContainer,
  ImageGroup,
  ImageItem,
  ImageButton,
  ImageControls,
  ImageElement,
  UploaderIcon,
  UploaderContent,
  UploaderLabel,
} from './ImageRenderer.styled'
import { getHeaders } from '@state/utils'
import { getValidImages } from '../utils'

const UploaderPlaceholder = ({ onChange }) => (
  <UploaderLabel className="custom-file-upload">
    <UploaderIcon>
      <Icon name="IMAGE" size={20} stroke="#838A9B" fill="#838A9B" />
    </UploaderIcon>
    <UploaderContent>Add Images</UploaderContent>
    <input type="file" accept="image/*" onChange={onChange} />
  </UploaderLabel>
)

const areEqual = (prevProps, nextProps) => {
  if (prevProps.images !== nextProps.images) {
    return false
  }
  return true
}

const API = {
  path: `https://stagek.api.aima.fit/aima`,
}

const getUrl = ({ path, kind, id, parentId }) => {
  if (kind === 'user') {
    return `${path}/${kind}/download-image/${parentId}/${id}`
  } else if (kind === 'workout') {
    return `${path}/${kind}/download-image/${parentId}/${id}`
  } else if (kind === 'exercise') {
    return `${path}/${kind}/download-image/${parentId}/${id}`
  } else {
    return ''
  }
}

const getBlobFromImageId = async (url) => {
  const config = {
    url: url,
    method: 'GET',
    headers: getHeaders(['AUTH']),
    responseType: 'blob',
  }

  const blob = await axios(config).then((response) => response.data)
  if (!blob) return null
  return blob
}

const Image = ({ imageId, parentId, variant, kind, callback = () => {} }) => {
  const [blob, setBlob] = useState(null)
  useEffect(() => {
    const fetchBlob = async () => {
      try {
        const endpoint = getUrl({
          path: API.path,
          kind: kind,
          id: imageId,
          parentId,
        })

        let result = await getBlobFromImageId(endpoint)
        setBlob(result)
        callback({ action: 'IMAGE_LOADED' })
      } catch (e) {
        setBlob(null)
        callback({ action: 'IMAGE_FAILED' })
      }
    }
    fetchBlob()
  }, [imageId, parentId, variant])

  if (!blob) return null
  return (
    <ImageElement
      className="ImageElement"
      imageSrc={URL.createObjectURL(blob)}
    />
  )
}

const ImageRenderer = memo(
  ({
    parentId = '',
    images = [],
    callback = () => {},
    kind,
    config,
    variant,
  }) => {
    const { controlType = '', isDefaultEnabled = false } = config || {}
    const [loading, setLoading] = useState(null)

    const onDelete = (e, item) => {
      e.preventDefault()
      callback({ action: 'DELETE_IMAGE', value: item })
    }
    const handleCallback = ({ action }) => {
      if (action === 'IMAGE_LOADING') {
        setLoading(true)
      }
      if (action === 'IMAGE_LOADED') {
        setLoading(false)
      } else {
        setLoading(null)
      }
    }

    useEffect(() => {
      return () => {
        setLoading(null)
      }
    }, [])

    const renderUploader = () => {
      if (!isDefaultEnabled) return null
      if (!images || !images.length) {
        return <UploaderPlaceholder onChange={callback} />
      }
    }

    const filteredImages = getValidImages(images, variant)

    const getClassName = () => {
      if (controlType === 'banner') {
        return 'Banner'
      }
      if (controlType === 'default') {
        return 'Default'
      }
      if (controlType === 'swap') {
        return 'Swap'
      }
      if (controlType === 'thumnail') {
        return 'Thumnail'
      }
      return ''
    }

    const renderImages = () => {
      if (!filteredImages || !filteredImages.length) return null
      return (
        <ImageGroup className={`ImageGroup ${getClassName()}`}>
          {filteredImages.map((item) => {
            return (
              <ImageItem
                key={item.id}
                className={`ImageItem ${getClassName()}`}
              >
                {controlType === 'default' && !loading && (
                  <ImageControls className="ImageControls Default">
                    <ImageButton
                      className="ImageButton"
                      onClick={(e) => onDelete(e, item)}
                    >
                      <Icon name="REMOVE" stroke="#121B2D" size={20} />
                    </ImageButton>
                  </ImageControls>
                )}
                {controlType === 'swap' && !loading && (
                  <ImageControls className="ImageControls Swap">
                    <ImageButton
                      className="ImageButton"
                      onClick={(e) => onDelete(e, item)}
                    >
                      <Icon name="SWAP" stroke="#121B2D" size={20} />
                    </ImageButton>
                  </ImageControls>
                )}
                <Image
                  imageId={item.id}
                  variant={item.variant}
                  parentId={parentId}
                  callback={handleCallback}
                  kind={kind}
                />
              </ImageItem>
            )
          })}
          {loading && <Loading />}
          {renderUploader()}
        </ImageGroup>
      )
    }

    return <ImageContainer>{renderImages()}</ImageContainer>
  },
  areEqual,
)

ImageRenderer.defaultProps = {
  images: [],
  parentId: '',
  kind: '',
  mt: 0,
  mb: 0,
  callback: () => {},
}

ImageRenderer.propTypes = {
  images: PropTypes.array,
  kind: PropTypes.string,
  parentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mt: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mb: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  callback: PropTypes.func,
}

export { ImageRenderer }
