import styled from 'styled-components'
import { media } from '@common/Theme'

const FieldLabel = styled.span.attrs({ className: 'label' })`
  font-weight: normal;
  font-style: normal;
  line-height: 16px;
  margin-bottom: 0;
  font-size: 14px;
  color: #121b2d;
  font-weight: 500;
  display: block;
  margin-bottom: 8px;
`

const Container = styled.div`
  display: inline-block;
  width: 100%;
  margin-top: 16px;
  ${media.sm`
    width: unset;
  `}
  ${({ styled }) => ({ ...styled })};
`

const FieldWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  background-color: white;
  padding: 14px 16px 14px 10px;
  background: #f7f8fa;
  border: 1px solid transparent;
  border-radius: 3px;
  margin-top: 16px;
  overflow: hidden;
  ${({ styled }) => ({ ...styled })};

  &.is-invalid {
    border: 1px solid #ff3860;
    ${({ invalidStyled }) => ({ ...invalidStyled })};
  }
`

const CardImage = styled.img`
  height: 1em;
  ${({ styled }) => ({ ...styled })};
`

const InputWrapper = styled.label`
  align-items: center;
  display: ${(props) => (props.isActive ? 'flex' : 'none')};
  margin-left: 0.5em;
  position: relative;
  transition: transform 0.5s;
  transform: translateX(${(props) => (props.translateX ? '4rem' : '0')});

  &::after {
    content: attr(data-max);
    visibility: hidden;
    height: 0;
  }

  & .credit-card-input {
    border: 0px;
    position: absolute;
    width: 100%;
    font-size: 1em;
    background: transparent;
    ${({ inputStyled }) => ({ ...inputStyled })};

    &:focus {
      outline: 0px;
    }
  }

  & .zip-input {
    display: ${(props) => (props.isZipActive ? 'flex' : 'none')};
  }
`

const DangerText = styled.p`
  font-size: 0.8rem;
  margin: 5px 0 0 0;
  color: #ff3860;
  ${({ styled }) => ({ ...styled })};
`

export {
  FieldLabel,
  Container,
  FieldWrapper,
  CardImage,
  InputWrapper,
  DangerText,
}
