import styled, { css } from 'styled-components'
import { mbFn, mtFn } from '@common/Theme'
const colors = {
  orange: `#FF5A45`,
  light: `#ff998c`,
  track: `#d7dcdf`,
}

const theme = {
  width: `100%`,
  rangehandlecolor: `${colors.orange}`,
  rangehandlecolorhover: `${colors.orange}`,
  rangehandlesize: `18px`,
  rangetrackcolor: `${colors.track}`,
  rangetrackheight: `6px`,
  rangelabelcolor: `${colors.orange}`,
  rangelabelwidth: `60px`,
}

const RangeSliderContainer = styled.div`
  padding: 16px 0;
  appearance: none;
  width: 25px;
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;
  ${mbFn};
  ${mtFn};
  label {
    display: inline-block;
    color: #a9aeb9;
    text-align: left;
    font-size: 14px;
    margin-bottom: 8px;
  }
`

const RangeInput = styled.input`
  &::-moz-range-track {
    background: ${theme.rangetrackcolor};
    border: 0;
  }
  &::-moz-focus-inner,
  &::-moz-focus-outer {
    border: 0;
  }
  width: ${theme.width};
  height: ${theme.rangetrackheight};
  border-radius: 5px;
  outline: none;
  margin: 0;
  margin-top: 0 !important;
  -webkit-appearance: none;
  &::-webkit-slider-thumb {
    appearance: none;
    width: ${theme.rangehandlesize};
    height: ${theme.rangehandlesize};
    border-radius: 50%;
    background: ${theme.rangehandlecolor};
    border: 2px solid white;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    transform: scale(1);
    position: relative;
    &:hover {
      background: ${theme.rangehandlecolorhover};
      transform: scale(1.15);
      box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.12);
    }
  }
  &::-moz-range-thumb {
    width: ${theme.rangehandlesize};
    height: ${theme.rangehandlesize};
    border: 0;
    border-radius: 50%;
    background: ${theme.rangehandlecolor};
    cursor: pointer;
    transition: background 0.15s ease-in-out;
    &:hover {
      background: ${theme.rangehandlecolorhover};
      box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.12);
    }
  }
  &:active::-webkit-slider-thumb {
    background: ${theme.rangehandlecolorhover};
  }
  &:active::-moz-range-thumb {
    background: ${theme.rangehandlecolorhover};
  }
  &:focus {
    &::-webkit-slider-thumb {
      box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.12);
    }
  }
  ${(props) => {
    const { value, minVal, maxVal } = props
    const customValue = ((value - minVal) / (maxVal - minVal)) * 100
    const bg = `linear-gradient(90deg, ${colors.orange} ${customValue}%, ${colors.track} ${customValue}%)`
    return css`
      background: ${bg};
    `
  }}
`

export { RangeSliderContainer, RangeInput }
