import React from 'react'
import PropTypes from 'prop-types'
import { AdvancedGroup } from '../AdvancedGroup'
import { Icon } from '@common/Icon'
import { Button } from '@common/Button'
import { Draggable, Droppable } from 'react-beautiful-dnd'
import { prop } from 'ramda'
import {
  SectionGroupContainer,
  SectionHeader,
  SectionGroup,
  IconContainer,
} from './AdvancedSection.styled'
import styled from 'styled-components'
import { SECTION } from '../constants/AdvancedConstants'
import { AdvancedLabel } from '../AdvancedLabel'

const Controls = styled.div`
  margin-left: auto;
`

const getContainerStyle = (isDraggingOver) => ({
  minHeight: isDraggingOver ? '200px' : 'auto',
  borderRadius: '6px',
  border: isDraggingOver ? '1px dashed #f4f4f4' : '1px solid transparent',
  transition: isDraggingOver ? 'all .3s ease-in-out' : 'all .3s ease-in-out',
})

const getStepStyle = (isDragging, draggableStyles) => {
  return {
    // change background colour if dragging
    ...draggableStyles,
    background: isDragging ? '#eaeaea' : '',

    // styles we need to apply on draggables
  }
}

const AdvancedSection = ({
  onRemoveGroup = () => {},
  onRemoveActivity = () => {},
  callback = () => {},
  groupLabelCallback = () => {},
  sectionId,
  ...props
}) => {
  const section = prop('section')(props)
  const disabledItems = prop('disabledItems')(props)
  const isSectionDisabled = prop(SECTION)(disabledItems)

  return (
    <Droppable
      droppableId={section.id}
      type={SECTION}
      isDropDisabled={isSectionDisabled}
    >
      {(provided, snapshot) => (
        <>
          <SectionGroup
            ref={provided.innerRef}
            className="AdvancedSectionElement"
            style={getContainerStyle(snapshot.isDraggingOver)}
          >
            {section.children.map((group, index) => (
              <Draggable key={group.id} draggableId={group.id} index={index}>
                {(provided, snapshot) => {
                  const updatedDraggableProps = {
                    ...provided.draggableProps,
                  }
                  return (
                    <SectionGroupContainer
                      key={index}
                      ref={provided.innerRef}
                      {...updatedDraggableProps}
                      style={getStepStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style,
                      )}
                    >
                      <SectionHeader>
                        <IconContainer {...provided.dragHandleProps}>
                          <Icon name="DRAG" fill="#A9AEB9" size={20} />
                        </IconContainer>
                        <AdvancedLabel
                          label={group.label}
                          defaultValue="Group"
                          id={group.id}
                          callback={groupLabelCallback}
                        />
                        <Controls>
                          <Button
                            center
                            variant="white"
                            onClick={(e) =>
                              onRemoveGroup(e, {
                                groupId: group.id,
                                parentId: section.id,
                              })
                            }
                          >
                            <Icon name="TRASH" size={20} />
                          </Button>
                        </Controls>
                      </SectionHeader>
                      <AdvancedGroup
                        group={group}
                        groupId={group.id}
                        sectionId={section.id}
                        callback={callback}
                        disabledItems={disabledItems}
                        onRemoveActivity={onRemoveActivity}
                      />
                      {provided.placeholder}
                    </SectionGroupContainer>
                  )
                }}
              </Draggable>
            ))}
          </SectionGroup>
          {provided.placeholder}
        </>
      )}
    </Droppable>
  )
}

AdvancedSection.propTypes = {
  disabledItems: PropTypes.object,
  type: PropTypes.string,
  children: PropTypes.array,
}

export { AdvancedSection }
