import styled, { css } from 'styled-components'
import { media } from '@common/Theme'
import { Shadows } from '@common/Theme/Shadows'

const configLevel = {
  1: 100,
  2: 200,
  3: 300,
  9: 999,
}

const Container = styled.div.attrs({ className: 'modal-container' })`
  padding: 24px;
  background: white;
  border-radius: 10px;
  box-shadow: ${Shadows.modalShadow};
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 364px;
  min-width: 364px;
  transform: translate(-50%, -50%);
  ${(props) =>
    props.level &&
    css`
      z-index: ${configLevel[props.level] || 100};
    `}
  ${(props) =>
    props.dev &&
    css`
      position: relative;
      z-index: 0;
      transform: unest;
      left: unset;
      top: unset;
    `}
    ${media.sm`
      max-width: 364px;
      min-width: 364px;
    `}
    ${media.md`
      max-width: 472px;
      min-width: 400px;
    `}
`

const MenuClose = styled.button`
  background: #ffffff;
  border: 1px solid #e3e5eb;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(28, 17, 44, 0.0488);
  border-radius: 8px;
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  margin-top: 20px;
  box-sizing: border-box;
  cursor: pointer;
`

const ModalClose = styled.button`
  width: 24px;
  height: 24px;
  padding: 0;
  margin: 0;
  border: none;
  display: flex;
  background: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  svg {
    path {
      transition: all 0.2s ease-in-out;
    }
  }
  &:hover {
    svg {
      path {
        stroke: #e0e0e0;
      }
    }
  }
`

const flexDirectionFn = ({ direction }) => css`
  flex-direction: ${direction};
`

const ModalBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  ${flexDirectionFn};
`

const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px;
  width: 60px;
  height: 60px;
  background: #ffedeb;
  border-radius: 16px;
`

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(29, 42, 67, 0.24);
  display: none;
  ${(props) =>
    props.level &&
    css`
      z-index: ${configLevel[props.level] || 100};
    `}
  ${(props) =>
    props.isActive &&
    css`
      display: block;
    `}
    ${(props) =>
    props.dev &&
    css`
      z-index: 0;
      position: relative;
    `}
`
const centerFn = ({ center }) =>
  center &&
  css`
    text-align: center;
  `

const Content = styled.div`
  color: #a9aeb9;
  font-weight: 400;
  font-size: 16px;
  display: flex;
  margin-top: 16px;
  ${centerFn};
  p,
  span {
    color: #a9aeb9;
    font-weight: 400;
    font-size: 16px;
  }
`

export {
  Container,
  MenuClose,
  ModalClose,
  ModalBlock,
  IconContainer,
  Overlay,
  Content,
}
