import {
  WORKOUTS_CREATE_SUCCESS,
  WORKOUTS_FETCH_SUCCESS,
  WORKOUTS_UPDATE_SUCCESS,
  WORKOUTS_DELETE_SUCCESS,
  WORKOUTS_SET_ACTIVE_SUCCESS,
  WORKOUTS_SET_FILTERS,
  WORKOUTS_BY_ID_SUCCESS,
  UPDATE_ACTIVE_WORKOUT,
  WORKOUTS_UPDATE_BY_ID_SUCCESS,
  WORKOUTS_CREATE_IMAGE_SUCCESS,
  WORKOUTS_DELETE_IMAGE_SUCCESS,
  WORKOUTS_UPDATE_LISTEN_SUCCESS,
  WORKOUTS_UPDATE_IMAGE_SUCCESS,
  WORKOUTS_CLEAR_IMAGE,
} from '../actionTypes'

export const fetchWorkoutsSuccess = (payload) => ({
  type: WORKOUTS_FETCH_SUCCESS,
  payload,
})

export const updateWorkoutSuccess = (payload) => {
  return {
    type: WORKOUTS_UPDATE_SUCCESS,
    payload,
  }
}

export const createWorkoutSuccess = (payload) => ({
  type: WORKOUTS_CREATE_SUCCESS,
  payload,
})

export const updateWorkoutListenSuccess = (payload) => ({
  type: WORKOUTS_UPDATE_LISTEN_SUCCESS,
  payload,
})

export const setActiveWorkoutSuccess = (payload) => ({
  type: WORKOUTS_SET_ACTIVE_SUCCESS,
  payload,
})

export const getWorkoutByIdSuccess = (payload) => {
  return {
    type: WORKOUTS_BY_ID_SUCCESS,
    payload,
  }
}

export const updateWorkoutProtocolSuccess = (payload) => {
  return {
    type: WORKOUTS_UPDATE_WORKOUT_PROTOCOL_SUCCESS,
    payload,
  }
}

export const deleteWorkoutSuccess = () => ({
  type: WORKOUTS_DELETE_SUCCESS,
})

export const setWorkoutFilters = (payload) => ({
  type: WORKOUTS_SET_FILTERS,
  payload,
})

export const updateActiveWorkout = (payload) => {
  return {
    type: UPDATE_ACTIVE_WORKOUT,
    payload: payload,
  }
}

export const updateWorkoutByIdSuccess = (payload) => {
  return { type: WORKOUTS_UPDATE_BY_ID_SUCCESS, payload }
}

export const createWorkoutImageSuccess = (payload) => ({
  type: WORKOUTS_CREATE_IMAGE_SUCCESS,
  payload,
})

export const deleteWorkoutImageSuccess = ({ payload, state }) => {
  const newImages = state.filter((el) => el.uid !== payload)
  return { type: WORKOUTS_DELETE_IMAGE_SUCCESS, payload: newImages }
}

/**
 *
 * @param  payload : {uid : "", imageId : ""} ,
 * @param state : activeWorkout
 * @returns
 */
export const updateWorkoutImageSuccess = ({ payload, state }) => {
  return {
    type: WORKOUTS_UPDATE_IMAGE_SUCCESS,
    payload: {
      activeWorkout: {
        ...state,
        images: state.images
          ? [...state.images, payload.imageId]
          : [payload.imageId],
      },
    },
  }
}

export const clearWorkoutImageIds = () => ({ type: WORKOUTS_CLEAR_IMAGE })
