import { getEnvironment } from '../utils';

const { baseURL } = getEnvironment();

import {
  SETTINGS_GET_ACTIVE_PRICES_SUCCESS,
  SETTINGS_UPDATE_QUOTE_PRICE_SUCCESS,
  SETTINGS_UPDATE_QUOTE_QUANTITY_SUCCESS,
  SETTINGS_GET_ACTIVE_SUBSCRIPTIONS_SUCCESS,
  SETTINGS_GENERATE_DRAFT_QUOTE_SUCCESS,
  SETTINGS_CREATE_PAYMENT_METHOD_SUCCESS,
  SETTINGS_GET_ALL_PAYMENT_METHODS_SUCCESS,
  SETTINGS_UPDATE_QUOTE_APPLY_COUPON_SUCCESS,
} from './actionTypes';

const API = {
  [SETTINGS_GET_ACTIVE_PRICES_SUCCESS]: {
    endpoint: `${baseURL}/aima/stripe-wrapper/get-active-prices`,
    method: 'GET',
    headers: ['AUTH', 'JSON'],
  },
  [SETTINGS_GET_ACTIVE_SUBSCRIPTIONS_SUCCESS]: {
    endpoint: `${baseURL}/aima/stripe-wrapper/get-active-subscriptions`,
    method: 'GET',
    headers: ['AUTH', 'JSON'],
  },
  [SETTINGS_UPDATE_QUOTE_PRICE_SUCCESS]: {
    endpoint: `${baseURL}/aima/stripe-wrapper/update-quote-price`,
    method: 'POST',
    headers: ['AUTH', 'JSON'],
  },
  [SETTINGS_UPDATE_QUOTE_QUANTITY_SUCCESS]: {
    endpoint: `${baseURL}/aima/stripe-wrapper/update-quote-quantity`,
    method: 'POST',
    headers: ['AUTH', 'JSON'],
  },
  [SETTINGS_GENERATE_DRAFT_QUOTE_SUCCESS]: {
    endpoint: `${baseURL}/aima/stripe-wrapper/generate-draft-quote`,
    method: 'POST',
    headers: ['AUTH', 'JSON'],
  },
  [SETTINGS_CREATE_PAYMENT_METHOD_SUCCESS]: {
    endpoint: `${baseURL}/aima/stripe-wrapper/create-payment-method`,
    method: 'POST',
    headers: ['AUTH', 'JSON'],
  },
  [SETTINGS_GET_ALL_PAYMENT_METHODS_SUCCESS]: {
    endpoint: `${baseURL}/aima/stripe-wrapper/get-payment-methods`,
    method: 'GET',
    headers: ['AUTH', 'JSON'],
  },
  [SETTINGS_UPDATE_QUOTE_APPLY_COUPON_SUCCESS]: {
    endpoint: `${baseURL}/aima/stripe-wrapper/update-quote-apply-coupon`,
    method: 'POST',
    headers: ['AUTH', 'JSON'],
  },
};

// SUBSCRIPTION FOR NETFLIX
// AIMA --> INPUT PAYMENT (CARD DETAILS --> GETS PAYMENT INTENT FROM STRIPE --> VALIDATE CARD )
// AIMA --> WHAT IS SUBSCRIPTION RENEWAL IS NOT SUCCESSFUL -->
/*

1. MONTHLY ONLY
2. 

*/

export { API };
