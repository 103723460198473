import styled from 'styled-components'
import { colors } from '@common/Theme'
import { allDefaultStyles } from './utils'

const Blue = styled.button`
  ${allDefaultStyles};
  background: rgb(58 163 238 / 4%);
  border: 1.5px solid ${colors.blue};
  span {
    color: ${colors.blue};
  }
  svg.RESEND {
    path {
      fill: rgb(58 65 238);
    }
    line {
      fill: rgb(58 65 238);
    }
  }
  svg.HISTORY {
    path {
      stroke: ${colors.blue};
    }
  }
  svg.CHEVRON {
    path {
      fill: ${colors.blue};
    }
  }
  svg.PLAY {
    path {
      fill: ${colors.blue};
    }
    line {
      fill: ${colors.blue};
    }
  }
  svg.TRASH {
    path {
      stroke: ${colors.blue};
    }
    line {
      stroke: ${colors.blue};
    }
  }
  svg.SETTINGS {
    path {
      stroke: ${colors.blue};
    }
    line {
      stroke: ${colors.blue};
    }
  }
  &:hover {
    background: rgb(58 163 238 / 30%);
    span {
      font-size: 16px;
    }
    svg.TRASH {
      path {
        stroke: rgb(252, 115, 115);
      }
      line {
        stroke: rgb(252, 115, 115);
      }
    }
    svg.RESEND {
      path {
        fill: ${colors.blue};
      }
      line {
        fill: ${colors.blue};
      }
    }
  }
  ${(props) => props.extend};
`

export { Blue }
