import React from 'react';
import styled from 'styled-components';
import {
  basicFunctions,
  basicStyles,
  basicText,
  pseudoStyles,
} from './buttonStyles';
import { mbFn, mtFn, mlFn, mrFn } from '@common/Theme';

const SaveStyle = styled.button`
  ${basicStyles};
  display: inline-flex;
  ${basicText};
  ${pseudoStyles}
  ${basicFunctions};
  ${mbFn};
  ${mtFn};
  ${mrFn};
  ${mlFn};
  // FONT
  font-weight: 500;
  color: black;
  // BORDER
  border: 1px solid #e3e5eb;
  border-radius: 8px;
  // FLEX
  justify-content: center;
  align-items: center;
  max-height: 48px;
  padding: 10px 12px;
  background: #f7f8fa;
  transition: all 0.2s ease-in-out;
  box-sizing: border-box;
  &:hover {
    background: #f7f8fa;
    box-shadow: 0px 2px 8px rgba(29, 42, 67, 0.2);
  }
`;
const Save = ({ style, children, ...props }) => (
  <SaveStyle style={style} {...props}>
    {children}
  </SaveStyle>
);

export { Save };
