import { api } from '@state/utils'
import { API } from './api'
import { localVars } from '@features/Auth/Service/config'

import {
  PREFERENCES_FETCH_SUCCESS,
  PREFERENCES_SAVE_BOOKMARK_SUCCESS,
  PREFERENCES_REMOVE_BOOKMARK_SUCCESS,
  USER_FETCH_ME_SUCCESS,
  USER_UPDATE_SUCCESS,
  USER_CREATE_IMAGE_SUCCESS,
  USER_VALIDATE_CHANNEL_SUCCESS,
} from './actionTypes'

import REQUEST from './data'

// function that performs the api request and returns a Promise for that response
const mockApi = ({ type }) => {
  const response = (responseType) =>
    ({
      [PREFERENCES_FETCH_SUCCESS]: REQUEST.PREFERENCES_FETCH_SUCCESS,
      [PREFERENCES_REMOVE_BOOKMARK_SUCCESS]:
        REQUEST.PREFERENCES_REMOVE_BOOKMARK_SUCCESS,
      [PREFERENCES_SAVE_BOOKMARK_SUCCESS]:
        REQUEST.PREFERENCES_SAVE_BOOKMARK_SUCCESS,
    }[responseType])

  setTimeout(() => {
    const result = response(type)
    resolve({ data: result })
  }, 1000)
}

const apiInterceptor = (props) => {
  if (localVars.mock === 'enabled') {
    return mockApi(props)
  } else {
    return api(props)(API)
  }
}

export const requestFetchMe = () =>
  apiInterceptor({
    type: USER_FETCH_ME_SUCCESS,
  })

export const requestFetchPreferences = () =>
  apiInterceptor({
    type: PREFERENCES_FETCH_SUCCESS,
  })

export const requestSaveExerciseBookmark = (payload) =>
  apiInterceptor({
    type: PREFERENCES_SAVE_BOOKMARK_SUCCESS,
    body: payload,
  })

export const requestUpdateUser = (payload) =>
  apiInterceptor({
    type: USER_UPDATE_SUCCESS,
    body: payload,
  })

export const requestCreateUserImage = (payload) => {
  const form = new FormData()
  form.append('files', payload)
  return apiInterceptor({ type: USER_CREATE_IMAGE_SUCCESS, body: form })
}

export const requestValidateChannel = (payload) =>
  apiInterceptor({
    type: USER_VALIDATE_CHANNEL_SUCCESS,
    params: payload.params,
  })

export const requestRemoveExerciseBookmark = () =>
  apiInterceptor({
    type: PREFERENCES_REMOVE_BOOKMARK_SUCCESS,
  })
