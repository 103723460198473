import React from 'react';
import styled from 'styled-components';
import { buttons, Button } from '@common/Button';
import { keys, toUpper } from 'ramda';

const ButtonWrapper = styled.div`
  display: inline-flex;
`;

const SystemButtons = () => {
  const buttonList = keys(buttons);

  return buttonList.map((item, index) => (
    <ButtonWrapper key={index}>
      <Button mr={8} variant={item} label={toUpper(item)} />
    </ButtonWrapper>
  ));
};

export { SystemButtons };
