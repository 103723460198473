import React from 'react'
import PropTypes from 'prop-types'
import { Text } from '@common/Text'
import styled, { css } from 'styled-components'
import { green, mbFn, mtFn, red } from '@common/Theme'
import { Icon } from '@common/Icon'

const NoteContainer = styled.div`
  width: 100%;
  padding: 11px;
  border: 1px solid #333b44;
  border-radius: 4px;
  background: #282e32;
  ${mbFn};
  ${mtFn};
  display: flex;
  flex-direction: column;
  ${(props) => {
    const { variant: preType } = props
    const type = preType.toUpperCase()
    if (type === 'NOTE') {
      return css`
        background: #282e32;
      `
    }
    if (type === 'ISSUE') {
      return css`
        background: ${red};
        .Text {
          color: #e7e6e9;
        }
      `
    }
    if (type === 'RESOLVED') {
      return css`
        background: ${green};
        .Text {
          color: #305b4e;
        }
      `
    }
  }}
`

const IconContainer = styled.div`
  display: flex;
  margin: 0;
  height: 20px;
  width: 20px;
  justify-content: center;
  align-items: center;
  flex-basis: 20px;
  margin-right: 8px;

  ${(props) => {
    const { type: preType } = props
    const type = preType.toUpperCase()
    if (type === 'ISSUE') {
      return css`
        background: ${red};
        svg > path {
          fill: #e7e6e9;
        }
      `
    }
    if (type === 'RESOLVED') {
      return css`
        svg > path {
          fill: #305b4e;
        }
      `
    }
  }}
`

const NoteContent = styled.div`
  ${mbFn};
  ${mtFn};
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    margin-left: 2em;
    li {
      &:before {
        content: '-';
        color: #fbfbfb;
        display: inline-block;
        width: 1em;
      }
      position: relative;
      font-size: 14px;
      line-height: 24px;
      color: #fbfbfb;
      list-style: none;
      padding: 0;
      margin-bottom: 8px;
      span {
        color: #fbfbfb;
        padding: 3px 6px;
        border-radius: 4px;
        background: #333b44;
        font-size: 14px;
      }
      strong {
        color: black;
        padding: 3px 6px;
        border-radius: 4px;
        background: #3aeeb8;
        font-weight: 300;
        font-size: 14px;
      }
      a {
        color: #3aeeb8;
        text-decoration: underline;
      }
    }
  }
`

const NoteWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
`

const Note = ({
  text,
  children,
  color = '#fbfafb',
  type = 'note',
  icon = 'INFO',
  mt,
  mb,
}) => {
  const renderIcon = () => {
    if (!icon || !icon.length) return null
    return (
      <IconContainer type={type}>
        <Icon name={icon} fill="#95929e" size={20} />
      </IconContainer>
    )
  }

  return (
    <NoteContainer mb={mb} mt={mt} variant={type} className={`Note__Item`}>
      {text && (
        <NoteWrapper>
          {renderIcon()}
          <Text
            variant="p"
            style={{ display: 'inline-block' }}
            color={color}
            weight={400}
            lineHeight={24}
            size={14}
            className="Text"
          >
            {text}
          </Text>
        </NoteWrapper>
      )}
      {children && (
        <NoteContent mt={text && text.length ? 16 : 0}>{children}</NoteContent>
      )}
    </NoteContainer>
  )
}

Note.propTypes = {
  content: PropTypes.node,
  text: PropTypes.string,
}

export { Note }
