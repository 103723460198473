export const EXERCISES_FETCH_PENDING = 'EXERCISES_FETCH_PENDING'
export const EXERCISES_FETCH_SUCCESS = 'EXERCISES_FETCH_SUCCESS'
export const EXERCISES_FETCH_FAILURE = 'EXERCISES_FETCH_FAILURE'

export const EXERCISES_UPDATE_PENDING = 'EXERCISES_UPDATE_PENDING'
export const EXERCISES_UPDATE_SUCCESS = 'EXERCISES_UPDATE_SUCCESS'
export const EXERCISES_UPDATE_FAILURE = 'EXERCISES_UPDATE_FAILURE'

export const EXERCISES_CREATE_IMAGE_PENDING = 'EXERCISES_CREATE_IMAGE_PENDING'
export const EXERCISES_CREATE_IMAGE_SUCCESS = 'EXERCISES_CREATE_IMAGE_SUCCESS'
export const EXERCISES_CREATE_IMAGE_FAILURE = 'EXERCISES_CREATE_IMAGE_FAILURE'

export const EXERCISES_DELETE_PENDING = 'EXERCISES_DELETE_PENDING'
export const EXERCISES_DELETE_SUCCESS = 'EXERCISES_DELETE_SUCCESS'
export const EXERCISES_DELETE_FAILURE = 'EXERCISES_DELETE_FAILURE'

export const EXERCISES_UPDATE_IMAGE_PENDING = 'EXERCISES_UPDATE_IMAGE_PENDING'
export const EXERCISES_UPDATE_IMAGE_SUCCESS = 'EXERCISES_UPDATE_IMAGE_SUCCESS'
export const EXERCISES_UPDATE_IMAGE_FAILURE = 'EXERCISES_UPDATE_IMAGE_FAILURE'

export const EXERCISES_BUILDER_FETCH_PENDING = 'EXERCISES_BUILDER_FETCH_PENDING'
export const EXERCISES_BUILDER_FETCH_SUCCESS = 'EXERCISES_BUILDER_FETCH_SUCCESS'
export const EXERCISES_BUILDER_FETCH_FAILURE = 'EXERCISES_BUILDER_FETCH_FAILURE'

export const EXERCISE_SET_ACTIVE_PENDING = 'EXERCISE_SET_ACTIVE_PENDING'
export const EXERCISE_SET_ACTIVE_SUCCESS = 'EXERCISE_SET_ACTIVE_SUCCESS'
export const EXERCISE_SET_ACTIVE_FAILURE = 'EXERCISE_SET_ACTIVE_FAILURE'

export const EXERCISE_DELETE_IMAGE_PENDING = 'EXERCISE_DELETE_IMAGE_PENDING'
export const EXERCISE_DELETE_IMAGE_SUCCESS = 'EXERCISE_DELETE_IMAGE_SUCCESS'
export const EXERCISE_DELETE_IMAGE_FAILURE = 'EXERCISE_DELETE_IMAGE_FAILURE'

export const EXERCISE_CLEAR_IMAGE = 'EXERCISE_CLEAR_IMAGE'

export const EXERCISE_DELETE_UPDATE_IMAGE_PENDING =
  'EXERCISE_DELETE_UPDATE_IMAGE_PENDING'
export const EXERCISE_DELETE_UPDATE_IMAGE_SUCCESS =
  'EXERCISE_DELETE_UPDATE_IMAGE_SUCCESS'
export const EXERCISE_DELETE_UPDATE_IMAGE_FAILURE =
  'EXERCISE_DELETE_UPDATE_IMAGE_FAILURE'

export const EXERCISES_CREATE_PENDING = 'EXERCISES_CREATE_PENDING'
export const EXERCISES_CREATE_SUCCESS = 'EXERCISES_CREATE_SUCCESS'
export const EXERCISES_CREATE_FAILURE = 'EXERCISES_CREATE_FAILURE'
export const EXERCISES_SET_FILTERS = 'EXERCISES_SET_FILTERS'
