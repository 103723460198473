import React, { useEffect, useState } from 'react'
import { ImageCropper } from './ImageCropper'
import { Loading } from '@common/Loading'
import { UploadButton } from '@components/UploadButton'

import { ErrorList } from '@common/ErrorList'
import styled from 'styled-components'
import { Label } from '@common/Label'
import { getValidImages, formatBytes } from './utils'

const UploadContainer = styled.div``

const ImageUploader = ({
  variant,
  children,
  callback = () => {},
  loading,
  images,
  label,
}) => {
  const [errors, setErrors] = useState([])
  const [errorOptions, setErrorOptions] = useState([
    {
      name: 'error',
      variant: 'error',
      message: 'There was a problem with the image. Please try again.',
    },
  ])

  const [image, setImage] = useState(null)
  const [state, setState] = useState({
    isModalOpen: false,
  })

  const closeError = ({ value }) => {
    setErrors((state) => state.filter((item) => item.name !== value))
  }

  const handleImageUpload = async (e) => {
    e.preventDefault()
    const uploadLimit = 2
    const size = e.target.files[0].size / 1024 / 1024
    if (size < uploadLimit) {
      setImage(URL.createObjectURL(e.target.files[0]))
      setErrors([])
      return
    }
    setErrorOptions(
      errorOptions.map((item) => ({
        ...item,
        message: `File size must be less than ${uploadLimit}MB, however your file was ${formatBytes(
          e.target.files[0].size,
        )}.`,
      })),
    )
    setErrors(['error'])
  }

  useEffect(() => {
    if (!image) return
    setState((state) => ({
      ...state,
      isModalOpen: true,
    }))
  }, [image])

  const handleCallback = async ({ action, value }) => {
    if (action === 'CLOSE') {
      setImage(null)
      setState((state) => ({
        ...state,
        isModalOpen: false,
      }))
    }
    if (action === 'SAVE') {
      let blob = await fetch(value).then((r) => r.blob())
      callback({ action: 'SAVE', value: { rawImage: blob, variant: variant } })
      setImage(null)
      setState((state) => ({
        ...state,
        isModalOpen: false,
      }))
    }
  }

  const isValid =
    !!getValidImages(images, variant) &&
    !!getValidImages(images, variant).length
  return (
    <>
      {/* {loading && <Loading />} */}
      {!isValid && (
        <UploadContainer className="uploader-container">
          {label && <Label>{label || 'Choose an photo'}</Label>}
          <UploadButton
            name="cover"
            type="file"
            variant={variant}
            onChange={handleImageUpload}
          />

          <ErrorList
            needles={errors}
            haystack={errorOptions}
            mb={16}
            mt={16}
            callback={closeError}
          />
        </UploadContainer>
      )}
      {children}
      {state.isModalOpen && (
        <ImageCropper
          image={image}
          callback={handleCallback}
          variant={variant}
        />
      )}
    </>
  )
}

ImageUploader.defaultProps = {
  variant: 'banner',
  loading: false,
  images: [],
  callback: () => {},
  label: '',
}

export { ImageUploader }
