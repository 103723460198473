import React from 'react'
import { Layout, Menu } from '@features/Layout'
import { AdvancedDrag } from './AdvancedDrag'
import { Provider as WorkoutUploaderProvider } from './WorkoutUploader/WorkoutUploaderProvider'

const Builder = () => {
  return (
    <Layout variant="CUSTOM">
      <WorkoutUploaderProvider>
        <Menu />
        <AdvancedDrag />
      </WorkoutUploaderProvider>
    </Layout>
  )
}

export { Builder }
