import styled from 'styled-components'
import { allDefaultStyles } from './utils'

const Text = styled.button`
  ${allDefaultStyles};
  border: 1.5px solid transparent;
  background: transparent;
  ${(props) =>
    props.buttonLabel &&
    css`
      justify-content: center;
      span {
        width: unset;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        margin-right: 4px;
      }
    `}
  &:hover {
    background: transparent;
    span {
      text-decoration: underline;
    }
  }
  &:disabled {
    cursor: not-allowed;
    user-select: none;
    span {
      text-decoration-line: none;
      color: #a9aeb9;
    }
  }
  ${(props) => props.extend};
`

export { Text }
