/* eslint-disable react/prop-types */
import React from 'react';

const IconPath = (props) => {
  const { fill = 'none', stroke = '#A9AEB9' } = props;
  return (
    <svg>
      <g fill={fill} stroke="red">
        <path
          d="M13.8606 3.17416L13.8638 3.18084L13.8671 3.18746C14.1094 3.67067 14.6157 4.05256 15.2252 4.05256H19.2324L19.2324 4.05374C19.2327 4.06019 19.232 4.06581 19.2308 4.07051H17.5557C16.8825 4.07051 16.3834 4.60852 16.3314 5.22224L15.0533 17.062C15.0532 17.063 15.0531 17.0639 15.053 17.0649C14.9002 18.3662 13.8885 19.2692 12.7636 19.2705C12.7627 19.2705 12.7619 19.2705 12.7611 19.2705L9.9968 19.25L9.9968 19.2499L9.98569 19.25L7.22143 19.2705C7.22059 19.2705 7.21975 19.2705 7.21891 19.2705C6.09398 19.2692 5.08234 18.3662 4.9295 17.0649C4.92938 17.064 4.92927 17.063 4.92915 17.062L3.65113 5.22225C3.59907 4.60853 3.10002 4.07051 2.42678 4.07051H0.751707C0.750598 4.06616 0.749892 4.06103 0.750019 4.05517C0.75031 4.0548 0.750769 4.05426 0.751445 4.05362L0.752793 4.05256H4.77635C5.35889 4.05256 5.88713 3.71574 6.14218 3.17163C6.83216 1.72722 8.15496 0.824258 9.62728 0.75H9.99125H10.0104H10.0295H10.393C11.8291 0.824322 13.1712 1.72883 13.8606 3.17416Z"
          stroke={stroke}
          strokeWidth="1.5"
        />
        <path
          d="M10.7135 3.75C11.0495 3.75 11.2912 3.94656 11.379 4.17977L8.78858 4.19737C8.87744 3.96476 9.11911 3.76187 9.46375 3.75H10.7135ZM11.6113 4.9282L8.59314 4.94872C8.26841 4.94872 8.02008 4.70256 8.00098 4.39487L11.6113 4.9282Z"
          stroke={stroke}
          strokeWidth="1.5"
        />
        <line
          x1="11.751"
          y1="9.75"
          x2="11.751"
          y2="14.25"
          stroke={stroke}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <line
          x1="8.05078"
          y1="9.75"
          x2="8.05078"
          y2="14.25"
          stroke={stroke}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default IconPath;
