import styled, { css } from 'styled-components';

const TypeLabelContainer = styled.div`
  min-width: 110px;
`;

const TypeLabelItem = styled.div`
  padding: 0;
`;

const TypeLabelBlock = styled.div`
  padding: 4px;
  display: inline-block;
  border-radius: 2px;
  font-size: 10px;
  font-weight: 600;
  ${props =>
    props.type === 'WARMUP' &&
    css`
      color: #f59e0b;
      background: #fef3c7;
    `}
  ${props =>
    props.type === 'NORMAL' &&
    css`
      color: #a9aeb9;
      background: #f4f5f8;
    `}
  ${props =>
    props.type === 'COOL_DOWN' &&
    css`
      color: #42a4ff;
      background: rgba(66, 164, 255, 0.1);
    `}
  ${props =>
    props.type === 'DROP_SET' &&
    css`
      color: #a9aeb9;
      background: #f4f5f8;
    `}
`;

export { TypeLabelContainer, TypeLabelBlock, TypeLabelItem };
