export default {
  email: 'anusha6716@gmail.com',
  stripeCustomerId: 'cus_LjFo8MyDN3Eox6',
  roles: ['PersonalTrainer'],
  createdAt: '2022-05-21T13:34:55.157028',
  updatedAt: '2022-05-21T13:39:51.210784',
  isActive: true,
  isDeleted: false,
  stripeSetupIntentObject: {
    setupIntentId: 'seti_1L1nNjCTOd88lFo5dwJEtnvo',
    paymentMethodId: 'pm_1L1nNgCTOd88lFo5Yy7SSU2L',
    setupIntentSecret:
      'seti_1L1nNjCTOd88lFo5dwJEtnvo_secret_LjFtuAPHwCKJqxv2DPUrNlheHzq0HSd',
  },
};
