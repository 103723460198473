const options = [
  {
    label: 'Profile',
    value: 'profile',
    content: <div>Content for Profile</div>,
    route: '/account/profile',
    roles: ['PERSONALTRAINER'],
  },
  {
    label: 'Clients',
    value: 'clients',
    content: <div>Content for Settings</div>,
    route: '/account/clients',
    roles: [],
  },
  {
    label: 'Settings',
    value: 'settings',
    content: <div>Content for Settings</div>,
    route: '/account/settings',
    roles: ['CLIENT', 'PERSONALTRAINER'],
  },
]

export default options
