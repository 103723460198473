import React from 'react'
import { GlobalTheme } from './GlobalTheme'
import { ThemeProvider } from './ThemeProvider'
import { Background } from './Background'

const Theme = ({ children }) => {
  return (
    <ThemeProvider>
      {children}
      <Background />
      <GlobalTheme />
    </ThemeProvider>
  )
}
export { Theme }
