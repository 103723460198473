import styled, { css } from 'styled-components'

const BladeItem = styled.li`
  list-style: none;
  margin: 0;
  display: flex;
  margin-top: 8px;
  width: 100%;
  border-radius: 8px;
  padding: 8px;
  display: flex;
  margin-top: 16px;
  border: 1px solid #e3e5eb;
  margin: 8px 0px 8px 0;
  border-radius: 10px;
  max-width: 380px;
  background-color: #ffffff;
  position: relative;
  &:nth-child(1) {
    margin-top: 0;
  }
  &:hover {
    cursor: pointer;
  }
`

const BladeType = styled.span`
  padding: 3px;
  background: #53b253;
  border-radius: 3px;
  color: white;
  display: inline;
  position: absolute;
  font-size: 13px;
  top: 50%;
  transform: translate(-10px, calc(-50%));
  right: 0px;
  span {
    font-weight: 600;
    color: black;
  }
`

const BladeContentCount = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0px;
  color: #121b2d;
`

const BladeGroupContainer = styled.div`
  background: #fafafb;
  border-radius: 8px;
  padding: 12px 8px 8px;
  border: 1px solid #eaeaea;
  height: 100%;
  margin-bottom: 10px;
`

const BladeCount = styled.div`
  margin-bottom: 8px;
`

const BladeGroup = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: none;
  &::-webkit-scrollbar-track {
    background: none;
    border: none;
    box-shadow: none;
    border-radius: 4px;
    width: 14px;
  }
  &::-webkit-scrollbar-thumb {
    background: #eeeff1;
    border: none;
    box-shadow: none;
    border-radius: 6px;
  }

  ${(props) =>
    props.maxHeight &&
    css`
      max-height: ${props.maxHeight}px;
    `};
`

const BladeItemContent = styled.div`
  display: flex;
  margin-left: 8px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .BladeType {
    display: none;
  }
`

const BladeItemLabel = styled.span`
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  color: #1d2a43;
  letter-spacing: 0px;
`

const BladeSearchContainer = styled.div`
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  flex-direction: column;
  padding-top: 12px;
  flex: 1;
`

export {
  BladeItem,
  BladeGroupContainer,
  BladeGroup,
  BladeItemContent,
  BladeContentCount,
  BladeCount,
  BladeItemLabel,
  BladeType,
  BladeSearchContainer,
}
