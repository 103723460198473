import React from 'react'
import styled from 'styled-components'
import { mtFn, mbFn, mrFn, mlFn } from '@common/Theme'

import {
  basicFunctions,
  basicStyles,
  basicIconStyles,
  basicText,
  pseudoStyles,
} from './buttonStyles'

const DiscountStyle = styled.button`
  ${basicStyles};
  ${basicText};
  ${pseudoStyles}
  ${basicFunctions};

  ${basicIconStyles};
  ${mtFn};
  ${mrFn};
  ${mbFn};
  ${mlFn};
  padding: 17px 16px;
  background: white;
  border-radius: 8px;
  font-weight: 500;
  transition: all 0.2s ease-in-out;
  color: white;
  &:hover {
    background: white;
    box-shadow: 0px 2px 8px rgba(209, 48, 28, 0.2),
      0px 5px 12px rgba(209, 48, 28, 0.06);
  }
`

const Discount = ({ style, children, ...props }) => (
  <DiscountStyle style={style} {...props}>
    {children}
  </DiscountStyle>
)

export { Discount }
