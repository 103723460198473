import React from 'react'
import { prop } from 'ramda'
import PropTypes from 'prop-types'
import { Icon } from '@common/Icon'
import styled from 'styled-components'

import {
  WorkoutItemContainer,
  WorkoutRowContainer,
  WorkoutName,
  WorkoutImage,
  FromIconContainer,
  WorkoutControls,
} from './WorkoutItem.styled'
import { getByVariousKeys } from '@utils/regular'
const WorkoutId = styled.div``

const DOWNLOAD_IMAGE_URL =
  'https://aima-workout-images.s3.ap-southeast-2.amazonaws.com/'

const isHelper = false
const imageConfig = (type) =>
  ({
    THUMB: `/assets/exercises/thumb.jpg`,
  }[type || 'THUMB'])

const getImage = (image) => {
  if (image && image[0]) {
    return `${DOWNLOAD_IMAGE_URL}${image[0]?.id}`
  } else {
    return imageConfig('THUMB')
  }
}

const WorkoutItem = ({ onSelect, onDelete, onEdit, ...props }) => {
  // DESTRUCTURE
  const name = prop('workoutName')(props)
  const image = prop('images')(props)
  const id = prop('workoutId')(props)

  return (
    <WorkoutItemContainer onClick={onSelect} {...props}>
      <WorkoutRowContainer width="100%">
        {image && image[0] ? (
          <WorkoutImage imageSrc={getImage(image)} />
        ) : (
          <WorkoutImage imageSrc={imageConfig('THUMB')} />
        )}
        {name && <WorkoutName color="#1D2A43">{name}</WorkoutName>}
        <WorkoutControls className="controls">
          {isHelper && (
            <FromIconContainer>
              {id && <WorkoutId>{id}</WorkoutId>}
              <Icon name="COPY" size={20} />
            </FromIconContainer>
          )}
          <FromIconContainer className="DeleteButton" onClick={onDelete}>
            <Icon name="REMOVE" size={20} />
          </FromIconContainer>
        </WorkoutControls>
      </WorkoutRowContainer>
    </WorkoutItemContainer>
  )
}

WorkoutItem.defaultProps = {
  onSelect: () => {},
  onDelete: () => {},
  onEdit: () => {},
}

WorkoutItem.propTypes = {
  onSelect: PropTypes.func,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
}

export { WorkoutItem }
