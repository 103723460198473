import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  getActiveSubscriptionsPending,
  getActivePricesPending,
  settingsSelector,
} from '@state/settings'

import { useSelector } from '@hooks/useSelector'

// import { DraftQuote } from './DraftQuote'
// import { ActiveSubscription } from './ActiveSubscription'
import { Block } from '@common/Block'
import { Heading } from '@common/Heading'
import { Line } from '@common/Line'
import { Text } from '@common/Text'
import { Button } from '@common/Button'

import { Plan } from './Plan'
import { Payment } from './Payment'

const Billing = () => {
  const dispatch = useDispatch()
  // const [isEditMode, setEditMode] = useState(false)

  // const noActiveSubscriptions = useSelector(settingsSelector.subscriptions)

  // useEffect(() => {
  //   dispatch(getActiveSubscriptionsPending())
  //   dispatch(getActivePricesPending())
  // }, [])

  // const onCallback = (value) => setEditMode(value)

  // const hasNoLength = noActiveSubscriptions && noActiveSubscriptions.length <= 0
  return (
    <>
      <Plan />
      <Payment />
    </>
  )
}

Billing.defaultProps = {}

// }

// {
//   /* <ActiveSubscription callback={onCallback} /> */
// }
// {
//   /* <DraftQuote callback={onCallback} /> */
// }

export { Billing }
