import React, { useEffect, useState } from 'react'
import { Loading } from '@common/Loading'
import { useDebounce } from '@hooks/useDebounce'

const localTypes = {
  INACTIVE: 'IN_ACTIVE',
  ACTIVE: 'ACTIVE',
}

const Loader = ({ ...props }) => {
  return <Loading {...props} />
}

export { Loader }
