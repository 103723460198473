import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Provider } from './Provider'
import { FileLocation } from '@common/FileLocation'
import { useDisableBodyScroll } from '@hooks/useDisableBodyScroll'
import {
  SectionContainer,
  SectionContent,
  SectionControl,
  SectionTypeGroup,
  SectionTypeItem,
  SectionFooter,
} from './Section.styled'

const SectionType = ({ variant = 'default', ...props }) => {
  const options = [
    {
      variant: 'default',
      fill: '#E24E33',
      stroke: '#D44126',
    },
    {
      variant: 'high',
      fill: '#E24E33',
      stroke: '#D44126',
    },
    {
      variant: 'medium',
      fill: '#E68A3C',
      stroke: '#E68A3C',
    },
    {
      variant: 'low',
      fill: '#EFCC4C',
      stroke: '#EFCC4C',
    },
    {
      variant: 'fair',
      fill: '#72CEBC',
      stroke: '#72CEBC',
    },
    {
      variant: 'light',
      fill: '#5AAFED',
      stroke: '#5AAFED',
    },
    {
      variant: 'welcome',
      fill: '#6E6BEF',
      stroke: '#6E6BEF',
    },
  ]

  const items = options.filter(
    (item) => item.variant.toUpperCase() === variant.toUpperCase(),
  )

  const renderType = items.map((item) => (
    <SectionTypeItem
      key={item.variant}
      variant={item.variant}
      bgColor={item.fill}
    />
  ))

  return <SectionTypeGroup style={props.style}>{renderType}</SectionTypeGroup>
}

const Section = ({
  children,
  title,
  id,
  portalId,
  path,
  config,
  extend,
  ...props
}) => {
  const { priority, bgColor } = config || {}
  const [state, setState] = useState({
    isExpanded: false,
  })

  const handleCallback = ({ action }) => {
    if (action === 'EXPAND') {
      setState((state) => ({
        ...state,
        isExpanded: !state.isExpanded,
      }))
    }
  }

  useDisableBodyScroll(state.isExpanded)

  return (
    <Provider>
      <SectionContainer
        priority={priority}
        className={`Section ${title}`}
        id={id}
        bgColor={bgColor}
        extend={extend}
        isExpanded={state.isExpanded}
        {...props}
      >
        <SectionControl>
          {title && (
            <>
              <FileLocation
                id="file-section"
                title={title}
                path={path}
                isExpanded={state.isExpanded}
                callback={handleCallback}
              />
            </>
          )}
          {children}
        </SectionControl>
        {!state.isExpanded && <SectionFooter className="SectionFooter" />}
      </SectionContainer>
    </Provider>
  )
}

Section.defaultProps = {
  children: null,
  title: '',
  id: '',
  portalId: '',
  path: '',
}

Section.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  portalId: PropTypes.string,
  path: PropTypes.string,
  id: PropTypes.string,
  config: PropTypes.shape({
    priority: PropTypes.string,
    bgColor: PropTypes.string,
  }),
}

export { Section, SectionType }
