import styled, { css } from 'styled-components'
import { media } from '@common/Theme'

const Center = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  width: 100%;
  box-sizing: border-box;
  padding: 24px;
  position: relative;
  ${media.md`
    position: absolute;
    top: 45%;
    left: 50%;
    z-index: 1;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, calc(-50% + 60px));
  `}
`

const Group = styled.div`
  width: 100%;
`

const Wrap = styled.div`
  max-width: 1000px;
  width: 100%;
  position: fixed;
  top: 50%;
  left: 50%;
  height: 100%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  display: none;
  ${media.md`
        display: block;
    `}
  .login-image {
    display: none;
    ${media.lg`
        display: block;
    `}
  }

  ${(props) =>
    props.$imageSrc &&
    css`
      background-image: none;
      background-repeat: no-repeat;
      background-position: center center;
      ${media.md`
          background-image: ${`url(${props.$imageSrc})`};
        `}
    `};
  ${(props) =>
    props.custom &&
    css`
      ${media.sm`
        position: relative;
        top: unset;
        left: unset;
      `}
    `}
`

export { Center, Wrap, Group }
