import React, {
  forwardRef,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react'
import { useOnClickOutside } from '@hooks/useOnClickOutside'
import {
  EditInputGroup,
  EditInputField,
  EditContainer,
  EditHeading,
} from './AdvancedLabel.styled'
import { useEscape } from '@hooks/useEscape'

const InputField = forwardRef(
  ({ onChange, value, style, focus, ...props }, ref) => (
    <EditInputGroup>
      <EditInputField
        type="text"
        ref={ref}
        onChange={onChange}
        value={value}
        focus={focus}
        style={style}
        {...props}
      />
    </EditInputGroup>
  ),
)

const keyCodes = {
  ENTER: 'Enter',
}

const AdvancedLabel = ({
  label = '',
  callback = () => {},
  defaultValue = '',
  config = {},
  id = '',
}) => {
  const { style = {} } = config
  const ref = useRef()
  const refClickOutside = useRef()
  const [isEditMode, setEditMode] = useState(false)
  const [value, setValue] = useState('')

  const handleValue = (value) => {
    if (!id) return
    callback({ id, data: value })
  }

  const handleValidation = (value) => {
    if (!value || value.length <= 0) {
      handleValue(defaultValue)
      return
    }
    handleValue(value)
  }

  useOnClickOutside(refClickOutside, () => {
    if (isEditMode === false) return
    setEditMode(false)
    handleValidation(value)
  })

  const onChange = (e) => {
    const { value } = e.target
    setValue(value)
  }

  const onFocus = () => {
    if (ref.current) {
      ref.current.focus()
    }
  }

  const handleFocus = () => {
    setEditMode(true)
    onFocus()
  }

  const onKeyPress = (e) => {
    const key = e.keyCode || e.charCode
    if (key === keyCodes.ENTER || key === 13) {
      setEditMode(false)
      handleValidation(value)
    }
  }

  useEscape(() => setEditMode(false))

  useEffect(() => {
    onFocus()
  }, [isEditMode])

  useEffect(() => {
    onFocus()
    if (label !== value) {
      setValue(label)
    }
    // eslint-disable-next-line
  }, [isEditMode, label])

  return (
    <EditContainer
      className="editContainer"
      tabindex={1}
      ref={refClickOutside}
      onClick={handleFocus}
    >
      {isEditMode ? (
        <InputField
          value={value}
          ref={ref}
          onChange={onChange}
          maxLength={90}
          onFocus={onFocus}
          onKeyPress={onKeyPress}
          focus={isEditMode}
          style={style}
          className="editField"
        />
      ) : (
        <EditHeading className="editHeading">{value}</EditHeading>
      )}
    </EditContainer>
  )
}

export { AdvancedLabel }
