import styled from 'styled-components'

const ConfigContainer = styled.div`
  margin-bottom: 24px;
  h3 {
    margin: 0;
    margin-top: 16px;
  }
`

const TokenButton = styled.div`
  bottom: 24px;
  right: 24px;
  margin-top: 32px;
  border-top: 1px solid #e3e5eb;
  button {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  span {
    font-size: 16px;
    display: none;
    font-weight: 500;
    line-height: 20px;
    color: #121b2d;
  }
  .isExpanded & {
    span {
      display: inline-block;
    }
  }
`

export { ConfigContainer, TokenButton }
