import React from 'react'
import { keys, prop } from 'ramda'
import { useSelector } from 'react-redux'

import { exerciseSelector } from '@state/exercise'
import { ExerciseEditor } from './ExerciseEditor'
import { ExerciseCreator } from './ExerciseCreator'

import { ExerciseBuilderContainer } from './ExerciseBuilder.styled'

const ExerciseBuilder = () => {
  const activeExercise = useSelector(exerciseSelector.activeExercise)
  const exercises = useSelector(exerciseSelector.exercises)

  const renderExerciseEditorType = () => {
    if (!exercises || !exercises.length) {
      return <ExerciseCreator />
    }
    if (!!prop('exerciseId')(activeExercise)) {
      return <ExerciseEditor />
    } else return <ExerciseCreator />
  }

  return (
    <>
      <ExerciseBuilderContainer>
        {renderExerciseEditorType()}
      </ExerciseBuilderContainer>
      <div id="exercise-controls" />
    </>
  )
}

export { ExerciseBuilder }
