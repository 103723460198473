import React, { useEffect } from 'react'
import { Block } from '@common/Block'
import { Heading } from '@common/Heading'
import { Line } from '@common/Line'
import { Icon } from '@common/Icon'
import { Text } from '@common/Text'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from '@common/Button'
import styled, { css } from 'styled-components'
import { media } from '@common/Theme'
import { getAllPaymentMethodsPending, settingsSelector } from '@state/settings'

const ExtendButton = css`
  padding: 8px 12px;
  max-width: 105px;
  span {
    font-size: 14px;
  }
`

const ExtendButtonSmall = css`
  flex-basis: 160px;
  margin-left: 8px;
`

const ExtendOption = css`
  padding: 8px;
  margin-left: auto;
`

const PaymentContainer = styled.div`
  .billing-card {
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .table {
    border-collapse: separate;
    border-spacing: 0;
    width: 100%;
    display: table;
  }
  th {
    padding: 0 10px;
    text-align: left;
  }
  thead {
    display: table-header-group;
    border-collapse: separate;
    border-spacing: 0;
    box-sizing: border-box;
    tr {
      display: table-row;
      border-spacing: 0;

      div {
        font-size: 13px;
        padding-top: 8px;
        padding-bottom: 8px;
      }
      span {
        font-size: 13px;
      }
      th.table-cell {
        display: table-cell;
        border-spacing: 0;
        border-bottom: 1px solid #e3e5eb;
        border-top: 1px solid #e3e5eb;
        &:first-child {
          border-left: 1px solid #e3e5eb;
          border-radius: 6px 0px 0px 6px;
        }
        &:last-child {
          border-right: 1px solid #e3e5eb;
          border-radius: 0px 6px 6px 0px;
        }

        &.column-brand,
        /* &.column-default, */
        &.column-type,
        /* &.column-number, */
        /* &.column-exp, */
        &.column-controls {
          display: none;
        }

        ${media.xs`
        &.column-type {
          display: none;
        }
        `};

        ${media.sm`
          &.column-brand,
          &.column-default,
          &.column-type,
          &.column-number,
          &.column-exp,
          &.column-controls {
            display: table-cell;
          }
        `};
      }
    }
  }
  tbody {
    display: table-row-group;
    border-spacing: 0;
    tr {
      display: table-row;
      border-spacing: 0;
      border-bottom: 1px solid #e3e5eb;
      div {
        font-size: 13px;
        padding-top: 8px;
        padding-bottom: 8px;
      }
      span {
        font-size: 13px;
      }
      td.table-cell {
        display: table-cell;
        border-spacing: 0;
        padding: 0px 10px;
        text-align: left;
        border-bottom: 1px solid rgb(227, 229, 235);

        .icon-container {
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }
        &.column-controls {
          div.control-row {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
        }

        &.column-brand,
        /* &.column-default, */
        &.column-type,
        /* &.column-number, */
        /* &.column-exp, */
        &.column-controls {
          display: none;
        }

        ${media.sm`
          &.column-brand, &.column-default,
          &.column-type,
          &.column-number,
          &.column-exp,
          &.column-controls {
            display: table-cell;
          }
        `};
      }
    }
  }
`

const constructPaymentMethods = (methods) => {
  if (!methods || !methods.length) return []
  return methods.map((method) => {
    const card = method.card
    return {
      brand: card.brand,
      default: false,
      type: card.funding,
      digits: card.last4,
      expiration: `${card.expMonth}/${card.expYear}`,
    }
  })
}

const PaymentMethods = () => {
  const dispatch = useDispatch()
  const methods = useSelector(settingsSelector.methods)

  useEffect(() => {
    dispatch(getAllPaymentMethodsPending())
  }, [])

  const paymentMethods = constructPaymentMethods(methods)

  console.log({ paymentMethods })

  // const paymentMethods = [
  //   {
  //     brand: 'MasterCard',
  //     default: true,
  //     type: 'Credit',
  //     digits: '3000',
  //     expiration: '11/2025',
  //   },
  //   {
  //     brand: 'Visa',
  //     default: false,
  //     type: 'Credit',
  //     digits: '2000',
  //     expiration: '12/2024',
  //   },
  // ]

  const renderPaymentMethods = () => {
    if (!paymentMethods || !paymentMethods.length) return null
    return paymentMethods.map((paymentMethod, index) => (
      <tr className="row" key={index}>
        <td className="table-cell row-cell column-brand">
          {paymentMethod.brand && <div>{paymentMethod.brand}</div>}
        </td>
        <td className="table-cell row-cell column-default">
          {paymentMethod.default && (
            <div className="icon-container">
              <Icon name="TICKFILLED" size={20} />
            </div>
          )}
        </td>
        <td
          data-testid="payment/method/funding"
          className="table-cell row-cell column-type"
        >
          {paymentMethod.type && <div>{paymentMethod.type}</div>}
        </td>
        <td
          data-testid="payment/method/digits"
          className="table-cell row-cell column-number"
        >
          {paymentMethod.digits && <div>{paymentMethod.digits}</div>}
        </td>
        <td
          data-testid="payment/method/expiration"
          className="table-cell row-cell column-exp"
        >
          {paymentMethod.expiration && <div>{paymentMethod.expiration}</div>}
        </td>
        <td className="table-cell row-cell column-controls">
          <div className="control-row">
            {!paymentMethod.default && (
              <Button extend={ExtendButton} variant="black" type="submit">
                <span className="hidden">Default</span>
                <span className="hidden-sm">Default</span>
              </Button>
            )}
            <Button variant="transparent" extend={ExtendOption}>
              <Icon name="TRASH" size={20} />
            </Button>
          </div>
        </td>
      </tr>
    ))
  }
  return (
    <PaymentContainer>
      <div className="billing-card">
        <table className="table">
          <thead>
            <tr>
              <th column="0" className="table-cell head-cell column-brand">
                <div>Brand</div>
              </th>
              <th column="1" className="table-cell head-cell column-default">
                <div>Default</div>
              </th>
              <th column="2" className="table-cell head-cell column-type">
                <div>Type</div>
              </th>
              <th column="3" className="table-cell head-cell column-number">
                <div>
                  <span className="">Number (Last 4)</span>
                </div>
              </th>
              <th column="4" className="table-cell head-cell column-exp">
                <div>Exp. Date</div>
              </th>
              <th column="5" className="table-cell head-cell column-controls">
                <div>&nbsp;</div>
              </th>
            </tr>
          </thead>
          <tbody>{renderPaymentMethods()}</tbody>
        </table>
      </div>
    </PaymentContainer>
  )
}

const Payment = () => {
  const dispatch = useDispatch()

  const onAddCard = () => {
    dispatch()
  }
  return (
    <Block.Column mb={16}>
      <Block.Header>
        <Heading size={20} color="white" variant="TERTIARY" mb={0}>
          Payment Methods
        </Heading>
      </Block.Header>
      <Block.Content>
        <Text size={14} color="#323232" weight="300">
          {`Your charges will be deducted from the default card shown below. This can be changed by adding a new card and making it the default using the menu on the right.`}
        </Text>
        <PaymentMethods />
      </Block.Content>
      <Block.Footer>
        <Text size={14} color="#323232" weight="300">
          {`At most, three credit cards, or debit cards can be added.`}
        </Text>
        <Button variant="black" onClick={onAddCard} extend={ExtendButtonSmall}>
          <span className="hidden">Add new card</span>
          <span className="hidden-sm">Add card</span>
        </Button>
      </Block.Footer>
    </Block.Column>
  )
}

export { Payment }
