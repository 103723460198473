const options = {
  dashboard: {
    id: 'dashboard',
    value: 'dashboard',
    route: '/dashboard',
    label: 'Dashboard',
    active: true,
    icon: 'DASHBOARD',
    roles: ['CLIENT', 'PERSONALTRAINER'],
  },
  play: {
    id: 'play',
    value: 'play',
    route: '/start',
    label: 'Start',
    active: true,
    icon: 'PLAY',
    roles: ['CLIENT', 'PERSONALTRAINER'],
  },
  workouts: {
    id: 'workouts',
    value: 'workouts',
    route: '/library/workout',
    label: 'Workouts',
    active: true,
    icon: 'WEIGHT_FILLED',
    roles: ['PERSONALTRAINER'],
  },
  exercises: {
    id: 'exercises',
    value: 'exercises',
    route: '/library/exercise',
    label: 'Exercises',
    active: true,
    icon: 'LIGHTNING',
    roles: ['PERSONALTRAINER'],
  },
  settings: {
    id: 'settings',
    value: 'settings',
    route: '/account/settings',
    label: 'Settings',
    active: true,
    icon: 'ADJUST',
    roles: ['CLIENT', 'PERSONALTRAINER'],
  },
  profile: {
    id: 'profile',
    value: 'profile',
    route: '/account/profile',
    label: 'Profile',
    active: true,
    icon: 'CLIENT',
    roles: ['PERSONALTRAINER'],
  },
  clients: {
    id: 'clients',
    value: 'clients',
    route: '/account/clients',
    label: 'Clients',
    active: true,
    icon: 'WHISTLE_FILLED',
    roles: [],
  },
}

export default options
