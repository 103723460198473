import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { FieldGroup } from '@common/FieldGroup'
import { Icon } from '@common/Icon'
import {
  FieldLabel,
  InputElement,
  InputClose,
  InputContainer,
} from './Input.styled'

const Input = forwardRef((props, ref) => {
  const {
    variant,
    label,
    mb,
    mt,
    config,
    onClear,
    hasClear,
    extend,
    value,
    name,
    type,
    ...rest
  } = props
  const { inputStyles } = config || {}

  const handleKeyDown = (e) => {
    if (e.key === 'Escape') {
      if (!!hasClear && !!onClear) {
        e.preventDefault()
        onClear({ name })
      }
    }
  }

  const handleClear = (e) => {
    console.log('e --> clear')
    if (!!hasClear && !!onClear) {
      e.preventDefault()
      onClear({ name })
    }
  }

  const renderIcon = () => {
    if (!hasClear || !onClear) return null
    return (
      <InputClose type="button" onClick={handleClear}>
        <Icon
          name="CLOSE_SMALL"
          stroke="#A9AEB9"
          viewBox={`-1 -1 20 20`}
          size={20}
        />
      </InputClose>
    )
  }

  return (
    <FieldGroup
      className={`FieldGroup ${variant}`}
      variant={'ELEMENT'}
      mb={mb}
      mt={mt}
      extend={extend}
    >
      {label && <FieldLabel>{label}</FieldLabel>}
      <InputContainer type={type}>
        <InputElement
          style={inputStyles}
          ref={ref}
          type={type}
          value={value}
          variant={variant}
          isDate={type === 'date'}
          onKeyDown={handleKeyDown}
          name={name}
          {...rest}
        />
        {renderIcon()}
      </InputContainer>
    </FieldGroup>
  )
})

Input.defaultProps = {
  variant: 'secondary',
  label: '',
  config: {},
  onChange: () => {},
  onClear: () => {},
  hasClear: false,
}

Input.propTypes = {
  variant: PropTypes.string,
  label: PropTypes.string,
  config: PropTypes.object,
  onChange: PropTypes.func,
  onClear: PropTypes.func,
  hasClear: PropTypes.bool,
}

export { Input }
