import {
  EQUIPMENT_FETCH_PENDING,
  EQUIPMENT_FETCH_SUCCESS,
  MUSCLES_FETCH_SUCCESS,
  MUSCLES_FETCH_PENDING,
  MUSCLES_FETCH_FAILURE,
  EQUIPMENT_FETCH_FAILURE,
} from './actionTypes';

export const fetchMuscles = () => {
  return {
    type: MUSCLES_FETCH_PENDING,
  };
};

export const fetchMusclesSuccess = muscles => {
  return {
    type: MUSCLES_FETCH_SUCCESS,
    payload: muscles,
  };
};

export const fetchMusclesFailure = error => {
  return {
    type: MUSCLES_FETCH_FAILURE,
    payload: error,
  };
};

export const fetchEquipment = () => {
  return {
    type: EQUIPMENT_FETCH_PENDING,
  };
};

export const fetchEquipmentSuccess = equipment => {
  return {
    type: EQUIPMENT_FETCH_SUCCESS,
    payload: equipment,
  };
};

export const fetchEquipmentFailure = error => {
  return {
    type: EQUIPMENT_FETCH_FAILURE,
    payload: error,
  };
};
