import styled, { css, keyframes } from 'styled-components'
import { colors, font } from '@common/Theme'
import { media } from '@common/Theme'

const pulse = keyframes`
	0% {
			transform: scale(1);
		}
		75% {
			transform: scale(1.05);
		}
		100% {
			transform: scale(1);
		}
 `

const Header = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-top: 16px;
  padding-right: 16px;
  padding-left: 16px;
  padding-bottom: 0;
  position: relative;
  cursor: pointer;
  outline: none;
  background: transparent;
  &:focus {
    outline: none;
  }
  span.type {
    color: rgb(247, 192, 58);
    font-size: 14px;
    margin: 0px;
  }
  span.title {
    color: ${colors.gold};
    font-size: 14px;
    font-weight: 500;
    margin: 0px;
    padding: 0px;
    align-self: flex-start;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  h3.heading {
    font-size: 16px;
    font-weight: bold;
    user-select: none;
    margin: 0;
    color: white;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 28px;
    max-width: 400px;
  }
`

const TaskConent = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  position: relative;
  flex: 1;
  margin-bottom: 16px;
`

const TaskMenu = styled.div`
  display: flex;
  flex-direction: column;
`

const TimerControl = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 16px;
  gap: 16px;
  border-top: 1px solid rgb(51, 59, 68);
  padding-top: 16px;
  button {
    span.label {
      line-height: 21px;
    }
  }
`

const TimerContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`

const PlayItemDetail = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;
  padding: 16px;
  &.link {
    padding-top: 0;
  }
  &.notes {
    padding-top: 0;
  }
  &.entries {
    padding-bottom: 0;
  }
`

const Controls = styled.div`
  width: 100%;
  padding: 0 16px 12px 16px;
  display: flex;
  gap: 16px;
  justify-content: flex-end;
  button {
    &:only-child {
      margin-left: auto;
    }
  }
`

const TimerForm = styled.form`
  padding: 16px;
  background: #202428;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  flex-basis: auto;
  min-width: 300px;
  width: 33%;
`

const TimerFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  opacity: 0;
  width: 100%;
  transition: all 0.3s ease-in-out;

  .active & {
    opacity: 1;
  }
`

const TimerFormHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 10px;
  align-items: center;
  margin-bottom: 8px;
  h2 {
    font-size: 18px;
    line-height: 20px;
    font-weight: 500;
    margin: 0px;
    padding: 0px;
    align-self: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: white;
  }
`

const TimerAccordion = styled.div`
  width: 100%;
  position: relative;
  border-radius: 0 0 8px 8px;
  overflow: hidden;
  transition: all 0.1s ease;
  max-height: 0;
  ${(props) =>
    props.isExpanded &&
    css`
      max-height: 500px;
    `};
`

const TimerButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  position: absolute;
  top: 16px;
  right: 8px;
  .active & {
    svg {
      transform: rotate(180deg);
    }
  }
`

const List = styled.li`
  display: flex;
  flex-shrink: 0;
  list-style: none;
  outline: none;
  margin: 0;
  padding: 0;
  transition: all 0.3s ease-in-out;
  border-radius: 6px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  &:focus {
    outline: none;
  }
  &.active {
    flex: 1;
  }
`

const StatusControl = styled.div`
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  border: 1px solid transparent;
  transition: all 0.3s ease-in-out;
  background: #191d22;
  min-width: 72px;
  border-radius: 6px 0px 0px 6px;
  padding-right: 16px;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 16px;
  gap: 8px;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 16px;
  position: relative;
  width: calc(100% - 72px);
  border-radius: 0px 6px 6px 0px;
  transition: all 0.3s ease-in-out;
  background: #22282c;
  ${(props) =>
    props.isActive &&
    css`
      padding-bottom: 32px;
    `};
  &:first-child {
    padding-bottom: 42px;
  }
  &.is-closed {
    &:hover {
      background: #191d22;
    }
  }
`

const TimerDetail = styled.div`
  display: flex;
  align-items: center;
  margin-left: 8px;

  span.remaining {
    line-height: 21px;
    color: white;
    font-size: 12px;
    font-weight: ${font.fontWeightLight};
    position: relative;
    top: 1px;
    user-select: none;
  }
  span.status {
    line-height: 21px;
    color: white;
    font-size: 12px;
    font-weight: ${font.fontWeightLight};
    position: relative;
    top: 1px;
    user-select: none;
  }
`

const TimerItemCounter = styled.div`
  position: absolute;
  top: 16px;
  right: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  &.active {
    right: 40px;
  }
`

const TimerItemControl = styled.div`
  display: flex;
  position: relative;
  display: flex;
  margin-left: auto;
  width: 48px;
`

const TimerGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: #282e33;
  border-radius: 6px;
  margin-bottom: 16px;
  width: 100%;
  flex-grow: 1;

  &.empty {
    height: 100%;
    max-height: 100%;
    align-items: center;
    justify-content: center;
  }
  h2 {
    font-size: 16px;
    color: white;
    margin: 0;
  }
  ${media.sm`
    margin-bottom: 0;
  `};
`

const LogContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #22282c;
  padding: 0;
  padding: 16px;
  border-radius: 6px;
  button {
    align-self: center;
    animation-name: ${pulse};
    animation-duration: 1.5s;
    animation-delay: 0;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-timing-function: ease-out;
  }
`

const Group = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  gap: 16px;
  border-radius: 6px;
  overflow-y: auto;

  &.group {
    align-items: center;
    width: 100%;
    max-height: 100%;
    height: 100%;
    border: 1px solid rgb(51, 59, 68);
    padding: 16px;
    justify-content: center;
    h2 {
      color: white;
      font-size: 16px;
      margin: 0;
    }
  }

  .bottom {
    padding-bottom: 16px;
  }
  .bottom.last {
    display: none;
  }
`

const Wrapper = styled.div`
  display: flex;
  margin-bottom: 16px;
  gap: 16px;
  width: 100%;
  flex-direction: column;
`

const TimerBar = styled.div`
  width: calc(100% - 32px);
  height: 5px;
  background: rgb(65 74 79);
  border-radius: 4px;
  position: absolute;
  bottom: 16px;
  left: 16px;
  overflow: hidden;
  &:after {
    content: '';
    width: 100%;
    height: 100%;
    background: #3aeeb8;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(100%);
    transition: all 0.3s ease-in-out;
  }
  ${(props) =>
    props.percentage &&
    css`
      &:after {
        transform: translateX(-${props.percentage}%);
      }
    `};
  ${(props) =>
    props.isComplete &&
    css`
      &:after {
        transform: translateX(0%);
      }
    `};
`

const ExtendedAccordion = css`
  background-color: #282e32;
  border-radius: 8px;
  span.title {
    color: white;
    font-weight: 600;
    font-size: 20px;
  }
  .header {
    border: 1px solid transparent;
  }
  &.active {
    .header {
      border: 1px solid transparent;
    }
    .content {
      border: 1px solid transparent;
      padding: 0 16px;
    }
  }
`

export {
  ExtendedAccordion,
  Header,
  TimerContent,
  TimerForm,
  StatusControl,
  TimerAccordion,
  TimerButton,
  Container,
  Group,
  Wrapper,
  List,
  TimerDetail,
  TaskConent,
  TimerBar,
  TimerItemCounter,
  PlayItemDetail,
  TimerControl,
  TaskMenu,
  Controls,
  LogContainer,
  TimerFormHeader,
  TimerItemControl,
  TimerFormContainer,
  TimerGroupContainer,
}
