import React from 'react'
import PropTypes from 'prop-types'
import { prop, isEmpty, toLower } from 'ramda'
import { Children } from './Variants/buttonStyles'
import {
  Outline,
  Discount,
  Secondary,
  Primary,
  Disabled,
  Menu,
  GoogleSignIn,
  GoogleSignUp,
  Save,
  Success,
  White,
  Small,
  Text,
  Blue,
  Grey,
  Purple,
  Green,
  Orange,
  Transparent,
  Red,
  Black,
  Gold,
} from './Variants'

const buttons = {
  blue: Blue,
  grey: Grey,
  purple: Purple,
  green: Green,
  red: Red,
  gold: Gold,
  black: Black,
  text: Text,
  small: Small,
  orange: Orange,
  primary: Primary,
  white: White,
  disabled: Disabled,
  signin: GoogleSignIn,
  signup: GoogleSignUp,
  discount: Discount,
  transparent: Transparent,
  outline: Outline,
  menu: Menu,
  save: Save,
  success: Success,
}

const validateButton = (givenCode, defaultCode) => {
  const code =
    givenCode && givenCode.length > 0 ? toLower(givenCode) : defaultCode
  const isCodeValid = code in buttons

  if (isCodeValid) return code
  return toLower(defaultCode)
}

const Button = ({ variant, label, className, children, ...props }) => {
  const { layout: layoutStyle, text: textStyle } = prop('config')(props) || {}
  const { center } = prop('config')(props)

  const defaultCode = 'orange'
  const code = validateButton(variant, defaultCode)

  const Component = buttons[code]
  return (
    <Component
      buttonLabel={!!isEmpty(label)}
      style={layoutStyle}
      center={center}
      className={className}
      disabled={props.disabled}
      alone={props.alone}
      {...props}
    >
      {!center && (
        <>
          {label && (
            <span className="label" style={textStyle}>
              {label}
            </span>
          )}
          {children}
        </>
      )}

      {center && (
        <Children>
          {label && (
            <span className="label" style={textStyle}>
              {label}
            </span>
          )}
          {children}
        </Children>
      )}
    </Component>
  )
}

Button.defaultProps = {
  mt: 0,
  mb: 0,
  underline: false,
  config: {},
  variant: 'primary',
}

Button.propTypes = {
  mt: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mb: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  underline: PropTypes.bool,
  config: PropTypes.object,
  variant: PropTypes.string,
}

export { Button, buttons }
