import React from 'react'
import { Toggle } from '@common/Toggle'
import { updateDevMode } from '@state/settings'
import { colors } from '@common/Theme'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { settingsSelector } from '@state/settings'
import { userSelector } from '@state/user'
import { updateUser } from '@state/user/actions'
import { useDispatch } from 'react-redux'

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Control = () => {
  const isPublished = useSelector(userSelector.isPublished)
  const dispatch = useDispatch()

  const handleToggle = ({ value }) => {
    dispatch(
      updateUser({
        isPublished: value,
      }),
    )
  }

  return (
    <Container>
      <Toggle isActive={isPublished} onChange={handleToggle} label="Publish" />
    </Container>
  )
}

export { Control }
