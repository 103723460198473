import React from 'react'
import { Icon } from '../Icon'
import { length, toUpper } from 'ramda'
import {
  ErrorContainer,
  ErrorWrapper,
  ErrorContent,
  ErrorIcon,
  ErrorClose,
} from './ErrorList.styled.js'
import PropTypes from 'prop-types'

const Message = ({ onClose, name, hasClose, variant, message }) => {
  const derivedVariant = toUpper(variant) || 'ERROR'
  const isError = derivedVariant === 'ERROR'
  const isSuccess = derivedVariant === 'SUCCESS'

  return (
    <ErrorWrapper error={isError} success={isSuccess}>
      <ErrorContent
        className={name}
        hasClose={hasClose}
        success={isSuccess}
        error={isError}
      >
        <ErrorIcon>
          <Icon name="BOLT" fill={isError ? '#f72e44' : '#FFF'} size={20} />
        </ErrorIcon>
        {message && <p>{message}</p>}
        {hasClose && (
          <ErrorClose onClick={onClose}>
            <Icon
              name="CLOSE_SMALL"
              size={20}
              stroke={isError ? '#f72e44' : '#FFF'}
              viewBox="-1 -1 20 20"
            />
          </ErrorClose>
        )}
      </ErrorContent>
    </ErrorWrapper>
  )
}

const ErrorList = ({
  needles,
  haystack,
  callback,
  variant,
  mt,
  mb,
  ...rest
}) => {
  const filteredErrors = haystack
    .filter((match) => needles.includes(match.name))
    .map((error) => error)

  if (!filteredErrors || !length(filteredErrors)) return null

  return filteredErrors.map((item, index) => (
    <ErrorContainer key={`${index}-${item.name}`} mt={mt} mb={mb}>
      <Message
        message={item.message}
        name={item.name}
        variant={item.variant || 'error'}
        onClose={() => callback({ name: 'error', value: item.name })}
        {...rest}
      />
    </ErrorContainer>
  ))
}

Message.defaultProps = {
  onClose: () => {},
  name: '',
  hasClose: false,
  variant: 'error',
  message: 'There was an error',
}

Message.propTypes = {
  /** Array of items passed in which can be rendered */
  needles: PropTypes.array,
  /** Array of items to be filtered by */
  haystack: PropTypes.array,
  /** Function to invoke when the user clicks on the close button with a customised outcome.*/
  callback: PropTypes.func,
  /** String defining type of error style */
}

ErrorList.defaultProps = {
  mt: 0,
  mb: 0,
  callback: () => {},
  needles: [],
  haystack: [],
}

export { ErrorList }
