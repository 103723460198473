import {
  EXERCISES_FETCH_PENDING,
  EXERCISES_UPDATE_PENDING,
  EXERCISES_CREATE_IMAGE_PENDING,
  EXERCISES_DELETE_PENDING,
  EXERCISES_UPDATE_IMAGE_PENDING,
  EXERCISE_DELETE_UPDATE_IMAGE_PENDING,
  EXERCISES_CREATE_PENDING,
  EXERCISE_DELETE_IMAGE_PENDING,
  EXERCISES_BUILDER_FETCH_PENDING,
} from '../actionTypes'

export const fetchExercises = () => ({
  type: EXERCISES_FETCH_PENDING,
})

export const fetchBuilderExercises = () => ({
  type: EXERCISES_BUILDER_FETCH_PENDING,
})

export const createExercise = (payload) => ({
  type: EXERCISES_CREATE_PENDING,
  payload,
})

// NOT IN USE -- TODO -- CHECK THIS USAGE
export const updateExercise = (exercise) => ({
  type: EXERCISES_UPDATE_PENDING,
  payload: exercise,
})

export const createExerciseImage = (payload) => ({
  type: EXERCISES_CREATE_IMAGE_PENDING,
  payload: payload,
})

export const updateExerciseImage = (payload) => ({
  type: EXERCISES_UPDATE_IMAGE_PENDING,
  payload: payload,
})

export const deleteAndUpdateExerciseImage = (payload) => ({
  type: EXERCISE_DELETE_UPDATE_IMAGE_PENDING,
  payload,
})

export const deleteExerciseImage = (payload) => ({
  type: EXERCISE_DELETE_IMAGE_PENDING,
  payload,
})

export const removeExercise = (payload) => ({
  type: EXERCISES_DELETE_PENDING,
  payload,
})
