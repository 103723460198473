import React, { useReducer, useMemo } from 'react';
import reducer, { InitialState } from './Reducer';
import ThemeContext from './ThemeContext';

const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, InitialState);

  const context = useMemo(() => [state, dispatch], [state, dispatch]);

  return (
    <ThemeContext.Provider value={context}>{children}</ThemeContext.Provider>
  );
};

export default Provider;
