function getByVariousKeys(obj, key = '', exclude = []) {
  // Check if the object has the "exerciseId" property
  if (obj.hasOwnProperty('exerciseId')) {
    return obj.exerciseId
  } else if (obj.hasOwnProperty('workoutId')) {
    return obj.workoutId
  } else if (obj.hasOwnProperty('id')) {
    return obj.id
  }
  // If neither "id" nor "exerciseId" or "workoutId" is found, return null or handle as needed
  else {
    return null // or any other default value or handling
  }
}

export { getByVariousKeys }
