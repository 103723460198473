import React from 'react'
import { prop } from 'ramda'
import { ImageContainer, ImageItem } from './Image.styled'

/*
 * bgSize: 30px
 * width: 30px
 * height: 30px
 * radius: 0px
 */

const Image = (props) => {
  const {
    bgSize,
    objectFit,
    radius,
    minHeight,
    minWidth,
    bgColor,
    bgPosition,
    src,
    config,
  } = props

  const matchedLayoutStyles = prop('layout')(config)
  const matchedImageStyles = prop('image')(config)

  if (!src || !src.length) {
    return <></>
  }
  return (
    <ImageContainer
      className="aima-common-image"
      bgColor={bgColor}
      radius={radius}
      style={matchedLayoutStyles}
      {...props}
    >
      <ImageItem
        objectFit={objectFit}
        $imageSrc={src}
        minWidth={minWidth}
        minHeight={minHeight}
        bgSize={bgSize}
        bgPosition={bgPosition}
        style={matchedImageStyles}
        {...props}
      />
    </ImageContainer>
  )
}

Image.defaultProps = {
  bgSize: '30px',
  objectFit: 'cover',
  radius: '0px',
  minHeight: 'unset',
  minWidth: 'unset',
  bgColor: 'transparent',
  bgPosition: 'transparent',
  config: {},
}

export { Image }
