import React from 'react'
import styled from 'styled-components'
import { Button } from '@common/Button'

const HideText = styled.span`
  margin-left: 4px;
  display: inline-block;
`

const ExerciseUploaderFooterHeader = styled.div`
  min-width: 100px;
  flex: 1;
  width: 100%;
`

const ExerciseUploaderFooterContainer = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 24px;
  border-top: 1px solid #e3e5eb;
  justify-content: flex-end;
`

const ExerciseUploaderFooter = ({ onCancel = () => {}, onSave = () => {} }) => (
  <ExerciseUploaderFooterContainer>
    <ExerciseUploaderFooterHeader />
    <Button
      variant="OUTLINE"
      type="button"
      onClick={onCancel}
      config={{
        layout: {
          marginTop: '16px',
          textAlign: 'center',
          marginRight: '16px',
          justifyContent: 'center',
        },
      }}
    >
      Cancel
    </Button>
    <Button
      variant="orange"
      type="button"
      className="confirm-button"
      onClick={onSave}
      config={{
        layout: {
          marginTop: '16px',
          textAlign: 'center',
          justifyContent: 'center',
        },
      }}
    >
      Save
      <HideText>Image</HideText>
    </Button>
  </ExerciseUploaderFooterContainer>
)

export { ExerciseUploaderFooter }
