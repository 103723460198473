import React, { useEffect, useContext } from 'react'
import { useDispatch } from 'react-redux'
import { string, object } from 'yup'
import { Formik, Form, Field } from 'formik'
import { InputField } from '@common/InputField'
import { LayoutContext, LayoutTypes } from '@features/Layout'
import { usePortal as Portal } from '@hooks/usePortal'
import { uuid } from '@utils/uuid'
// STATE
import { createWorkout, setActiveWorkoutSuccess } from '@state/workout'
import { WorkoutControls } from './WorkoutControls'
// STYLED
import {
  FlexRow,
  FlexItem,
  FormScroll,
  FormikContainer,
  WorkoutCreatorContainer,
} from './WorkoutCreator.styled'

const Schema = object().shape({
  label: string()
    .max(30, `Name of workout`)
    .required('Please enter a name for the workout'),
})

const WorkoutCreator = () => {
  const dispatch = useDispatch()

  const [localState, localDispatch] = useContext(LayoutContext)

  const initialValues = {
    label: '',
    description: '',
  }

  const handleSubmit = ({ label, description }) => {
    const workoutId = uuid()
    dispatch(
      createWorkout({
        workoutName: label,
        workoutDescription: description,
        id: workoutId,
      }),
    )
  }

  const onResetFields = (setFieldValue) => {
    setFieldValue('label', '')
    setFieldValue('description', '')
    localDispatch({
      type: LayoutTypes.CLOSE_BLADE,
    })
  }

  useEffect(() => {
    dispatch(setActiveWorkoutSuccess({}))
    localDispatch({
      type: LayoutTypes.SET_BLADE_TITLE,
      data: 'Create Workout',
    })
  }, [])

  return (
    <WorkoutCreatorContainer>
      <FormikContainer>
        <FormScroll>
          <Formik
            initialValues={initialValues}
            validateOnChange
            validationSchema={Schema}
            onSubmit={handleSubmit}
          >
            {({ setFieldValue, submitForm }) => (
              <Form>
                <FlexRow>
                  <FlexItem>
                    <Field
                      name="label"
                      variant="OUTLINE"
                      label="Name"
                      mb={16}
                      component={InputField}
                      placeholder="Enter Name"
                    />
                  </FlexItem>
                </FlexRow>
                <FlexRow>
                  <FlexItem>
                    <Field
                      name="description"
                      variant="OUTLINE"
                      label="Description"
                      mb={16}
                      component={InputField}
                      placeholder="Enter Description"
                    />
                  </FlexItem>
                </FlexRow>

                <Portal selector="#workout-controls">
                  <WorkoutControls
                    onSave={submitForm}
                    onCancel={() => onResetFields(setFieldValue)}
                  />
                </Portal>
              </Form>
            )}
          </Formik>
        </FormScroll>
      </FormikContainer>
    </WorkoutCreatorContainer>
  )
}

export { WorkoutCreator }
