import React from 'react'
import { Main, Layout, Header, Content, Menu, Blade } from '@features/Layout'
import { WorkoutBody } from './WorkoutBody'
import { Tabs } from '@common/Tabs'
import { WorkoutBuilderForm } from './WorkoutBuilderForm'

const options = [
  {
    label: 'Workouts',
    value: 'workout',
    content: null,
    route: '/workout',
    roles: ['PERSONALTRAINER'],
  },
]

const Library = () => (
  <Layout variant="custom">
    <Menu />
    <Main className="Content">
      <Header config={{ isProfileEnabled: true }}>
        <Tabs
          options={options}
          active={'workout'}
          loading={false}
          showPanel={false}
        />
      </Header>
      <Content>
        <WorkoutBody />
      </Content>
    </Main>
    <Blade variant="workout">
      <WorkoutBuilderForm />
    </Blade>
  </Layout>
)

export { Library }
