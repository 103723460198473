import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Button } from '@common/Button'
import { useFormikContext } from 'formik'
import { usePrevious } from '@hooks/usePrevious'
import { workoutSelector } from '@state/workout'

const Controls = styled.div`
  display: flex;
  flex-direction: row;
  .cancel {
    margin-right: 8px;
  }
`

const ControlsContainer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  padding-top: 16px;
  padding-right: 8px;
  flex-direction: column;
  background: white;
  padding-bottom: 16px;
  padding-right: 16px;
  padding-left: 16px;
  border-top: 1px solid #e3e5eb;
  z-index: 1;
`

const WorkoutControls = ({ onCancel, onSave }) => {
  const { setFieldValue } = useFormikContext()

  const workouts = useSelector(workoutSelector.workouts)
  const previousWorkouts = usePrevious(workouts)

  useEffect(() => {
    if (previousWorkouts !== workouts) {
      setFieldValue('label', '')
      setFieldValue('description', '')
    }
  }, [workouts])

  const handleSave = () => onSave()

  return (
    <ControlsContainer>
      <Controls>
        <Button
          variant="outline"
          onClick={onCancel}
          mb={16}
          className="cancel"
          type="button"
        >
          <span>Cancel</span>
        </Button>
        <Button
          variant="orange"
          mb={16}
          type="submit"
          onClick={handleSave}
          className="save"
        >
          <span>Save</span>
        </Button>
      </Controls>
    </ControlsContainer>
  )
}

export { WorkoutControls }
