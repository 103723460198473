import styled from 'styled-components';

const ExerciseUploaderOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(29, 42, 67, 0.24);
  display: block;
  z-index: 100;
`;

const ExerciseUploaderFieldGroup = styled.div``;

export { ExerciseUploaderOverlay, ExerciseUploaderFieldGroup };
