import { api } from '@state/utils'
import { API } from './api'
import { localVars } from '@features/Auth/Service/config'

import {
  WORKOUTS_BY_ID_SUCCESS,
  WORKOUTS_FETCH_SUCCESS,
  WORKOUTS_UPDATE_SUCCESS,
  WORKOUTS_CREATE_SUCCESS,
  WORKOUTS_DELETE_SUCCESS,
  WORKOUTS_CREATE_IMAGE_SUCCESS,
} from './actionTypes'

import workoutsFetchSuccess from './data/workoutsFetchSuccess'
import workoutByIdSuccess from './data/workoutByIdSuccess'

// import { response as workoutsFetchFailure } from './data/workoutsFetchFailure';
// import { response as workoutCreateFailure } from './data/workoutCreateFailure';
// import { response as workoutUpdateSuccess } from './data/workoutUpdateSuccess';
// import { response as workoutGetByIdSuccess } from './data/workoutGetByIdSuccess';
// import { response as workoutUpdateFailure } from './data/workoutUpdateFailure';
// import { response as workoutByIdFailure } from './data/workoutByIdFailure';
// import { uuid } from '@utils/uuid';

const apiWrapper = (data) => {
  // const result = pipe(prop(key), defaultTo(data))(data);

  return { data }
}

// function that performs the api request and returns a Promise for that response
const mockApi = ({ type }) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(
        {
          [WORKOUTS_FETCH_SUCCESS]: apiWrapper(workoutsFetchSuccess),
          [WORKOUTS_BY_ID_SUCCESS]: apiWrapper(workoutByIdSuccess),
        }[type],
      )
    }, 1000)
  })
}

const apiInterceptor = (props) => {
  if (localVars.mock === 'enabled') {
    return mockApi(props)
  } else {
    return api(props)(API)
  }
}

export const fetchWorkouts = () => {
  return apiInterceptor({ type: WORKOUTS_FETCH_SUCCESS })
}

export const createWorkout = (payload) => {
  return apiInterceptor({ type: WORKOUTS_CREATE_SUCCESS, body: payload })
}

export const requestUpdateWorkout = (payload) =>
  apiInterceptor({
    type: WORKOUTS_UPDATE_SUCCESS,
    body: payload,
    params: payload.workoutId,
  })

export const deleteWorkout = (payload) => {
  return apiInterceptor({
    type: WORKOUTS_DELETE_SUCCESS,
    params: payload,
  })
}

export const addWorkoutImage = (payload) => {
  const form = new FormData()
  form.append('files', payload)
  return apiInterceptor({ type: WORKOUTS_CREATE_IMAGE_SUCCESS, body: form })
}

export const getWorkoutById = (payload) => {
  return apiInterceptor({ type: WORKOUTS_BY_ID_SUCCESS, params: payload })
}
