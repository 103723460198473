import React from 'react'
import PropTypes from 'prop-types'
import { Heading } from '@common/Heading'
import { Icon } from '@common/Icon'
import { Container, ModalBlock, ModalClose } from '../Modal.styled'

const DeleteWorkout = ({
  style,
  dev,
  callback = () => {},
  children,
  level,
}) => {
  return (
    <Container style={style || {}} dev={dev} level={level}>
      <ModalBlock direction="row" justifyContent="space-between">
        <Heading variant="page" style={{ textAlign: 'left', width: '100%' }}>
          Are you sure?
        </Heading>
        <ModalClose
          onClick={() => callback({ action: 'CLOSE' })}
          style={{ position: 'absolute', right: '16px', top: '16px' }}
        >
          <Icon name="CLOSE" size={20} />
        </ModalClose>
      </ModalBlock>
      {children}
    </Container>
  )
}

DeleteWorkout.defaultProps = {
  data: {
    message: '',
  },
  style: {},
  dev: false,
  level: 2,
}

DeleteWorkout.propTypes = {
  data: PropTypes.object,
  style: PropTypes.object,
  dev: PropTypes.bool,
  level: PropTypes.number,
  callback: PropTypes.func,
}

export default DeleteWorkout
