import React from 'react';
import styled, { css } from 'styled-components';
import {
  basicFunctions,
  basicStyles,
  basicText,
  pseudoStyles,
} from './buttonStyles';
import { mbFn, mtFn, mlFn, mrFn } from '@common/Theme';

const MenuStyle = styled.button`
  ${basicStyles};
  ${basicText};
  ${pseudoStyles}
  ${basicFunctions};
  ${mbFn};
  ${mtFn};
  ${mlFn};
  ${mrFn};
  font-weight: 500;
  color: #a9aeb9;
  border: 1px solid transparent;
  box-shadow: none;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  max-height: 48px;
  padding: 17px 16px;
  transition: all 0.2s ease-in-out;
  box-sizing: border-box;
  background: transparent;
  ${props =>
    props.isActive &&
    css`
      background: linear-gradient(
        0deg,
        rgba(255, 90, 69, 0.1),
        rgba(255, 90, 69, 0.1)
      );
      svg {
        rect {
          stroke: #ff5a45;
        }
        path {
          stroke: #ff5a45;
        }
      }
    `};
  &:hover {
    background: linear-gradient(
      0deg,
      rgba(255, 90, 69, 0.1),
      rgba(255, 90, 69, 0.1)
    );
    svg {
      rect {
        stroke: #ff5a45;
      }
      path {
        stroke: #ff5a45;
      }
    }
  }
  svg {
    rect {
      stroke: #a9aeb9;
    }
    path {
      stroke: #a9aeb9;
    }
  }
`;

const Menu = ({ style, children, ...props }) => (
  <MenuStyle asBlue style={style} {...props}>
    {children}
  </MenuStyle>
);

export { Menu };
