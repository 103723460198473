import styled from 'styled-components';

const EditInputGroup = styled.div`
  min-width: 250px;
  display: flex;
  align-items: center;
  height: 100%;
`;

const EditInputField = styled.input`
  font-size: 26px;
  font-weight: 500;
  line-height: 30px;
  width: 100%;
  padding: 0;
  border: none;
  outline: none;
  background: transparent;
`;

const EditContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  height: 100%;
`;

const EditHeading = styled.h2`
  margin: 0;
  padding: 0;
  font-size: 26px;
  font-weight: 500;
  line-height: 30px;
  max-width: 400px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export { EditInputGroup, EditInputField, EditContainer, EditHeading };
