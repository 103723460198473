import React from 'react'
import { includes, toUpper, length, keys } from 'ramda'
import { Primary, Secondary, Tertiary } from './IconContainer.styled'

const config = {
  PRIMARY: Primary,
  SECONDARY: Secondary,
  TERTIARY: Tertiary,
}

const IconContainer = ({ variant = '', children, ...props }) => {
  const upperCaseVariant = toUpper(variant)

  if (
    !variant ||
    !length(upperCaseVariant) ||
    !includes(upperCaseVariant)(keys(config))
  ) {
    return <Primary {...props}>{children}</Primary>
  }

  const Component = config[upperCaseVariant]
  return <Component {...props}>{children}</Component>
}

export { IconContainer }
