import { media } from '@common/Theme'
import styled, { css } from 'styled-components'

const Content = styled.div`
  border-top: 1px solid #e3e5eb;
  padding-left: 16px;
  padding-top: 16px;
  padding-right: 16px;
  transition: all 0.3s ease-in-out;
  ${media.sm`
    padding-left: 24px;
    padding-top: 24px;
    padding-right: 24px;
  `};
  ${media.md`
    padding-left: 32px;
    padding-top: 32px;
    padding-right: 34px;
  `};
  ${media.lg`
    padding-left: 48px;
    padding-top: 48px;
    padding-right: 48px;
  `};
`

export { Content }
