import React, { useState, useEffect } from 'react'
import { Button } from '@common/Button'
import { StateView } from '@common/StateView'
import { Icon } from '@common/Icon'
import { colors, mbFn, mtFn } from '@common/Theme'
import {
  TimerContent,
  StatusControl,
  TimerAccordion,
  TimerItemCounter,
  List,
  Controls,
  TimerButton,
  Container,
  TimerDetail,
  TimerBar,
  Header,
  PlayItemDetail,
} from './Play.styled'
import { TaskCircle } from './TaskCircle'
import { TaskRemaining } from './TaskRemaining'
import { LinkTo } from '@common/Link'
import styled from 'styled-components'
import { TextArea } from '@common/TextArea'
import { Label } from '@common/Label'
import { useSelector } from 'react-redux'
import { settingsSelector } from '@state/settings'

const TimerColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`
const HeaderControl = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  .header-group {
    display: flex;
  }
`
const TimerImage = styled.div`
  border-radius: 6px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px; /* Set the width of the container */
  height: 200px; /* Set the height of the container */
  overflow: hidden; /* Hide overflow content */

  img {
    width: 100%; /* Ensure the image takes up the full width of the container */
    height: 100%; /* Ensure the image takes up the full height of the container */
    object-fit: cover; /* Make the image cover the entire container while maintaining aspect ratio */
  }
`
const EntryContainer = styled.div`
  background: rgb(40, 46, 51);
  font-size: 16px;
  padding: 13px 10px 12px 11px;
  color: white;
  width: 100%;
  box-sizing: border-box;
  border: 1.5px solid rgb(51, 59, 68);
  position: relative;
  border-radius: 6px;
  transition: all 0.2s ease-in-out 0s;
  appearance: none;
`
const EntryLabel = styled.span`
  font-weight: 300;
  font-size: 14px;
  font-weight: normal;
  color: #b2b2b2;

  margin-bottom: 8px;
  font-weight: 400;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  white-space: nowrap;
`
const EntryItemContent = styled.div``

const EntryWrapper = styled.div`
  ${mbFn};
  ${mtFn};
  min-width: 100px;
`

const Suffix = ({ variant = '' }) => {
  if (variant.toUpperCase() === 'REST') {
    return <span> sec</span>
  }
  if (variant.toUpperCase() === 'WEIGHT') {
    return <span> kg</span>
  }
  return null
}

const EntryItem = ({ label = '', value = '', mb, mt }) => {
  return (
    <EntryWrapper mb={mb} mt={mt}>
      {label && <EntryLabel>{label}</EntryLabel>}
      <EntryContainer>
        {
          <EntryItemContent>
            {value || '0'}
            <Suffix variant={label} />
          </EntryItemContent>
        }
      </EntryContainer>
    </EntryWrapper>
  )
}

function calculateRemainingSeconds(startTime, minutes, seconds) {
  const calcMin = minutes * 60000
  const min = minutes > 0 ? calcMin : 0
  const sec = seconds * 1000
  const targetTime = new Date(startTime.getTime() + min + sec)
  return Math.max(Math.floor((targetTime - startTime) / 1000), 0)
}

const PlayItem = ({
  minutes,
  seconds,
  label,
  id,
  sound,
  image,
  maxLength,
  reps,
  sets,
  weight,
  status,
  timerStatus,
  variant,
  notes,
  remainingSeconds,
  callback,
  link,
  index,
  date,
}) => {
  const collatedDate = new Date(date)
  const [remainingTime, setRemainingTime] = useState(0)
  const isDevMode = useSelector(settingsSelector.isDevMode)

  useEffect(() => {
    if (!!remainingSeconds) {
      setRemainingTime(remainingSeconds)
    } else {
      setRemainingTime(
        calculateRemainingSeconds(collatedDate, minutes, seconds),
      )
    }
  }, [remainingSeconds])

  useEffect(() => {
    const intervalId = setInterval(() => {
      setRemainingTime((prevRemainingSeconds) => {
        return Math.max(prevRemainingSeconds - 1, 0)
      })
    }, 1000)

    if (remainingSeconds === 0) {
      clearInterval(intervalId)
    }

    if (timerStatus === 'IS_PAUSED') {
      clearInterval(intervalId)
    }

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId)
  }, [minutes, seconds, timerStatus, remainingSeconds])

  const calcMin = minutes * 60
  const min = minutes > 0 ? calcMin : 0
  const sec = seconds

  const totalTimeSeconds = min + sec
  const percentageRemaining = Math.ceil(
    (remainingTime / totalTimeSeconds) * 100,
  )

  const onTogglePlay = (id) => {
    if (remainingTime === 0) return
    if (timerStatus === 'IS_PAUSED') {
      callback({ action: 'PLAY', value: { id } })
      return
    }
    callback({
      action: 'PAUSE',
      value: {
        id,
        remainingTime,
      },
    })
  }

  const renderControls = () => {
    const isNextButtonVisible = index < maxLength - 1
    const isFinishedButtonVisible = index === maxLength - 1
    const hideSkipButton = false
    return (
      <Controls>
        <Button
          variant="red"
          disabled={hideSkipButton}
          onClick={() => callback({ action: 'SKIP', value: { id, index } })}
        >
          <Icon name="CANCEL" mr={5} rotate={180} stroke={colors.red} />
          <span>Skip</span>
        </Button>
        {isNextButtonVisible && (
          <Button
            variant="green"
            disabled={!isNextButtonVisible}
            onClick={() => callback({ action: 'NEXT', value: { id, index } })}
          >
            <span>Done</span>
            <Icon name="ARROW_RIGHT" ml={5} stroke={colors.green} />
          </Button>
        )}
        {isFinishedButtonVisible && (
          <Button
            variant="green"
            onClick={() => callback({ action: 'FINISH', value: { id } })}
          >
            <span>Next</span>
            <Icon name="ARROW_RIGHT" ml={5} stroke={colors.green} />
          </Button>
        )}
      </Controls>
    )
  }

  const renderButton = () => {
    if (status === 'COMPLETED') {
      return (
        <Button variant="green">
          <Icon name="CHECK" stroke={colors.green} />
        </Button>
      )
    }
    if (status === 'SKIPPED') {
      return (
        <Button variant="red">
          <Icon name="CANCEL" rotate={180} stroke={colors.red} />
        </Button>
      )
    }
    return (
      <Button variant="gold" onClick={() => onTogglePlay(id)}>
        {timerStatus === 'IS_PLAYING' ? (
          <Icon name="PAUSE" stroke={colors.gold} />
        ) : (
          <Icon name="PLAY" fill={colors.gold} stroke={colors.gold} />
        )}
      </Button>
    )
  }

  const renderMuteControls = () => {
    if (!percentageRemaining.toFixed(0) > 0) return
    return (
      <TimerItemCounter className={'active'}>
        <Icon mt={8} name={sound ? 'UNMUTE' : 'MUTE'} size={24} />
        <TaskCircle
          size={32}
          percentage={percentageRemaining.toFixed(0) || 100}
          isComplete={
            percentageRemaining.toFixed(0) === 'NaN' ||
            percentageRemaining.toFixed(0) <= 0
          }
        />
      </TimerItemCounter>
    )
  }

  return (
    <List>
      <StatusControl>{renderButton()}</StatusControl>
      <Container
        className={status === 'IN_PROGRESS' ? 'expanded' : 'is-closed'}
        isActive={
          status !== 'SKIPPED' &&
          status !== 'COMPLETED' &&
          status !== 'IS_PENDING'
        }
      >
        <Header
          onClick={() =>
            callback({ action: 'SET_IN_PROGRESS', value: { id, index } })
          }
        >
          <HeaderControl>
            <span className="title">
              {variant === 'REST' ? 'Rest' : 'Exercise'}
            </span>
            <div className="header-group">
              <h3 className="heading">{label}</h3>
              <TimerDetail>
                {remainingTime && remainingTime > 0 ? (
                  <TaskRemaining seconds={remainingTime} />
                ) : (
                  <>
                    <span className="status">Time's up</span>
                  </>
                )}
              </TimerDetail>
            </div>
          </HeaderControl>

          <TimerButton>
            <Icon
              name="CHEVRON"
              rotate={status === 'IN_PROGRESS' ? '0' : '90'}
              size={24}
            />
          </TimerButton>
        </Header>
        {status !== 'SKIPPED' &&
          status !== 'COMPLETED' &&
          status !== 'IS_PENDING' && (
            <TimerBar
              onClick={() =>
                callback({
                  action: 'SET_IN_PROGRESS',
                  value: { id, index },
                })
              }
              percentage={percentageRemaining.toFixed(0) || '100'}
              isComplete={percentageRemaining.toFixed(0) === 'NaN'}
            />
          )}

        <TimerAccordion isExpanded={status === 'IN_PROGRESS'}>
          <TimerContent>
            <TimerColumn>
              <PlayItemDetail className="entries">
                {label.toUpperCase() !== 'REST' && (
                  <EntryItem mb={16} label="Reps" value={reps} />
                )}
                {label.toUpperCase() !== 'REST' && (
                  <EntryItem mb={16} label="Sets" value={sets} />
                )}
                {label.toUpperCase() !== 'REST' && (
                  <EntryItem mb={16} label="Weight" value={weight} />
                )}
                <EntryItem mb={16} label="Rest" value={seconds} />
              </PlayItemDetail>
              {notes && (
                <PlayItemDetail className="notes">
                  <TextArea
                    rows={3}
                    value={notes}
                    label="Notes"
                    readOnly
                    placeholder="Notes.."
                    config={{ isCopyVariant: false }}
                  />
                </PlayItemDetail>
              )}
              {isDevMode && (
                <StateView
                  state={{
                    status,
                    timerStatus,
                    remainingSeconds,
                    index,
                  }}
                />
              )}
              {link && (
                <PlayItemDetail className="link">
                  <Label>Resource</Label>
                  <LinkTo label="Link" link={link} />
                </PlayItemDetail>
              )}
              {renderControls()}
            </TimerColumn>
            <TimerColumn style={{ display: 'none' }}>
              <TimerImage>
                <img src={image} alt={label} />
              </TimerImage>
            </TimerColumn>
          </TimerContent>
        </TimerAccordion>
      </Container>
    </List>
  )
}

export { PlayItem }
