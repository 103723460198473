import { api } from '@state/utils';
import { API } from './api';
import { localVars } from '@features/Auth/Service/config';

import REQUEST from './data';
// import preferencesSaveExerciseBookmarkSuccess from './data/preferencesSaveExerciseBookmarkSuccess';
// import preferencesRemoveExerciseBookmarkSuccess from './data/preferencesRemoveExerciseBookmarkSuccess';

import {
  SETTINGS_GET_ACTIVE_SUBSCRIPTIONS_SUCCESS,
  SETTINGS_GET_ACTIVE_PRICES_SUCCESS,
  SETTINGS_UPDATE_QUOTE_PRICE_SUCCESS,
  SETTINGS_UPDATE_QUOTE_QUANTITY_SUCCESS,
  SETTINGS_GENERATE_DRAFT_QUOTE_SUCCESS,
  SETTINGS_CREATE_PAYMENT_METHOD_SUCCESS,
  SETTINGS_GET_ALL_PAYMENT_METHODS_SUCCESS,
  SETTINGS_UPDATE_QUOTE_APPLY_COUPON_SUCCESS,
} from './actionTypes';

const mockApi = ({ type, params, body }) => {
  const response = type =>
    ({
      [SETTINGS_GET_ACTIVE_SUBSCRIPTIONS_SUCCESS]:
        REQUEST.SETTINGS_GET_ACTIVE_SUBSCRIPTIONS_SUCCESS,
      [SETTINGS_GET_ACTIVE_PRICES_SUCCESS]:
        REQUEST.SETTINGS_GET_ACTIVE_PRICES_SUCCESS,
      [SETTINGS_UPDATE_QUOTE_PRICE_SUCCESS]:
        REQUEST.SETTINGS_UPDATE_QUOTE_PRICE_SUCCESS,
      [SETTINGS_UPDATE_QUOTE_QUANTITY_SUCCESS]:
        REQUEST.SETTINGS_UPDATE_QUOTE_QUANTITY_SUCCESS,
      [SETTINGS_CREATE_PAYMENT_METHOD_SUCCESS]:
        REQUEST.SETTINGS_CREATE_PAYMENT_METHOD_SUCCESS,
      [SETTINGS_GENERATE_DRAFT_QUOTE_SUCCESS]:
        REQUEST.SETTINGS_GENERATE_DRAFT_QUOTE_SUCCESS,
      [SETTINGS_UPDATE_QUOTE_QUANTITY_SUCCESS]:
        REQUEST.SETTINGS_UPDATE_QUOTE_QUANTITY_SUCCESS,
      [SETTINGS_GET_ALL_PAYMENT_METHODS_SUCCESS]:
        REQUEST.SETTINGS_GET_ALL_PAYMENT_METHODS_SUCCESS,
    }[type]);

  return new Promise(resolve => {
    setTimeout(() => {
      const result = response(type);
      resolve({ data: result });
    }, 1000);
  });
};

const apiInterceptor = props => {
  if (localVars.mock === 'enabled') {
    return mockApi(props);
  } else {
    return api(props)(API);
  }
};

export const requestGetActiveSubscriptions = () =>
  apiInterceptor({
    type: SETTINGS_GET_ACTIVE_SUBSCRIPTIONS_SUCCESS,
  });

export const requestGetActivePrices = () =>
  apiInterceptor({ type: SETTINGS_GET_ACTIVE_PRICES_SUCCESS });

export const requestUpdateQuotePrice = payload =>
  apiInterceptor({
    type: SETTINGS_UPDATE_QUOTE_PRICE_SUCCESS,
    body: payload.body,
  });

export const requestUpdateQuoteQuantity = payload =>
  apiInterceptor({
    type: SETTINGS_UPDATE_QUOTE_QUANTITY_SUCCESS,
    body: payload.body,
  });

export const requestGenerateDraftQuote = payload =>
  apiInterceptor({
    type: SETTINGS_GENERATE_DRAFT_QUOTE_SUCCESS,
    body: payload.body,
  });

export const requestCreatePaymentMethod = payload =>
  apiInterceptor({
    type: SETTINGS_CREATE_PAYMENT_METHOD_SUCCESS,
    body: payload.body,
  });

export const requestGetAllPaymentMethods = () =>
  apiInterceptor({
    type: SETTINGS_GET_ALL_PAYMENT_METHODS_SUCCESS,
  });

export const requestQuoteApplyCoupon = () =>
  apiInterceptor({
    type: SETTINGS_UPDATE_QUOTE_APPLY_COUPON_SUCCESS,
  });
