import {
  AUTH_PENDING,
  AUTH_SUCCESS,
  AUTH_FAILURE,
  AUTH_SIGNIN_FAILURE,
  AUTH_SIGNIN_PENDING,
  AUTH_SIGNIN_SUCCESS,
  AUTH_SIGNUP_PENDING,
  AUTH_SIGNUP_SUCCESS,
  AUTH_SIGNUP_FAILURE,
} from './actionTypes'

// reducer with initial state
const initialState = {
  authResponse: null,
  loading: false,
  error: '',
  authSignup: false,
  isAuthenticated: false,
}

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case AUTH_PENDING:
      return {
        ...state,
        loading: true,
      }
    case AUTH_SUCCESS:
      return {
        ...state,
        loading: false,
        authResponse: action.payload,
        isAuthenticated: true,
      }
    case AUTH_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isAuthenticated: false,
      }
    case AUTH_SIGNIN_PENDING:
      return {
        ...state,
        loading: true,
      }
    case AUTH_SIGNIN_SUCCESS:
      return {
        ...state,
        loading: false,
        authResponse: action.payload,
        isAuthenticated: true,
      }
    case AUTH_SIGNIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isAuthenticated: false,
      }
    case AUTH_SIGNUP_PENDING:
      return {
        ...state,
        loading: true,
      }
    case AUTH_SIGNUP_SUCCESS:
      return {
        ...state,
        loading: false,
        authResponse: action.payload,
        authSignup: true,
      }
    case AUTH_SIGNUP_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        authSignup: false,
      }
    default:
      return state
  }
}
