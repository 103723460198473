import styled from 'styled-components'
import { colors } from '@common/Theme'
import { allDefaultStyles } from './utils'

const Green = styled.button`
  ${allDefaultStyles};
  background: transparent;
  border: 1.5px solid ${colors.green};
  span {
    color: ${colors.green};
  }
  &:hover {
    background: rgb(58 238 184 / 9%);
  }
  svg.HOME {
    path {
      stroke: ${colors.green};
    }
  }
  svg.REFRESH {
    path {
      fill: ${colors.green};
    }
  }
  svg.CHEVRON {
    path {
      fill: ${colors.green};
    }
  }
  svg.ARROW_RIGHT {
    path {
      stroke: ${colors.green};
    }
  }
  svg.SAVE {
    path {
      stroke: ${colors.green};
    }
  }
  ${(props) => props.extend};
`

export { Green }
