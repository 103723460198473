export default [
  {
    stripeSubscriptionId: 'sub_1L58HyCTOd88lFo5zvMY0Mcz',
    status: 'active',
    currentPeriodStart: '2022-05-30T18:35:42',
    currentPeriodEnd: '2022-06-07T18:35:42',
    cancelAtPeriodEnd: false,
    defaultPaymentMethod: 'pm_1L57KbCTOd88lFo5IbkRz7iM',
    quantity: 15, // clients
    createdAt: '2022-05-30T18:35:45.739782',
    updatedAt: '2022-05-30T18:35:45.739782',
    priceInfo: {
      stripePriceId: 'price_1L57BDCTOd88lFo5vTF7p5CL',
      stripeProductId: 'prod_LmgYTVY0kxAP71',
      amount: 0,
      label: {
        interval: 'year',
        usageType: 'licensed',
        lastResponse: null,
        intervalCount: 1,
        rawJsonObject: null,
        aggregateUsage: null,
        trialPeriodDays: null,
      },
      description: 'Aima Yearly Plan',
      active: true,
      tiers: [
        {
          upTo: 3,
          flatAmount: 24000,
          unitAmount: null,
          lastResponse: null,
          rawJsonObject: null,
          flatAmountDecimal: 24000,
          unitAmountDecimal: null,
        },
        {
          upTo: null,
          flatAmount: null,
          unitAmount: 3600,
          lastResponse: null,
          rawJsonObject: null,
          flatAmountDecimal: null,
          unitAmountDecimal: 3600,
        },
      ],
      taxBehavior: 'inclusive',
      tiersMode: 'graduated',
      type: 'recurring',
      currency: 'aud',
      isDefault: false,
      createdAt: '2022-05-30T17:24:41.275721',
      updatedAt: '2022-05-30T17:24:41.275721',
    },
    userInfo: {
      email: 'support@prostaid.health',
      stripeCustomerId: 'cus_LlA9RYOvQPQDq1',
      roles: ['PersonalTrainer'],
      createdAt: '2022-05-26T15:51:27.579485',
      updatedAt: '2022-05-30T21:18:27.118272',
      isActive: true,
      isDeleted: false,
      stripeSetupIntentObject: {
        setupIntentId: 'seti_1L57KdCTOd88lFo5HZrVXp0l',
        paymentMethodId: 'pm_1L57KbCTOd88lFo5IbkRz7iM',
        setupIntentSecret:
          'seti_1L57KdCTOd88lFo5HZrVXp0l_secret_LmgibJLPLRaNFPyfRWlHmFo7sOotToX',
      },
    },
  },
];
