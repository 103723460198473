import React from 'react';
import styled from 'styled-components';
import {
  basicFunctions,
  basicStyles,
  basicText,
  pseudoStyles,
} from './buttonStyles';
import { mbFn, mtFn, mrFn, mlFn } from '@common/Theme';

const colors = {
  orange: `#FF5A45`,
  light: `#ff998c`,
  track: `#d7dcdf`,
};

const SuccessStyle = styled.button`
  ${basicStyles};
  display: inline-flex;
  ${basicText};
  ${pseudoStyles}
  ${basicFunctions};
  ${mbFn};
  ${mtFn};
  ${mrFn};
  ${mlFn};
  // FONT
  font-weight: 500;
  color: white;
  // BORDER
  border: 1px solid ${colors.orange};
  border-radius: 8px;
  // FLEX
  justify-content: center;
  align-items: center;
  max-height: 48px;
  padding: 10px 12px;
  background: ${colors.orange};
  transition: all 0.2s ease-in-out;
  box-sizing: border-box;
  &:hover {
    background: ${colors.light};
  }
`;
const Success = ({ style, children, ...props }) => (
  <SuccessStyle style={style} {...props}>
    {children}
  </SuccessStyle>
);

export { Success };
