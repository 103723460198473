import React from 'react';
import { Icon } from '@common/Icon';
import { NumberInput } from '../../NumberInput';
import { TypeLabel } from '../../TypeLabel';

import {
  RowContainer,
  RowGroup,
  RowColumn,
  RowButton,
  RowIcon,
  RowNumber,
} from './Draggable.styled';

const DraggableRow = ({
  callback = () => {},
  data,
  isDragging,
  onRemove,
  index,
}) => {
  const { id, reps = 0, rest = 0, weight = 0, type = 'NORMAL' } = data || {};

  return (
    <RowContainer isDragging={isDragging}>
      <RowGroup>
        <RowColumn>
          <RowIcon>
            <Icon name="DRAG" fill="#E3E5EB" size={20} viewBox="-5 -2 20 20" />
          </RowIcon>
          <RowNumber>{`${index}.`}</RowNumber>
          <TypeLabel
            callback={value => callback({ id, name: 'type', value })}
            activeId={type}
          />
        </RowColumn>
        <RowColumn>
          <NumberInput
            max={1000}
            unit="Kg"
            value={weight}
            callback={value =>
              callback({ id, name: 'weight', value: Number(value) })
            }
          />
        </RowColumn>
        <RowColumn>
          <NumberInput
            max={500}
            unit="Reps"
            value={reps}
            callback={value =>
              callback({ id, name: 'reps', value: Number(value) })
            }
          />
        </RowColumn>
        <RowColumn>
          <NumberInput
            value={rest}
            unit="Sec"
            step={5}
            callback={value =>
              callback({ id, name: 'rest', value: Number(value) })
            }
          />
        </RowColumn>

        <RowColumn customWidth={true} style={{ marginLeft: 'auto' }}>
          <RowButton onClick={() => onRemove(id)}>
            <Icon name="TRASH" size={20} />
          </RowButton>
        </RowColumn>
      </RowGroup>
    </RowContainer>
  );
};

DraggableRow.defaultProps = {
  data: {},
  isDragging: false,
  onRemove: () => {},
  onCopy: () => {},
  callback: () => {},
};

export { DraggableRow };
