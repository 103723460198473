import { uuid } from '@utils/uuid'
const generatedId = uuid()

const exerciseCreateSuccess = {
  data: {
    id: generatedId,
    label: 'DeadLifts',
    exerciseDescription: 'Test Description',
    images: [],
    isActive: true,
    isDeleted: false,
    images: ['e1fbff1e-0edd-42f2-8d92-d6af70b13782'],
    primaryMuscles: [
      {
        muscleId: 1,
        muscleName: 'Calves',
        isActive: true,
        isDeleted: false,
      },
      {
        muscleId: 2,
        muscleName: 'Hamstring',
        isActive: true,
        isDeleted: false,
      },
      {
        muscleId: 3,
        muscleName: 'Quadriceps',
        isActive: true,
        isDeleted: false,
      },
    ],
    equipments: [
      {
        equipmentId: 1,
        equipmentName: 'Powerbells',
        isActive: true,
        isDeleted: false,
      },
      {
        equipmentId: 2,
        equipmentName: 'Powerplates',
        isActive: true,
        isDeleted: false,
      },
      {
        equipmentId: 3,
        equipmentName: 'Cube Suspension Trainer',
        isActive: true,
        isDeleted: false,
      },
    ],
  },
}

export { exerciseCreateSuccess }
