import React, { forwardRef, useEffect, useState } from 'react';
import { isNil, complement, length } from 'ramda';
import { useDispatch } from 'react-redux';
import { ErrorList } from '@common/ErrorList';
import { useSelector } from '@hooks/useSelector';
import { useDebounce } from '@hooks/useDebounce';
import { usePrevious } from '@hooks/usePrevious';
// STATE
import { exerciseSelector, updateExercise } from '@state/exercise';
// COMPONENTS
import { TextArea } from './TextArea';
// STYLED
import { FieldContainer, FieldLabel } from './ExerciseEditorDescription.styled';

const ERROR_NAMES = {
  LENGTH: 'length',
  ERROR: 'error',
};

const haystack = [
  { name: ERROR_NAMES.ERROR, message: 'You must enter a name.' },
  {
    name: ERROR_NAMES.LENGTH,
    message: 'You must enter a name that is at least one character.',
  },
];

const isPresent = complement(isNil);

const ExerciseEditorDescription = forwardRef((_, ref) => {
  const dispatch = useDispatch();
  // LOCAL
  const [errors, setErrors] = useState([]);
  const [localState, setLocalState] = useState('');

  // SELECTORS
  const activeExercise = useSelector(exerciseSelector.activeExercise);

  // DESTRUCTURE
  const { exerciseDescription: description } = activeExercise;

  // HOOKS
  const previousDescription = usePrevious(description);
  const actualDescription = useDebounce(localState, 2000);

  const defaultProps = {
    exerciseDescription: 'exerciseDescription',
    id: 'exerciseDescription',
    variant: 'outline',
    type: 'text',
    placeholder: 'Enter Description',
    verticalPush: '16px',
    fontSize: '14px',
    config: {
      layout: {
        fontSize: '14px',
      },
    },
  };

  const closeError = error => {
    setErrors(state => state.filter(item => item !== error));
  };

  const onChange = e => {
    const { value } = e.target;
    if (length(value) <= 1) {
      setErrors([ERROR_NAMES.LENGTH]);
    } else {
      setErrors(state => state.filter(item => item !== ERROR_NAMES.LENGTH));
    }
    setLocalState(value);
  };

  useEffect(() => {
    if (errors && errors.length > 0) return;
    if (!actualDescription) return;
    if (previousDescription !== actualDescription) {
      dispatch(
        updateExercise({
          ...activeExercise,
          exerciseDescription: actualDescription,
        })
      );
    }
  }, [actualDescription]);

  useEffect(() => {
    if (!description) return;
    setLocalState(description);
  }, [description]);

  const hasErrors = errors && errors.length > 0;

  return (
    <FieldContainer>
      <FieldLabel error={hasErrors}>Description</FieldLabel>
      <TextArea
        value={localState}
        onChange={onChange}
        error={hasErrors}
        ref={ref}
        {...defaultProps}
      />
      {isPresent(errors) && (
        <ErrorList
          haystack={haystack}
          needles={errors}
          mb={16}
          callback={closeError}
        />
      )}
    </FieldContainer>
  );
});

export { ExerciseEditorDescription };
