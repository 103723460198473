import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { Loading } from '@common/Loading'
import { useAuth } from '@hooks/useAuth'
import Error from '@pages/404'
import { fetchMe } from '@state/user'
import { useDispatch } from 'react-redux'

const PrivateRoutes = () => {
  const { isAuthenticated, authStatus, types } = useAuth()
  const dispatch = useDispatch()

  useEffect(() => {
    if (authStatus !== types.SUCCESS) return
    dispatch(fetchMe())
  }, [authStatus])

  if (authStatus === types.PENDING) {
    return <Loading />
  }

  if (authStatus === types.FAILURE) {
    return <Error />
  }

  if (isAuthenticated && authStatus === types.SUCCESS) {
    return <Outlet />
  }

  return null
}

export default PrivateRoutes
