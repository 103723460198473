import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Label } from '@common/Label';
import { PillSelector } from '@common/PillSelector';
import { exerciseSelector, updateExercise } from '@state/exercise';
// STATE
import { masterSelector } from '@state/master';

const ExerciseEditorEquipment = () => {
  const dispatch = useDispatch();
  // SELECTORS
  const equipmentList = useSelector(masterSelector.equipment);
  const activeExercise = useSelector(exerciseSelector.activeExercise);
  const equipments = activeExercise.equipments;

  const onChange = items => {
    dispatch(
      updateExercise({
        ...activeExercise,
        equipments: items,
      })
    );
  };

  return (
    <>
      <Label>Equipment</Label>
      <PillSelector
        options={equipmentList}
        selectedOptions={equipments}
        onChange={onChange}
        variantId="equipmentId"
        variantName="equipmentName"
      />
    </>
  );
};

export { ExerciseEditorEquipment };
