import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TabBar } from '@common/TabBar';
import { TabUnderlined } from '@common/TabBar';
// LOCAL STYLED
import { ControlContainer } from './ExerciseControlBar.styled';

const ControlTabs = ({ callback = () => {}, isExpanded, active, children }) => (
  <ControlContainer isExpanded={isExpanded}>
    <TabBar active={active} className="TabBar">
      {asTab => [
        <TabUnderlined {...asTab('primary')} action={() => callback('primary')}>
          All
        </TabUnderlined>,
        <TabUnderlined
          {...asTab('secondary')}
          action={() => callback('secondary')}
        >
          My Exercises
        </TabUnderlined>,
      ]}
    </TabBar>
    {children}
  </ControlContainer>
);

ControlTabs.propTypes = {
  active: PropTypes.string,
};

const ExerciseControlBar = ({ children, ...props }) => {
  const [activeTab, setActiveTab] = useState('primary');
  return (
    <ControlTabs active={activeTab} callback={setActiveTab} {...props}>
      {children}
    </ControlTabs>
  );
};

export { ExerciseControlBar };
