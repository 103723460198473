import {
  EXERCISES_FETCH_FAILURE,
  EXERCISES_UPDATE_FAILURE,
  EXERCISES_UPDATE_IMAGE_FAILURE,
  EXERCISES_CREATE_IMAGE_FAILURE,
  EXERCISES_DELETE_FAILURE,
  EXERCISE_DELETE_IMAGE_FAILURE,
  EXERCISES_BUILDER_FETCH_FAILURE,
  EXERCISES_CREATE_FAILURE,
} from '../actionTypes'

export const fetchExercisesFailure = (error) => ({
  type: EXERCISES_FETCH_FAILURE,
  payload: error,
})

export const fetchBuilderExercisesFailure = (error) => ({
  type: EXERCISES_BUILDER_FETCH_FAILURE,
  payload: error,
})

export const updateExerciseFailure = (error) => ({
  type: EXERCISES_UPDATE_FAILURE,
  payload: error,
})

export const createExerciseFailure = (payload) => ({
  type: EXERCISES_CREATE_FAILURE,
  payload,
})

export const createExerciseImageFailure = (error) => ({
  type: EXERCISES_CREATE_IMAGE_FAILURE,
  payload: error,
})

export const updateExerciseImageFailure = (error) => ({
  type: EXERCISES_UPDATE_IMAGE_FAILURE,
  payload: error,
})

export const deleteExerciseFailure = (error) => ({
  type: EXERCISES_DELETE_FAILURE,
  payload: error,
})

export const deleteExerciseImageFailure = (error) => ({
  type: EXERCISE_DELETE_IMAGE_FAILURE,
  payload: error,
})
