import React, { useCallback, useState } from 'react'
import Cropper from 'react-easy-crop'
import { getCroppedImg } from './utils'
import { RangeSlider } from '@common/RangeSlider'

import { usePortal as Portal } from '@hooks/usePortal'
import { useEscape } from '@hooks/useEscape'
import { useDisableBodyScroll } from '@hooks/useDisableBodyScroll'
import { Button } from '@common/Button'
import { Icon } from '@common/Icon'
import { Overlay } from '@common/Overlay'
import {
  Container,
  Controls,
  FooterControls,
  Content,
  Header,
} from './ImageCropper.styled'

const ImageCropper = ({
  image,
  callback = () => {},
  variant,
  portalId = '',
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [rotation, setRotation] = useState(0)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [croppedImage, setCroppedImage] = useState(null)

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const getImageSize = (variant) => {
    return {
      thumbnail: { width: 320, height: 240 },
      banner: { width: 168, height: 160 },
    }[variant]
  }

  const imageDimensions = getImageSize(variant)

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        image,
        croppedAreaPixels,
        rotation,
      )
      setCroppedImage(croppedImage)
    } catch (e) {
      console.error(e)
    }
  }, [croppedAreaPixels, rotation, image])

  const onClose = useCallback(() => {
    setCroppedImage(null)
    callback({ action: 'CLOSE' })
  }, [])

  useEscape(() => onClose())
  useDisableBodyScroll()

  return (
    <Portal selector={!!portalId ? portalId : '#image-cropper'}>
      <Container>
        <Header>
          <h2>Image Cropper</h2>
          <Button variant="white" alone width="unset" onClick={onClose}>
            <Icon name="CLOSE" />
          </Button>
        </Header>
        <Content>
          {croppedImage === null && (
            <Cropper
              image={image}
              crop={crop}
              rotation={rotation}
              zoom={zoom}
              zoomSpeed={4}
              maxZoom={3}
              zoomWithScroll={true}
              showGrid={true}
              aspect={4 / 3}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
              onRotationChange={setRotation}
            />
          )}
          {!!croppedImage && (
            <img className="cropped-image" src={croppedImage} alt="cropped" />
          )}
        </Content>
        <FooterControls className="controls">
          {croppedImage === null && (
            <>
              <RangeSlider
                type="range"
                label="Adjust Zoom"
                value={zoom}
                min={1}
                max={3}
                step={0.1}
                aria-labelledby="Zoom"
                onChange={setZoom}
                className="zoom-range"
              />
            </>
          )}
          <Controls>
            <Button
              onClick={onClose}
              label="Cancel"
              variant="outline"
              config={{
                layout: {
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: 'unset',
                },
              }}
            >
              <Icon ml={5} name="CANCEL" stroke="#a9aeb9" />
            </Button>
            <Button
              onClick={showCroppedImage}
              label="Crop"
              config={{
                layout: {
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: 'unset',
                  display: croppedImage === null ? 'flex' : 'none',
                },
              }}
            >
              <Icon ml={5} name="Save" stroke="white" />
            </Button>

            <Button
              onClick={() => callback({ action: 'SAVE', value: croppedImage })}
              label="Save"
              config={{
                layout: {
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: 'unset',
                  display: croppedImage !== null ? 'flex' : 'none',
                },
              }}
            >
              <Icon ml={5} name="Save" stroke="white" />
            </Button>
          </Controls>
        </FooterControls>
      </Container>
      <Overlay onClick={() => onClose()} />
    </Portal>
  )
}

export { ImageCropper }
