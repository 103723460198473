/* eslint-disable react/prop-types */
import React from 'react';

const IconPath = props => {
  const { fill = '#A9AEB9', stroke = '#fffff' } = props;
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <mask id="path-1-inside-1" fill={stroke}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.3505 11.8242C14.3505 15.2347 11.5857 17.9995 8.17524 17.9995C4.76475 17.9995 2 15.2347 2 11.8242C2 9.10065 3.20239 7.03111 5.64959 6.21036L15.494 2.3244C15.9634 2.13909 16.4974 2.33263 16.739 2.7757L17.6598 4.46371C17.8635 4.83727 17.8086 5.29885 17.5228 5.61413L13.9295 9.57869C14.2013 10.2747 14.3505 11.032 14.3505 11.8242ZM9.4 11.9999C9.4 12.7731 8.7732 13.3999 8 13.3999C7.2268 13.3999 6.6 12.7731 6.6 11.9999C6.6 11.2267 7.2268 10.5999 8 10.5999C8.7732 10.5999 9.4 11.2267 9.4 11.9999ZM11 11.9999C11 13.6568 9.65685 14.9999 8 14.9999C6.34315 14.9999 5 13.6568 5 11.9999C5 10.3431 6.34315 8.99995 8 8.99995C9.65685 8.99995 11 10.3431 11 11.9999Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3505 11.8242C14.3505 15.2347 11.5857 17.9995 8.17524 17.9995C4.76475 17.9995 2 15.2347 2 11.8242C2 9.10065 3.20239 7.03111 5.64959 6.21036L15.494 2.3244C15.9634 2.13909 16.4974 2.33263 16.739 2.7757L17.6598 4.46371C17.8635 4.83727 17.8086 5.29885 17.5228 5.61413L13.9295 9.57869C14.2013 10.2747 14.3505 11.032 14.3505 11.8242ZM9.4 11.9999C9.4 12.7731 8.7732 13.3999 8 13.3999C7.2268 13.3999 6.6 12.7731 6.6 11.9999C6.6 11.2267 7.2268 10.5999 8 10.5999C8.7732 10.5999 9.4 11.2267 9.4 11.9999ZM11 11.9999C11 13.6568 9.65685 14.9999 8 14.9999C6.34315 14.9999 5 13.6568 5 11.9999C5 10.3431 6.34315 8.99995 8 8.99995C9.65685 8.99995 11 10.3431 11 11.9999Z"
        fill={fill}
      />
    </svg>
  );
};

export default IconPath;
