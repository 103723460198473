import React, { useEffect } from 'react'
import { Main, Header, Content, Layout, Menu } from '@features/Layout'

import { workoutSelector, fetchWorkouts } from '@state/workout'
import { useSelector, useDispatch } from 'react-redux'
import { settingsSelector } from '@state/settings'
import { StateView } from '@common/StateView'
import { Card } from '@components/Card'
import { Group, List } from '@common/List'

import { Tabs } from '@common/Tabs'
import { GroupLower } from './Start.styled'
import { useNavigate } from 'react-router-dom'

const options = [
  {
    label: 'Start',
    value: 'start',
    content: null,
    route: '/start',
    roles: ['PERSONALTRAINER', 'CLIENT'],
  },
]

const Start = () => {
  const navigate = useNavigate()
  const isDevMode = useSelector(settingsSelector.isDevMode)
  const workouts = useSelector(workoutSelector.workouts)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchWorkouts())
  }, [])

  const handleCallback = ({ action, value }) => {
    if (action === 'SELECT') navigate(`/start/${value}`)
  }

  const renderItems = () => {
    if (!workouts || !workouts.length) return null
    const result = workouts.map((workout) => {
      return (
        <Card
          key={workout.workoutId}
          id={workout.workoutId}
          images={workout.images}
          callback={handleCallback}
          name={workout.workoutName}
          footer={
            <Group>
              <List>12 Exercises</List>
            </Group>
          }
          description={workout.workoutDescription}
        >
          {isDevMode && <StateView variant="MODAL" state={workout} />}
        </Card>
      )
    })

    return <GroupLower>{result}</GroupLower>
  }

  return (
    <Layout variant="CUSTOM">
      <Menu />
      <Main className="Content">
        <Header config={{ isProfileEnabled: true }}>
          <Tabs
            options={options}
            active={'start'}
            loading={false}
            showPanel={false}
          />
        </Header>
        <Content>{renderItems()}</Content>
      </Main>
    </Layout>
  )
}

export { Start }
