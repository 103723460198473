import styled, { css } from 'styled-components';

const StorageContainer = styled.div`
  display: flex;
  max-width: 100px;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  flex-grow: 1;
  span {
    padding: 0;
    font-size: 12px;
    color: #121b2d;
    font-weight: 500;
    padding-left: 2px;
  }
  span {
    &:nth-child(2) {
      color: #a9aeb9;
      padding-left: 2px;
    }
  }
`;

const RemainingContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin-bottom: 16px;
  padding-right: 14px;
`;

const RemainingTitle = styled.span`
  padding: 0;
  margin: 0;
  font-size: 12px;
  color: #121b2d;
  font-weight: 500;
`;

const RangeContainer = styled.div`
  display: flex;
  width: 100%;
`;

export { StorageContainer, RemainingContainer, RemainingTitle, RangeContainer };
