import Types from './Types';

export const initialState = {
  files: [], // [{ uid: '', preview: {}]
  imageResult: null,
  imageFile: null,
  isModalOpen: false,
};

const Reducer = (state = {}, action) => {
  const { type, data } = action;
  switch (type) {
    case Types.ADD_IMAGE:
      return {
        ...state,
        files: [...state.files, data],
        imageResult: data.imageResult,
      };
    case Types.SET_IMAGE_READER:
      return {
        ...state,
        imageResult: data.imageResult,
        imageFile: data.imageFile,
      };
    case Types.TOGGLE_MODAL:
      return {
        ...state,
        isModalOpen: data,
      };
    case Types.REMOVE_IMAGE:
      return {
        ...state,
        files: state.files.filter(t => t.uid !== data),
      };
    case Types.CLEAR_IMAGES:
      return {
        ...state,
        files: [],
      };
    default:
      return state;
  }
};

export default Reducer;
