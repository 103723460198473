const loading = (state) => state.settings.loading
const prices = (state) => state.settings.prices
const subscriptions = (state) => state.settings.subscriptions
const quantity = (state) => state.settings.quantity
const quote = (state) => state.settings.quote
const methods = (state) => state.settings.methods
const isDevMode = (state) => state.settings.isDevMode
const paymentMethod = (state) => state.settings.paymentMethod
const coupon = (state) => state.settings.coupon

export const settingsSelector = {
  loading,
  isDevMode,
  prices,
  subscriptions,
  quote,
  methods,
  paymentMethod,
  quantity,
  coupon,
}
