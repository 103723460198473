import styled from 'styled-components'
import { mtFn, mbFn } from '@common/Theme'

const UploaderIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  background: #f7f8fa;
  border-radius: 50%;
  margin: 8px 0px;
`

const UploaderContent = styled.span`
  margin-left: 8px;
  font-size: 14px;
  font-weight: 500;
  color: #121b2d;
`

const UploaderLabel = styled.label`
  ${mtFn};
  ${mbFn};
  input {
    display: none;
  }
  &.file-upload-thumbnail {
    box-sizing: border-box;
    border-radius: 6px;
    padding: 0;
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 9px 12px;
    flex-direction: column;
    transition: all 0.2s ease-in-out;
    margin-right: 0;
    background: white;
    height: 120px;
    width: 120px;
    min-width: 120px;
    min-height: 120px;
    cursor: pointer;
    border: 1px solid #e3e5eb;
  }
  &.file-upload-banner {
    box-sizing: border-box;
    border-radius: 8px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 9px;
    cursor: pointer;
    outline: none;
    height: 160px;
    width: 100%;
    flex-direction: column;
    transition: all 0.2s ease-in-out;
    margin-right: 0;
    background: #ffffff;
    border: 1px solid #e3e5eb;
    border-radius: 8px;
    ${(props) =>
      props.isActive &&
      css`
        border: 1px solid #ff5a45;
      `}
  }
  .file-preview {
    margin: 0 10px;
  }
`

export { UploaderIcon, UploaderContent, UploaderLabel }
