import React, { useEffect } from 'react'
import { Block } from '@common/Block'
import { Heading } from '@common/Heading'
import { Line } from '@common/Line'
import { Text } from '@common/Text'
import { Button } from '@common/Button'
import { userSelector } from '@state/user'
import { useSelector } from '@hooks/useSelector'
import { css } from 'styled-components'

import { getHighestRole } from '@utils/user'

const ExtendDowngrade = css`
  margin-right: 8px;
`

const Plan = () => {
  const userRoles = useSelector(userSelector.roles)

  const role = getHighestRole(userRoles)

  const total = {
    PersonalTrainer: 20,
    Client: 3,
    none: 0,
  }

  const renderOptions = () => {
    if (role.roleLabel === 'No role allocated' || role.roleLabel === 'Client') {
      return <Button variant="orange" label="Upgrade" width="unset" alone />
    }
    return (
      <Button
        variant="black"
        label="Downgrade"
        alone
        extend={ExtendDowngrade}
      ></Button>
    )
  }

  return (
    <Block.Column mb={16}>
      <Block.Header>
        <Heading size={20} color="white" variant="TERTIARY" mb={0}>
          {role.roleLabel}
        </Heading>
      </Block.Header>
      <Block.Content>
        <Text size={14} color="#323232" weight="300">
          {`Monthly charges `}
        </Text>
        <Line mb={16} mt={16} />
        <Text size={20} color="#323232" weight="600">
          {`Total $${total[role.roleId]}`}
        </Text>
      </Block.Content>
      <Block.Footer>
        {renderOptions()}
        <Text size={14} color="#323232" weight="300">
          {role.message}
        </Text>
      </Block.Footer>
    </Block.Column>
  )
}

export { Plan }
