const ADD_SET = 'ADD_SET';
const REMOVE_SET = 'REMOVE_SET';
const EDIT_SET = 'EDIT_SET';
const REORDER_SET = 'REORDER_SET';

export default {
  ADD_SET,
  REMOVE_SET,
  EDIT_SET,
  REORDER_SET,
};
