import styled, { css } from 'styled-components';

const labelColorFn = ({ labelColor }) =>
  labelColor &&
  css`
    color: ${labelColor || '#1d2a43'};
  `;

const BaseFieldLabel = css`
  line-height: 16px;
  font-size: 14px;
  font-weight: 500;
  font-family: 'IBM Plex Sans', sans-serif;
  color: #a9aeb9;
  display: block;
  margin: 0 0 8px 0;
`;

const FieldLabel = styled.span.attrs({ className: 'label' })`
  ${BaseFieldLabel};
  ${labelColorFn};
  ${props =>
    props.error &&
    css`
      color: #eb3d2b;
    `}
`;

const FieldContainer = styled.div`
  margin-top: 16px;
`;

export { FieldContainer, FieldLabel };
