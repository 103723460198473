import styled from 'styled-components';

const IconContainer = styled.div`
  cursor: pointer;
  background: none;
  height: 20px;
  width: 20px;
  margin-right: 8px;
`;

const AdvancedGroupActivityContainer = styled.div`
  width: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background-color: transparent;
`;

const GroupHeader = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  h2 {
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-weight: 600;
  }
`;

export { IconContainer, AdvancedGroupActivityContainer, GroupHeader };
