import React from 'react'
import PropTypes from 'prop-types'
import {
  ToggleWrapper,
  ToggleLabel,
  ToggleTitle,
  ToggleContent,
  ToggleCheckbox,
} from './Toggle.styled'

const Toggle = ({
  isActive,
  id,
  className,
  onChange,
  disabled,
  name,
  label,
  ...props
}) => {
  const handleChange = (e) => {
    const value = e.target.checked
    onChange({ name, value })
  }
  return (
    <ToggleWrapper className={`ToggleWrapper ${className}`} id={id} {...props}>
      {label && (
        <ToggleTitle className={disabled ? 'is-disabled' : ''}>
          {label}
        </ToggleTitle>
      )}
      <ToggleContent className="ToggleContent">
        <ToggleCheckbox
          checked={isActive}
          onChange={(e) => handleChange(e)}
          className={isActive ? 'ToggleCheckbox checked' : 'ToggleCheckbox'}
          id="checkbox"
          type="checkbox"
        />
        <ToggleLabel
          className={isActive ? 'ToggleLabel checked' : 'ToggleLabel'}
          htmlFor="checkbox"
        />
      </ToggleContent>
    </ToggleWrapper>
  )
}

Toggle.defaultProps = {
  isActive: false,
  onChange: () => {},
  id: '',
  className: '',
  label: '',
}

Toggle.propTypes = {
  isActive: PropTypes.bool,
  className: PropTypes.string,
  onChange: PropTypes.func,
  title: PropTypes.string,
  id: PropTypes.string,
}

export { Toggle }
