import styled, { css } from 'styled-components'

const basicStyles = css`
  display: flex;
  flex-direction: row;
  border: none;
  box-sizing: border-box;
`

const basicIconStyles = css`
  ${(props) =>
    props.hasIcon &&
    css`
      svg {
        margin-right: 8px;
      }
    `}
`

const basicText = css`
  line-height: 14px;
  font-size: 14px;
`

const pseudoStyles = css`
  &:focus {
    outline: none;
  }
  &:hover {
    cursor: pointer;
  }
`

const Children = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: auto;
`

const asBlue = css`
  ${(props) =>
    props.asBlue &&
    css`
      background: #4285f4;
      &:hover {
        background: #2966ca;
      }
    `}
`

const asPurple = css`
  ${(props) =>
    props.asPurple &&
    css`
      background: #9862db;
      &:hover {
        background: #894cd6;
      }
    `}
`

const basicFunctions = css`
  ${(props) =>
    props.isDisabled &&
    css`
      color: #a9aeb9 !important;
      background: #f7f7f9 !important;
      border: 1px solid #f7f7f9 !important;
      cursor: not-allowed;
      &:hover {
        cursor: not-allowed;
        color: #a9aeb9 !important;
        background: #f7f7f9 !important;
        border: 1px solid #f7f7f9 !important;
        box-shadow: 0px 2px 8px rgba(29, 42, 67, 0.2) !important;
      }
      &:focus {
        cursor: not-allowed;
        box-shadow: 0px 2px 8px rgba(29, 42, 67, 0.2) !important;
      }
    `}
`

export {
  basicStyles,
  basicText,
  pseudoStyles,
  basicFunctions,
  basicIconStyles,
  asBlue,
  asPurple,
  Children,
}
