import styled from 'styled-components'
import { colors } from '@common/Theme'
import { allDefaultStyles } from './utils'

const Purple = styled.button`
  ${allDefaultStyles};
  border: 1.5px solid ${colors.purple};
  background: transparent;
  span {
    color: ${colors.purple};
  }
  svg.EDIT {
    path {
      stroke: none;
      fill: ${colors.purple};
    }
    rect {
      stroke: none;
      fill: ${colors.purple};
    }
  }
  svg.HISTORY {
    path {
      stroke: ${colors.purple};
    }
  }
  svg.CANCEL {
    path {
      stroke: ${colors.purple};
    }
    circle {
      stroke: ${colors.purple};
    }
  }
  svg.REFRESH {
    path {
      stroke: none;
      fill: ${colors.purple};
    }
    rect {
      stroke: none;
      fill: ${colors.purple};
    }
  }
  svg.WEIGHT {
    path {
      stroke: ${colors.purple};
      fill: ${colors.purple};
    }
    rect {
      stroke: ${colors.purple};
      fill: ${colors.purple};
    }
  }

  &:hover {
    background: rgb(176 58 238 / 24%);
    span {
      color: #b681d2;
    }

    svg.REFRESH {
      path {
        stroke: none;
        fill: #b681d2;
      }
      rect {
        stroke: none;
        fill: #b681d2;
      }
    }
  }
  ${(props) => props.extend};
`

export { Purple }
