import React from 'react'

const Logo = ({ width = 38, height = 38 }) => (
  <div className="logo-small">
    <svg width={width} height={height} viewBox="0 0 38 38" fill="none">
      <path
        d="M17.1621 10.1426C17.5129 9.44291 18.2364 9 19.0286 9C20.5671 9 21.574 10.5872 20.8997 11.9492L14.0294 25.8266C13.674 26.5445 12.9342 26.9999 12.1233 26.9999C10.5515 26.9999 9.52592 25.3744 10.2219 23.9861L17.1621 10.1426Z"
        fill="#121B2D"
      />
      <path
        d="M21.5517 14.6304C21.9078 13.9052 22.6536 13.4444 23.4715 13.4444C25.0445 13.4444 26.0762 15.0646 25.3913 16.4595L20.7987 25.8138C20.4427 26.5391 19.6968 26.9999 18.8789 26.9999C17.3059 26.9999 16.2743 25.3797 16.9591 23.9848L21.5517 14.6304Z"
        fill="#121B2D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.7532 22.5556C24.8936 22.5556 24.1035 23.0433 23.7241 23.7971C22.987 25.2617 24.0647 27 25.7349 27C26.5946 27 27.3847 26.5123 27.7641 25.7585C28.5012 24.294 27.4235 22.5556 25.7532 22.5556Z"
        fill="#121B2D"
      />
    </svg>
  </div>
)

export default Logo
