import React, { useState, useEffect, useContext } from 'react'
import { useDispatch } from 'react-redux'
import { string, object } from 'yup'
import { Formik, Form, Field, useFormikContext } from 'formik'

import { InputField } from '@common/InputField'
import { TextAreaField } from '@common/TextAreaField'

import { useSelector } from '@hooks/useSelector'
import { LayoutContext, LayoutTypes } from '@features/Layout'
import { prop } from 'ramda'
import { usePortal as Portal } from '@hooks/usePortal'
import { masterSelector } from '@state/master'
// STATE
import {
  createExercise,
  exerciseSelector,
  setActiveExercise,
  clearImageIds,
} from '@state/exercise'
import { PillSelector } from '@common/PillSelector'

// LOCAL COMPONENTS
import { ExerciseUploader } from '../ExerciseUploader'
import { ExerciseControls } from './ExerciseControls'
import {
  Context as ExerciseUploaderContext,
  Types as ExerciseUploaderTypes,
} from '../ExerciseUploader/ExerciseUploaderProvider'

// STYLED
import {
  FlexRow,
  FlexItem,
  FieldContainer,
  FieldLabel,
  FormScroll,
  FormikContainer,
  ExerciseCreatorContainer,
} from './ExerciseCreator.styled'

const Schema = object().shape({
  exerciseName: string()
    .max(30, `Name of exercise`)
    .required('Please enter a name for the exercise'),
  exerciseDescription: string(),
})

const isImageUploaderEnabled = false

const CustomInputField = (props) => {
  const { setFieldValue } = useFormikContext()
  const [state] = useContext(LayoutContext)
  const { bladeName, bladeExpanded } = state

  useEffect(() => {
    if (bladeExpanded && bladeName && bladeName.length > 0) {
      setFieldValue('label', bladeName)
    }
  }, [bladeName, bladeExpanded])

  return <InputField {...props} />
}

const initialValues = {
  exerciseName: '',
  exerciseDescription: '',
  primaryMuscles: [],
  equipments: [],
}

const validateForm = (values) => {
  const errors = {}
  if (!values.exerciseName) {
    errors.exerciseName = 'Required'
  }
  if (prop('exerciseName')(values)) {
    return false
  }
  return true
}

const ExerciseCreator = () => {
  const dispatch = useDispatch()
  const [state, localDispatch] = useContext(LayoutContext)
  const [uploaderState, uploaderDispatch] = useContext(ExerciseUploaderContext)

  // SELECTORS
  const musclesList = useSelector(masterSelector.muscles)
  const equipmentList = useSelector(masterSelector.equipment)
  const imageIds = useSelector(exerciseSelector.images)

  // LOCAL
  const [selectedMuscles, setSelectedMuscles] = useState([])
  const [selectedEquipment, setSelectedEquipment] = useState([])

  const handleSubmit = ({ exerciseName, exerciseDescription }) => {
    const payload = {
      primaryMuscles: selectedMuscles,
      equipments: selectedEquipment,
      images:
        imageIds && imageIds.length > 0
          ? imageIds.map((item) => item.imageId)
          : [],
    }
    dispatch(createExercise({ exerciseName, exerciseDescription, ...payload }))
  }

  const onChangeMuscle = (items) => {
    setSelectedMuscles(items)
  }

  const onChangeEquipment = (items) => {
    setSelectedEquipment(items)
  }

  const onResetFields = ({ setFieldValue, setFieldError, resetForm }) => {
    setFieldValue('exerciseName', '')
    setFieldValue('exerciseDescription', '')
    setFieldValue('primaryMuscles', [])
    setFieldValue('equipments', [])
    setSelectedEquipment([])
    setSelectedMuscles([])
    uploaderDispatch({
      type: ExerciseUploaderTypes.CLEAR_IMAGES,
    })
    dispatch(clearImageIds())
  }

  useEffect(() => {
    dispatch(setActiveExercise(null))
    localDispatch({
      type: LayoutTypes.SET_BLADE_TITLE,
      data: 'Create Exercise',
    })
  }, [])

  return (
    <ExerciseCreatorContainer>
      <FormikContainer>
        <FormScroll>
          {!!isImageUploaderEnabled && <ExerciseUploader type="CREATE" />}

          <Formik
            initialValues={initialValues}
            validateOnChange
            validationSchema={Schema}
            onSubmit={(values) => handleSubmit(values)}
          >
            {({
              setFieldValue,
              submitForm,
              values,
              setFieldError,
              resetForm,
            }) => (
              <Form>
                <FlexRow>
                  <FlexItem>
                    <Field
                      name="exerciseName"
                      variant="OUTLINE"
                      label="Exercise Name"
                      mb={16}
                      placeholder="Enter Name"
                      component={CustomInputField}
                    />
                    <Field
                      name="exerciseDescription"
                      component={TextAreaField}
                      label="Description"
                      mb={16}
                      placeholder="Enter Description"
                    />
                    <FieldContainer>
                      <FieldLabel labelColor="#1D2A43">Muscle Group</FieldLabel>
                      <PillSelector
                        options={musclesList}
                        variantId="muscleId"
                        variantName="muscleName"
                        selectedOptions={selectedMuscles}
                        onChange={onChangeMuscle}
                      />
                    </FieldContainer>
                    <FieldContainer>
                      <FieldLabel labelColor="#1D2A43">Equipment</FieldLabel>
                      <PillSelector
                        options={equipmentList}
                        variantId="equipmentId"
                        variantName="equipmentName"
                        selectedOptions={selectedEquipment}
                        onChange={onChangeEquipment}
                      />
                    </FieldContainer>
                  </FlexItem>
                </FlexRow>
                <Portal selector="#exercise-controls">
                  <ExerciseControls
                    isValid={!validateForm(values)}
                    onSave={submitForm}
                    onCancel={() =>
                      onResetFields({ setFieldValue, setFieldError, resetForm })
                    }
                  />
                </Portal>
              </Form>
            )}
          </Formik>
        </FormScroll>
      </FormikContainer>
    </ExerciseCreatorContainer>
  )
}

export { ExerciseCreator }
