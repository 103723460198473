import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { prop } from 'ramda'
import { Droppable, Draggable } from 'react-beautiful-dnd'
import {
  AdvancedGroupActivityContainer,
  GroupHeader,
} from './AdvancedGroup.styled'
import { ActivityEntry } from './ActivityEntry'
import { ACTIVITY, GROUP } from '../constants/AdvancedConstants'

const getContainerStyle = (isDragging) => ({
  transition: isDragging
    ? 'all .3s ease-in-out .3s'
    : 'all .3s ease-in-out .3s',
})

const getStepStyle = (isDragging, draggableStyles) => ({
  // change background colour if dragging
  padding: '4px 0px',
  boxSizing: 'border-box',
  // styles we need to apply on draggables
  ...draggableStyles,
})

const AdvancedGroup = ({
  onRemoveActivity = () => {},
  callback = () => {},
  sectionId,
  groupId,
  ...props
}) => {
  const group = prop('group')(props)
  const disabledItems = prop('disabledItems')(props)
  const isGroupDisabled = prop(ACTIVITY)(disabledItems)

  return (
    <Droppable
      droppableId={group.id}
      type={GROUP}
      isDropDisabled={isGroupDisabled}
    >
      {(provided, snapshot) => (
        <>
          <div
            ref={provided.innerRef}
            className="AdvancedGroupElement"
            style={getContainerStyle(snapshot.isDraggingOver)}
          >
            {group.children.map((activity, index) => (
              <Draggable
                key={activity.id}
                draggableId={activity.id}
                index={index}
              >
                {(provided, snapshot) => (
                  <Fragment>
                    <AdvancedGroupActivityContainer
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      key={index}
                      style={getStepStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style,
                      )}
                    >
                      <GroupHeader>
                        <ActivityEntry
                          dragProps={provided.dragHandleProps}
                          label={activity.label}
                          callback={callback}
                          activityId={activity.id}
                          data={activity.data || []}
                          activitySequence={activity.sequence}
                          groupId={groupId}
                          exerciseName={activity.exerciseName}
                          sectionId={sectionId}
                          variant={activity.variant}
                          onRemove={(e) =>
                            onRemoveActivity(e, {
                              activityId: activity.id,
                              parentId: group.id,
                            })
                          }
                        />
                      </GroupHeader>
                    </AdvancedGroupActivityContainer>

                    {provided.placeholder}
                  </Fragment>
                )}
              </Draggable>
            ))}
          </div>
          {provided.placeholder}
        </>
      )}
    </Droppable>
  )
}

AdvancedGroup.propTypes = {
  disabledItems: PropTypes.object,
  type: PropTypes.string,
  children: PropTypes.array,
}

export { AdvancedGroup }
