import { prop, head } from 'ramda';
import { getProp } from '@utils/ramda';
import {
  SETTINGS_UPDATE_QUOTE_PRICE_SUCCESS,
  SETTINGS_GET_ACTIVE_SUBSCRIPTIONS_SUCCESS,
  SETTINGS_GET_ACTIVE_PRICES_SUCCESS,
  SETTINGS_UPDATE_QUOTE_QUANTITY_SUCCESS,
  SETTINGS_GENERATE_DRAFT_QUOTE_SUCCESS,
  SETTINGS_CREATE_PAYMENT_METHOD_SUCCESS,
  SETTINGS_GET_ALL_PAYMENT_METHODS_SUCCESS,
  SETTINGS_UPDATE_QUOTE_APPLY_COUPON_SUCCESS,
} from '../actionTypes';

export const updateQuotePriceSuccess = payload => {
  return {
    type: SETTINGS_UPDATE_QUOTE_PRICE_SUCCESS,
    payload,
  };
};

export const updateQuoteQuantitySuccess = payload => {
  return {
    type: SETTINGS_UPDATE_QUOTE_QUANTITY_SUCCESS,
    payload,
  };
};

const getData = payload => {
  const defaultData = {
    paymentMethod: '',
    price: '',
    interval: '',
    quantity: 0,
    status: '',
    description: '',
    id: '',
  };

  if (!payload) {
    return defaultData;
  }

  const firstItem = head(payload);

  const stripeSubscriptionId = prop('stripeSubscriptionId')(firstItem);
  const description = getProp('priceInfo.description')(firstItem);
  const quantity = prop('quantity')(firstItem);
  const status = prop('status')(firstItem);
  const interval = getProp('priceInfo.label.interval')(firstItem);

  const data = {
    paymentMethod: '',
    price: '',
    interval: interval,
    quantity: quantity,
    status: status,
    description: description,
    id: stripeSubscriptionId,
  };

  return data;
};

export const getActiveSubscriptionsSuccess = payload => {
  return {
    type: SETTINGS_GET_ACTIVE_SUBSCRIPTIONS_SUCCESS,
    payload: getData(payload),
  };
};

export const getActivePricesSuccess = payload => {
  return {
    type: SETTINGS_GET_ACTIVE_PRICES_SUCCESS,
    payload,
  };
};

export const generateDraftQuoteSuccess = payload => {
  return {
    type: SETTINGS_GENERATE_DRAFT_QUOTE_SUCCESS,
    payload,
  };
};

export const createPaymentMethodSuccess = payload => {
  return {
    type: SETTINGS_CREATE_PAYMENT_METHOD_SUCCESS,
    payload,
  };
};

export const getAllPaymentMethodsSuccess = payload => {
  return {
    type: SETTINGS_GET_ALL_PAYMENT_METHODS_SUCCESS,
    payload,
  };
};

export const updateQuoteApplyCouponSuccess = payload => {
  return {
    type: SETTINGS_UPDATE_QUOTE_APPLY_COUPON_SUCCESS,
    payload,
  };
};
