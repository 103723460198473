export default {
  id: 'abc-123',
  label: 'Full Upper Body Strength',
  description: 'Full Upper Body Strength Description',
  videos: ['test-workout-Video1', 'test-workout-Video2'],
  images: ['test-workout-image1', 'test-workout-image2'],
  contents: {
    // This top level "page" one is basically just so that the main
    // dropzone for the workout can be handled properly by
    // the drag n drop code.
    page: {
      type: 'page',
      id: 'page',
      data: {
        name: 'Page',
      },
      children: [
        { id: 'section_warmup_warmup1', sequence: 0 },
        { id: 'section_warmup_warmup_two', sequence: 1 },
      ],
    },
    section_warmup_warmup1: {
      type: 'section',
      id: 'section_warmup_warmup1',
      data: {
        name: 'warmup 1',
        notes: 'warmup notes',
      },
      children: [
        {
          id: 'group_superset_superset1',
        },
      ],
    },
    section_warmup_warmup_two: {
      type: 'section',
      id: 'section_warmup_warmup_two',
      data: {
        name: 'warmup 2',
        notes: 'warmup notes',
      },
      children: [
        {
          id: 'group_superset_superset2',
        },
      ],
    },
    group_superset_superset1: {
      type: 'group',
      id: 'group_superset_superset1',
      data: {
        name: 'Superset',
        notes: 'superset notes',
        reps: 5,
        rest: 60,
        weight: 20,
      },
      children: [
        {
          id: 'activity_warmup_standingshoulderextension',
          sequence: 0,
        },
        {
          id: 'activity_warmup_gettinjacked',
          sequence: 1,
        },
        {
          id: 'activity_warmup_gettinjacked_three',
        },
      ],
    },
    activity_warmup_standingshoulderextension: {
      type: 'activity',
      id: 'activity_warmup_standingshoulderextension',
      data: {
        style: 'warmup',
        images: ['test-image3', 'test-image4'],
        videos: ['test-Video3', 'test-Video4'],
        isActive: true,
        createdAt: '2021-12-15T21:15:24.689431',
        createdBy: {
          type: 'CLIENT',
          email: 'testclient',
          phoneNo: '123456789',
          lastName: 'client',
          userName: 'amithbs@gmail.com',
          firstName: 'test',
        },
        isDeleted: false,
        updatedAt: '2021-12-15T21:15:24.689431',
        difficulty: 'EASY',
        exerciseDescription: 'Test Description',
        id: 'abc',
        name: 'Standing Shoulder Extension',
        equipments: [
          {
            isActive: true,
            isDeleted: false,
            equipmentId: 1,
            equipmentName: 'Powerbells',
          },
          {
            isActive: true,
            isDeleted: false,
            equipmentId: 2,
            equipmentName: 'Powerplates',
          },
          {
            isActive: true,
            isDeleted: false,
            equipmentId: 3,
            equipmentName: 'Cube Suspension Trainer',
          },
        ],
        primaryMuscles: [
          {
            isActive: true,
            muscleId: 1,
            isDeleted: false,
            muscleName: 'Calves',
          },
          {
            isActive: true,
            muscleId: 2,
            isDeleted: false,
            muscleName: 'Hamstring',
          },
          {
            isActive: true,
            muscleId: 3,
            isDeleted: false,
            muscleName: 'Quadriceps',
          },
        ],
      },
    },
    activity_warmup_gettinjacked: {
      type: 'activity',
      id: 'activity_warmup_gettinjacked',
      data: {
        style: 'warmup',
        images: ['test-image3', 'test-image4'],
        videos: ['test-Video3', 'test-Video4'],
        isActive: true,
        createdAt: '2021-12-15T21:15:24.689431',
        createdBy: {
          type: 'CLIENT',
          email: 'testclient',
          phoneNo: '123456789',
          lastName: 'client',
          userName: 'amithbs@gmail.com',
          firstName: 'test',
        },
        isDeleted: false,
        updatedAt: '2021-12-15T21:15:24.689431',
        difficulty: 'EASY',
        exerciseDescription: 'Test Description',
        id: 2,
        name: 'Standing Shoulder Extension',
        equipments: [
          {
            isActive: true,
            isDeleted: false,
            equipmentId: 1,
            equipmentName: 'Powerbells',
          },
          {
            isActive: true,
            isDeleted: false,
            equipmentId: 2,
            equipmentName: 'Powerplates',
          },
          {
            isActive: true,
            isDeleted: false,
            equipmentId: 3,
            equipmentName: 'Cube Suspension Trainer',
          },
        ],
        primaryMuscles: [
          {
            isActive: true,
            muscleId: 1,
            isDeleted: false,
            muscleName: 'Calves',
          },
          {
            isActive: true,
            muscleId: 2,
            isDeleted: false,
            muscleName: 'Hamstring',
          },
          {
            isActive: true,
            muscleId: 3,
            isDeleted: false,
            muscleName: 'Quadriceps',
          },
        ],
      },
    },
    group_superset_superset2: {
      type: 'group',
      id: 'group_superset_superset2',
      data: {
        name: 'Superset',
        notes: 'superset notes',
        reps: 5,
        rest: 60,
        weight: 20,
      },
      children: [
        {
          id: 'activity_warmup_standingshoulderextension_two',
          sequence: 0,
        },
        {
          id: 'activity_warmup_gettinjacked_two',
          sequence: 1,
        },
      ],
    },
    activity_warmup_standingshoulderextension_two: {
      type: 'activity',
      id: 'activity_warmup_standingshoulderextension_two',
      data: {
        style: 'warmup',
        images: ['test-image3', 'test-image4'],
        videos: ['test-Video3', 'test-Video4'],
        isActive: true,
        createdAt: '2021-12-15T21:15:24.689431',
        createdBy: {
          type: 'CLIENT',
          email: 'testclient',
          phoneNo: '123456789',
          lastName: 'client',
          userName: 'amithbs@gmail.com',
          firstName: 'test',
        },
        isDeleted: false,
        updatedAt: '2021-12-15T21:15:24.689431',
        difficulty: 'EASY',
        exerciseDescription: 'Test Description',
        id: 2,
        name: 'Standing Shoulder Extension',
        equipments: [
          {
            isActive: true,
            isDeleted: false,
            equipmentId: 1,
            equipmentName: 'Powerbells',
          },
          {
            isActive: true,
            isDeleted: false,
            equipmentId: 2,
            equipmentName: 'Powerplates',
          },
          {
            isActive: true,
            isDeleted: false,
            equipmentId: 3,
            equipmentName: 'Cube Suspension Trainer',
          },
        ],
        primaryMuscles: [
          {
            isActive: true,
            muscleId: 1,
            isDeleted: false,
            muscleName: 'Calves',
          },
          {
            isActive: true,
            muscleId: 2,
            isDeleted: false,
            muscleName: 'Hamstring',
          },
          {
            isActive: true,
            muscleId: 3,
            isDeleted: false,
            muscleName: 'Quadriceps',
          },
        ],
      },
    },
    activity_warmup_gettinjacked_two: {
      type: 'activity',
      id: 'activity_warmup_gettinjacked_two',
      data: {
        style: 'warmup',
        images: ['test-image3', 'test-image4'],
        videos: ['test-Video3', 'test-Video4'],
        isActive: true,
        createdAt: '2021-12-15T21:15:24.689431',
        createdBy: {
          type: 'CLIENT',
          email: 'testclient',
          phoneNo: '123456789',
          lastName: 'client',
          userName: 'amithbs@gmail.com',
          firstName: 'test',
        },
        isDeleted: false,
        updatedAt: '2021-12-15T21:15:24.689431',
        difficulty: 'EASY',
        exerciseDescription: 'Test Description',
        id: 2,
        name: 'Standing Shoulder Extension',
        equipments: [
          {
            isActive: true,
            isDeleted: false,
            equipmentId: 1,
            equipmentName: 'Powerbells',
          },
          {
            isActive: true,
            isDeleted: false,
            equipmentId: 2,
            equipmentName: 'Powerplates',
          },
          {
            isActive: true,
            isDeleted: false,
            equipmentId: 3,
            equipmentName: 'Cube Suspension Trainer',
          },
        ],
        primaryMuscles: [
          {
            isActive: true,
            muscleId: 1,
            isDeleted: false,
            muscleName: 'Calves',
          },
          {
            isActive: true,
            muscleId: 2,
            isDeleted: false,
            muscleName: 'Hamstring',
          },
          {
            isActive: true,
            muscleId: 3,
            isDeleted: false,
            muscleName: 'Quadriceps',
          },
        ],
      },
    },
    activity_warmup_gettinjacked_three: {
      type: 'activity',
      id: 'activity_warmup_gettinjacked_three',
      data: {
        style: 'warmup',
        images: ['test-image3', 'test-image4'],
        videos: ['test-Video3', 'test-Video4'],
        isActive: true,
        createdAt: '2021-12-15T21:15:24.689431',
        createdBy: {
          type: 'CLIENT',
          email: 'testclient',
          phoneNo: '123456789',
          lastName: 'client',
          userName: 'amithbs@gmail.com',
          firstName: 'test',
        },
        isDeleted: false,
        updatedAt: '2021-12-15T21:15:24.689431',
        difficulty: 'EASY',
        exerciseDescription: 'Test Description',
        id: 2,
        name: 'Standing Shoulder Extension',
        equipments: [
          {
            isActive: true,
            isDeleted: false,
            equipmentId: 1,
            equipmentName: 'Powerbells',
          },
          {
            isActive: true,
            isDeleted: false,
            equipmentId: 2,
            equipmentName: 'Powerplates',
          },
          {
            isActive: true,
            isDeleted: false,
            equipmentId: 3,
            equipmentName: 'Cube Suspension Trainer',
          },
        ],
        primaryMuscles: [
          {
            isActive: true,
            muscleId: 1,
            isDeleted: false,
            muscleName: 'Calves',
          },
          {
            isActive: true,
            muscleId: 2,
            isDeleted: false,
            muscleName: 'Hamstring',
          },
          {
            isActive: true,
            muscleId: 3,
            isDeleted: false,
            muscleName: 'Quadriceps',
          },
        ],
      },
    },
  },
}
