import styled from 'styled-components';

const unit = value => (typeof value === 'number' ? `${value}px` : value);

const Spacer = styled.div`
  ${({ mt }) => mt && `margin-top: ${unit(mt)};`};
  ${({ mb }) => mb && `margin-bottom: ${unit(mb)};`};
  ${({ mx }) => mx && `margin-top: ${unit(mx)}; margin-bottom: ${unit(mx)};`};
  ${({ my }) => my && `margin-left: ${unit(my)}; margin-right: ${unit(my)};`};
  ${({ px }) => px && `padding-top: ${unit(px)}; padding-bottom: ${unit(px)};`};
  ${({ py }) => py && `padding-left: ${unit(py)}; padding-right: ${unit(py)};`};
`;

export { Spacer };
