import React from 'react'
import { mbFn, mtFn } from '@common/Theme'
import styled, { css } from 'styled-components'

const ControlsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 16px;
  ${(props) =>
    props.withLine &&
    css`
      border-top: 1px solid #e3e5eb;
      padding-top: 16px;
    `};
  ${(props) => props.extend};
  ${mbFn};
  ${mtFn};
`
const Controls = ({ extend, className, mb, mt, withLine, children }) => {
  return (
    <ControlsContainer
      className={className}
      withLine={withLine}
      extend={extend}
      mt={mt}
      mb={mb}
    >
      {children}
    </ControlsContainer>
  )
}

export { Controls }
