import React, { useState, useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useClickOutside as ClickOutside } from '@hooks/useClickOutside';
import { Icon } from '@common/Icon';

const SeachWrapper = styled.div`
  padding: 0 8px;
`;

const SearchContainer = styled.div`
  position: relative;
  width: 100%;
  background: #f4f5f8;
  border-radius: 6px;
  border: 1px solid transparent;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 13px 16px 10px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #eaeaea;
  justify-content: flex-start;
  ${props =>
    props.isFocused &&
    css`
      background: white;
      border: 1px solid #ff5a45;
      box-shadow: 0px 0px 0px 2px rgba(255, 90, 69, 0.2);
    `}
`;

const SearchIcon = styled.div`
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
`;

const SearchInput = styled.input`
  line-height: 20px;
  font-size: 14px;
  background: transparent;
  padding: 0;
  margin: 0;
  color: #121b2d;
  width: 100%;
  box-sizing: border-box;
  border: none;
  border-radius: 0;
  &:focus {
    outline: none;
  }
`;

const AdvancedSearch = ({ onChange = () => {}, value, ...props }) => {
  const [focus, setFocus] = useState(false);
  const ref = useRef();

  const onFocus = () => {
    if (ref.current) {
      setFocus(true);
      ref.current.focus();
    }
  };

  const handleClose = () => setFocus(false);

  useEffect(() => {
    return setFocus(false);
  }, []);

  return (
    <SeachWrapper>
      <ClickOutside callback={handleClose}>
        <SearchContainer onClick={onFocus} isFocused={focus}>
          <SearchIcon>
            <Icon name="SEARCH" size={20} />
          </SearchIcon>
          <SearchInput
            ref={ref}
            focus={focus}
            value={value}
            placeholder="Search"
            onChange={onChange}
            {...props}
          />
        </SearchContainer>
      </ClickOutside>
    </SeachWrapper>
  );
};

export { AdvancedSearch };
