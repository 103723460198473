import { mrFn, mtFn } from '@common/Theme'
import styled, { css } from 'styled-components'

const AddContainer = styled.div`
  width: 100%;
  display: flex;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 16px;
`

const AddButton = styled.button`
  background: #f7f8fa;
  border-radius: 4px;
  border: none;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 13px 8px;
  &:hover {
  }
  span {
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    color: #a9aeb9;
  }
`

const AddButtonIcon = styled.div`
  margin-right: 4px;
`

const basicStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  border: none;
  box-sizing: border-box;
`

const basicText = css`
  line-height: 14px;
  font-size: 14px;
`

const pseudoStyles = css`
  &:focus {
    outline: none;
  }
  &:hover {
    cursor: pointer;
  }
`

const SetButton = styled.button`
  ${basicStyles};
  ${basicText};
  ${pseudoStyles}
  padding: 10px 16px;
  background: white;
  border-radius: 4px;
  font-weight: 500;
  color: #ff5a45;
  border: 1.5px solid #ffa296;
  transition: all 0.2s ease-in-out;
  &:hover {
    box-shadow: 0px 2px 8px rgba(209, 48, 28, 0.2);
  }
`

const SaveButton = styled.button`
  ${basicStyles};
  ${basicText};
  ${pseudoStyles}
  padding: 10px 16px;
  border-radius: 4px;
  font-weight: 500;
  border: 1.5px solid rgb(179 216 189);
  transition: all 0.2s ease-in-out;
  background: white;
  ${mtFn};
  ${mrFn};
  color: rgb(25 178 68);
  &:hover {
    box-shadow: 0px 2px 8px rgb(179 216 189);
  }
`

const SetContainer = styled.div`
  width: 100%;
  margin-top: 8px;
`

const SetDragContainer = styled.div`
  margin-bottom: 8px;
  width: 100%;
`

export {
  AddContainer,
  SetContainer,
  SetButton,
  SaveButton,
  SetDragContainer,
  AddButton,
  AddButtonIcon,
}
