import {
  SETTINGS_UPDATE_QUOTE_PRICE_PENDING,
  SETTINGS_UPDATE_QUOTE_PRICE_SUCCESS,
  SETTINGS_UPDATE_QUOTE_PRICE_FAILURE,
  SETTINGS_GET_ACTIVE_SUBSCRIPTIONS_PENDING,
  SETTINGS_GET_ACTIVE_SUBSCRIPTIONS_SUCCESS,
  SETTINGS_GET_ACTIVE_SUBSCRIPTIONS_FAILURE,
  SETTINGS_UPDATE_DEVMODE_PENDING,
  SETTINGS_GET_ACTIVE_PRICES_PENDING,
  SETTINGS_GET_ACTIVE_PRICES_SUCCESS,
  SETTINGS_GET_ACTIVE_PRICES_FAILURE,
  SETTINGS_UPDATE_QUOTE_QUANTITY_PENDING,
  SETTINGS_UPDATE_QUOTE_QUANTITY_SUCCESS,
  SETTINGS_UPDATE_QUOTE_QUANTITY_FAILURE,
  SETTINGS_GENERATE_DRAFT_QUOTE_PENDING,
  SETTINGS_GENERATE_DRAFT_QUOTE_SUCCESS,
  SETTINGS_GENERATE_DRAFT_QUOTE_FAILURE,
  SETTINGS_CREATE_PAYMENT_METHOD_PENDING,
  SETTINGS_CREATE_PAYMENT_METHOD_SUCCESS,
  SETTINGS_CREATE_PAYMENT_METHOD_FAILURE,
  SETTINGS_GET_ALL_PAYMENT_METHODS_PENDING,
  SETTINGS_GET_ALL_PAYMENT_METHODS_SUCCESS,
  SETTINGS_GET_ALL_PAYMENT_METHODS_FAILURE,
  SETTINGS_UPDATE_QUOTE_APPLY_COUPON_PENDING,
  SETTINGS_UPDATE_QUOTE_APPLY_COUPON_SUCCESS,
  SETTINGS_UPDATE_QUOTE_APPLY_COUPON_FAILURE,
} from './actionTypes'

const initialState = {
  loading: false,
  prices: [],
  error: null,
  subscriptions: [],
  quantity: null,
  quote: null,
  paymentMethod: null,
  methods: null,
  coupon: null,
  isDevMode: false,
}

export default function settingsReducer(state = initialState, action) {
  switch (action.type) {
    case SETTINGS_UPDATE_DEVMODE_PENDING:
      return {
        ...state,
        isDevMode: action.payload,
      }
    case SETTINGS_GET_ACTIVE_SUBSCRIPTIONS_PENDING:
      return {
        ...state,
        loading: true,
      }
    case SETTINGS_GET_ACTIVE_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        subscriptions: action.payload,
      }
    case SETTINGS_GET_ACTIVE_SUBSCRIPTIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    case SETTINGS_UPDATE_QUOTE_PRICE_PENDING:
      return {
        ...state,
        loading: true,
      }
    case SETTINGS_UPDATE_QUOTE_PRICE_SUCCESS:
      return {
        ...state,
        loading: false,
        quote: action.payload,
      }
    case SETTINGS_UPDATE_QUOTE_PRICE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    case SETTINGS_GET_ACTIVE_PRICES_PENDING:
      return {
        ...state,
        loading: true,
      }
    case SETTINGS_GET_ACTIVE_PRICES_SUCCESS:
      return {
        ...state,
        loading: false,
        prices: action.payload,
      }
    case SETTINGS_GET_ACTIVE_PRICES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    case SETTINGS_UPDATE_QUOTE_QUANTITY_PENDING:
      return {
        ...state,
        loading: true,
      }
    case SETTINGS_UPDATE_QUOTE_QUANTITY_SUCCESS:
      return {
        ...state,
        loading: false,
        quantity: action.payload,
      }
    case SETTINGS_UPDATE_QUOTE_QUANTITY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    case SETTINGS_GENERATE_DRAFT_QUOTE_PENDING:
      return {
        ...state,
        loading: true,
      }
    case SETTINGS_GENERATE_DRAFT_QUOTE_SUCCESS:
      return {
        ...state,
        loading: false,
        quote: action.payload,
      }
    case SETTINGS_GENERATE_DRAFT_QUOTE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    case SETTINGS_CREATE_PAYMENT_METHOD_PENDING:
      return {
        ...state,
        loading: true,
      }
    case SETTINGS_CREATE_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        loading: false,
        paymentMethod: action.payload,
      }
    case SETTINGS_CREATE_PAYMENT_METHOD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    case SETTINGS_GET_ALL_PAYMENT_METHODS_PENDING:
      return {
        ...state,
        loading: true,
      }
    case SETTINGS_GET_ALL_PAYMENT_METHODS_SUCCESS:
      return {
        ...state,
        loading: false,
        methods: action.payload,
      }
    case SETTINGS_GET_ALL_PAYMENT_METHODS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    case SETTINGS_UPDATE_QUOTE_APPLY_COUPON_PENDING:
      return {
        ...state,
        loading: true,
      }
    case SETTINGS_UPDATE_QUOTE_APPLY_COUPON_SUCCESS:
      return {
        ...state,
        loading: false,
        coupon: action.payload,
      }
    case SETTINGS_UPDATE_QUOTE_APPLY_COUPON_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    default:
      return state
  }
}
