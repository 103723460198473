import { call, put, select } from 'redux-saga/effects'
import {
  createWorkout,
  fetchWorkouts,
  deleteWorkout,
  getWorkoutById,
  addWorkoutImage,
  requestUpdateWorkout,
} from './requests'
import {
  updateWorkoutFailure,
  updateWorkoutSuccess,
  fetchWorkoutsSuccess,
  fetchWorkoutsFailure,
  createWorkoutSuccess,
  deleteWorkoutSuccess,
  deleteWorkoutFailure,
  createWorkoutFailure,
  getWorkoutByIdSuccess,
  getWorkoutByIdFailure,
  createWorkoutImageSuccess,
  createWorkoutImageFailure,
  updateWorkoutListenSuccess,
  updateWorkoutImageSuccess,
  updateWorkoutImageFailure,
  setActiveWorkoutSuccess,
} from './actions'

import { activeWorkout, workouts, images } from './selectors'

export function* onCreateWorkout({ payload: workout }) {
  try {
    const response = yield call(createWorkout, workout)
    if (response && response.data) {
      yield put(createWorkoutSuccess(response.data))
    } else {
      yield put(
        createWorkoutFailure('Something went wrong while creating workout.'),
      )
    }
  } catch (error) {
    yield put(createWorkoutFailure(error))
  }
}

export function* onFetchWorkouts() {
  try {
    const response = yield call(fetchWorkouts)

    if (response && response.data)
      yield put(fetchWorkoutsSuccess(response.data.content))
  } catch (error) {
    yield put(fetchWorkoutsFailure(error))
  }
}

export function* onUpdateWorkout({ payload }) {
  try {
    const response = yield call(requestUpdateWorkout, payload)
    yield put(updateWorkoutSuccess(response.data))
  } catch (error) {
    yield put(updateWorkoutFailure(error))
  }
}

export function* onUpdateWorkoutImage({ payload }) {}

export function* onGetWorkoutById({ payload }) {
  try {
    const response = yield call(getWorkoutById, payload)
    yield put(getWorkoutByIdSuccess(response.data))
  } catch (error) {
    yield put(getWorkoutByIdFailure(error))
  }
}

export function* onWorkoutUpdateListen({ payload }) {
  const response = yield call(updateWorkout, {
    body: payload,
    params: payload.workoutId,
  })
  // Updates the activeWorkout with the updated workout(response) received from server
  if (response.data) {
    yield put(updateWorkoutListenSuccess(response.data))
  }
}

export function* onCreateWorkoutImage({ payload: { rawImage, variant } }) {
  try {
    const response = yield call(addWorkoutImage, rawImage)
    if (response && response.data) {
      const activeWorkoutObject = yield select(activeWorkout)
      const validateImages =
        !!activeWorkoutObject && !!activeWorkoutObject.images
          ? activeWorkoutObject.images
          : []
      const workout = {
        ...activeWorkoutObject,
        images: [
          ...validateImages,
          {
            id: response.data.imageid,
            label: variant || 'Image',
            variant: variant,
          },
        ],
      }
      try {
        const response = yield call(requestUpdateWorkout, workout)
        yield put(updateWorkoutSuccess(response.data))
      } catch (error) {
        yield put(updateWorkoutFailure(error))
      }
    }
  } catch (error) {
    yield put(createWorkoutImageFailure(error))
  }
}

export function* onDeleteWorkout({ payload }) {
  try {
    const response = yield call(deleteWorkout, payload)
    if (response) {
      yield put(deleteWorkoutSuccess())
      try {
        const response = yield call(fetchWorkouts)
        if (response && response.data)
          yield put(fetchWorkoutsSuccess(response.data.content))
      } catch (error) {
        yield put(fetchWorkoutsFailure(error))
      }
    }
  } catch (error) {
    yield put(deleteWorkoutFailure(error))
  }
}
