import styled from 'styled-components'
import { mbFn, mtFn, bgColorFn } from '@common/Theme'

const Line = styled.hr`
  width: 100%;
  background: #e3e5eb;
  height: 1px;
  margin-top: 24px;
  margin-bottom: 24px;
  border: none;
  ${mtFn};
  ${mbFn};
  ${bgColorFn};
`

export { Line }
