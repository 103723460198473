import React, { useEffect, useState } from 'react'
import { Field, useFormikContext } from 'formik'
import { InputField } from '@common/InputField'
import { ExtendInput, ChanelRow, ChanelContainer } from './Channel.styled'
import { generatedChannelId } from './utils'
import { isNil, complement } from 'ramda'
import styled from 'styled-components'
import { mtFn, mbFn } from '@common/Theme'

// saga
// reducer
// error action
// success action
// request method
// api endpoint definition
// selector coming from the user slice (make a new key for channel validation - pending)
// create unique button here to execute the validation
// if there is an error, show the error message (<ErrorList />)
// Concerns, can I use the formik input field? Yes, I can use the formik input field
// Execute the validation on the  button click

const ErrorMessage = styled.div`
  padding: 12px 16px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-start;

  border-radius: 6px;
  background: #ffe4e6;
  ${mbFn};
  ${mtFn};
  p {
    color: red;
    font-size: 14px;
    margin: 0;
    padding: 0;
  }
`

export const ErrorList = ({ mb, mt, error }) => {
  if (!error || !error.length) return null
  return (
    <ErrorMessage mb={mb} mt={mt}>
      <p>{error}</p>
    </ErrorMessage>
  )
}

const isPresent = complement(isNil)

const Channel = ({
  prefix = '',
  name = '',
  label = '',
  firstName,
  lastName,
  value,
  children,
}) => {
  const { setFieldValue, errors } = useFormikContext()
  useEffect(() => {
    if (!value || !value.length) {
      setFieldValue(name, generatedChannelId(firstName, lastName))
      return
    }
    setFieldValue(name, value)
  }, [firstName, lastName, value])

  const onChange = (event) => {
    setFieldValue(name, event.target.value.replace(/\s/g, ''))
  }

  const getError = (errors) => {
    if (isPresent(errors)) {
      const item = errors[name]
      return item
    }
    return ''
  }

  const errorItem = getError(errors)

  return (
    <ChanelContainer>
      {label && <label>{label}</label>}
      <ChanelRow>
        {prefix}
        <Field
          label=""
          isErrorEnabled={false}
          name={name}
          component={InputField}
          onChange={onChange}
          extend={ExtendInput}
        />
      </ChanelRow>
      <ErrorList mb={16} variant="error" error={errorItem} />
      {children}
    </ChanelContainer>
  )
}

export { Channel }
