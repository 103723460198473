import { getEnvironment } from '../utils'

const { baseURL } = getEnvironment()

import {
  PREFERENCES_FETCH_SUCCESS,
  PREFERENCES_SAVE_BOOKMARK_SUCCESS,
  PREFERENCES_REMOVE_BOOKMARK_SUCCESS,
  USER_FETCH_ME_SUCCESS,
  USER_UPDATE_SUCCESS,
  USER_CREATE_IMAGE_SUCCESS,
  USER_VALIDATE_CHANNEL_SUCCESS,
} from './actionTypes'

const API = {
  [PREFERENCES_FETCH_SUCCESS]: {
    endpoint: `${baseURL}/aima/user-preference`,
    method: 'GET',
  },
  [PREFERENCES_SAVE_BOOKMARK_SUCCESS]: {
    endpoint: `${baseURL}/aima/user-preference/set-favourite-exercise`,
    method: 'POST',
  },
  [PREFERENCES_REMOVE_BOOKMARK_SUCCESS]: {
    endpoint: `${baseURL}/aima/user-preference/unset-favourite-exercise`,
    method: 'POST',
  },
  [USER_FETCH_ME_SUCCESS]: {
    endpoint: `${baseURL}/aima/user/me`,
    method: 'GET',
  },
  [USER_UPDATE_SUCCESS]: {
    endpoint: `${baseURL}/aima/user`,
    method: 'PUT',
  },
  [USER_CREATE_IMAGE_SUCCESS]: {
    endpoint: `${baseURL}/aima/user/file-upload`,
    method: 'POST',
    headers: ['AUTH', 'IMAGE'],
  },
  [USER_VALIDATE_CHANNEL_SUCCESS]: {
    endpoint: `${baseURL}/aima/user/validate-channel-name`,
    method: 'GET',
  },
}

export { API }
