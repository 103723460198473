import {
  CONTAINER,
  ADD_CONTAINER,
  SECTION,
  ADD_SECTION,
  GROUP,
  ADD_GROUP,
  ADD_ACTIVITY,
  ADD_REST,
  REST,
  EXERCISE,
  SURVEY,
  SUPERSET,
  ACTIVITY,
  ADD_SURVEY,
} from '../constants/AdvancedConstants'

import { propEq, prop, find, omit } from 'ramda'

// import { uuid } from '@utils/uuid'

const getUniqueId = (item, key) => {
  if (!item) {
    console.warn('No item was passed. [getUniqueId]')
    return ''
  }
  if (!prop(key)(item)) {
    // console.warn('No [key] was found. [getUniqueId]')
    return ''
  }

  return `${prop(key)(item)}`
}

const getUniqueLabel = (item, key) => {
  if (!item) {
    // console.warn('No item was passed. [getUniqueLabel]')
    return ''
  }
  if (!prop(key)(item)) {
    // console.warn('No label or [key] was found. [getUniqueLabel]')
    return ''
  }

  return `${prop(key)(item)}`
}

const applyIndexByMap = (items = []) =>
  items.map((m, i) => ({ ...m, sequence: i }))

const updateLabelById = (state, id, newLabel) => {
  if (!state || !state.length) {
    console.warn('No state array provided. [updateLabelById]')
    return state
  }

  // Helper function to recursively update label by id
  function updateLabelRecursive(items) {
    return items.map((item) => {
      const children = item.children
      if (item.id === id) {
        // Update the label if the id matches
        return { ...item, label: newLabel } // Create a new object with the updated label
      }
      if (children && children.length) {
        // Recursively search for the item in children
        return { ...item, children: updateLabelRecursive(children) } // Recurse and update children
      }
      return item // Return the original item if id doesn't match and it has no children
    })
  }

  // Call the recursive function starting from the root level
  return updateLabelRecursive(state)
}

const getRandomWord = (type) => {
  const sectionList = ['Pre Workout', 'Post Workout', 'During Workout']
  const groupList = [
    'Leg Superset',
    'Upper Body Superset',
    'Abdominal Superset',
  ]
  const activityList = ['Leg Press', 'Squats', 'Romanian Dead Lifts']

  const getType = (list) =>
    ({
      section: sectionList,
      group: groupList,
      activity: activityList,
    }[list || 'section'])

  const pickType = getType(type)

  const getRandomNumber = (num) => Math.floor(Math.random() * num)
  const random = Math.floor(Math.random() * pickType.length)
  const result = pickType[random]
  return `${result} ${getRandomNumber(100)}`
}

const generatePath = (destination, sourceId, id) => {
  const renderDestination = (destination) => {
    if (!destination) return ''
    return `${destination}`
  }
  const applySourceId = (sourceId) => {
    if (!sourceId) return ''
    return `/${sourceId}`
  }
  const applyId = (id) => {
    if (!id) return ''
    return `/${id}`
  }

  return `${renderDestination(destination)}${applySourceId(sourceId)}${applyId(
    id,
  )}`
}

const comprehendPath = (path = '') => {
  if (!path || !path.length) return { destination: '', sourceId: '', id: '' }
  const splitPath = path.split('/')
  if (!splitPath.length) return { destination: '', sourceId: '', id: '' }
  const [destination, sourceId, id] = splitPath
  return { destination, sourceId, id }
}

// No need for ACTIVITY as this item will not behave as a parent

const generateTypes = (options = []) => options.join('_')

const getControlActivities = () => {
  const sectionId = ADD_SECTION
  const groupId = ADD_GROUP
  const restId = ADD_ACTIVITY

  const controls = [
    {
      id: sectionId,
      droppableId: generatePath(ADD_SECTION, sectionId),
      draggableId: generatePath(ADD_SECTION, sectionId),
      droppableType: CONTAINER,
      type: generateTypes([CONTAINER, ADD_CONTAINER]),
      label: 'Add Section',
      variant: SECTION,
      index: 0,
    },
    {
      id: groupId,
      droppableId: generatePath(ADD_GROUP, groupId),
      draggableId: generatePath(ADD_GROUP, groupId),
      droppableType: SECTION,
      type: generateTypes([SECTION, ADD_SECTION]),
      label: 'Add Superset',
      variant: SUPERSET,
      index: 1,
    },
    {
      id: restId,
      droppableId: generatePath(ADD_ACTIVITY, restId),
      draggableId: generatePath(ADD_ACTIVITY, restId),
      droppableType: GROUP,
      type: generateTypes([GROUP, ADD_GROUP]),
      label: 'Add Rest',
      variant: REST,
      rest: 60,
      index: 2,
    },
  ]
  return controls
}
const generateData = (exercises = []) => {
  // these should only be re-created once dropped!

  const mapPropsToItems = (items = []) => {
    return items.reduce((prev, curr, index) => {
      if (!prop('variant')(curr)) {
        const item = {
          ...curr,
          id: getUniqueId(curr),
          droppableId: generatePath(
            ADD_ACTIVITY,
            getUniqueId(curr, 'exerciseId'),
          ),
          draggableId: generatePath(
            ADD_ACTIVITY,
            getUniqueId(curr, 'exerciseId'),
          ),
          droppableType: GROUP,
          label: getUniqueLabel(curr, 'exerciseName') || 'New Exercise',
          variant: 'EXERCISE',
          type: generateTypes([GROUP, ADD_GROUP]),
          index: index,
        }
        return [...prev, item]
      }

      if (curr.variant.toUpperCase() === 'EXERCISE') {
        // const exerciseId = uuid()
        const item = {
          ...curr,
          id: getUniqueId(curr, 'exerciseId'),
          droppableId: generatePath(
            ADD_ACTIVITY,
            getUniqueId(curr, 'exerciseId'),
          ),
          draggableId: generatePath(
            ADD_ACTIVITY,
            getUniqueId(curr, 'exerciseId'),
          ),
          droppableType: GROUP,
          variant: EXERCISE,
          rest: curr.rest || 60,
          label: getUniqueLabel(curr, 'exerciseName') || 'New Activity',
          type: generateTypes([GROUP, ADD_GROUP]),
          index: index,
        }
        return [...prev, item]
      }

      // if (curr.variant.toUpperCase() === 'SURVEY') {
      //   const item = {
      //     ...curr,
      //     id: getUniqueId(curr, 'exerciseId'),
      //     droppableId: generatePath(
      //       ADD_ACTIVITY,
      //       getUniqueId(curr, 'exerciseId'),
      //     ),
      //     draggableId: generatePath(
      //       ADD_ACTIVITY,
      //       getUniqueId(curr, 'exerciseId'),
      //     ),
      //     droppableType: GROUP,
      //     label: getUniqueLabel(curr, 'exerciseName') || 'New Survey',
      //     variant: SURVEY,
      //     type: generateTypes([GROUP, ADD_GROUP]),
      //     index: index,
      //   }
      //   return [...prev, item]
      // }

      return prev
    }, [])
  }
  const allItemsMapped = mapPropsToItems([...exercises])
  const result = applyIndexByMap([...getControlActivities(), ...allItemsMapped])
  return result
}

const findBySourceId =
  (sourceId) =>
  (items = []) => {
    const result = items.find((item) => {
      if (!item || !item.id) {
        console.warn('No item id found. [findBySourceId]')
        return false
      }
      if (typeof item.id === 'string') {
        return item.id.includes(sourceId)
      }
    })
    if (!result) {
      console.warn('No matching item found. [findBySourceId]')
      return null
    }
    return result
  }

const getActivity =
  (id, omitKeys = []) =>
  (items = []) => {
    if (!id || !id.length) return null
    if (!items || !items.length) return null

    const found = findBySourceId(id)(items)

    if (!found) return null

    const keysToRemove = [...omitKeys]
    const updatedItem = {
      ...found,
    }

    return omit(keysToRemove)(updatedItem)
  }

export {
  generateData,
  comprehendPath,
  generatePath,
  getRandomWord,
  getActivity,
  updateLabelById,
  applyIndexByMap,
  getControlActivities,
}
