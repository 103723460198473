import React, { forwardRef, useEffect, useState } from 'react'
import { isNil, complement, length, prop } from 'ramda'
import { useDispatch } from 'react-redux'
import { ErrorList } from '@common/ErrorList'
import { useSelector } from '@hooks/useSelector'
import { usePrevious } from '@hooks/usePrevious'
import { useDebounce } from '@hooks/useDebounce'
// STATE
import { exerciseSelector, updateExercise } from '@state/exercise'
// COMPONENTS
import { Input } from './Input'
// STYLED
import { FieldLabel, FieldContainer } from './ExerciseEditorName.styled'

const defaultProps = {
  name: 'Abc',
  id: 'abc-123',
  variant: 'outline',
  type: 'text',
  placeholder: 'Enter Name',
  verticalPush: '16px',
  config: {
    layout: {
      fontSize: '14px',
    },
  },
}

const haystack = [
  { name: 'error', message: 'You must enter a name.' },
  {
    name: 'length',
    message: 'You must enter a name that is at least one character.',
  },
]

const isPresent = complement(isNil)

const ExerciseEditorName = forwardRef((_, ref) => {
  const dispatch = useDispatch()
  // LOCAL
  const [errors, setErrors] = useState([])
  const [localState, setLocalState] = useState('')

  // SELECTORS
  const activeExercise = useSelector(exerciseSelector.activeExercise)

  // DERIVED
  const name = prop('exerciseName')(activeExercise)
  const previousName = usePrevious(name)
  const actualName = useDebounce(localState, 1500)

  useEffect(() => {
    if (errors && errors.length > 0) return
    if (!actualName) return
    if (previousName !== actualName) {
      dispatch(
        updateExercise({
          ...activeExercise,
          exerciseName: localState,
        }),
      )
    }
  }, [actualName])

  useEffect(() => {
    if (!name) return
    setLocalState(name)
  }, [name])

  const onChange = (e) => {
    const { value } = e.target
    if (length(value) <= 1) {
      setErrors(['length'])
    } else {
      setErrors((state) => state.filter((item) => item !== 'length'))
    }
    setLocalState(value)
  }

  const closeError = (error) => {
    setErrors((state) => state.filter((item) => item !== error))
  }

  const hasErrors = errors && errors.length > 0

  return (
    <FieldContainer>
      <FieldLabel error={hasErrors}>{`Name`}</FieldLabel>
      <Input
        value={localState}
        onChange={onChange}
        error={hasErrors}
        autocomplete="off"
        ref={ref}
        {...defaultProps}
      />
      {isPresent(errors) && (
        <ErrorList
          haystack={haystack}
          needles={errors}
          mb={16}
          callback={closeError}
        />
      )}
    </FieldContainer>
  )
})

export { ExerciseEditorName }
