import React from 'react'
import styled, { css } from 'styled-components'
import { mtFn, mbFn } from '@common/Theme'
import { plFn, prFn } from '@common/Theme'
import PropTypes from 'prop-types'

const CopyGroup = styled.div`
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 0px;
  margin-top: 16px;
  margin-bottom: 16px;
  ${mtFn};
  ${mbFn};
  ${prFn};
  ${plFn};

  ${(props) =>
    props.reset &&
    css`
      padding-left: 0;
      padding-right: 0;
    `};
  ${(props) => props.extend};
`

const CopyProvider = ({
  children,
  mb,
  mt,
  pl,
  pr,
  className,
  extend,
  config = {},
  ...props
}) => {
  const hasLength = children && children.length && children.length > 1
  return (
    <CopyGroup
      className={className ? className : 'Copy__Provider'}
      hasBorderRadius={config.hasBorderRadius}
      hasMargins={config.hasMargins}
      hasLength={hasLength}
      mt={mt}
      pl={pl}
      pr={pr}
      mb={mb}
      extend={extend}
      reset={config.reset}
      {...props}
    >
      {children}
    </CopyGroup>
  )
}

CopyProvider.defaultProps = {
  className: '',
  config: {},
  mb: 0,
  mt: 0,
}

CopyProvider.propTypes = {
  className: PropTypes.string,
  config: PropTypes.shape({
    hasBorderRadius: PropTypes.bool,
    hasMargins: PropTypes.bool,
  }),
  mb: PropTypes.number,
  mt: PropTypes.number,
}

export { CopyProvider }
