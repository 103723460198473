import React, { memo, useContext } from 'react'
import { useDispatch } from 'react-redux'
import { keys } from 'ramda'

import { Icon } from '@common/Icon'
import { useSelector } from '@hooks/useSelector'

import {
  exerciseSelector,
  deleteExerciseImage,
  deleteAndUpdateExerciseImage,
} from '@state/exercise'

import {
  Context as ExerciseUploaderContext,
  Types as ExerciseUploaderTypes,
} from '../ExerciseUploaderProvider'

import {
  ImageContainer,
  ImageGroup,
  ImageItem,
  ImageButton,
  ImageControls,
  Image,
  UploaderIcon,
  UploaderContent,
  UploaderLabel,
} from './ExerciseUploaderImages.styled'

const UploaderPlaceholder = ({ onChange }) => (
  <UploaderLabel className="custom-file-upload">
    <UploaderIcon>
      <Icon name="IMAGE" size={20} stroke="#838A9B" fill="#838A9B" />
    </UploaderIcon>
    <UploaderContent>Add Photos</UploaderContent>
    <input type="file" accept="image/*" onChange={onChange} />
  </UploaderLabel>
)

const areEqual = (prevProps, nextProps) => {
  if (prevProps.localImages !== nextProps.localImages) {
    return false
  }
  return true
}

const ExerciseUploaderImages = memo(
  ({ localImages = [], callback = () => {} }) => {
    const [_, localDispatch] = useContext(ExerciseUploaderContext)

    const dispatch = useDispatch()
    const activeExercise = useSelector(exerciseSelector.activeExercise)
    const onRemove = (item) => {
      const { uid } = item
      localDispatch({
        type: ExerciseUploaderTypes.REMOVE_IMAGE,
        data: uid,
      })
      if (activeExercise && keys(activeExercise).length > 0) {
        dispatch(deleteAndUpdateExerciseImage(uid))
        return
      }
      dispatch(deleteExerciseImage(uid))
    }

    const renderImages = () => {
      if (!localImages || localImages.length <= 0) return null

      return (
        <ImageGroup>
          {localImages.map((item) => {
            let path = URL.createObjectURL(item.preview)
            return (
              <ImageItem key={item.uid}>
                <ImageControls>
                  <ImageButton
                    className="ImageButton"
                    onClick={() => onRemove(item)}
                  >
                    <Icon name="REMOVE" stroke="#121B2D" size={20} />
                  </ImageButton>
                </ImageControls>
                <Image imageSrc={path} />
              </ImageItem>
            )
          })}
          {!localImages ||
            (localImages.length <= 1 && (
              <UploaderPlaceholder onChange={callback} />
            ))}
        </ImageGroup>
      )
    }
    return <ImageContainer>{renderImages()}</ImageContainer>
  },
  areEqual,
)

export { ExerciseUploaderImages }
