import styled, { css } from 'styled-components';
import {
  basicFunctions,
  basicStyles,
  pseudoStyles,
  basicText,
  basicIconStyles,
} from './buttonStyles';
import { mbFn, mtFn, mlFn, mrFn } from '@common/Theme';

const SecondaryStyle = styled.button`
  ${basicStyles};
  ${basicText};
  ${pseudoStyles}
  ${basicFunctions};
  ${basicIconStyles};
  ${mtFn};
  ${mbFn};
  padding: 17px 16px;
  background: #ff5a45;
  border-radius: 8px;
  font-weight: 500;
  transition: all 0.2s ease-in-out;
  color: white;
  &:hover {
    background: #ff5a45;
    box-shadow: 0px 2px 8px rgba(209, 48, 28, 0.2),
      0px 5px 12px rgba(209, 48, 28, 0.06);
  }
`;

const Secondary = ({ style, children, ...props }) => (
  <SecondaryStyle style={style} {...props}>
    {children}
  </SecondaryStyle>
);

export { Secondary };
