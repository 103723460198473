import { getEnvironment } from '../utils'

const { baseURL } = getEnvironment()

import {
  EXERCISES_FETCH_SUCCESS,
  EXERCISES_CREATE_SUCCESS,
  EXERCISES_UPDATE_SUCCESS,
  EXERCISES_DELETE_SUCCESS,
  EXERCISES_CREATE_IMAGE_SUCCESS,
  EXERCISES_BUILDER_FETCH_SUCCESS,
} from './actionTypes'

const API = {
  [EXERCISES_FETCH_SUCCESS]: {
    endpoint: `${baseURL}/aima/exercise/v2?sortBy=exerciseId&sortOrder=DESC&fetchForCurrentUser=true`,
    method: 'GET',
    headers: ['AUTH', 'JSON'],
  },
  [EXERCISES_BUILDER_FETCH_SUCCESS]: {
    endpoint: `${baseURL}/aima/exercise/builder-fetch-all?&pageNo=0&sortBy=exercise_name&sortOrder=ASC&pageSize=10`,
    method: 'GET',
    headers: ['AUTH', 'JSON'],
  },
  [EXERCISES_CREATE_SUCCESS]: {
    endpoint: `${baseURL}/aima/exercise`,
    method: 'POST',
    headers: ['AUTH', 'JSON'],
  },
  [EXERCISES_UPDATE_SUCCESS]: {
    endpoint: `${baseURL}/aima/exercise`,
    method: 'PUT',
    headers: ['AUTH', 'JSON'],
  },
  [EXERCISES_DELETE_SUCCESS]: {
    endpoint: `${baseURL}/aima/exercise`,
    method: 'DELETE',
    headers: ['AUTH', 'JSON'],
  },
  [EXERCISES_CREATE_IMAGE_SUCCESS]: {
    endpoint: `${baseURL}/aima/exercise/file-upload`,
    method: 'POST',
    headers: ['AUTH', 'IMAGE'],
  },
}

export { API }
