import styled, { css } from 'styled-components'
import { colors, mbFn, mtFn } from '@common/Theme'

const FieldLabel = styled.div`
  color: white;
  font-weight: 300;
  font-size: 14px;
  padding-bottom: 8px;
  color: #b2b2b2;
  line-height: 20px;
  span {
    font-size: 11px;
    color: white;
  }
`

const InputContainer = styled.div`
  position: relative;
  margin-top: 1px;
  width: 100%;
  ${(props) =>
    props.type &&
    props.type === 'number' &&
    css`
      input {
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
        }
      }
    `};
`

const InputClose = styled.button`
  width: 24px;
  height: 24px;
  position: absolute;
  padding: 0;
  margin: 0;
  border: none;
  display: flex;
  background: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
  right: 16px;
  outline: none;
  border: 1px solid transparent;
  transition: all 0.2s ease-in-out;
  opacity: 0;
  &:hover {
    opacity: 1;
    background: rgb(76, 58, 238);
    border: 1.5px solid rgb(76, 58, 238);
  }
`

const srOnly = css`
  outline: none;
  &:active {
    &:after {
      display: none;
    }
  }
  &:focus-visible {
    outline: none;
    &::placeholder {
      color: transparent;
    }
  }
`

const InputElement = styled.input`
  outline: none;
  border-radius: 6px;
  background: white;
  font-size: 16px;
  padding: 13px 10px 12px 11px;
  color: black;
  width: 100%;
  appearance: none;
  box-sizing: border-box;
  border: 1px solid rgb(227, 229, 235);
  position: relative;
  transition: all 0.2s ease-in-out;
  appearance: none;

  ${(props) =>
    props.isDate &&
    css`
      &[type='date']::-webkit-calendar-picker-indicator {
        display: none;
        -webkit-appearance: none;
      }
      background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="%23bbbbbb" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>');
      background-repeat: no-repeat;
      background-position: right 10px center;
    `};

  ${(props) =>
    props.isTime &&
    css`
      &[type='time']::-webkit-calendar-picker-indicator {
        display: none;
        -webkit-appearance: none;
      }
      background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="%23bbbbbb" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>');
      background-repeat: no-repeat;
      background-position: right 10px center;
    `};

  ${srOnly};

  &:focus {
    border: 1px solid rgb(153 154 160);
  }

  &::placeholder {
    color: #b2b2b2;
  }
  &:disabled {
    background: #1f2226;
    border: 1px solid rgb(153 154 160);
    color: #a9aeb9;
    cursor: not-allowed;
  }

  ${(props) =>
    props.isMatched &&
    css`
      border: 1px solid rgb(153 154 160);
      background: #282e32;
      color: #26a982;
      &:focus {
        border: 1px solid rgb(153 154 160);
        background: #282e32;
      }
    `};

  ${(props) =>
    props.isCopied &&
    css`
      border: 1px solid rgb(153 154 160);
      background: ${colors.green}
      &:focus {
        border: 1px solid rgb(153 154 160);
        background: ${colors.green}
      }
    `};

  ${(props) =>
    props.variant === 'warning' &&
    css`
      background: transparent;
      color: white;
      border: 1px solid ${colors.red};
      &:focus {
        border: 1.5px solid #d28533;
        box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.12);
      }
    `};

  ${mbFn};
  ${mtFn};
`

export { InputElement, FieldLabel, InputClose, InputContainer }
