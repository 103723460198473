import { mbFn, mtFn } from '@common/Theme'
import styled from 'styled-components'

const BackContainer = styled.button`
  outline: none;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  ${mbFn};
  ${mtFn};
  &:hover {
    color: black;
  }
  &:focus {
    outline: none;
    border: 0;
  }
`

const BackLabel = styled.span`
  font-size: 16px;
  margin-left: 8px;
  color: black;
  line-height: 21px;
  font-weight: 500;
`

export { BackContainer, BackLabel }
