import {
  EXERCISES_FETCH_SUCCESS,
  EXERCISES_UPDATE_SUCCESS,
  EXERCISES_CREATE_IMAGE_SUCCESS,
  EXERCISES_DELETE_SUCCESS,
  EXERCISES_UPDATE_IMAGE_SUCCESS,
  EXERCISE_SET_ACTIVE_SUCCESS,
  EXERCISES_CREATE_SUCCESS,
  EXERCISES_SET_FILTERS,
  EXERCISE_DELETE_IMAGE_SUCCESS,
  EXERCISE_CLEAR_IMAGE,
  EXERCISES_BUILDER_FETCH_SUCCESS,
} from '../actionTypes'

/**
 *
 * @param  payload : {uid : "", imageId : ""} ,
 * @param state : activeExerciseObject
 * @returns
 */
export const updateExerciseImageSuccess = ({ payload, state }) => {
  return {
    type: EXERCISES_UPDATE_IMAGE_SUCCESS,
    payload: {
      activeExercise: {
        ...state,
        images: state.images
          ? [...state.images, payload.imageId]
          : [payload.imageId],
      },
      imageId: payload,
    },
  }
}

export const fetchExercisesSuccess = (exercises) => ({
  type: EXERCISES_FETCH_SUCCESS,
  payload: exercises,
})

export const fetchBuilderExercisesSuccess = (exercises) => ({
  type: EXERCISES_BUILDER_FETCH_SUCCESS,
  payload: exercises,
})

export const updateExerciseSuccess = ({ payload, state }) => {
  const derivedPayload = state.map((m) => {
    if (!!m && m.exerciseId === payload.exerciseId) {
      return payload
    }
    return m
  })
  return {
    type: EXERCISES_UPDATE_SUCCESS,
    payload: { activeExercise: payload, exercises: derivedPayload },
  }
}

export const createExerciseImageSuccess = ({ payload }) => ({
  type: EXERCISES_CREATE_IMAGE_SUCCESS,
  payload: payload,
})

export const deleteExerciseImageSuccess = ({ payload, state }) => {
  const newImages = state.filter((el) => el.uid !== payload)
  return { type: EXERCISE_DELETE_IMAGE_SUCCESS, payload: newImages }
}

export const createExerciseSuccess = ({ payload, state }) => ({
  type: EXERCISES_CREATE_SUCCESS,
  payload: [payload, ...state],
})

export const deleteExerciseSuccess = ({ payload, state }) => {
  const updatedExercisesList = state.filter((el) => el.exerciseId !== payload)
  return {
    type: EXERCISES_DELETE_SUCCESS,
    payload: updatedExercisesList,
  }
}

export const setActiveExercise = (payload) => ({
  type: EXERCISE_SET_ACTIVE_SUCCESS,
  payload,
})

export const setExerciseFilters = (payload) => ({
  type: EXERCISES_SET_FILTERS,
  payload,
})

export const clearImageIds = () => ({ type: EXERCISE_CLEAR_IMAGE })
