import styled, { css } from 'styled-components'
import { media } from '@common/Theme'
const transitionEase = '.3s all ease-in-out'

const config = {
  bladeContainer: {
    maxWidth: `392px`,
    maxWidthInner: `380px`,
  },
  bladeHidden: {
    maxWidth: `392px`,
  },
  bladeStyled: {
    maxWidth: `380px`,
  },
}

const BladeContainer = styled.div`
  height: 100vh;
  display: none;
  width: 100%;
  overflow: hidden;
  max-width: 10px;
  transition: ${transitionEase};
  height: 100vh;
  min-height: 100vh;
  z-index: 1;
  position: relative;
  ${media.xs`
    display: none;
  `};
  ${media.sm`
    display: flex;
  `};
  ${(props) =>
    props.isExpanded &&
    css`
      max-width: ${config.bladeContainer.maxWidth};
      width: 100%;
      position: fixed;
      ${media.sm`
        position: relative;
      `}
    `};
`

const BladeHidden = styled.div`
  position: relative;
  max-width: ${config.bladeHidden.maxWidth};
  min-width: 200px;
  width: 100%;
  background: white;
  height: 100vh;
  ${(props) =>
    props.isLoading &&
    css`
      transition: none;
    `}
  ${(props) =>
    props.bladeExpanded &&
    css`
      max-width: ${config.bladeHidden.maxWidth};
    `};
`

const BladeStyled = styled.div`
  max-width: ${config.bladeStyled.maxWidth};
  min-width: 200px;
  width: 100%;
  border-radius: 8px;
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 12px;
  box-shadow: 0px 2px 4px rgba(28, 17, 44, 0.04),
    0px 5px 12px rgba(28, 17, 44, 0.06);
  border-radius: 8px;
  background: white;
  transition: height 0.3s ease-in-out;
  height: calc(100vh - 24px);
  overflow-y: hidden;
  ${media.sm`
  max-width: ${config.bladeStyled.maxWidthInner};
  min-width: 200px;
  `}
  ${(props) =>
    props.height &&
    css`
      height: ${props.height - 50}px;
    `};
`

const BladeKnob = styled.div`
  width: 6px;
  height: 30px;
  background: #a9aeb9;
  border-radius: 21px;
  margin-right: 8px;
  top: 50%;
  transform: translate(0, -50%);
  position: absolute;
  transition: all 0.2s ease-in-out;
`

const BladeClickable = styled.div`
  width: 24px;
  transform: translate(-14px, 0%);
  position: fixed;
  height: calc(100vh - 12px);
  cursor: pointer;
  transition: ${transitionEase};
  ${(props) =>
    props.isOnTop &&
    css`
      z-index: 10;
    `};
  &:hover {
    background: transparent;
    .knob {
      transform: translate(-4px, -50%);
    }
  }
`

const BladeHeader = styled.div`
  padding: 24px 16px 12px 16px;
`

const BladeControls = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`

const BladeContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  ${(props) =>
    props.variant.toUpperCase() === 'EXERCISE' &&
    css`
      width: 100%;
    `}
  ${(props) =>
    props.variant.toUpperCase() === 'WORKOUT' &&
    css`
      width: 100%;
    `}
  ${(props) =>
    props.variant.toUpperCase() === 'WORKOUT_BUILDER' &&
    css`
      width: 100%;
    `}
`

const BladeButton = styled.button`
  border: none;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: none;
  outline: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  height: 24px;
  width: 24px;
  border: 1px solid #e9ebf1;
  border-radius: 4px;
`

const BladeTitle = styled.div`
  font-weight: 500;
  line-height: 16px;
  padding-top: 4px;
  font-size: 16px;
  padding-bottom: 4px;
`

export {
  BladeContainer,
  BladeHidden,
  BladeStyled,
  BladeKnob,
  BladeContent,
  BladeClickable,
  BladeHeader,
  BladeControls,
  BladeButton,
  BladeTitle,
}
