import styled, { css } from 'styled-components';

const PillGroup = styled.ul`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  padding-left: 1px;
`;

const shadowGenerator = (color = '', borderWidth = 1) => css`
  box-shadow: 0px 0px 0px ${borderWidth}px ${color};
`;

const PillItem = styled.li`
  width: max-content;
  display: inline-flex;
  background-color: transparent;
  margin-right: 6px;
  margin-left: 6px;
  margin-top: 6px;
  margin-bottom: 6px;
  border-radius: 6px;
  user-select: none;
  ${shadowGenerator(`#E3E5EB`, 1)};
  &:last-child {
    margin-right: 0;
  }
  &:hover {
    cursor: pointer;
    background-color: #ffffff;
    ${shadowGenerator(`#E3E5EB`, 2)};
    .tag__text {
      color: #a9aeb9;
      font-size: 12px;
    }
  }
  ${props =>
    props.isActive &&
    css`
      background-color: #ffffff;
      ${shadowGenerator(`#121b2d`, 2)};
      .tag__text {
        color: #121b2d;
        font-size: 12px;
      }
      &:hover {
        background-color: #f2f2f2;
        ${shadowGenerator(`#121b2d`, 2)};
        .tag__text {
          font-size: 12px;
          color: #121b2d;
        }
      }
    `}
`;

const PillText = styled.span.attrs({ className: 'tag__text' })`
  color: #778092;
  line-height: 16px;
  padding: 6px 8px;
  font-weight: 500;
  max-width: 250px;
  width: 100%;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
`;

const PillContainer = styled.div``;

export { PillGroup, PillItem, PillText, PillContainer };
