import { call, put, select } from 'redux-saga/effects'
import { prop } from 'ramda'
import {
  requestFetchPreferences,
  requestSaveExerciseBookmark,
  requestRemoveExerciseBookmark,
  requestFetchMe,
  requestUpdateUser,
  requestCreateUserImage,
  requestValidateChannel,
} from './requests'
import {
  saveExerciseBookmarkSuccess,
  saveExerciseBookmarkFailure,
  removeExerciseBookmarkSuccess,
  removeExerciseBookmarkFailure,
  fetchPreferencesSuccess,
  fetchPreferencesFailure,
  fetchMeSuccess,
  fetchMeFailure,
  updateUserSuccess,
  updateUserFailure,
  createUserImageFailure,
  fetchMe,
  createUserImageSuccess,
  validateChannelSuccess,
  validateChannelFailure,
} from './actions'

import { user } from './selectors'

export function* onFetchPreferences() {
  try {
    const response = yield call(requestFetchPreferences)
    if (!!response && response.data)
      yield put(fetchPreferencesSuccess(response.data.content))
  } catch (error) {
    yield put(fetchPreferencesFailure(error))
  }
}

export function* onFetchMe() {
  try {
    const response = yield call(requestFetchMe)
    if (response && response.data) {
      yield put(fetchMeSuccess(response.data))
    } else {
      yield put(fetchMeFailure('Failed to fetch user'))
    }
  } catch (error) {
    yield put(fetchMeFailure(error))
  }
}

export function* onUpdateUser({ payload }) {
  try {
    const response = yield call(requestUpdateUser, payload)
    if (response && response.data) {
      yield put(updateUserSuccess(response.data))
    } else {
      yield put(updateUserFailure('Failed to update user'))
    }
  } catch (error) {
    yield put(updateUserFailure(error))
  }
}
export function* onValidateChannel({ payload }) {
  try {
    const response = yield call(requestValidateChannel, { params: payload })
    if (response && response.data) {
      yield put(validateChannelSuccess(response.data))
    } else {
      yield put(validateChannelFailure('Failed to update user'))
    }
  } catch (error) {
    yield put(validateChannelFailure(error))
  }
}

export function* onCreateUserImage({ payload: { rawImage, variant } }) {
  try {
    const response = yield call(requestCreateUserImage, rawImage)
    if (response && response.data) {
      const activeUser = yield select(user)
      const validateImages = !!prop('images')(activeUser)
        ? prop('images')(activeUser)
        : []

      const payload = {
        images: [
          ...validateImages,
          {
            id: response.data.imageid,
            label: variant || 'Image',
            variant: variant,
          },
        ],
      }

      try {
        const response = yield call(requestUpdateUser, payload)
        if (response && response.data) {
          yield put(createUserImageSuccess(response.data))
        } else {
          yield put(createUserImageFailure('error with saving user'))
        }
      } catch (error) {
        yield put(updateUserFailure(error))
      }
    }
  } catch (error) {
    yield put(createUserImageFailure(error))
  }
}

export function* onSaveExerciseBookmark({ payload }) {
  try {
    const response = yield call(requestSaveExerciseBookmark)
    if (!!response && response.data)
      yield put(saveExerciseBookmarkSuccess(response.data.content))
  } catch (error) {
    yield put(saveExerciseBookmarkFailure(error))
  }
}

export function* onRemoveExerciseBookmark() {
  try {
    const response = yield call(requestRemoveExerciseBookmark)
    if (!!response && response.data)
      yield put(removeExerciseBookmarkSuccess(response.data.content))
  } catch (error) {
    yield put(removeExerciseBookmarkFailure(error))
  }
}
