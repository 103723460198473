import styled from 'styled-components'
import { colors, media, widthFn } from '@common/Theme'
import { allDefaultStyles } from './utils'

const Black = styled.button`
  ${allDefaultStyles};
  background: rgb(18, 27, 45);
  border: 1px solid rgb(18, 27, 45);
  border-radius: 8px;
  padding: 12px 16px;
  transition: background 0.3s;

  ${widthFn};
  &:hover {
    background: rgb(18 27 45 / 90%);
    border: 1px solid rgb(18, 27, 45);
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 8px,
      rgba(209, 48, 28, 0.06) 0px 5px 12px;
  }
  span {
    color: white;
    font-weight: 500;
    font-size: 14px;
    width: 100%;
    line-height: 21px;
    &.hidden {
      display: none;
      ${media.sm`
        display: inline;
      `};
    }
    &.hidden-sm {
      display: inline;
      ${media.sm`
        display: none;
      `};
    }
  }

  svg.WEIGHT {
    path {
      stroke: ${colors.white};
      fill: ${colors.white};
    }
    rect {
      stroke: ${colors.white};
      fill: ${colors.white};
    }
  }
  svg.PLAY {
    path {
      stroke: ${colors.white};
      fill: ${colors.white};
    }
  }
  svg.CHEVRON {
    path {
      fill: ${colors.white};
    }
  }
  svg.HISTORY {
    path {
      stroke: ${colors.white};
    }
  }
  svg.REFRESH {
    path {
      stroke: none;
      fill: ${colors.white};
    }
    rect {
      stroke: none;
      fill: ${colors.white};
    }
  }
  ${(props) => props.extend};
`

export { Black }
