import styled, { css } from 'styled-components'
import { Shadows, media, mtFn, mbFn, bgColorFn, heightFn } from '@common/Theme'

const Background = styled.div`
  background: #eafefc;
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
  height: 100%;
`

const Center = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  width: 100%;
  box-sizing: border-box;
  ${(props) =>
    props.isEnabled &&
    css`
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, calc(-50% + 60px));
    `};
`

const Block = styled.div`
  position: relative;
  width: 100%;
  box-sizing: border-box;
  padding: 16px;
  border-radius: 8px;
  background: #e8aea2;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  ${mtFn};
  ${mbFn};
  ${bgColorFn};
  ${heightFn};
  ${(props) =>
    props.zIndex &&
    css`
      position: fixed;
      bottom: 20px;
      left: -50%;
      transform: translate(50%);
      width: 100%;
      z-index: ${props.zIndex};
    `}
`

const Container = styled.div.attrs({ className: 'Block__Container' })`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  padding: 24px;
  border-radius: 8px;
  background: white;
  box-sizing: border-box;
  ${(props) =>
    props.shadow &&
    css`
      box-shadow: ${Shadows.blockShadow};
    `};
  ${mtFn};
  ${mbFn};
  ${(props) =>
    props.column &&
    css`
      flex-direction: column;
      box-sizing: border-box;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      padding: 16px 16px 16px 16px;
    `}
`

const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 24px;
  background: #ffffff;
  box-shadow: ${Shadows.blockShadow};
  border-radius: 8px;
  ${mtFn};
  ${mbFn};
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  box-sizing: border-box;
  ${(props) => {
    const { height } = props
    return css`
      max-height: ${height};
      height: ${height};
    `
  }}
`

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 16px;
  border-radius: 6px 6px 0 0;
  border: 1px solid #e3e5eb;
  background: rgb(18, 27, 45);

  ${bgColorFn};
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  padding: 16px;
  border-radius: 0;
  background: #ffffff;
  border-left: 1px solid #e3e5eb;
  border-right: 1px solid #e3e5eb;
  ${bgColorFn};
`

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 16px;
  border-radius: 0 0 6px 6px;
  border-top: 1px solid #e3e5eb;
  border-bottom: 1px solid #e3e5eb;
  border-right: 1px solid #e3e5eb;
  border-left: 1px solid #e3e5eb;
  background: #f9fafc;
  ${bgColorFn};
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  ${(props) =>
    props.hasBorder &&
    css`
      border: 1px solid #e3e5eb;
    `};
  ${(props) =>
    props.shadow &&
    css`
      box-shadow: rgb(0 0 0 / 20%) 0px 2px 8px,
        rgba(209, 48, 28, 0.06) 0px 5px 12px;
    `};
  ${(props) =>
    props.borderRadius &&
    css`
      border-radius: 8px;
    `};
  ${(props) =>
    props.padding &&
    css`
      padding: 24px 24px 32px 24px;
    `};
  ${mtFn};
  ${mbFn};
  ${(props) => {
    const { height } = props
    return css`
      max-height: ${height};
      height: ${height};
    `
  }};
`

const Account = styled.div`
  .BladeMenu {
    margin-left: 0px;
    margin-top: 24px;
    ${media.sm`
        margin-left: 24px;
        margin-top:32px;
        `};
    ${media.md`
        margin-left: 32px;
        margin-top:48px;
    `};
  }
  .BladeMenu {
    .BladeBlock {
      padding-right: 8px;
      ${media.sm`
        padding-right: 32px;
        `};
      ${media.md`
        padding-right: 48px;
    `};
    }
  }
`

const Flex = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  ${(props) =>
    props.justify &&
    css`
      justify-content: ${props.justify};
    `};
  ${(props) =>
    props.align &&
    css`
      align-items: ${props.align};
    `};
  ${mtFn};
  ${mbFn};
`

export {
  Flex,
  Row,
  Footer,
  Header,
  Container,
  Column,
  Content,
  Block,
  Center,
  Box,
  Account,
  Background,
}
