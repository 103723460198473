import React from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from '@hooks/useSelector'

import { prop } from 'ramda'
import PropTypes from 'prop-types'
import { Icon } from '@common/Icon'

import {
  saveExerciseBookmark,
  removeExerciseBookmark,
  userSelector,
} from '@state/user'

import {
  FromIconContainer,
  ExerciseItemContainer,
  ExerciseRowContainer,
  ExerciseName,
  ExerciseImage,
  ExerciseControls,
} from './ExerciseItem.styled'

const DOWNLOAD_IMAGE_URL =
  'https://aima-exercise-images.s3.ap-southeast-2.amazonaws.com/'

const imageConfig = (type) =>
  ({
    THUMB: `/assets/exercises/thumb.jpg`,
  }[type || 'THUMB'])

// LOCAL UTILS
const getFormattedMusclesName = (array = []) => {
  const formattedMuscles = array.map((n) => prop('muscleName')(n))
  return formattedMuscles.join(', ')
}

const getMatchingFavourites = (id, favourites = []) => {
  return favourites.find((el) => prop('id')(el) === id)
}

const isHidden = (hide) => {
  const hideItems = ['EDIT', 'DOWNLOAD']
  return !hideItems.includes(hide)
}

const ExerciseItem = ({
  onSelect,
  onDelete,
  onEdit,
  isActive,
  isBladeExpanded,
  exerciseName,
  exerciseId,
  ...props
}) => {
  const dispatch = useDispatch()

  // DESTRUCTURE
  const image = prop('images')(props)
  const primaryMuscles = prop('primaryMuscles')(props)
  const author = prop('author')(props)

  const onDownloadClicked = () => {}

  // const favourites = useSelector(userSelector.favourites)
  const loading = useSelector(userSelector.loading)
  // const isBookmarked = getMatchingFavourites(exerciseId, favourites)

  const onFavouriteClicked = (e, isBookmarked) => {
    e.stopPropagation()
    if (loading) return
    if (!exerciseId) return
    if (isBookmarked) {
      dispatch(removeExerciseBookmark({ payload: exerciseId }))
    } else {
      dispatch(saveExerciseBookmark({ payload: exerciseId }))
    }
  }

  return (
    <ExerciseItemContainer onClick={onSelect} isActive={isActive}>
      <ExerciseRowContainer className="image">
        {image && image[0] ? (
          <ExerciseImage imageSrc={`${DOWNLOAD_IMAGE_URL}${image[0]}`} />
        ) : (
          <ExerciseImage imageSrc={imageConfig('THUMB')} />
        )}
        {exerciseName && (
          <ExerciseName color="#1D2A43">{exerciseName}</ExerciseName>
        )}
      </ExerciseRowContainer>
      <ExerciseRowContainer className="muscles">
        {primaryMuscles && primaryMuscles[0] && (
          <ExerciseName color="#A9AEB9">
            {getFormattedMusclesName(primaryMuscles)}
          </ExerciseName>
        )}
      </ExerciseRowContainer>
      {author && (
        <ExerciseName color="#A9AEB9" className="author">
          AIMA
        </ExerciseName>
      )}
      <ExerciseControls className="controls" isActive={isBladeExpanded}>
        {isHidden('EDIT') && (
          <FromIconContainer onClick={onEdit}>
            <Icon name="EDIT" size={20} />
          </FromIconContainer>
        )}
        {isHidden('DOWNLOAD') && (
          <FromIconContainer className="DOWNLOAD" onClick={onDownloadClicked}>
            <Icon name="DOWNLOAD" size={20} />
          </FromIconContainer>
        )}
        <FromIconContainer onClick={onDelete}>
          <Icon name="REMOVE" size={20} />
        </FromIconContainer>
      </ExerciseControls>
    </ExerciseItemContainer>
  )
}

ExerciseItem.defaultProps = {
  onSelect: () => {},
  onDelete: () => {},
  onEdit: () => {},
}

ExerciseItem.propTypes = {
  onSelect: PropTypes.func,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
}

export { ExerciseItem }
