import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button } from '@common/Button'

const Controls = styled.div`
  display: flex;
  flex-direction: row;
  .cancel {
    margin-right: 8px;
  }
`

const ExerciseControlContainer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  padding-top: 16px;
  padding-right: 8px;
  flex-direction: column;
  background: white;
  padding-bottom: 16px;
  padding-right: 16px;
  padding-left: 16px;
  border-top: 1px solid #e3e5eb;
  z-index: 1;
`

const ExerciseControls = ({ onCancel, isValid, onSave }) => (
  <ExerciseControlContainer>
    <Controls>
      <Button
        variant="outline"
        onClick={onCancel}
        mb={16}
        className="cancel"
        type="reset"
      >
        <span>Clear</span>
      </Button>
      <Button
        variant="orange"
        mb={16}
        type="submit"
        onClick={onSave}
        disabled={!isValid}
        className="save"
      >
        <span>Save</span>
      </Button>
    </Controls>
  </ExerciseControlContainer>
)

ExerciseControls.propTypes = {
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
}

export { ExerciseControls }
