import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TabContainer, TabGroup } from './TabBar.styled';
import { toUpper } from 'ramda';

const TabBar = ({ children, aside, variant, active, className, ...props }) => {
  const [activeTab, setActiveTab] = useState(active);

  const config = {
    PRIMARY: 'PRIMARY',
    SECONDARY: 'SECONDARY',
    DEFAULT: 'DEFAULT',
  };

  const activeSetter = name => () => setActiveTab(name);

  const asTabItem = name => {
    const isActive = name === activeTab;
    const onClick = activeSetter(name);

    return {
      isActive,
      onClick,
      key: name,
    };
  };

  return (
    <TabContainer
      variant={config[toUpper(variant || 'DEFAULT')]}
      className={className}
    >
      <TabGroup
        variant={config[toUpper(variant || 'DEFAULT')]}
        className="TabGroup"
        {...props}
      >
        {children(asTabItem)}
        {aside}
      </TabGroup>
    </TabContainer>
  );
};

TabBar.propTypes = {
  aside: PropTypes.node,
};
export { TabBar };
