import React, { useState, useCallback, useEffect } from 'react'
import { loadState } from '@utils/storage'
import { useKeyboardShortcut } from '@hooks/useKeyboardShortcut'
import { Icon } from '@common/Icon'
import { Button } from '@common/Button'
import { CopyProvider, CopyValue } from '@common/CopyValue'
import { Modal } from '@common/Modal'

import { usePortal as Portal } from '@hooks/usePortal'
import { useDispatch } from 'react-redux'
import { useSelector } from '@hooks/useSelector'
import { Toggle } from '@common/Toggle'

import { fetchMe, userSelector } from '@state/user'
import { MultiSelect } from '@common/MultiSelect'
import { Line } from '@common/Line'
import { updateUser } from '@state/user/actions'

import { settingsSelector, updateDevMode } from '@state/settings'
import { validateItems } from './utils'
import { equals } from 'ramda'

import { ConfigContainer, TokenButton } from './Token.styled'

const modalConfig = {
  layout: {
    position: 'fixed',
    zIndex: 999,
  },
}

const Configurations = () => {
  const [state, setState] = useState({
    roles: configOptions,
  })

  const userRoles = useSelector(userSelector.roles)
  const isDevMode = useSelector(settingsSelector.isDevMode)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!userRoles || !userRoles.length) return
    const matchingRoles = configOptions.map((k) => ({
      ...k,
      active: userRoles.includes(k.value),
    }))

    setState((state) => ({
      ...state,
      roles: matchingRoles,
    }))
  }, [userRoles])

  const handleToggle = ({ name, value }) => {
    dispatch(updateDevMode(value))
  }

  const handleSelect = ({ name, value }) => {
    const mapRoles = value
      .map((k) => (!!k.active ? k.value : undefined))
      .filter((role) => !!role)

    const isValid = validateItems(mapRoles)

    if (!isValid) return

    setState((state) => ({
      ...state,
      [name]: value,
    }))

    if (equals(mapRoles, userRoles)) {
      console.warn('No changes detected')
      return
    }

    dispatch(updateUser({ roles: mapRoles }))
  }

  return (
    <ConfigContainer>
      <h3>Developer</h3>

      <MultiSelect
        name="roles"
        label="Adjust Roles"
        mt={16}
        callback={handleSelect}
        options={state.roles}
        config={{
          isCopyVariant: false,
          initialiseAll: false,
          showCheckbox: true,
          singleSelect: false,
          isToggleAllEnabled: false,
        }}
      />
      <Button
        variant="black"
        mt={16}
        label="Apply Changes"
        width="unset"
        onClick={() => location.reload()}
      >
        <Icon ml={5} name="REFRESH" fill="white" />
      </Button>

      <Toggle
        mt={16}
        isActive={isDevMode}
        onChange={handleToggle}
        label="Activate developer mode"
      />
    </ConfigContainer>
  )
}

const configOptions = [
  { id: 'a', label: 'Client', value: 'Client', active: true, disabled: true },
  {
    id: 'b',
    label: 'Personal Trainer',
    value: 'PersonalTrainer',
    active: false,
    disabled: false,
  },
]

const Token = () => {
  const [modal, setModal] = useState(false)
  const token = loadState('token')

  // the role will be an array of strings not a single string
  // I need to interpret the array of strings into a single string matching either 'Client' or 'PersonalTrainer'

  const userRoles = useSelector(userSelector.roles)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!!userRoles && !!userRoles.length) return
    dispatch(fetchMe())
  }, [])

  const handleCallback = ({ action }) => {
    if (action === 'CLOSE') {
      setModal(false)
    }
  }

  const keys = ['T', 'O']
  const handleKeyboardShortcut = useCallback(() => {
    setModal(true)
  }, [])

  useKeyboardShortcut(keys, handleKeyboardShortcut)

  return (
    <>
      {modal && (
        <Portal selector="#modal">
          <Modal callback={handleCallback} variant="TOKEN" config={modalConfig}>
            <CopyProvider>
              <CopyValue label="Copy Token" value={token} />
              <CopyValue label="Visa" value="4242424242424242" />
              <CopyValue label="MasterCard" value="5555555555554444" />
              <CopyValue label="American Express" value="378282246310005" />
            </CopyProvider>

            <Line mb={0} mt={32} />

            <Configurations />
          </Modal>
        </Portal>
      )}
      <TokenButton>
        <Button
          onClick={() => setModal(true)}
          variant="MENU"
          config={{ layout: { padding: '12px', marginTop: '8px' } }}
        >
          <span className="label">Developer</span>
          <Icon name="CODE" stroke="white" size={24} />
        </Button>
      </TokenButton>
    </>
  )
}

export { Token }
