import styled, { css } from 'styled-components'
import { media } from '@common/Theme'

const ellipses = css`
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const FromIconContainer = styled.button`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  margin-left: 15px;
  border: 1px solid transparent;
  padding: 0;
  background: none;
  height: 20px;
  width: 20px;
`

const ExerciseItemContainer = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  list-style: none;
  padding: 12px 16px 12px 12px;
  width: 100%;
  background: #ffffff;
  /* border: 1px solid transparent; */
  border: 1px solid rgb(227, 229, 235);
  box-shadow: 0px 2px 4px rgba(28, 17, 44, 0.04),
    0px 5px 12px rgba(28, 17, 44, 0.03);
  border-radius: 8px;
  margin: 8px 0px;
  user-select: none;
  cursor: pointer;
  box-sizing: border-box;
  transition: 0.1s ease-in-out;
  &:hover {
    border: 1px solid #a9aeb9;
    box-shadow: 0px 2px 4px rgba(28, 17, 44, 0.04),
      0px 5px 12px rgba(28, 17, 44, 0.03);
  }
  ${(props) =>
    props.isActive &&
    css`
      border: 1px solid #ff5a45;
      box-shadow: 0px 2px 4px rgba(28, 17, 44, 0.04),
        0px 5px 12px rgba(28, 17, 44, 0.06);
      &: hover {
        border: 1px solid #ff5a45;
        box-shadow: 0px 2px 4px rgba(28, 17, 44, 0.04),
          0px 5px 12px rgba(28, 17, 44, 0.06);
      }
    `}
  &:hover {
    div.ExerciseItem {
      opacity: 1;
    }
  }
`

const ExerciseRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  position: relative;
  width: ${(props) => props.width || '100%'};
  &.muscles {
    display: none;
    width: 30%;
    padding-right: 8px;
  }
  &.author {
    width: 10%;
  }
  &.image {
    width: 100%;
  }
  ${media.md`
    &.muscles {
      display: flex;
    }
  `}
`

const ExerciseName = styled.h4`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  margin: 0;
  padding: 0;
  color: ${(props) => props.color || '#1D2A43'};
  width: ${(props) => props.width || '100%'};
  ${ellipses};
  line-height: 30px;
`

const ExerciseImage = styled.div`
  ${(props) =>
    props.imageSrc &&
    css`
      width: 40px;
      height: 40px;
      min-width: 40px;
      min-height: 40px;
      border-radius: 6px;
      background-image: none;
      margin-right: 10px;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      background-image: ${`url(${props.imageSrc})`};
    `};
`

const ExerciseControls = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  min-width: 56px;
  visibility: ${(props) => props.visibility || 'visible'};
  &.controls {
    transition: 0.25s ease-in-out;
    ${(props) =>
      props.isActive &&
      css`
        display: none;
      `}
    ${media.md`
        ${(props) =>
          props.isActive &&
          css`
            display: flex;
          `}
    `}
  }

  button {
    &:first-child {
      margin-left: 0;
    }
  }
`

export {
  FromIconContainer,
  ExerciseItemContainer,
  ExerciseRowContainer,
  ExerciseName,
  ExerciseImage,
  ExerciseControls,
}
