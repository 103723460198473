import React from 'react';
import { Icon } from '@common/Icon';
import PropTypes from 'prop-types';
import {
  UploaderIcon,
  UploaderContent,
  UploaderLabel,
} from './ExerciseUploaderButton.styled';

const ExerciseUploaderButton = ({ onChange }) => (
  <UploaderLabel className="exercise-file-upload">
    <UploaderIcon>
      <Icon name="IMAGE_CROP" size={20} />
    </UploaderIcon>
    <UploaderContent>Add Photos</UploaderContent>
    <input type="file" accept="image/*" onChange={onChange} />
  </UploaderLabel>
);

ExerciseUploaderButton.propTypes = {
  onChange: PropTypes.func,
};

export { ExerciseUploaderButton };
