import ExerciseLibraryPage from '@pages/library/exercise'
import WorkoutLibraryPage from '@pages/library/workout'
import WorkoutBuilderPage from '@pages/library/workout/builder'

import { Provider } from 'react-redux'
import PrivateRoutes from '@components/PrivateRoutes'

import ClientsPage from '@pages/clients'
import DashboardPage from '@pages/dashboard'
import ValidatePage from '@pages/validate'
import LandingPage from '@pages/landing'
import AccountProfilePage from '@pages/account/profile'
import ProfilePage from '@pages/profile'
import NotFoundPage from '@pages/404'
import SettingsPage from '@pages/settings'
import PlayPage from '@pages/play'
import SystemPage from '@pages/system'
import StartPage from '@pages/start'
import SetupPage from '@pages/setup'

import { Account } from '@features/Account'

import { Theme } from '@common/Theme'
import store from '@state/store'
import { prop } from 'ramda'

import { Loading } from '@common/Loading'

import React, { useEffect } from 'react'
import { Route, BrowserRouter, Routes, useNavigate } from 'react-router-dom'
import { Auth0Provider } from '@auth0/auth0-react'
import { AUTH_CONFIG } from '@features/Auth/Service'

import { useAuth0 } from '@auth0/auth0-react'
import PrivateRoleRoutes from '@components/PrivateRoleRoutes'
import { saveState } from '@utils/storage'

if (!('process' in window)) {
  window.process = {}
}
const isDevelopment = process.env.NODE_ENV === 'development'

const SignupPage = () => {
  const { loginWithRedirect } = useAuth0()

  useEffect(() => {
    loginWithRedirect()
  }, [])

  return null
}

const Callback = () => {
  const { user, getAccessTokenSilently } = useAuth0()
  const navigate = useNavigate()
  const isEmailVerified = prop('email_verified')(user)

  useEffect(() => {
    const getUserMetadata = async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: AUTH_CONFIG.audience,
            scope: AUTH_CONFIG.scope,
          },
        })
        saveState({ token: accessToken })
      } catch (e) {
        navigate('/404')
      }
    }

    getUserMetadata()
  }, [getAccessTokenSilently])

  useEffect(() => {
    if (isEmailVerified !== undefined) {
      if (!isEmailVerified) {
        navigate('/validate')
      }
      navigate('/account/profile')
    }
  }, [isEmailVerified])

  return <Loading />
}

const Auth0ProviderWithRedirectCallback = ({ children, ...props }) => {
  const navigate = useNavigate()
  const onRedirectCallback = (appState) => {
    navigate((appState && appState.returnTo) || window.location.pathname)
  }
  return (
    <Auth0Provider onRedirectCallback={onRedirectCallback} {...props}>
      {children}
    </Auth0Provider>
  )
}

export default function App() {
  return (
    <Provider store={store}>
      <Theme>
        <BrowserRouter>
          <Auth0ProviderWithRedirectCallback
            domain={AUTH_CONFIG.domain}
            clientId={AUTH_CONFIG.clientId}
            authorizationParams={{
              scope: AUTH_CONFIG.scope,
              audience: AUTH_CONFIG.audience,
              redirect_uri: AUTH_CONFIG.redirectUri,
            }}
          >
            <Routes>
              <Route path="/" exact element={<LandingPage />}></Route>
              <Route path="/signup" exact element={<SignupPage />}></Route>
              <Route path="/callback" element={<Callback />}></Route>
              <Route path="/validate" exact element={<ValidatePage />}></Route>

              <Route element={<PrivateRoutes />}>
                <Route path="/dashboard" exact element={<DashboardPage />} />
                <Route path="/start" exact element={<StartPage />} />
                <Route path="/start/:startId" element={<PlayPage />}></Route>
                <Route path="/account" element={<Account />}>
                  <Route
                    path="/account/profile"
                    exact
                    element={<AccountProfilePage />}
                  />
                  <Route
                    path="/account/settings"
                    element={<SettingsPage />}
                  ></Route>
                  <Route
                    element={<PrivateRoleRoutes roles={['PERSONALTRAINER']} />}
                  >
                    <Route
                      path="/account/clients"
                      exact
                      element={<ClientsPage />}
                    />
                  </Route>
                </Route>

                <Route
                  element={<PrivateRoleRoutes roles={['PERSONALTRAINER']} />}
                >
                  <Route
                    path="/library/workout"
                    element={<WorkoutLibraryPage />}
                  ></Route>
                  <Route
                    path="/library/workout/:workoutId"
                    element={<WorkoutBuilderPage />}
                  ></Route>
                  <Route
                    path="/library/exercise"
                    element={<ExerciseLibraryPage />}
                  ></Route>
                </Route>
              </Route>
              {isDevelopment && (
                <Route path="/system" element={<SystemPage />}></Route>
              )}
              <Route path="/profile" element={<ProfilePage />} />
              <Route path="/setup" element={<SetupPage />}></Route>
              <Route path="/404" element={<NotFoundPage />} />
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </Auth0ProviderWithRedirectCallback>
        </BrowserRouter>
      </Theme>
    </Provider>
  )
}

export { App }
