import { useCallback, useEffect } from 'react';

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

const useSleep = (callback = () => {}, delay = 0) => {
  const handleCallback = useCallback(event => callback(event), []);

  useEffect(() => {
    const callTimer = async () => {
      if (!delay || delay <= 0) return;
      await sleep(delay);
      handleCallback();
    };
    callTimer();
  }, []);

  return handleCallback; // not really necessary but whatever
};

export { useSleep };
