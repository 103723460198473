import { call, put } from 'redux-saga/effects';
import {
  authenticate,
  authenticateSignin,
  authenticateSignup,
} from './requests';
import {
  authSuccess,
  authFailure,
  authSigninSuccess,
  authSigninFailure,
  authSignupSuccess,
  authSignupFailure,
} from './actions';

export function* onAuthenticate() {
  try {
    const response = yield call(authenticate);
    if (response && response.status === 'SUCCESS')
      yield put(authSuccess(response));
  } catch (error) {
    yield put(authFailure(error));
  }
}

export function* onAuthenticateSignin({ payload }) {
  try {
    const response = yield call(authenticateSignin, {
      email: payload.email,
      password: payload.password,
    });
    if (response && response.status === 'SUCCESS')
      yield put(authSigninSuccess(response));
  } catch (error) {
    yield put(authSigninFailure(error));
  }
}

export function* onAuthenticateSignup({ payload }) {
  try {
    const response = yield call(authenticateSignup, {
      email: payload.email,
      password: payload.password,
    });
    if (response && response.status === 'SUCCESS')
      yield put(authSignupSuccess(response));
  } catch (error) {
    yield put(authSignupFailure(error));
  }
}
