import React, { useState, useEffect } from 'react'

import { Tabs } from '@common/Tabs'
import { Text } from '@common/Text'
import { Icon } from '@common/Icon'
import { prop, values } from 'ramda'
import { Button } from '@common/Button'
import { Heading } from '@common/Heading'
import LogoIcon from '@features/Layout/Menu/MenuHeader/assets/Logo'
import LogoIconFull from '@features/Layout/Menu/MenuHeader/assets/LogoFull'
import { useNavigate, useLocation } from 'react-router-dom'
import { Controls } from '@common/Controls'
import { useAuth0 } from '@auth0/auth0-react'
import { AUTH_CONFIG } from '@features/Auth/Service'
import { clearState } from '@utils/storage'
import { Group, List } from '@common/List'
import { Grid } from '@common/Grid'
import { useBackgroundColor } from '@hooks/useBackgroundColor'
import { IconContainer } from '@common/IconContainer'

import {
  options,
  Navbar,
  Wrapper,
  Content,
  LogoContainer,
  FloatingBlock,
  CardGroup,
  Card,
  Footer,
} from './Landing.styled'

import styled, { css } from 'styled-components'
import { fontSizeFn, lineHeightFn, colors } from '@common/Theme'

const TextElement = styled.h1`
  ${lineHeightFn};
  ${fontSizeFn};
  font-weight: 600;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  background-image: linear-gradient(136deg, white 0%, ${colors.orange} 82%);
`

const Intro = () => {
  return (
    <TextElement size={32} lineHeight={48}>
      Target your goals with aima.
    </TextElement>
  )
}

const Landing = () => {
  const [activeId, setActiveId] = useState('profile')
  const navigate = useNavigate()
  const match = useLocation()
  const list = values(options)

  const { loginWithRedirect, isAuthenticated, logout } = useAuth0()

  const onBack = () => {
    navigate('/login')
  }

  const onLogout = () => {
    clearState('token')
    logout({
      logoutParams: {
        returnTo: AUTH_CONFIG.returnTo,
      },
    })
  }

  const setBackgroundColor = useBackgroundColor('whitesmoke')

  useEffect(() => {
    setBackgroundColor('whitesmoke')
    return () => setBackgroundColor('transparent')
  }, [])

  const handleTabChange = (value) => {
    setActiveId(value)
    console.log({ list })
    const lookup = list.find((route) => route.id.includes(value))
    navigate(lookup.route)
  }

  useEffect(() => {
    const matchedPath = match.pathname
    const lookup = list.find((tab) => matchedPath.includes(tab.value))
    if (!!lookup && prop('value')(lookup)) {
      setActiveId(lookup.value)
    }
  }, [match])

  const renderCards = () => (
    <CardGroup>
      <Card className="Trainer">
        <div className="Header Trainer">
          <Heading size={18} mb={0} mt={0} color="white">
            Trainer
          </Heading>
        </div>
        <div className="Content">
          <div className="Row">
            <div className="CardIcon">
              <Icon name="TROPHY" size={32} stroke={'black'} />
            </div>
            <Text
              size={32}
              lineHeight={48}
              weight="600"
              color="black"
              align="left"
            >
              $20 <span className="period">/mo</span>
            </Text>
          </div>
          <Text
            size={16}
            weight="600"
            color="black"
            align="left"
            mt={8}
            mb={16}
          >
            Premium personal training software.
          </Text>
          <Text size={14} weight="300" color="#121B2D" align="left">
            Build workouts, exercises and consume your own content. Track and
            manage your clients through a single platform.
          </Text>
          <Heading size={16} mt={24} mb={8} color="black">
            What's included
          </Heading>
          <Group gap={24} mt={8}>
            <List>
              <Icon name="CHECKBOX_FILLED" mr={5} />
              <Text size={14} weight="300" color="#121B2D" align="left">
                Earn money by selling your content
              </Text>
            </List>
            <List>
              <Icon name="CHECKBOX_FILLED" mr={5} />
              <Text size={14} weight="300" color="#121B2D" align="left">
                Share content with clients
              </Text>
            </List>
            <List>
              <Icon name="CHECKBOX_FILLED" mr={5} />
              <Text size={14} weight="300" color="#121B2D" align="left">
                Custom landing page URL
              </Text>
            </List>
            <List>
              <Icon name="CHECKBOX_FILLED" mr={5} />
              <Text size={14} weight="300" color="#121B2D" align="left">
                Build customised <strong>workouts</strong> and{' '}
                <strong>exercises</strong>
              </Text>
            </List>
            <List>
              <Icon name="CHECKBOX_FILLED" mr={5} />
              <Text size={14} weight="300" color="#121B2D" align="left">
                Access to all features
              </Text>
            </List>
            <List>
              <Icon name="CHECKBOX_FILLED" mr={5} />
              <Text size={14} weight="300" color="#121B2D" align="left">
                Build and consume your own content
              </Text>
            </List>
            <List>
              <Icon name="CHECKBOX_FILLED" mr={5} />
              <Text size={14} weight="300" color="#121B2D" align="left">
                Efficiently monitor and guide both your personal and client
                progress
              </Text>
            </List>
          </Group>
        </div>
        <div className="Footer">
          <Button
            variant="black"
            width="100%"
            className="trainer-button"
            onClick={() => navigate('/signup')}
          >
            <span>Get Started</span>
          </Button>
        </div>
      </Card>
      <Card>
        <div className="Header">
          <Heading size={18} mb={0} mt={0} color="white">
            Client
          </Heading>
        </div>
        <div className="Content">
          <div className="Row">
            <div className="CardIcon">
              <Icon
                name="WEIGHT_FILLED"
                size={32}
                fill="black"
                stroke={'black'}
              />
            </div>
            <Text
              size={32}
              lineHeight={48}
              weight="600"
              color="black"
              align="left"
            >
              $3 <span className="period">/mo</span>
            </Text>
          </div>

          <Text
            size={16}
            weight="600"
            color="black"
            align="left"
            mt={8}
            mb={16}
          >
            Workout at your leisure.
          </Text>
          <Text size={14} weight="300" color="#121B2D" align="left">
            Choose from a variety of workouts developed by <strong>AIMA</strong>
            , or choose a <strong>Trainer</strong> to help you on your journey.
          </Text>
          <Heading size={16} mt={24} mb={8} color="black">
            What's included
          </Heading>
          <Group gap={24} mt={8}>
            <List>
              <Icon name="CHECKBOX_FILLED" mr={5} />
              <Text size={14} weight="300" color="#121B2D" align="left">
                Access to all content on{' '}
                <a href="aima.fit">
                  <span className="link">aima.fit</span>
                </a>
              </Text>
            </List>
            <List>
              <Icon name="CHECKBOX_FILLED" mr={5} />
              <Text size={14} weight="300" color="#121B2D" align="left">
                Consume workouts curated by <strong>Trainers</strong> of your
                choice, using our unique fitness app
              </Text>
            </List>
          </Group>
        </div>
        <div className="Footer">
          <Button
            variant="orange"
            className="client-button"
            width="100%"
            onClick={() => navigate('/signup')}
          >
            <span>Get Started</span>
          </Button>
        </div>
      </Card>
    </CardGroup>
  )

  return (
    <Wrapper className="wrapper">
      <Navbar className="navbar">
        <LogoContainer className="Logo" onClick={() => navigate('/')}>
          <LogoIcon />
          <LogoIconFull />
        </LogoContainer>
        <Tabs
          callback={handleTabChange}
          options={list}
          active={activeId}
          config={{
            ml: 16,
          }}
          loading={false}
          showPanel={false}
        />
        <Controls className="Controls">
          {isAuthenticated && (
            <Button
              label="Dashboard"
              className="login-button"
              variant="TRANSPARENT"
              onClick={() => navigate('/dashboard')}
            >
              <IconContainer>
                <Icon name="DASHBOARD" ml={5} size={20} stroke="black" />
              </IconContainer>
            </Button>
          )}
          {!isAuthenticated && (
            <Button
              label="Login"
              className="login-button"
              variant="TRANSPARENT"
              onClick={() => loginWithRedirect()}
            />
          )}

          {!isAuthenticated && (
            <Button
              onClick={() => navigate('/signup')}
              label="Sign up"
              variant="orange"
              width={90}
              className="signup-button"
              alone
            ></Button>
          )}
          {isAuthenticated && (
            <Button
              onClick={() => onLogout()}
              label="Logout"
              variant="orange"
              width="unset"
              alone
            >
              <Icon name="LEAVE" size={20} ml={5} stroke="white" />
            </Button>
          )}
        </Controls>
      </Navbar>
      <Content className="content">
        <Grid>
          <FloatingBlock>
            {/* <Intro size={32} lineHeight={48} /> */}
            <Heading size={32} lineHeight={48}>
              Target your goals with aima.
            </Heading>
            <Icon name="PROGRAM" size={100} viewBox="0 0 40 40" mt={20} />
          </FloatingBlock>

          {renderCards()}
        </Grid>
      </Content>
      <Footer className="footer">
        <Text size={14} weight="300" color="#8e8e8e">
          Copyright 2024, all rights reserved.
        </Text>
      </Footer>
    </Wrapper>
  )
}

export { Landing }
