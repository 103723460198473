export default {
  content: [
    {
      id: 'default',
      label: 'Test Drag Workout 2',
      description: 'Test Description for Update1',
      videos: ['test-video-5'],
      images: ['4571531b-44f3-45ea-a661-9eee52ba7214'],
      workoutProtocol: [
        {
          id: 'Section_98423f94-45bb-4ea3-8cda-7a75abc6eb23 SECTION',
          label: 'Section',
          sequence: 0,
          type: 'section',
          children: [
            {
              id: 'Group_70f4c5b9-f338-4471-8fcd-7e028d5599c0 GROUP',
              label: 'Group',
              sequence: 0,
              variant: 'SUPERSET',
              type: 'group',
              children: [
                {
                  id: 'ADD_ACTIVITY/ed7baf79-d656-4dfd-81de-3354d0d6a75d/72637224-bbea-4b41-b8a4-c3b37750f195',
                  sequence: 0,
                },
                {
                  label: 'Leg Curls',
                  notes: 'Great core exercise | equipment is required',
                  videos: null,
                  image: 'LEG_CURLS',
                  variant: 'EXERCISE',
                  rest: 60,
                  index: 0,
                  sequence: 1,
                  id: 'ADD_ACTIVITY/abc/238f6ace-5d28-48d1-94ac-722d7df61e0d',
                },
              ],
            },
          ],
        },
        {
          id: 'Section A',
          label: 'Warmup',
          type: 'section',
          sequence: 1,
          children: [
            {
              id: 'Group_e1057bab-8b10-4f43-96d8-a41edc4cd35d GROUP',
              label: 'Group',
              sequence: 0,
              variant: 'SUPERSET',
              type: 'group',
              children: [],
            },
          ],
        },
      ],
      workoutNotes: 'test-video-5sdfa sdf ',
      isActive: true,
      isDeleted: false,
      createdAt: '2022-05-23T10:16:00.526155',
      updatedAt: '2022-06-12T01:47:57.48162',
      createdBy: {
        email: 'philiphultgren7@gmail.com',
        stripeCustomerId: 'cus_LfumKzA6MLt3Og',
        roles: ['Client', 'PersonalTrainer'],
        createdAt: '2022-05-23T09:15:12.851408',
        updatedAt: '2022-06-12T03:14:19.554777',
        isActive: true,
        isDeleted: false,
        stripeSetupIntentObject: {
          setupIntentId: 'seti_1L7uE6CTOd88lFo508BDsk9U',
          paymentMethodId: 'pm_1L7uE4CTOd88lFo5cfWe2kyX',
          setupIntentSecret:
            'seti_1L7uE6CTOd88lFo508BDsk9U_secret_LpZMmCh2eZ71gFAo7pfjuuO9ElTqurf',
        },
      },
      primaryMuscles: [
        {
          muscleId: 1,
          muscleName: 'Calves',
          isActive: true,
          isDeleted: false,
        },
        {
          muscleId: 2,
          muscleName: 'Hamstring',
          isActive: true,
          isDeleted: false,
        },
      ],
      equipments: [
        {
          equipmentId: 1,
          equipmentName: 'Powerbells',
          isActive: true,
          isDeleted: false,
        },
      ],
    },
  ],
  pageable: {
    sort: {
      empty: false,
      unsorted: false,
      sorted: true,
    },
    offset: 0,
    pageNumber: 0,
    pageSize: 10,
    paged: true,
    unpaged: false,
  },
  totalPages: 1,
  totalElements: 1,
  last: true,
  size: 10,
  number: 0,
  sort: {
    empty: false,
    unsorted: false,
    sorted: true,
  },
  numberOfElements: 1,
  first: true,
  empty: false,
}
