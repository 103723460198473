import styled, { css } from 'styled-components'

const BaseFieldLabel = css`
  line-height: 16px;
  font-size: 14px;
  font-weight: 500;
  font-family: 'IBM Plex Sans', sans-serif;
  color: #121b2d;
  display: block;
  margin: 0 0 8px 0;
`

const labelColorFn = ({ color }) =>
  color &&
  css`
    color: ${color || '#121B2D'};
  `

const Label = styled.span.attrs({ className: 'label' })`
  ${BaseFieldLabel};
  ${labelColorFn};
  ${(props) =>
    props.error &&
    css`
      color: #eb3d2b;
    `}
`

export { Label }
