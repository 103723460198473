import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import Error from '@pages/404'
import { useSelector } from '@hooks/useSelector'
import { userSelector } from '@state/user'
import { Loading } from '@common/Loading'

const AccessDenied = () => {
  return <Error />
}

const mapUpper = (arr) => {
  if (typeof arr === 'string') return []
  if (!arr || !arr.length) return []
  return arr.map((item) => !!item && item.toUpperCase())
}

function hasMatch(arrayA, arrayB) {
  const requiredRoles = mapUpper(arrayA)
  const currentRoles = mapUpper(arrayB)
  // Iterate through each element in arrayB
  return currentRoles.some((currRole) => requiredRoles.includes(currRole))
}

const PrivateRoleRoutes = ({ roles = [] }) => {
  const userRoles = useSelector(userSelector.roles)
  const userHasRequiredRole = hasMatch(userRoles, roles)

  if (!userRoles || !userRoles.length || !userHasRequiredRole) {
    return <Loading />
  }

  return <Outlet />
}

export default PrivateRoleRoutes
