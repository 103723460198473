import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { includes, prop } from 'ramda';
import { Label } from '@common/Label';

import {
  PillContainer,
  PillGroup,
  PillItem,
  PillText,
} from './PillSelector.styled';
import { usePrevious } from '@hooks/usePrevious';

const getActiveFilters = (options = [], activeOptions = [], variantId) => {
  return options.filter(upperFilter =>
    activeOptions.find(
      activeItem => prop(variantId)(activeItem) === prop(variantId)(upperFilter)
    )
  );
};

const PillSelector = ({
  id,
  label,
  labelColor,
  options,
  variantId,
  variantName,
  selectedOptions,
  onChange = () => {},
}) => {
  const [localFilters, setLocalFilters] = useState([]);
  const prevSelected = usePrevious(selectedOptions);

  const onSelect = selectedObj => {
    if (
      localFilters.some(
        el => prop(variantId)(el) === prop(variantId)(selectedObj)
      )
    ) {
      const filters = localFilters.filter(
        el => prop(variantId)(el) !== prop(variantId)(selectedObj)
      );
      setLocalFilters(filters);
      onChange(filters);
    } else {
      setLocalFilters(state => [...state, selectedObj]);
      onChange([...localFilters, selectedObj]);
    }
  };

  useEffect(() => {
    if (prevSelected !== selectedOptions) {
      setLocalFilters([
        ...getActiveFilters(options, selectedOptions, variantId),
      ]);
    }
  }, [selectedOptions]);

  const renderOptions = () => {
    if (!options || options.length <= 0) return null;
    return options.map(item => (
      <PillItem
        key={prop(variantName)(item)}
        isActive={includes(item)(localFilters)}
        onClick={() => onSelect(item)}
      >
        <PillText>{prop(variantName)(item)}</PillText>
      </PillItem>
    ));
  };

  return (
    <PillContainer id={id}>
      <PillGroup>
        {label && (
          <Label labelColor={labelColor} error={fieldError}>
            {label}
          </Label>
        )}
        {renderOptions()}
      </PillGroup>
    </PillContainer>
  );
};

PillSelector.defaultProps = {
  labelColor: '#121B2D',
  variantId: 'muscleId',
  variantName: 'muscleName',
};

PillSelector.propTypes = {
  id: PropTypes.string,
  variantId: PropTypes.string,
  variantName: PropTypes.string,
  selectedOptions: PropTypes.array,
  options: PropTypes.array,
};

export { PillSelector };
