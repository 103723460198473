import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { usePrevious } from '@hooks/usePrevious'
import styled from 'styled-components'
import { prop } from 'ramda'
import { mtFn, mbFn } from '@common/Theme'

import { usePortal as Portal } from '@hooks/usePortal'
import { useClickOutside as ClickOutside } from '@hooks/useClickOutside'
import { useEscape } from '@hooks/useEscape'
import { Overlay } from '@common/Overlay'
import { Button } from '@common/Button'
import { Icon } from '@common/Icon'
import { Heading } from '@common/Heading'

import { SyntaxPre, syntaxHighlight } from '../Syntax'

const Wrapper = styled.div`
  width: 100%;
  background: transparent;
  border: none;
  box-shadow: none;
  border-radius: 6px;
  padding: 0;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  &.Modal {
    overflow-y: auto;
    max-height: calc(100vh - 200px);
    margin-top: 24px;
  }
  ${mtFn};
  ${mbFn};
  pre {
    border-radius: 6px;
  }
`

const Modal = styled.div`
  padding: 24px;
  background: white;
  border-radius: 10px;
  box-shadow: 0px 4px 54px 0px rgba(0, 0, 0, 0.15);
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 800px;
  min-width: 400px;
  transform: translate(-50%, -50%);
  z-index: 999;
`

const Footer = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`

const ModalClose = styled.button`
  width: 24px;
  height: 24px;
  padding: 0;
  margin: 0;
  border: none;
  display: flex;
  background: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  svg {
    path {
      transition: all 0.2s ease-in-out;
    }
  }
  &:hover {
    svg {
      path {
        stroke: #e0e0e0;
      }
    }
  }
`

const ModalBlock = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  width: 100%;
`

const StateViewModal = ({ children, callback = () => {}, ...props }) => {
  const handleCallback = () => {
    callback({ action: 'CLOSE' })
  }

  useEscape(() => {
    callback({ action: 'CLOSE' })
  })

  return (
    <Portal selector="#modal">
      <ClickOutside callback={handleCallback}>
        <Modal>
          <ModalBlock>
            <Heading
              config={{
                text: {
                  color: '#000000',
                  fontSize: '24px',
                  textAlign: 'left',
                  lineHeight: '32px',
                  margin: '0',
                },
              }}
              variant="SECONDARY"
            >
              State Viewer
            </Heading>
            <ModalClose
              onClick={() => callback({ action: 'CLOSE' })}
              style={{ position: 'absolute', right: '24px', top: '24px' }}
            >
              <Icon name="CLOSE" size={20} />
            </ModalClose>
          </ModalBlock>
          <Wrapper className="StateView Modal" {...props}>
            {children}
          </Wrapper>
        </Modal>
      </ClickOutside>
      <Overlay level={1} isActive onClick={handleCallback} />
    </Portal>
  )
}

const StateView = ({ state, type, layout, theme, variant, ...props }) => {
  const [html, setHtml] = useState(null)
  const prevState = usePrevious(state)
  const [localState, setLocalState] = useState({
    isActive: false,
  })
  const { layout: layoutStyle } = prop('config')(props) || {}

  useEffect(() => {
    if (prevState !== state) {
      setHtml(syntaxHighlight(JSON.stringify(state, undefined, 4)))
    }
  }, [html, state])

  const createMarkup = () => {
    return {
      __html: html,
    }
  }

  const handleCallback = () => {
    setLocalState((state) => ({ ...state, isActive: false }))
  }

  const renderMarkup = () => {
    if (!html) return null
    return <SyntaxPre theme={theme} dangerouslySetInnerHTML={createMarkup()} />
  }

  return !!variant && variant.toUpperCase() === 'MODAL' ? (
    <>
      <Button
        mt={16}
        onClick={() => setLocalState((state) => ({ ...state, isActive: true }))}
      >
        Code View
      </Button>
      {localState.isActive && (
        <StateViewModal callback={handleCallback} style={layoutStyle}>
          {renderMarkup()}
        </StateViewModal>
      )}
    </>
  ) : (
    <Wrapper className="StateView" style={layoutStyle} {...props}>
      {renderMarkup()}
    </Wrapper>
  )
}

StateView.defaultProps = {
  state: {},
  theme: 'MONOKI',
  type: 'js',
}

StateView.propTypes = {
  state: PropTypes.object,
  theme: PropTypes.string,
  type: PropTypes.string,
}

export { StateView, syntaxHighlight }
