import styled, { css } from 'styled-components'
import { mtFn, mbFn, media } from '@common/Theme'

const ImageContainer = styled.div`
  display: flex;
`

const ImageGroup = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  position: relative;
  width: 100%;
  min-height: 160px;
  flex-direction: row;
  &.ImageGroup {
    &.Default {
      width: 100%;
      min-height: 160px;
    }
    &.Swap {
      height: 120px;
      width: 120px;
    }
  }
`

const ImageItem = styled.li`
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;
  &.ImageItem {
    &.Thumnail {
      width: 100%;
      height: 160px;
      .ImageElement {
        border-radius: 8px;
      }
    }
    &.Default {
      border-radius: 8px;
      .ImageElement {
        border-radius: 8px;
      }
    }
    &.Swap {
      width: 100%;
      height: 120px;
      .ImageElement {
        border-radius: 50%;
      }
    }
  }
`

const ImageButton = styled.button`
  width: 32px;
  height: 32px;
  padding: 0;
  margin: 0;
  border: none;
  display: flex;
  background: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: white;
  border-radius: 50%;
  border: 1px solid transparent;
`

const ImageControls = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  padding: 10px;
  &.ImageControls {
    &.Default {
      top: 0;
      right: 0;
    }
    &.Swap {
      position: absolute;
      bottom: 0;
      right: 0;
      height: 48px;
      width: 48px;
      .ImageButton {
        background: rgb(249, 250, 252);
        border: 1px solid rgb(227, 229, 235);
        width: unset;
        padding: 5px;
        height: unset;
      }
    }
  }
`

const ImageElement = styled.div`
  ${(props) =>
    props.imageSrc &&
    css`
      background-image: none;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: cover;
      background-image: ${`url(${props.imageSrc})`};

      .Default & {
        min-width: 100%;
        width: 100%;
        height: 160px;

        ${media.md`
        height: 320px;
      `};
      }
      .Swap & {
        width: 120px;
        height: 120px;
        border: 1px solid #e3e5eb;
        ${media.md`
        height: 120px;
      `};
      }
    `};
`

const Placeholder = styled.div`
  width: 168px;
  height: 160px;
  margin-right: 10px;
  background: #f4f5f8;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const UploaderIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  background: #f7f8fa;
  margin: 0px 0px 8px 0px;
`

const UploaderContent = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: #838a9b;
  padding: 0;
  margin: 0;
`

const UploaderLabel = styled.label`
  input {
    display: none;
  }
  &.custom-file-upload {
    box-sizing: border-box;
    border-radius: 8px;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    flex-direction: column;
    outline: none;
    background: #f4f5f8;
    width: 100%;
    transition: all 0.2s ease-in-out;
    border: 1px solid transparent;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    width: 168px;
    height: 160px;
  }

  .file-preview {
    margin: 0 10px;
  }
`

export {
  ImageContainer,
  ImageGroup,
  ImageItem,
  ImageButton,
  ImageControls,
  ImageElement,
  Placeholder,
  UploaderIcon,
  UploaderContent,
  UploaderLabel,
}
