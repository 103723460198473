import React from 'react'

const IconPath = (props) => {
  const { stroke = '#A9AEB9', fill = 'none' } = props
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <g clipPath="url(#clip0_531_37)">
        <path
          d="M0.811096 6.8186C2.34208 10.3874 5.87929 12.8862 9.99998 12.8862C14.1249 12.8862 17.6581 10.3876 19.1889 6.8186"
          stroke="#A9AEB9"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M4.07125 14.2428C3.92918 14.4795 4.00596 14.7867 4.24275 14.9287C4.47954 15.0708 4.78667 14.994 4.92875 14.7572L4.07125 14.2428ZM18.1 12.3C18.2657 12.5209 18.5791 12.5657 18.8 12.4C19.0209 12.2343 19.0657 11.9209 18.9 11.7L18.1 12.3ZM9.5 16C9.5 16.2761 9.72386 16.5 10 16.5C10.2761 16.5 10.5 16.2761 10.5 16H9.5ZM15.0713 14.7572C15.2133 14.994 15.5205 15.0708 15.7572 14.9287C15.994 14.7867 16.0708 14.4795 15.9287 14.2428L15.0713 14.7572ZM0.646447 11.6464C0.451184 11.8417 0.451184 12.1583 0.646447 12.3536C0.841709 12.5488 1.15829 12.5488 1.35355 12.3536L0.646447 11.6464ZM16.6 10.3L18.1 12.3L18.9 11.7L17.4 9.7L16.6 10.3ZM5.57125 11.7428L4.07125 14.2428L4.92875 14.7572L6.42875 12.2572L5.57125 11.7428ZM9.5 13V14.5H10.5V13H9.5ZM9.5 14.5V16H10.5V14.5H9.5ZM13.5713 12.2572L15.0713 14.7572L15.9287 14.2428L14.4287 11.7428L13.5713 12.2572ZM2.64645 9.64645L0.646447 11.6464L1.35355 12.3536L3.35355 10.3536L2.64645 9.64645Z"
          fill="#A9AEB9"
        />
      </g>
      <defs>
        <clipPath id="clip0_531_37">
          <rect width="20" height="20" fill="none" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default IconPath
