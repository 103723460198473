/* eslint-disable react/prop-types */
import React from 'react'

const IconPath = (props) => {
  const { fill = '#A9AEB9' } = props
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M9.9997 3C6.13422 3 3 6.13422 3 9.9997C3 13.8652 6.13422 17 9.9997 17C13.8652 17 17 13.8652 17 9.9997C17 6.13422 13.8652 3 9.9997 3ZM11.4569 13.8486C11.0966 13.9908 10.8098 14.0987 10.5947 14.1733C10.3801 14.248 10.1307 14.2853 9.84681 14.2853C9.41067 14.2853 9.07111 14.1787 8.82933 13.9659C8.58756 13.7532 8.46726 13.4836 8.46726 13.1559C8.46726 13.0284 8.47615 12.8981 8.49393 12.7653C8.5123 12.6326 8.54133 12.4833 8.58104 12.3156L9.032 10.7227C9.0717 10.5698 9.10607 10.4246 9.13333 10.2895C9.16059 10.1532 9.17363 10.0281 9.17363 9.91437C9.17363 9.7117 9.13156 9.56948 9.048 9.48948C8.96326 9.40948 8.80385 9.37037 8.56622 9.37037C8.45007 9.37037 8.33037 9.38756 8.2077 9.4237C8.08622 9.46104 7.98074 9.49481 7.89422 9.528L8.01333 9.03733C8.30844 8.91704 8.59111 8.81393 8.86074 8.72859C9.13037 8.64207 9.38519 8.59941 9.62519 8.59941C10.0584 8.59941 10.3926 8.70489 10.6279 8.91348C10.8619 9.12267 10.9799 9.39467 10.9799 9.72889C10.9799 9.79822 10.9716 9.9203 10.9556 10.0945C10.9396 10.2693 10.9093 10.4287 10.8655 10.5751L10.4169 12.1633C10.3801 12.2907 10.3476 12.4364 10.3179 12.5994C10.2889 12.7624 10.2747 12.8868 10.2747 12.9704C10.2747 13.1813 10.3215 13.3253 10.4163 13.4018C10.5099 13.4782 10.6741 13.5167 10.9064 13.5167C11.016 13.5167 11.1387 13.4972 11.2773 13.4593C11.4148 13.4213 11.5144 13.3876 11.5772 13.3585L11.4569 13.8486ZM11.3775 7.40237C11.1683 7.59674 10.9164 7.69393 10.6219 7.69393C10.328 7.69393 10.0744 7.59674 9.86341 7.40237C9.65363 7.208 9.54756 6.97156 9.54756 6.69541C9.54756 6.41985 9.65422 6.18281 9.86341 5.98667C10.0744 5.78993 10.328 5.69215 10.6219 5.69215C10.9164 5.69215 11.1689 5.78993 11.3775 5.98667C11.5867 6.18281 11.6916 6.41985 11.6916 6.69541C11.6916 6.97215 11.5867 7.208 11.3775 7.40237Z"
        fill={fill}
      />
    </svg>
  )
}

export default IconPath
