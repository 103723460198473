import React, { useRef, useEffect } from 'react'
import { mbFn, mlFn, mrFn, mtFn } from '@common/Theme'
import styled from 'styled-components'

const Wrapper = styled.div`
  ${mbFn};
  ${mtFn};
  ${mlFn};
  ${mrFn};
`
/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideAlerter({ ref, callback }) {
  /**
   * Alert if clicked on outside of element
   */
  function handleClickOutside(event) {
    if (ref.current && !ref.current.contains(event.target)) {
      callback()
    }
  }

  useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  })
}

const useClickOutside = ({ children, style, ml, mt, mb, mr, ...props }) => {
  const wrapperRef = useRef(null)
  // eslint-disable-next-line react/prop-types
  useOutsideAlerter({ ref: wrapperRef, callback: props.callback })

  // eslint-disable-next-line react/prop-types
  return (
    <Wrapper ref={wrapperRef} style={style} mb={mb} mt={mt} ml={ml} mr={mr}>
      {children}
    </Wrapper>
  )
}

export { useClickOutside }
