import React from 'react'
import { Icon } from '@common/Icon'
import { Container } from './ImageControl.styled'

const ImageControl = ({ onClick = () => {}, isActive = false }) => (
  <Container onClick={onClick} isActive={isActive}>
    <Icon
      name="SETTINGS"
      size={20}
      fill={isActive ? '#ff5a45' : '#A9AEB9'}
      stroke={isActive ? '#ff5a45' : '#A9AEB9'}
    />
  </Container>
)

export { ImageControl }
