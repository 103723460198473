import React, { useEffect, useState } from 'react'
import { Grid, Row, Col } from '@common/Grid'
import { Toggle } from '@common/Toggle'
// import { Plan } from './Plan'
// import { Payment } from './Payment'
// import { Summary } from './Summary'
// import { Button } from '@common/Button'
import { StateView } from '@common/StateView'

import { Text } from '@common/Text'

import { useDispatch } from 'react-redux'
import { useSelector } from '@hooks/useSelector'
import {
  settingsSelector,
  getActiveSubscriptionsPending,
  getActivePricesPending,
  getActiveSubscriptionsSuccess,
  getActivePricesSuccess,
  updateQuoteQuantityPending,
  updateQuoteQuantitySuccess,
  generateDraftQuoteSuccess,
  generateDraftQuotePending,
  updateDevMode,
} from '@state/settings'

import { keys } from 'ramda'
import { UserRoles } from '@components/UserRoles'
import { Block } from '@common/Block'
import { Heading } from '@common/Heading'
import { BladeMenu } from '@common/BladeMenu'
import { Summary } from './Summary'
import { Payment } from './Payment'
import { Billing } from './Billing'

const Header = () => (
  <Block.Column mb={32}>
    <Heading size={20} color="#323232" variant="TERTIARY" mb={12}>
      Settings
    </Heading>
    <Text size={16} color="#778092" weight="400">
      This is where you can manage your account settings and your payment plan.
    </Text>
  </Block.Column>
)

const PaymentHelper = () => {
  const dispatch = useDispatch()
  const isDevMode = useSelector(settingsSelector.isDevMode)
  const activeSubscriptionPlan = useSelector(settingsSelector.subscriptions)
  const activeSubscriptionPrices = useSelector(settingsSelector.prices)
  const quantity = useSelector(settingsSelector.quantity)
  const quote = useSelector(settingsSelector.quote)

  const onChangePlan = ({ value }) => {
    if (value === false) {
      dispatch(getActiveSubscriptionsSuccess([]))
      return
    }

    dispatch(getActiveSubscriptionsPending())
  }

  const onChangePrices = ({ value }) => {
    if (value === false) {
      dispatch(getActivePricesSuccess([]))
      return
    }
    dispatch(getActivePricesPending())
  }

  const onChangeQuoteQantity = ({ value }) => {
    if (value === false) {
      dispatch(updateQuoteQuantitySuccess(20))
      return
    }
    dispatch(updateQuoteQuantityPending())
  }

  const onChangeDraftQuote = ({ value }) => {
    if (value === false) {
      dispatch(generateDraftQuoteSuccess({}))
      return
    }
    dispatch(generateDraftQuotePending())
  }

  const thereIsNotObject =
    !activeSubscriptionPlan || keys(activeSubscriptionPlan) <= 0

  return (
    <div>
      {/* <Toggle
        name="activeSubscriptionPlan"
        isActive={!thereIsNotObject}
        onChange={onChangePlan}
        title="Active Subscription Plan"
      /> */}
      <Toggle
        name="activeSubscriptionPrices"
        isActive={activeSubscriptionPrices.length >= 1}
        onChange={onChangePrices}
        title="Update Prices"
      />
      {/* <Toggle
        name="quantity"
        isActive={quantity >= 1}
        onChange={onChangeQuoteQantity}
        title="Update Quote Quantity"
      />
      <Toggle
        name="quote"
        isActive={!!quote}
        onChange={onChangeDraftQuote}
        title="Generate Draft Quote"
      /> */}
      {isDevMode && (
        <StateView
          state={{
            activeSubscriptionPlan,
            activeSubscriptionPrices,
            quantity,
            quote,
          }}
        />
      )}
    </div>
  )
}

const options = [
  // {
  //   id: 'general',
  //   label: 'General',
  //   value: 'general',
  //   index: 0,
  //   sequence: 0,
  //   active: true,
  // },
  {
    id: 'billing',
    label: 'Billing',
    value: 'billing',
    index: 2,
    sequence: 1,
    isComplete: false,
    active: false,
  },
  // {
  //   id: 'paymentSummary',
  //   label: 'Summary',
  //   value: 'paymentSummary',
  //   index: 3,
  //   sequence: 1,
  //   active: false,
  // },
]

const Settings = () => {
  const [state, setState] = useState({
    activeId: 'general',
  })

  const handleCallback = ({ action, value }) => {
    if (action === 'BACK') {
      setState((state) => ({ ...state, activeId: value }))
      return
    }
    if (action === 'SET') {
      setState((state) => ({ ...state, activeId: value }))
      return
    }
  }

  return (
    <Block.Account>
      <Header />
      <BladeMenu
        data={options}
        name="settings"
        callback={handleCallback}
        activeId={state.activeId}
        label="PAYMENT SETTINGS"
      >
        {state.activeId === 'billing' && <Billing />}
      </BladeMenu>
    </Block.Account>
  )
}

export { Settings }
