import { createSelector } from 'reselect'
import { prop } from 'ramda'

const getWorkoutState = (state) => state.workout
export const workouts = (state) => state.workout.workouts

export const filteredWorkouts = (state) => state.workout.filteredWorkouts
export const loading = (state) => state.workout.loading
export const activeWorkoutId = (state) => state.workout.activeWorkout?.workoutId

export const images = createSelector([getWorkoutState], (workout) => {
  return prop('images')(workout?.activeWorkout)
})
export const activeWorkout = createSelector([getWorkoutState], (workout) => {
  return prop('activeWorkout')(workout)
})
export const workoutProtocol = createSelector([getWorkoutState], (workout) => {
  return prop('workoutProtocol')(workout?.activeWorkout)
})
export const workoutSelector = {
  workouts,
  workoutProtocol,
  activeWorkout,
  filteredWorkouts,
  loading,
  images,
  activeWorkoutId,
}
