import React from 'react'
import { Icon } from '@common/Icon'
import PropTypes from 'prop-types'
import {
  UploaderIcon,
  UploaderContent,
  UploaderLabel,
} from './UploadButton.styled'

const BannerComponent = (props) => (
  <UploaderLabel className="file-upload-banner" {...props}>
    <UploaderIcon>
      <Icon name="IMAGE_CROP" size={20} />
    </UploaderIcon>
    <UploaderContent>{props.label || 'Add banner image'}</UploaderContent>
    <input
      type="file"
      accept="image/*"
      name={props.name}
      value={props.value}
      onChange={props.onChange}
    />
  </UploaderLabel>
)

const ThumbnailComponent = (props) => (
  <UploaderLabel className="file-upload-thumbnail" {...props}>
    <UploaderIcon>
      <Icon name="IMAGE_CROP" size={20} />
    </UploaderIcon>
    <UploaderContent>{props.label}</UploaderContent>
    <input
      type="file"
      accept="image/*"
      name={props.name}
      value={props.value}
      onChange={props.onChange}
    />
  </UploaderLabel>
)

const Variant = ({ variant, ...props }) => {
  const lookup = {
    BANNER: BannerComponent,
    THUMBNAIL: ThumbnailComponent,
  }

  const name = !!variant ? variant.toUpperCase() : 'THUMBNAIL'
  const Component = lookup[name] || ThumbnailComponent
  return <Component {...props} />
}

const UploadButton = ({
  onChange = () => {},
  name,
  mt,
  mb,
  value,
  variant,
}) => {
  return (
    <Variant
      variant={variant}
      onChange={onChange}
      name={name}
      mt={mt}
      mb={mb}
      value={value}
    />
  )
}

UploadButton.propTypes = {
  onChange: PropTypes.func,
}

export { UploadButton }
