import { colors } from '@common/Theme'
import styled, { css } from 'styled-components'

const developerTheme = {
  section: 'white',
  group: '#f7f8fa',
}

const SectionHighest = styled.div`
  max-width: 100%;
  flex: 1;
  background: #f7f8fa;
  border: 1px solid #e3e5eb;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  ${(props) =>
    props.hasChildren &&
    css`
      background: transparent;
      border: 1px solid transparent;
    `};
`

const SectionGroup = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  transition: all 0.3s ease-in-out;
`

const IconContainer = styled.div`
  cursor: pointer;
  background: none;
  height: 20px;
  width: 20px;
  margin-right: 8px;
`

const SectionControl = styled.div`
  margin-left: auto;
`

const SectionDraggingOver = styled.div`
  position: relative;
  width: 100%;
  border-radius: 8px;
  background: ${colors.black};
`

const SectionHeader = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  h2 {
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-weight: 600;
  }
`

const SectionDroppable = styled.div`
  padding: 0;
`

const SectionContainer = styled.div`
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  border: 1px solid #eaeaea;
  border-radius: 10px;
  background-color: ${developerTheme.section};
  .name {
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    align-items: center;
    padding-left: 5px;
    padding-right: 5px;
  }
`
const SectionGroupContainer = styled.div`
  width: 100%;
  padding: 16px 16px 16px 32px;
  display: flex;
  flex-direction: column;
  border: 1px solid #eaeaea;
  margin-top: 8px;
  margin-bottom: 8px;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
  background: ${developerTheme.group};
  position: relative;
  &:before {
    content: '';
    width: 5px;
    border-radius: 8px;
    background: #dae0e7;
    left: 16px;
    height: calc(100% - 50px);
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
  }
`

export {
  IconContainer,
  SectionGroup,
  SectionHeader,
  SectionGroupContainer,
  SectionContainer,
  SectionControl,
  SectionDraggingOver,
  SectionHighest,
  SectionDroppable,
}
