import React from 'react'
import PropTypes from 'prop-types'
import { prop } from 'ramda'
import { Heading } from '@common/Heading'
import { Button } from '@common/Button'
import { Icon } from '@common/Icon'
import { Container, Push, ModalBlock, ModalClose } from '../Modal.styled'

const ConfirmContent = ({ style, dev, callback, onClick, level, data }) => {
  const message = prop('message')(data) || {}
  return (
    <Container style={style || {}} dev={dev} level={level}>
      <ModalBlock direction="row" justifyContent="space-between">
        <Heading
          variant="page"
          size={20}
          style={{ textAlign: 'left', width: '100%' }}
        >
          Are you sure?
        </Heading>
        <ModalClose
          onClick={() => callback({ action: 'CLOSE' })}
          style={{ position: 'absolute', right: '16px', top: '16px' }}
        >
          <Icon name="CLOSE" size={20} />
        </ModalClose>
      </ModalBlock>
      <Button
        type="button"
        onClick={onClick}
        config={{
          layout: {
            display: 'flex',
          },
        }}
      >
        {message && <Push>{message}</Push>}
      </Button>
    </Container>
  )
}

ConfirmContent.defaultProps = {
  data: {
    message: '',
  },
  style: {},
  dev: false,
  level: 2,
}

ConfirmContent.propTypes = {
  data: PropTypes.object,
  style: PropTypes.object,
  dev: PropTypes.bool,
  level: PropTypes.number,
  callback: PropTypes.func,
}

export default ConfirmContent
