import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Label } from '@common/Label';
import { PillSelector } from '@common/PillSelector';
import { exerciseSelector, updateExercise } from '@state/exercise';
// STATE
import { masterSelector } from '@state/master';

const ExerciseEditorMuscles = () => {
  const dispatch = useDispatch();
  // SELECTORS
  const musclesList = useSelector(masterSelector.muscles);
  const activeExercise = useSelector(exerciseSelector.activeExercise);
  const primaryMuscles = activeExercise.primaryMuscles;

  const onChangeMuscle = items => {
    dispatch(
      updateExercise({
        ...activeExercise,
        primaryMuscles: items,
      })
    );
  };

  return (
    <>
      <Label>Muscle Group</Label>
      <PillSelector
        options={musclesList}
        selectedOptions={primaryMuscles}
        onChange={onChangeMuscle}
        variantId="muscleId"
        variantName="muscleName"
      />
    </>
  );
};

export { ExerciseEditorMuscles };
