import React, { useState, useEffect, useRef } from 'react';
import payment from 'payment';
import { useClickOutside as ClickOutside } from '@hooks/useClickOutside';

import creditCardType from 'credit-card-type';
import { split } from 'ramda';
import {
  formatCardNumber,
  formatExpiry,
  formatCvc,
  hasCardNumberReachedMaxLength,
  hasCVCReachedMaxLength,
  hasZipReachedMaxLength,
  isHighlighted,
} from './utils/formatter';
import localImages from './utils/images';
import isExpiryInvalid from './utils/is-expiry-invalid';
import isZipValid from './utils/is-zip-valid';
import placeholderCard from './placeholderCard.png';
import {
  FieldLabel,
  Container,
  FieldWrapper,
  CardImage,
  InputWrapper,
  DangerText,
} from './CreditCard.styled';

const BACKSPACE_KEY_CODE = 8;

const CARD_TYPES_LOCAL = {
  mastercard: 'MASTERCARD',
  visa: 'VISA',
  amex: 'AMERICAN_EXPRESS',
};

const inputRenderer = ({ inputComponent, props }) => {
  const Input = inputComponent || 'input';
  return <Input {...props} />;
};

const CreditCard = ({ callback, ...props }) => {
  const CARD_TYPES = { ...CARD_TYPES_LOCAL, ...props.CARD_TYPES };
  const images = { ...localImages, ...props.images };

  const [outsideBlur, setOutsideBlur] = useState(false);
  const [state, setState] = useState({
    cardImage: images.placeholder,
    cardNumberLength: 0,
    cardNumber: null,
    errorText: null,
    showZip: false,
  });

  const onOutsideBlur = () => {
    setOutsideBlur(!outsideBlur);
  };

  const cardExpiryField = useRef(null);
  const cvcField = useRef(null);
  const zipField = useRef(null);
  const cardNumberField = useRef(null);

  useEffect(() => {
    if (cardNumberField.current) {
      setState(state => ({
        ...state,
        cardNumber: cardNumberField.current.value,
      }));
    }
  }, [state.cardNumber]);

  useEffect(() => {
    const cardType = payment.fns.cardType(state.cardNumber);
    setState(state => ({
      ...state,
      cardImage: images[cardType] || placeholderCard,
    }));
  }, [state.cardNumber]);

  const isMonthDashKey = ({ key, target: { value } } = {}) => {
    return !value.match(/[/-]/) && /^[/-]$/.test(key);
  };

  const checkIsNumeric = e => {
    if (!/^\d*$/.test(e.key)) {
      e.preventDefault();
    }
  };

  const handleCardNumberBlur = onBlur => e => {
    const { customTextLabels } = props;
    if (!payment.fns.validateCardNumber(e.target.value)) {
      setFieldInvalid(
        customTextLabels.invalidCardNumber || 'Card number is invalid',
        'cardNumber'
      );
    }

    const { cardNumberInputProps } = props;
    cardNumberInputProps.onBlur && cardNumberInputProps.onBlur(e);
    onBlur && onBlur(e);
  };

  const handleCardNumberChange = onChange => e => {
    const { customTextLabels, enableZipInput, cardNumberInputProps } = props;
    const cardNumber = e.target.value;
    const cardNumberLength = cardNumber.split(' ').join('').length;
    const cardType = payment.fns.cardType(cardNumber);
    const cardTypeInfo =
      creditCardType.getTypeInfo(creditCardType.types[CARD_TYPES[cardType]]) ||
      {};
    const cardTypeLengths = cardTypeInfo.lengths || [16];

    cardNumberField.current.value = formatCardNumber(cardNumber);

    setState(state => ({
      ...state,
      cardImage: images[cardType] || images.placeholder,
      cardNumber,
    }));

    if (enableZipInput) {
      setState(state => ({ ...state, showZip: cardNumberLength >= 6 }));
    }

    setFieldValid();
    if (cardTypeLengths) {
      const lastCardTypeLength = cardTypeLengths[cardTypeLengths.length - 1];
      for (let length of cardTypeLengths) {
        if (
          length === cardNumberLength &&
          payment.fns.validateCardNumber(cardNumber)
        ) {
          cardExpiryField.current.focus();
          break;
        }
        if (cardNumberLength === lastCardTypeLength) {
          setFieldInvalid(
            customTextLabels.invalidCardNumber || 'Card number is invalid',
            'cardNumber'
          );
        }
      }
    }

    cardNumberInputProps.onChange && cardNumberInputProps.onChange(e);
    onChange && onChange(e);
  };

  useEffect(() => {
    // collectCreditPayment
    if (state.errorText) {
      return;
    }

    const cardCVC = cvcField.current.value.trim();
    const getExp = cardExpiryField.current.value;

    const getExpirySplit = date => {
      if (!date) {
        return '';
      } else {
        return split('/')(date);
      }
    };

    const getCardExpiryMonth = expirySplit => {
      const result = expirySplit && expirySplit[0];
      if (!result) {
        return '';
      } else {
        const combineResult = result.trim();
        return parseInt(combineResult, 10);
      }
    };
    const getCardExpiryYear = expirySplit => {
      const resultString =
        expirySplit && typeof expirySplit[1] === 'string'
          ? expirySplit[1].trim()
          : null;
      if (!resultString) {
        return '';
      } else {
        const combineResult = `20${resultString}`;
        const result = parseInt(combineResult.trim(), 10);
        return result;
      }
    };

    const expirySplit = getExpirySplit(getExp);
    const cardExpiryMonth = getCardExpiryMonth(expirySplit);
    const cardExpiryYear = getCardExpiryYear(expirySplit);
    const cardNumber = state.cardNumber && state.cardNumber.split(' ').join('');

    callback({
      cardNumber,
      cardCVC,
      cardExpiryMonth,
      cardExpiryYear,
    });
  }, [state.cardNumber, state.errorText, outsideBlur]);

  const handleCardNumberKeyPress = e => {
    const value = e.target.value;
    checkIsNumeric(e);
    if (value && !isHighlighted()) {
      const valueLength = value.split(' ').join('').length;
      if (hasCardNumberReachedMaxLength(value, valueLength)) {
        e.preventDefault();
      }
    }
  };

  const handleCardExpiryBlur = onBlur => e => {
    const { customTextLabels } = props;
    const cardExpiry = e.target.value.split(' / ').join('/');
    const expiryError = isExpiryInvalid(
      cardExpiry,
      customTextLabels.expiryError
    );
    if (expiryError) {
      setFieldInvalid(expiryError, 'cardExpiry');
    }

    const { cardExpiryInputProps } = props;
    cardExpiryInputProps.onBlur && cardExpiryInputProps.onBlur(e);
    onBlur && onBlur(e);
  };

  const handleCardExpiryChange = onChange => e => {
    const { customTextLabels } = props;

    cardExpiryField.current.value = formatExpiry(e);
    const value = cardExpiryField.current.value.split(' / ').join('/');

    setFieldValid();

    const expiryError = isExpiryInvalid(value, customTextLabels.expiryError);
    if (value.length > 4) {
      if (expiryError) {
        setFieldInvalid(expiryError, 'cardExpiry');
      } else {
        cvcField.current.focus();
      }
    }

    const { cardExpiryInputProps } = props;
    cardExpiryInputProps.onChange && cardExpiryInputProps.onChange(e);
    onChange && onChange(e);
  };

  const handleCardExpiryKeyPress = e => {
    const value = e.target.value;

    if (!isMonthDashKey(e)) {
      checkIsNumeric(e);
    }

    if (value && !isHighlighted()) {
      const valueLength = value.split(' / ').join('').length;
      if (valueLength >= 4) {
        e.preventDefault();
      }
    }
  };

  const handleCardCVCBlur = onBlur => e => {
    const { customTextLabels } = props;
    if (!payment.fns.validateCardCVC(e.target.value)) {
      setFieldInvalid(
        customTextLabels.invalidCvc || 'CVC is invalid',
        'cardCVC'
      );
    }

    const { cardCVCInputProps } = props;
    cardCVCInputProps.onBlur && cardCVCInputProps.onBlur(e);
    onBlur && onBlur(e);
  };

  const handleCardCVCChange = onChange => e => {
    const { customTextLabels } = props;
    const value = formatCvc(e.target.value);
    cvcField.current.value = value;
    const CVC = value;
    const CVCLength = CVC.length;
    const isZipFieldAvailable = props.enableZipInput && state.showZip;
    const cardType = payment.fns.cardType(state.cardNumber);

    setFieldValid();
    if (CVCLength >= 4) {
      if (!payment.fns.validateCardCVC(CVC, cardType)) {
        setFieldInvalid(
          customTextLabels.invalidCvc || 'CVC is invalid',
          'cardCVC'
        );
      }
    }

    if (isZipFieldAvailable && hasCVCReachedMaxLength(cardType, CVCLength)) {
      zipField.current.focus();
    }

    const { cardCVCInputProps } = props;
    cardCVCInputProps.onChange && cardCVCInputProps.onChange(e);
    onChange && onChange(e);
  };

  const handleCardCVCKeyPress = e => {
    const cardType = payment.fns.cardType(state.cardNumber);
    const value = e.target.value;
    checkIsNumeric(e);
    if (value && !isHighlighted()) {
      const valueLength = value.split(' / ').join('').length;
      if (hasCVCReachedMaxLength(cardType, valueLength)) {
        e.preventDefault();
      }
    }
  };

  const handleCardZipBlur = onBlur => e => {
    const { customTextLabels } = props;
    if (!isZipValid(e.target.value)) {
      setFieldInvalid(
        customTextLabels.invalidZipCode || 'Zip code is invalid',
        'cardZip'
      );
    }

    const { cardZipInputProps } = props;
    cardZipInputProps.onBlur && cardZipInputProps.onBlur(e);
    onBlur && onBlur(e);
  };

  const handleCardZipChange = onChange => e => {
    const { customTextLabels } = props;
    const zip = e.target.value;
    const zipLength = zip.length;

    setFieldValid();

    if (zipLength >= 5 && !isZipValid(zip)) {
      setFieldInvalid(
        customTextLabels.invalidZipCode || 'Zip code is invalid',
        'cardZip'
      );
    }

    const { cardZipInputProps } = props;
    cardZipInputProps.onChange && cardZipInputProps.onChange(e);
    onChange && onChange(e);
  };

  const handleCardZipKeyPress = e => {
    const cardType = payment.fns.cardType(state.cardNumber);
    const value = e.target.value;
    checkIsNumeric(e);
    if (value && !isHighlighted()) {
      const valueLength = value.split(' / ').join('').length;
      if (hasZipReachedMaxLength(cardType, valueLength)) {
        e.preventDefault();
      }
    }
  };

  const handleKeyDown = ref => {
    return e => {
      if (e.keyCode === BACKSPACE_KEY_CODE && !e.target.value) {
        ref.current.focus();
      }
    };
  };

  const setFieldInvalid = (errorText, inputName) => {
    const { invalidClassName, onError } = props;
    // $FlowFixMe
    document.getElementById('field-wrapper').classList.add(invalidClassName);
    setState(state => ({ ...state, errorText }));

    if (inputName) {
      const { onError } = props[`${inputName}InputProps`];
      onError && onError(errorText);
    }

    if (onError) {
      onError({ inputName, error: errorText });
    }
  };

  const setFieldValid = () => {
    const { invalidClassName } = props;
    // $FlowFixMe
    document.getElementById('field-wrapper').classList.remove(invalidClassName);
    setState(state => ({ ...state, errorText: null }));
  };

  const renderContent = () => {
    const { cardImage, errorText, showZip } = state;
    const {
      cardImageClassName,
      cardImageStyle,
      cardCVCInputProps,
      cardZipInputProps,
      cardExpiryInputProps,
      cardNumberInputProps,
      cardCVCInputRenderer,
      cardExpiryInputRenderer,
      cardNumberInputRenderer,
      cardZipInputRenderer,
      containerClassName,
      containerStyle,
      dangerTextClassName,
      dangerTextStyle,
      enableZipInput,
      fieldClassName,
      fieldStyle,
      inputClassName,
      inputComponent,
      inputStyle,
      invalidStyle,
      customTextLabels,
    } = props;

    return (
      <ClickOutside callback={onOutsideBlur}>
        <Container className={containerClassName} styled={containerStyle}>
          <FieldLabel>{`Card Number`}</FieldLabel>
          <FieldWrapper
            id="field-wrapper"
            className={fieldClassName}
            styled={fieldStyle}
            invalidStyled={invalidStyle}
          >
            <CardImage
              className={cardImageClassName}
              styled={cardImageStyle}
              src={cardImage}
            />
            <InputWrapper
              inputStyled={inputStyle}
              isActive
              translateX={false}
              data-max="9999 9999 9999 9999 9999"
            >
              {cardNumberInputRenderer({
                inputComponent,
                handleCardNumberChange: onChange =>
                  handleCardNumberChange({ onChange }),
                handleCardNumberBlur: onBlur => handleCardNumberBlur(onBlur),
                props: {
                  ref: cardNumberField,
                  id: 'card-number',
                  maxLength: '19',
                  autoComplete: 'cc-number',
                  className: `credit-card-input ${inputClassName}`,
                  placeholder:
                    customTextLabels.cardNumberPlaceholder || 'Card number',
                  type: 'tel',
                  ...cardNumberInputProps,
                  onBlur: handleCardNumberBlur(),
                  onChange: handleCardNumberChange(),
                  onKeyPress: handleCardNumberKeyPress,
                },
              })}
            </InputWrapper>
            <InputWrapper
              inputStyled={inputStyle}
              isActive
              data-max="MM / YY 9"
              translateX={enableZipInput && !showZip}
            >
              {cardExpiryInputRenderer({
                inputComponent,
                handleCardExpiryChange: onChange =>
                  handleCardExpiryChange(onChange),
                handleCardExpiryBlur: onBlur => handleCardExpiryBlur(onBlur),
                props: {
                  ref: cardExpiryField,
                  id: 'card-expiry',
                  autoComplete: 'cc-exp',
                  className: `credit-card-input ${inputClassName}`,
                  placeholder: customTextLabels.expiryPlaceholder || 'MM/YY',
                  type: 'tel',
                  ...cardExpiryInputProps,
                  onBlur: handleCardExpiryBlur(),
                  onChange: handleCardExpiryChange(),
                  onKeyDown: handleKeyDown(cardNumberField),
                  onKeyPress: handleCardExpiryKeyPress,
                },
              })}
            </InputWrapper>
            <InputWrapper
              inputStyled={inputStyle}
              isActive
              data-max="99999"
              translateX={enableZipInput && !showZip}
            >
              {cardCVCInputRenderer({
                inputComponent,
                handleCardCVCChange: onChange => handleCardCVCChange(onChange),
                handleCardCVCBlur: onBlur => handleCardCVCBlur(onBlur),
                props: {
                  ref: cvcField,
                  id: 'cvc',
                  maxLength: '5',
                  autoComplete: 'off',
                  className: `credit-card-input ${inputClassName}`,
                  placeholder: customTextLabels.cvcPlaceholder || 'CVC',
                  type: 'tel',
                  ...cardCVCInputProps,
                  onBlur: handleCardCVCBlur(),
                  onChange: handleCardCVCChange(),
                  onKeyDown: handleKeyDown(cardExpiryField),
                  onKeyPress: handleCardCVCKeyPress,
                },
              })}
            </InputWrapper>
            <InputWrapper
              data-max="999999"
              isActive={enableZipInput}
              isZipActive={showZip}
              translateX={enableZipInput && !showZip}
            >
              {cardZipInputRenderer({
                inputComponent,
                handleCardZipChange: onChange => handleCardZipChange(onChange),
                handleCardZipBlur: onBlur => handleCardZipBlur(onBlur),
                props: {
                  id: 'zip',
                  ref: zipField,
                  maxLength: '6',
                  className: `credit-card-input zip-input ${inputClassName}`,
                  pattern: '[0-9]*',
                  placeholder: customTextLabels.zipPlaceholder || 'Zip',
                  type: 'text',
                  ...cardZipInputProps,
                  onBlur: handleCardZipBlur(),
                  onChange: handleCardZipChange(),
                  onKeyDown: handleKeyDown(cvcField),
                  onKeyPress: handleCardZipKeyPress,
                },
              })}
            </InputWrapper>
          </FieldWrapper>
          {errorText && (
            <DangerText
              className={dangerTextClassName}
              styled={dangerTextStyle}
            >
              {errorText}
            </DangerText>
          )}
        </Container>
      </ClickOutside>
    );
  };

  return renderContent();
};

CreditCard.defaultProps = {
  cardCVCInputRenderer: inputRenderer,
  cardExpiryInputRenderer: inputRenderer,
  cardNumberInputRenderer: inputRenderer,
  cardZipInputRenderer: inputRenderer,
  cardExpiryInputProps: {},
  cardNumberInputProps: {},
  cardCVCInputProps: {},
  cardZipInputProps: {},
  cardImageClassName: '',
  cardImageStyle: {},
  containerClassName: '',
  containerStyle: {},
  dangerTextClassName: '',
  dangerTextStyle: {},
  enableZipInput: false,
  fieldClassName: '',
  fieldStyle: {},
  inputComponent: 'input',
  inputClassName: '',
  inputStyle: {},
  invalidClassName: 'is-invalid',
  invalidStyle: {},
  customTextLabels: {},
};

export { CreditCard };
