import React from 'react';
import { toUpper } from 'ramda';
import { ExerciseUploaderCreator } from './ExerciseUploaderCreator';
import { ExerciseUploaderEditor } from './ExerciseUploaderEditor';

const ExerciseUploader = ({ type }) => {
  const renderType = {
    CREATE: <ExerciseUploaderCreator />,
    UPDATE: <ExerciseUploaderEditor />,
  }[toUpper(type)];

  return renderType;
};

export { ExerciseUploader };
