import React, { useEffect, useState } from 'react'
// import { SwipeHeader } from '@components/SwipeHeader';
import { usePortal as Portal } from '@hooks/usePortal'
import { values } from 'ramda'
import { useClickOutside as ClickOutside } from '@hooks/useClickOutside'
import { Overlay } from './MenuMobile.styled'
import { useAuth0 } from '@auth0/auth0-react'
import { MenuMobileContent } from './MenuMobileContent'
import { useNavigate, useLocation } from 'react-router-dom'
import { Icon } from '@common/Icon'
import {
  MenuGroup,
  MenuItem,
  MenuIcon,
  MenuCreate,
  MenuCreateContainer,
  MenuCustomIcon,
  MenuViewContainer,
} from './MobileView.styled'

import options from '../options'
import { clearState } from '@utils/storage'

const withControls = (Component) => (props) => {
  const [state, setState] = useState('')

  const handleCallback = (value) => {
    if (value) setState(value)
    else {
      setState('CLOSED')
    }
  }

  useEffect(() => {
    if (state !== '') {
      props.callback('CLOSED')
    }
  }, [state])

  return (
    <>
      <ClickOutside callback={handleCallback}>
        <Component {...props} />
      </ClickOutside>
      <Overlay level={1} isActive />
    </>
  )
}

MenuMobileContent.defaultProps = {
  callback: () => {},
}

const MobileModal = withControls(MenuMobileContent)

const filterOptions = (options) => {
  return options.filter((item) => !['workouts', 'exercises'].includes(item.id))
}
const MobileView = () => {
  const navigate = useNavigate()

  const [selectedRoute, setSelectedRoute] = useState('')
  const [isExpanded, setIsExpanded] = useState(false)

  const match = useLocation()
  const { logout } = useAuth0()

  const handleCreate = () => {}
  const onSelect = (item) => navigate(item.route)

  const getSelectedRoute = (route) => {
    if (route === selectedRoute) return true
  }

  useEffect(() => {
    const matchedPath = match.pathname
    if (matchedPath) setSelectedRoute(matchedPath)
  }, [match])

  const onLogout = () => {
    clearState('token')
    logout()
  }

  const renderBody = () => {
    const list = filterOptions(values(options)) || []
    if (!list || list.length <= 0) return null

    return (
      <MenuGroup>
        <MenuCreateContainer>
          {/* <MenuCreate onClick={handleCreate}>
            <Icon name="PLUS" size={30} />
          </MenuCreate> */}
        </MenuCreateContainer>
        {list.map((item) => (
          <MenuItem
            key={item.id}
            onClick={() => onSelect(item)}
            className="MenuItem"
            isActive={getSelectedRoute(item.route)}
          >
            <MenuIcon>
              <Icon name={item.icon} size={20} />
            </MenuIcon>
            {item.id === 'settings' && isExpanded && (
              <MenuCustomIcon className={item.icon}>
                <Icon name={item.icon} size={20} />
              </MenuCustomIcon>
            )}
          </MenuItem>
        ))}
        <MenuItem onClick={() => onLogout()} className="MenuItem">
          <MenuIcon>
            <Icon name="LEAVE" size={20} stroke="white" />
          </MenuIcon>
        </MenuItem>
      </MenuGroup>
    )
  }

  return <MenuViewContainer>{renderBody()}</MenuViewContainer>
}

const MenuMobile = () => {
  const [showMobileMenu, setShowMobileMenu] = useState(false)

  const handleCallback = ({ action }) => {
    if (action === 'CLOSED') {
      setShowMobileMenu(false)
    }
  }

  return (
    <>
      <Portal selector="#modal">
        {showMobileMenu && <MobileModal callback={handleCallback} />}
      </Portal>
      <Portal selector="#menu">
        <MobileView />
      </Portal>
    </>
  )
}

export { MenuMobile }
