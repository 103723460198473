import {
  API_CALL_FAILURE,
  MUSCLES_FETCH_SUCCESS,
  EQUIPMENT_FETCH_SUCCESS,
  MUSCLES_FETCH_FAILURE,
  EQUIPMENT_FETCH_FAILURE,
} from './actionTypes';

const initialState = {
  loading: false,
  muscles: [],
  equipment: [],
  error: '',
};

export default function masterReducer(state = initialState, action) {
  switch (action.type) {
    case MUSCLES_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        muscles: action.payload,
      };

    case EQUIPMENT_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        equipment: action.payload,
      };

    case MUSCLES_FETCH_FAILURE:
    case EQUIPMENT_FETCH_FAILURE:
      return { ...state, loading: false, muscles: [], error: action.error };

    default:
      return state;
  }
}
