import styled, { keyframes, css } from 'styled-components'
import { heightFn, widthFn, mlFn, mrFn } from '@common/Theme'
import { colors } from '@common/Theme'
/* stroke-dashoffset: (1 * ${size}); */
/* stroke-dashoffset: (3.14 * ${size}); */
/* stroke-dashoffset: (1 * ${size}); */

const spinner = () => {
  return keyframes`
  0% {
      transform: rotate(0deg);
  } 50% {
      transform: rotate(720deg);
  } 100% {
      transform: rotate(1080deg);
  }`
}

const SpinnerWrapper = styled.span`
  ${mrFn};
  ${mlFn};
  ${heightFn};
  ${widthFn};
  ${({ variant }) => {
    return css`
      svg > svg > circle {
        stroke: #e3e5eb;
      }
      svg > svg > path {
        stroke: ${!!variant && variant.toUpperCase() === 'SUCCESS'
          ? colors.success
          : colors.orange};
        fill: transparent;
        stroke-linecap: round;
        transform-origin: 50%;
        animation: ${spinner()} 2s ease-in-out infinite;
      }
    `
  }}
`

export { SpinnerWrapper }
