import {
  PREFERENCES_FETCH_FAILURE,
  PREFERENCES_SAVE_BOOKMARK_FAILURE,
  PREFERENCES_REMOVE_BOOKMARK_FAILURE,
  USER_FETCH_ME_FAILURE,
  USER_UPDATE_FAILURE,
  USER_CREATE_IMAGE_FAILURE,
  USER_VALIDATE_CHANNEL_FAILURE,
} from '../actionTypes'

export const fetchPreferencesFailure = ({ payload }) => ({
  type: PREFERENCES_FETCH_FAILURE,
  payload,
})

export const validateChannelFailure = () => ({
  type: USER_VALIDATE_CHANNEL_FAILURE,
})

export const fetchMeFailure = (payload) => ({
  type: USER_FETCH_ME_FAILURE,
  payload,
})

export const createUserImageFailure = (error) => ({
  type: USER_CREATE_IMAGE_FAILURE,
  payload: error,
})

export const updateUserFailure = (payload) => ({
  type: USER_UPDATE_FAILURE,
  payload,
})

export const saveExerciseBookmarkFailure = (payload) => ({
  type: PREFERENCES_SAVE_BOOKMARK_FAILURE,
  payload,
})

export const removeExerciseBookmarkFailure = ({ payload }) => ({
  type: PREFERENCES_REMOVE_BOOKMARK_FAILURE,
  payload,
})
