import { Droppable, Draggable } from 'react-beautiful-dnd'
import React, { useState, useEffect, useContext } from 'react'
import { prop } from 'ramda'
import { BladeIcon } from './AdvancedBladeIcon'
import { Spacer } from '@common/Spacer'
import {
  BladeItem,
  // BladeGroupContainer,
  // BladeGroup,
  // BladeType,
  BladeItemContent,
  BladeItemLabel,
  BladeControls,
  BladeHeading,
} from './AdvancedBlade.styled'
import { LayoutContext, LayoutTypes } from '@features/Layout'
import { useSelector, useDispatch } from 'react-redux'
import { exerciseSelector, fetchExercises } from '@state/exercise'

import { Blade } from '@features/Layout'
import { AdvancedSearch } from './AdvancedSearch'
import {
  // BladeItem as BladeItemRebuild,
  BladeGroupContainer,
  BladeGroup,
  BladeType,
  // BladeItemContent,
  BladeCount,
  // BladeItemLabel,
  BladeSearchContainer,
} from './AdvancedBladeRebuild.styled'

import { Icon } from '@common/Icon'
import { SearchBar } from '@components/Search/SearchBar'
import { Line } from '@common/Line'

const getName = (item) => prop('label')(item)

const filterResults = ({ searchValue = '', results = [] }) => {
  if (searchValue) {
    const foundResults = results.filter((item) =>
      item.label.toLowerCase().includes(searchValue.toLowerCase()),
    )
    return foundResults
  }
}

const getControls = (items) => {
  if (!items || !items.length) return []

  return items.filter((item) => {
    const upperLabel = item.label.toUpperCase()
    return (
      upperLabel === 'ADD SECTION' ||
      upperLabel === 'ADD SUPERSET' ||
      upperLabel === 'ADD REST'
    )
  })
}

const getExercises = (items) => {
  if (!items || !items.length) return []
  return items.filter((item) => {
    const upperLabel = item.label.toUpperCase()
    const variant = item.variant.toUpperCase()
    return !(
      upperLabel === 'ADD SECTION' ||
      upperLabel === 'ADD SUPERSET' ||
      upperLabel === 'ADD REST' ||
      variant === 'SURVEY'
    )
  })
}

const getSurveys = (items) => {
  if (!items || !items.length) return []
  return items.filter((item) => {
    if (!prop('variant')(item)) return false
    const variant = item.variant.toUpperCase()
    return variant.includes('SURVEY')
  })
}

const RenderCount = (items = []) => {
  if (!items || !items.length) return null
  const countLength = items.length || 0
  const isZero = countLength === 0
  const count = countLength ? (
    <BladeCount>
      {countLength} {`Item${isZero ? '' : 's'}`}
    </BladeCount>
  ) : null
  return count
}

// const [filteredResults, setFilterResults] = useState(
//   !!items ? getExercises(items) : [],
// )

// const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms))
// useEffect(() => {
//   let currentQuery = true

//   const loadResults = async () => {
//     if (!value) {
//       setFilterResults(activities)
//       return
//     }
//     await sleep(350)
//     if (currentQuery) {
//       const results = filterResults({
//         searchValue: value,
//         results: activities,
//       })
//       setFilterResults(results)
//     }
//   }
//   loadResults()

//   return () => {
//     currentQuery = false
//   }
//   // eslint-disable-next-line
// }, [value, activities])

// dump data onto activity after drop...
const AdvancedBlade = ({ activities = [], state = [] }) => {
  const [value, setValue] = useState('')
  const [, localDispatch] = useContext(LayoutContext)

  const setSearch = (e) => {
    const { value } = e.target
    setValue(value)
  }

  const exercises = getExercises(activities)
  const controlItems = getControls(activities)

  useEffect(() => {
    localDispatch({
      type: LayoutTypes.SET_BLADE_TITLE,
      data: 'Workout Builder',
    })
  }, [])

  const renderControls = (items) => {
    if (!items || !items.length) return null

    const result = items.map((item, i) => {
      const { draggableId, droppableId, type, droppableType, index, label } =
        item

      const isSectionEnabled = label.toUpperCase() === 'ADD SECTION' && false
      const isGroupEnabled =
        label.toUpperCase() === 'ADD SUPERSET' && !state.length
      const isRestEnabled = label.toUpperCase() === 'ADD REST' && !state.length

      return (
        <React.Fragment key={i}>
          <Droppable
            droppableId={droppableId}
            isDropDisabled
            type={droppableType}
          >
            {(provided) => (
              <BladeGroup
                variant="controls"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                <Draggable draggableId={draggableId} index={index} type={type}>
                  {(provided, snapshot) => (
                    <React.Fragment>
                      <BladeItem
                        className="BladeItem"
                        ref={provided.innerRef}
                        isDisabled={
                          isSectionEnabled || isGroupEnabled || isRestEnabled
                        }
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <Icon name="DRAG" />
                        <BladeIcon type={droppableType} />
                        <BladeItemContent>
                          <BladeType className="BladeType">
                            {droppableType}
                          </BladeType>
                          <BladeItemLabel>{label}</BladeItemLabel>
                        </BladeItemContent>
                      </BladeItem>
                      {snapshot.isDragging && (
                        <BladeItem className="BladeItem">
                          <Icon name="DRAG" />
                          <BladeIcon type={droppableType} />
                          <BladeItemContent>
                            <BladeItemLabel>{getName(item)}</BladeItemLabel>
                          </BladeItemContent>
                        </BladeItem>
                      )}
                    </React.Fragment>
                  )}
                </Draggable>
                {provided.placeholder}
              </BladeGroup>
            )}
          </Droppable>
        </React.Fragment>
      )
    })
    return (
      <>
        <BladeHeading>Controls</BladeHeading>
        <BladeControls className="controls">
          {result}
          <Spacer mt={8} />
        </BladeControls>
      </>
    )
  }

  const renderSurveys = (items) => {
    if (!items || !items.length) return null

    const result = items.map((item, i) => {
      const { draggableId, droppableId, type, droppableType, index, label } =
        item

      return (
        <React.Fragment key={i}>
          <Droppable
            droppableId={droppableId}
            isDropDisabled
            type={droppableType}
          >
            {(provided) => (
              <BladeGroup
                variant="surveys"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                <Draggable draggableId={draggableId} index={index} type={type}>
                  {(provided, snapshot) => (
                    <React.Fragment>
                      <BladeItem
                        className="BladeItem"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <Icon name="DRAG" />
                        <Icon ml={8} name="HISTORY" size={20} />
                        <BladeItemContent>
                          <BladeType className="BladeType">
                            {droppableType}
                          </BladeType>
                          <BladeItemLabel>{label}</BladeItemLabel>
                        </BladeItemContent>
                      </BladeItem>
                      {snapshot.isDragging && (
                        <BladeItem>
                          <BladeIcon type={type} />
                          <BladeItemContent>
                            <BladeItemLabel>{getName(item)}</BladeItemLabel>
                          </BladeItemContent>
                        </BladeItem>
                      )}
                    </React.Fragment>
                  )}
                </Draggable>
                {provided.placeholder}
              </BladeGroup>
            )}
          </Droppable>
        </React.Fragment>
      )
    })
    return (
      <>
        <BladeHeading>Surveys</BladeHeading>
        <BladeControls className="surveys">
          {result}
          <Line mt={8} />
        </BladeControls>
      </>
    )
  }

  const renderExercises = (items) => {
    if (!items || !items.length) return null
    const result = items.map((item, i) => {
      const { draggableId, droppableId, type, droppableType, index, label } =
        item
      return (
        <React.Fragment key={i}>
          <Droppable
            droppableId={droppableId}
            isDropDisabled
            type={droppableType}
          >
            {(provided) => (
              <BladeGroup
                variant="exercises"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                <Draggable draggableId={draggableId} index={index} type={type}>
                  {(provided, snapshot) => (
                    <React.Fragment>
                      <BladeItem
                        className="BladeItem"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <Icon name="DRAG" />
                        <BladeItemContent>
                          <BladeType className="BladeType">
                            {droppableType}
                          </BladeType>
                          <BladeItemLabel>{label}</BladeItemLabel>
                        </BladeItemContent>
                      </BladeItem>
                      {snapshot.isDragging && (
                        <BladeItem>
                          <BladeIcon type={type} />
                          <BladeItemContent>
                            <BladeItemLabel>{getName(item)}</BladeItemLabel>
                          </BladeItemContent>
                        </BladeItem>
                      )}
                    </React.Fragment>
                  )}
                </Draggable>
                {provided.placeholder}
              </BladeGroup>
            )}
          </Droppable>
        </React.Fragment>
      )
    })
    return (
      <>
        <BladeHeading>Exercises</BladeHeading>
        <BladeControls className="exercises">{result}</BladeControls>
      </>
    )
  }

  return (
    <Blade variant="WORKOUT_BUILDER">
      {/* <AdvancedSearch onChange={setSearch} value={value} /> */}
      <BladeSearchContainer>
        <RenderCount items={activities} />
        <BladeGroupContainer>
          {renderControls(controlItems)}
          {/* {renderSurveys(getSurveys(items))} */}
          {renderExercises(exercises)}
        </BladeGroupContainer>
      </BladeSearchContainer>
    </Blade>
  )
}

export { AdvancedBlade }
