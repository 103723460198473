import {
  SETTINGS_UPDATE_QUOTE_PRICE_PENDING,
  SETTINGS_UPDATE_QUOTE_QUANTITY_PENDING,
  SETTINGS_GET_ACTIVE_PRICES_PENDING,
  SETTINGS_GET_ACTIVE_SUBSCRIPTIONS_PENDING,
  SETTINGS_GENERATE_DRAFT_QUOTE_PENDING,
  SETTINGS_CREATE_PAYMENT_METHOD_PENDING,
  SETTINGS_GET_ALL_PAYMENT_METHODS_PENDING,
  SETTINGS_UPDATE_QUOTE_APPLY_COUPON_PENDING,
  SETTINGS_UPDATE_DEVMODE_PENDING,
} from '../actionTypes'

export const updateDevMode = (payload) => ({
  type: SETTINGS_UPDATE_DEVMODE_PENDING,
  payload,
})

export const getActivePricesPending = () => ({
  type: SETTINGS_GET_ACTIVE_PRICES_PENDING,
})

export const getActiveSubscriptionsPending = () => ({
  type: SETTINGS_GET_ACTIVE_SUBSCRIPTIONS_PENDING,
})

export const updateQuoteQuantityPending = (payload) => ({
  type: SETTINGS_UPDATE_QUOTE_QUANTITY_PENDING,
  payload,
})

export const updateQuotePricePending = (payload) => ({
  type: SETTINGS_UPDATE_QUOTE_PRICE_PENDING,
  payload,
})

export const generateDraftQuotePending = () => ({
  type: SETTINGS_GENERATE_DRAFT_QUOTE_PENDING,
})

export const createPaymentMethodPending = (payload) => {
  return {
    type: SETTINGS_CREATE_PAYMENT_METHOD_PENDING,
    payload,
  }
}

export const getAllPaymentMethodsPending = (payload) => ({
  type: SETTINGS_GET_ALL_PAYMENT_METHODS_PENDING,
  payload,
})

export const updateQuoteApplyCouponPending = (payload) => ({
  type: SETTINGS_UPDATE_QUOTE_APPLY_COUPON_PENDING,
  payload,
})
