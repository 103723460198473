import { createSelector } from 'reselect'
import { prop } from 'ramda'

const getUserState = (state) => state.user
export const favourites = (state) => state.user.favourites
export const preferences = (state) => state.user.preferences

export const loading = createSelector([getUserState], (user) => {
  return prop('loading')(user)
})

export const user = createSelector([getUserState], (user) => {
  return prop('user')(user)
})

export const validating = createSelector([getUserState], (user) => {
  return prop('validating')(user)
})

export const isValidChannel = createSelector([getUserState], (user) => {
  return prop('isValidChannel')(user)
})

export const roles = createSelector([getUserState], (user) => {
  return prop('roles')(user)
})

export const images = createSelector([getUserState], (user) => {
  return prop('images')(user?.user)
})

export const isActive = createSelector([getUserState], (user) => {
  return prop('isActive')(user?.user)
})

export const isPublished = createSelector([getUserState], (user) => {
  return prop('isPublished')(user?.user)
})

export const userSelector = {
  user,
  isActive,
  validating,
  isPublished,
  isValidChannel,
  preferences,
  images,
  roles,
  loading,
  favourites,
}
