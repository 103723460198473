import { ThemeTypes, ThemeContext } from '@common/Theme'
import { media } from '@common/Theme'
import React, { useEffect, useContext, useRef } from 'react'
import styled, { css } from 'styled-components'
import { LayoutContext } from '../LayoutProvider'

const MainContainer = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
  flex-direction: column;
  position: relative;
  min-width: 264px;
  overflow: hidden;
  padding: 0px 0px 116px 0px;
  ${media.sm`
    padding: 24px 24px 32px 24px;
  `};
  ${(props) =>
    props.height &&
    css`
      height: ${props.height}px;
    `};
  ${(props) => props.extend};
  &.Main.Content {
    padding: 0px 0px 116px 0px;
    background: white;
    ${media.sm`
    padding: 24px 0px 32px 0px;
  `};
  }
`

const Main = ({ children, className, extend }) => {
  const ref = useRef(0)
  const [, dispatch] = useContext(LayoutContext)
  const [, themeDispatch] = useContext(ThemeContext)

  useEffect(() => {
    document.onreadystatechange = () => {
      dispatch({ type: 'SET_HEIGHT', data: ref.current.offsetHeight })
      themeDispatch({ type: ThemeTypes.SET_BACKGROUND, data: '#f7f8fa' })
    }
  }, [])
  return (
    <MainContainer
      className={`Main ${!!className ? className : ''}`}
      extend={extend}
      ref={ref}
    >
      {children}
    </MainContainer>
  )
}

export { Main }
