import React from 'react';
import styled from 'styled-components';
import { Icon } from '@common/Icon';

const PaymentItem = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
`;

const PaymentCardLabel = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  color: #121b2d;
  text-transform: capitalize;
`;

const PaymentCardDigits = styled.span`
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  text-align: center;
  color: #a9aeb9;
`;

const PaymentHiddenDigits = styled.span`
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  text-align: center;
  color: #a9aeb9;
  margin-right: 5px;
  position: relative;
  top: 3px;
`;

const PaymentCard = styled.div`
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
`;

const PaymentCardEdit = styled.button`
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
  background: transparent;
  padding: 0;
  margin: 0;
  margin-left: 12px;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  text-align: center;
  color: #ff5a45;

  &:hover {
    cursor: pointer;
    background: transparent;
  }
`;

const PaymentControls = styled.div`
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const PaymentDetails = ({ cardNumber, brand, onEdit }) => {
  if (!cardNumber) {
    return (
      <PaymentItem>
        <PaymentCard>
          <Icon name="CARD" size={24} />
        </PaymentCard>
        <PaymentCardLabel>No Card Provided</PaymentCardLabel>
        <PaymentControls>
          <PaymentCardDigits>{`****`}</PaymentCardDigits>
        </PaymentControls>
      </PaymentItem>
    );
  }
  const remainingDigits = (
    <PaymentHiddenDigits>**** **** **** ****</PaymentHiddenDigits>
  );

  const renderBrand = brand => {
    if (brand === 'visa') {
      return `${brand} Card`;
    }
    return brand;
  };

  return (
    <PaymentItem>
      <PaymentCard>
        <Icon name="CARD" size={24} />
      </PaymentCard>
      <PaymentCardLabel>{renderBrand(brand) || 'N/A'}</PaymentCardLabel>
      <PaymentControls>
        {cardNumber && (
          <PaymentCardDigits>
            {remainingDigits}
            {cardNumber}
          </PaymentCardDigits>
        )}
        <PaymentCardEdit onClick={onEdit}>Edit</PaymentCardEdit>
      </PaymentControls>
    </PaymentItem>
  );
};

PaymentDetails.defaultProps = {
  cardNumber: '',
  onEdit: () => {},
};

export { PaymentDetails };
