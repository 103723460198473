import styled, { css, keyframes } from 'styled-components'
import { media } from '@common/Theme'

const fadeIn = keyframes`
    0% { opacity: 1; }
    100% { opacity: 1; }
`

const MenuItem = styled.li`
  margin-right: 32px;
  margin: 0;
  list-style: none;
  padding: 12px;
  display: flex;
  width: 100%;
  width: 50px;
  border: 2px solid transparent;
  background: transparent;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
  &:first-child {
    margin-top: 0;
  }
  .DASHBOARD {
    rect {
      stroke: white;
      fill: white;
    }
  }
  .ADJUST {
    path {
      fill: white;
    }
    circle {
      stroke: white;
      fill: white;
    }
  }
  .LIGHTNING {
    path {
      fill: white;
      stroke: white;
    }
    circle {
      stroke: white;
      fill: white;
    }
  }
  .WHISTLE_FILLED {
    path {
      fill: white;
    }
  }
  .WEIGHT_FILLED {
    path {
      stroke: #fff;
      fill: #fff;
    }
    rect {
      fill: #fff;
      stroke: #fff;
    }
  }
  ${(props) =>
    props.isActive &&
    css`
      background: transparent;
      border: 2px solid #ff5a45;
      .MenuText {
        color: #fff;
      }
      .DASHBOARD {
        rect {
          stroke: white;
        }
      }
      .ADJUST {
        path {
          fill: white;
        }
      }
      .WEIGHT_FILLED {
        path {
          stroke: #fff;
          fill: #fff;
        }
        rect {
          fill: #fff;
          stroke: #fff;
        }
      }
      path {
        fill: #fff;
        stroke: transparent;
      }
      rect {
        fill: #fff;
        stroke: transparent;
      }
    `}
`

const MenuText = styled.span`
  opacity: 0;
  animation-name: ${fadeIn};
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  animation-duration: 0.3s;
  animation-delay: 0.1s;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: #121b2d;
`

const MenuCustomIcon = styled.div`
  width: 20px;
  height: 20px;
  transition: all 0.1s ease-in-out;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-left: auto;
`

const MenuCreateContainer = styled.div`
  padding: 7px;
  left: 50%;
  top: 0;
  border-radius: 50%;
  transform: translate(-50%, calc(-50% + 10px));
  position: absolute;
  background: rgb(18, 27, 45);
`

const MenuCreate = styled.button`
  background: none;
  border: 1px solid transparent;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 52px;
  height: 52px;
  position: relative;
  background: #ff5a45;
  transform: scale(1);
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  &:hover {
    transform: scale(1.09);
    background: #ff7a6a;
  }
  &:active {
    transform: scale(1.09);
    background: #ff7a6a;
  }
  &:focus {
    transform: scale(1.09);
    background: #ff7a6a;
    outline: none;
    border: none;
  }
`

const MenuGroup = styled.ul`
  width: 100%;
  position: fixed;
  z-index: 1;
  left: 50%;
  bottom: 0px;
  transform: translateX(-50%);
  margin: 0;
  padding: 8px 8px 24px 8px;
  background-color: #161616;
  list-style: none;
  border-radius: 0px;
  /* max-width: calc(100% - 16px); */
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-sizing: border-box;
`

const MenuViewContainer = styled.div`
  width: 100%;
  position: relative;
  background: transparent;
  display: flex;
  ${media.sm`
    display : none;
    `};
`

const MenuIcon = styled.div.attrs({ className: 'MenuIcon' })`
  transition: all 0.1s ease-in-out;
  align-items: center;
  justify-content: center;
  display: flex;
  .isExpanded & {
    display: none;
  }
`

export {
  MenuItem,
  MenuViewContainer,
  MenuCreateContainer,
  MenuText,
  MenuCustomIcon,
  MenuGroup,
  MenuCreate,
  MenuIcon,
}
