import styled, { css } from 'styled-components';

const TabItem = styled.button.attrs({ type: 'button' })`
  padding: 6px;
  border: none;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  ${props =>
    props.isActive &&
    css`
      background: #ff5a45;
      color: white;
      font-weight: 600;
    `}
`;

const List = styled.li`
  list-style: none;
  padding: 0;
  margin: 0;
  border-radius: 4px;
  overflow: hidden;
  &:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  &:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;

export { TabItem, List };
