import styled, { css } from 'styled-components'
import { media } from '@common/Theme'

const SearchWrapper = styled.div`
  padding: 0;
  display: flex;
  flex-grow: 1;
  margin-right: 8px;
  ${media.sm`
    margin: 0;
    padding: 0px;
  `}
  ${media.md`
    margin: 0;
    padding: 0px;
  `}
`

const SearchContainer = styled.div`
  position: relative;
  width: 100%;
  background: #f7f8fa;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 10px 16px 10px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #f1f2f6;
  justify-content: flex-start;
  border: 1px solid transparent;
  ${(props) =>
    props.isActive &&
    css`
      border: 1px solid #ff5a45;
      box-shadow: 0px 0px 0px 2px rgba(255, 90, 69, 0.2);
    `}
`

const SearchIcon = styled.div`
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
`

const SearchInput = styled.input`
  line-height: 20px;
  font-size: 14px;
  background: transparent;
  padding: 0;
  margin: 0;
  border: none;
  background: #f1f2f6;
  box-sizing: border-box;
  color: #a9aeb9;
  &::placeholder {
    color: #a9aeb9;
  }
  &:focus {
    outline: none;
  }
`

const WorkoutSearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 12px;
  margin-top: 16px;
  ${media.sm`
    margin-top: 0px;
  `}
`

export {
  SearchWrapper,
  WorkoutSearchContainer,
  SearchContainer,
  SearchIcon,
  SearchInput,
}
