export default {
  stripeQuoteId: 'qt_1L4ogrCTOd88lFo5ktggCUhu',
  currency: 'aud',
  collectionMethod: 'charge_automatically',
  status: 'draft',
  amountTotal: 33792,
  amountSubTotal: 38400,
  quantity: 7,
  stripeCreatedAt: '2022-05-29T21:40:05',
  expiresAt: '2022-06-28T21:40:05',
  computed: {
    lastResponse: null,
    rawJsonObject: null,
    recurring: {
      lastResponse: null,
      rawJsonObject: null,
      amountSubtotal: 38400,
      amountTotal: 38400,
      interval: 'year',
      intervalCount: 1,
      totalDetails: {
        lastResponse: null,
        rawJsonObject: null,
        amountDiscount: 0,
        amountShipping: 0,
        amountTax: 0,
        breakdown: null,
      },
    },
    upfront: {
      lastResponse: null,
      rawJsonObject: null,
      amountSubtotal: 0,
      amountTotal: 0,
      lineItems: null,
      totalDetails: {
        lastResponse: null,
        rawJsonObject: null,
        amountDiscount: 0,
        amountShipping: 0,
        amountTax: 0,
        breakdown: null,
      },
    },
  },
  statusTransition: {
    lastResponse: null,
    rawJsonObject: null,
    acceptedAt: null,
    canceledAt: null,
    finalizedAt: null,
  },
  subscriptionData: {
    lastResponse: null,
    rawJsonObject: null,
    effectiveDate: null,
    trialPeriodDays: 8,
  },
  totalDetails: {
    lastResponse: null,
    rawJsonObject: null,
    amountDiscount: 4608,
    amountShipping: 0,
    amountTax: 0,
    breakdown: null,
  },
  coupon: {
    stripeCouponId: 'MPA0akj3',
    stripeCouponName: 'Aima Premium Coupon - Stage',
    valid: true,
    amountOff: 0,
    percentOff: 12,
    duration: 'repeating',
    durationInMonths: 5,
    maxRedemptions: 0,
    redeemBy: '2022-05-25T15:01:26.046554',
    timesRedeemed: 0,
    createdAt: '2022-05-25T15:01:26.0729',
    updatedAt: '2022-05-25T15:01:26.0729',
  },
  priceInfo: {
    stripePriceId: 'price_1L3GW1CTOd88lFo5pRLAKFTb',
    stripeProductId: 'prod_Ljx4bgjysAPjTg',
    amount: 0,
    label: {
      interval: 'year',
      usageType: 'licensed',
      lastResponse: null,
      intervalCount: 1,
      rawJsonObject: null,
      aggregateUsage: null,
      trialPeriodDays: null,
    },
    active: true,
    tiers: [
      {
        upTo: 3,
        flatAmount: 24000,
        unitAmount: null,
        lastResponse: null,
        rawJsonObject: null,
        flatAmountDecimal: 24000,
        unitAmountDecimal: null,
      },
      {
        upTo: null,
        flatAmount: null,
        unitAmount: 3600,
        lastResponse: null,
        rawJsonObject: null,
        flatAmountDecimal: null,
        unitAmountDecimal: 3600,
      },
    ],
    taxBehavior: 'inclusive',
    tiersMode: 'graduated',
    type: 'recurring',
    currency: 'aud',
    isDefault: false,
    createdAt: '2022-05-25T14:58:31.112046',
    updatedAt: '2022-05-25T14:58:31.112046',
  },
  createdBy: {
    email: 'support@prostaid.health',
    stripeCustomerId: 'cus_LlA9RYOvQPQDq1',
    roles: ['PersonalTrainer'],
    createdAt: '2022-05-26T15:51:27.579485',
    updatedAt: '2022-05-26T15:56:41.277154',
    isActive: true,
    isDeleted: false,
    stripeSetupIntentObject: {
      setupIntentId: 'seti_1L3dsECTOd88lFo5OESS02BH',
      paymentMethodId: 'pm_1L3drmCTOd88lFo5qUN9f7iL',
      setupIntentSecret:
        'seti_1L3dsECTOd88lFo5OESS02BH_secret_LlACdyfZ5qFf7V9YbeRsCXbgxb1PNOw',
    },
  },
};
