import React from 'react'
import { Tabs } from '@common/Tabs'
import { Blade, Main, Content, Header, Layout, Menu } from '@features/Layout'
import { Provider as ExerciseUploaderProvider } from './ExerciseBuilder/ExerciseUploader/ExerciseUploaderProvider'

import { ExerciseBody } from './ExerciseBody'
import { ExerciseBuilder } from './ExerciseBuilder'

const options = [
  {
    label: 'Exercises',
    value: 'exercise',
    content: null,
    route: '/exercise',
    roles: ['PERSONALTRAINER'],
  },
]

const ExerciseLibrary = () => (
  <Layout variant="CUSTOM">
    <ExerciseUploaderProvider>
      <Menu />
      <Main className="Content">
        <Header config={{ isProfileEnabled: true }}>
          <Tabs
            options={options}
            active={'exercise'}
            loading={false}
            showPanel={false}
          />
        </Header>
        <Content>
          <ExerciseBody />
        </Content>
      </Main>
      <Blade variant="exercise">
        <ExerciseBuilder />
      </Blade>
    </ExerciseUploaderProvider>
  </Layout>
)

export { ExerciseLibrary }
