import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Icon } from '@common/Icon'
import { values } from 'ramda'
import { useNavigate, useLocation } from 'react-router-dom'

import {
  MenuCustomIcon,
  MenuGroup,
  MenuItem,
  MenuIcon,
  MenuText,
  MenuClose,
} from './MenuMobileContent.styled'

const MenuMobileContent = ({ options, ...props }) => {
  const navigate = useNavigate()
  const [selectedRoute, setSelectedRoute] = useState('')
  const [isExpanded, setIsExpanded] = useState(false)

  const match = useLocation()

  const onSelect = (item) => navigate(item.route)

  const getSelectedRoute = (route) => {
    if (route === selectedRoute) return true
  }

  useEffect(() => {
    const matchedPath = match.pathname
    if (matchedPath) setSelectedRoute(matchedPath)
  }, [match])

  const renderClose = () => {
    if (isExpanded) return null
    return (
      <MenuCloseContainer>
        <MenuClose onClick={onToggle}>
          <Icon rotate={isExpanded ? 180 : 0} name="ARROW_RIGHT" size={20} />
        </MenuClose>
      </MenuCloseContainer>
    )
  }

  const renderBody = () => {
    const list = values(options) || []
    if (!list || list.length <= 0) return null

    return (
      <MenuGroup>
        {list.map((item) => (
          <MenuItem
            key={item.id}
            onClick={() => onSelect(item)}
            className="MenuItem"
            isActive={getSelectedRoute(item.route)}
          >
            <MenuIcon>
              <Icon name={item.icon} size={20} />
            </MenuIcon>
            {isExpanded && (
              <MenuText className="MenuText">{item.label}</MenuText>
            )}
            {item.id === 'settings' && isExpanded && (
              <MenuCustomIcon className={item.icon}>
                <Icon name={item.icon} size={20} />
              </MenuCustomIcon>
            )}
          </MenuItem>
        ))}
      </MenuGroup>
    )
  }

  return <div>{renderBody()}</div>
}

MenuMobileContent.defaultProps = {
  style: {},
  dev: false,
  level: 2,
  options: {},
}

MenuMobileContent.propTypes = {
  level: PropTypes.number,
  options: PropTypes.object,
}

export { MenuMobileContent }
