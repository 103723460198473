import {
  SETTINGS_UPDATE_QUOTE_PRICE_FAILURE,
  SETTINGS_GET_ACTIVE_SUBSCRIPTIONS_FAILURE,
  SETTINGS_GET_ACTIVE_PRICES_FAILURE,
  SETTINGS_UPDATE_QUOTE_QUANTITY_FAILURE,
  SETTINGS_GENERATE_DRAFT_QUOTE_FAILURE,
  SETTINGS_CREATE_PAYMENT_METHOD_FAILURE,
  SETTINGS_GET_ALL_PAYMENT_METHODS_FAILURE,
  SETTINGS_UPDATE_QUOTE_APPLY_COUPON_FAILURE,
} from '../actionTypes';

export const updateQuotePriceFailure = ({ error }) => ({
  type: SETTINGS_UPDATE_QUOTE_PRICE_FAILURE,
  error,
});

export const updateQuoteQuantityFailure = ({ error }) => ({
  type: SETTINGS_UPDATE_QUOTE_QUANTITY_FAILURE,
  error,
});

export const getActiveSubscriptionsFailure = ({ error }) => ({
  type: SETTINGS_GET_ACTIVE_SUBSCRIPTIONS_FAILURE,
  error,
});

export const getActivePricesFailure = ({ error }) => ({
  type: SETTINGS_GET_ACTIVE_PRICES_FAILURE,
  error,
});

export const generateDraftQuoteFailure = ({ error }) => ({
  type: SETTINGS_GENERATE_DRAFT_QUOTE_FAILURE,
  error,
});

export const createPaymentMethodFailure = ({ error }) => {
  return {
    type: SETTINGS_CREATE_PAYMENT_METHOD_FAILURE,
    error,
  };
};

export const getAllPaymentMethodsFailure = ({ error }) => ({
  type: SETTINGS_GET_ALL_PAYMENT_METHODS_FAILURE,
  error,
});

export const updateQuoteApplyCouponFailure = ({ error }) => ({
  type: SETTINGS_UPDATE_QUOTE_APPLY_COUPON_FAILURE,
  error,
});
