import React from 'react';
import styled from 'styled-components';
import { mtFn, mrFn, mbFn, mlFn } from '@common/Theme';
import { Icon } from '@common/Icon';
import {
  asBlue,
  asPurple,
  basicFunctions,
  basicStyles,
  basicText,
  pseudoStyles,
} from './buttonStyles';

const GoogleStyle = styled.button`
  ${basicStyles};
  ${basicText};
  ${pseudoStyles}
  ${basicFunctions};
  ${mtFn};
  ${mlFn};
  ${mrFn};
  ${mbFn};
  width: 100%;
  padding: 14px;
  background: #f7f8fa;
  border: 1px solid #e3e5eb;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  border: 1px solid transparent;
  transition: all 0.2s ease-in-out;
  ${asPurple}
  ${asBlue}
`;

const GoogleSignIn = ({ style, children, ...props }) => (
  <GoogleStyle asBlue style={style} {...props}>
    <Icon name="GOOGLE" fill="white" size={20} />
    <span style={{ marginLeft: '16px', color: 'white', fontWeight: 500 }}>
      Sign in with Google
    </span>
  </GoogleStyle>
);

export { GoogleSignIn };
