import React, { useEffect } from 'react'
import { Main, Header, Content, Layout, Menu } from '@features/Layout'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { settingsSelector } from '@state/settings'
import { StateView } from '@common/StateView'
import { Card } from '@components/Card'
import { Button } from '@common/Button'
import { Group, List } from '@common/List'
import { Heading } from '@common/Heading'

import { Tabs } from '@common/Tabs'
import { useNavigate } from 'react-router-dom'

const options = [
  {
    label: 'Dashboard',
    value: 'dashboard',
    content: null,
    route: '/dashboard',
    roles: ['PERSONALTRAINER', 'CLIENT'],
  },
]

const GroupLower = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  background: transparent;
  border-radius: 6px;
  gap: 24px;
  flex-direction: row;
  flex-wrap: wrap;
`

const trainers = [
  {
    trainerId: '84',
    images: [
      {
        id: 'b213a3bf-79fa-4725-b7e5-66b3821ac406',
      },
    ],
    firstName: 'Bob',
    lastName: 'Smith',
    content: '',
  },
]

const Dashboard = () => {
  const navigate = useNavigate()
  const isDevMode = useSelector(settingsSelector.isDevMode)
  const dispatch = useDispatch()

  const renderItems = () => {
    if (!trainers || !trainers.length) return null
    const result = trainers.map((trainer) => {
      return (
        <Card
          key={trainer.trainerId}
          id={trainer.trainerId}
          images={trainer.images}
          name={trainer.trainer}
          description={trainer.content}
        ></Card>
      )
    })

    return <GroupLower>{result}</GroupLower>
  }

  return (
    <Layout variant="CUSTOM">
      <Menu />
      <Main className="Content">
        <Header
          config={{
            isProfileEnabled: true,
          }}
        >
          <Tabs
            options={options}
            active={'dashboard'}
            loading={false}
            showPanel={false}
          />
        </Header>

        <Content>
          <Heading mb={16}>Trainers</Heading>
          {renderItems()}
          <Button
            mt={32}
            label="View all workouts"
            alone
            width="unset"
            variant="orange"
            onClick={() => navigate('/start')}
          ></Button>
        </Content>
      </Main>
    </Layout>
  )
}

export { Dashboard }
