/* eslint-disable react/prop-types */
import React from 'react'

const IconPath = (props) => {
  const { fill = 'none', stroke = '#BEC2CF' } = props
  return (
    <svg width="20px" height="20px" viewBox="0 0 20 20">
      <g
        id="Artboard"
        stroke="none"
        strokeWidth="1"
        fill={fill}
        fillRule="evenodd"
      >
        <circle
          id="Oval"
          stroke={stroke}
          strokeWidth="1.5"
          cx="10.5"
          cy="10.5"
          r="3.75"
        />
        <path
          d="M2,7.87048074 L2,15.908391 L2,15.908391 C2.05623446,16.6361303 2.56234461,17 3.51833046,17 C4.4743163,17 8.95152099,17 16.9499445,17 C17.6499815,16.8389541 18,16.4057867 18,15.7004979 C18,14.995209 18,12.3255491 18,7.69151814 C17.8732781,6.93399575 17.5232596,6.47828737 16.9499445,6.32439301 C16.3766294,6.17049864 15.5639076,5.99922326 14.5117789,5.81056687 L13.7354155,3.81916985 C13.5428486,3.32522677 13.0669876,3 12.536835,3 C12.536835,3 12.536835,3 12.536835,3 C11.0030242,3 9.46921338,3 7.93540256,3 C7.58167705,3 7.23120781,3.06757213 6.90283828,3.19908328 C6.58586093,3.32603188 6.33606256,3.57924085 6.21342389,3.89791049 L5.49853952,5.75549711 L5.49853952,5.75549711 L2.51851398,6.47301879 C2.26536151,6.53397209 2.0774435,6.74686981 2.04839382,7.00563146 C2.01615649,7.2927873 2,7.58152101 2,7.87048074 Z"
          id="Path"
          stroke={stroke}
          strokeWidth="1.5"
          strokeLinejoin="round"
        />
        <path
          d="M11,9 C9.66666667,9.17035686 9,9.83702353 9,11"
          id="Path-2"
          stroke={stroke}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  )
}

export default IconPath
