import styled, { css, keyframes } from 'styled-components';

const fadeIn = keyframes`
    0% { opacity: 1; }
    100% { opacity: 1; }
`;

const MenuItem = styled.li`
  margin: 0;
  padding: 0;
  list-style: none;
  padding: 13px;
  display: flex;
  width: 100%;
  border-radius: 6px;
  cursor: pointer;
  user-select: none;
  margin-top: 4px;
  &:first-child {
    margin-top: 0;
  }
  ${props =>
    props.isActive &&
    css`
      background: linear-gradient(
        0deg,
        rgba(255, 90, 69, 0.1),
        rgba(255, 90, 69, 0.1)
      );
      .MenuText {
        color: #ff5a45;
      }
      svg {
        &.WEIGHT_FILLED {
          path {
            stroke: #ff5a45;
            fill: #ff5a45;
          }
          rect {
            fill: #ff5a45;
            stroke: #ff5a45;
          }
        }
        path {
          fill: #ff5a45;
          stroke: transparent;
        }
        rect {
          fill: #ff5a45;
          stroke: transparent;
        }
      }
    `}
  &:hover {
    background: linear-gradient(
      0deg,
      rgba(255, 90, 69, 0.1),
      rgba(255, 90, 69, 0.1)
    );
    .MenuText {
      color: #ff5a45;
    }
    svg {
      &.WEIGHT_FILLED {
        path {
          stroke: #ff5a45;
          fill: #ff5a45;
        }
        rect {
          fill: #ff5a45;
          stroke: #ff5a45;
        }
      }
      path {
        fill: #ff5a45;
        stroke: transparent;
      }
      rect {
        fill: #ff5a45;
        stroke: transparent;
      }
    }
  }
`;

const MenuText = styled.span`
  opacity: 0;
  animation-name: ${fadeIn};
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  animation-duration: 0.3s;
  animation-delay: 0.1s;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: #121b2d;
`;

const MenuCustomIcon = styled.div`
  width: 20px;
  height: 20px;
  transition: all 0.1s ease-in-out;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-left: auto;
`;

const MenuGroup = styled.ul`
  width: 100%;
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  box-sizing: border-box;
`;

const MenuIcon = styled.div`
  width: 20px;
  height: 20px;
  transition: all 0.1s ease-in-out;
  align-items: center;
  justify-content: center;
  display: flex;
  .isExpanded & {
    display: none;
  }
`;

const MenuClose = styled.button`
  background: #ffffff;
  border: 1px solid #e3e5eb;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(28, 17, 44, 0.0488);
  border-radius: 8px;
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  margin-top: 20px;
  box-sizing: border-box;
  cursor: pointer;
  svg {
    path {
      transition: all 0.2s ease-in-out;
    }
  }
  &:hover {
    svg {
      path {
        stroke: #e0e0e0;
      }
    }
  }
`;

export { MenuItem, MenuText, MenuClose, MenuCustomIcon, MenuGroup, MenuIcon };
