import { call, put } from 'redux-saga/effects';
import { fetchMuscles, fetchEquipment } from './requests';
import {
  fetchMusclesSuccess,
  fetchEquipmentSuccess,
  fetchMusclesFailure,
  fetchEquipmentFailure
} from './actions';

export function* onFetchMuscles() {
  try {
    const response = yield call(fetchMuscles);
    if (response && response.data)
      yield put(fetchMusclesSuccess(response.data.content));
  } catch (error) {
    yield put(fetchMusclesFailure(error));
  }
}

export function* onFetchEquipment() {
  try {
    const response = yield call(fetchEquipment);
    if (response && response.data)
      yield put(fetchEquipmentSuccess(response.data.content));
  } catch (error) {
    yield put(fetchEquipmentFailure(error));
  }
}
