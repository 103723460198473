import styled, { css } from 'styled-components'

const MenuHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  ${(props) =>
    props.isExpanded &&
    css`
      flex-direction: row;
      justify-content: space-between;
    `}
`

const MenuClose = styled.button`
  background: #ffffff;
  border: 1px solid #e3e5eb;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(28, 17, 44, 0.0488);
  border-radius: 8px;
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  margin-top: 20px;
  box-sizing: border-box;
  cursor: pointer;
  svg {
    path {
      transition: all 0.2s ease-in-out;
    }
  }
  &:hover {
    svg {
      path {
        stroke: #e0e0e0;
      }
    }
  }
  .isExpanded & {
    background: #f4f5f8;
    width: 24px;
    height: 24px;
    margin: 0;
    border-radius: 6px;
    box-shadow: none;
    border: 1px solid transparent;
  }
`

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 38px;
  width: 38px;
  box-sizing: border-box;
  border-radius: 8px;
  transition: all 0.3s ease-in-out;
  border: 1px solid #e3e5eb;
  cursor: pointer;
  .logo-small {
    display: flex;
  }
  .isExpanded & {
    border: 1px solid #e3e5eb;
    height: 38px;
    width: 38px;
  }
`

export { LogoContainer, MenuClose, MenuHeaderContainer }
