import React, { useEffect, useState, useContext, useRef } from 'react'
import axios from 'axios'
import { useClickOutside as ClickOutside } from '@hooks/useClickOutside'
import { useSelector } from '@hooks/useSelector'
import { usePortal as Portal } from '@hooks/usePortal'
import { useDispatch } from 'react-redux'
import { useEscape } from '@hooks/useEscape'
import { ExerciseUploaderButton } from '../ExerciseUploaderButton'
import { ExerciseUploaderModal } from '../ExerciseUploaderModal'
import { ExerciseUploaderSize } from '../ExerciseUploaderSize'
import { ExerciseUploaderImages } from '../ExerciseUploaderImages'
import { ExerciseUploaderCropper } from '../ExerciseUploaderCropper'
import { prop } from 'ramda'
import {
  Context as ExerciseUploaderContext,
  Types as ExerciseUploaderTypes,
} from '../ExerciseUploaderProvider'

import {
  clearImageIds,
  createExerciseImageSuccess,
  exerciseSelector,
} from '@state/exercise'

import {
  UploaderOverlay,
  UploaderFieldGroup,
} from './ExerciseUploaderEditor.styled'

import { uuid } from '@utils/uuid'
import { getHeaders } from '@state/utils'

const ExerciseUploaderEditor = () => {
  const [state, localDispatch] = useContext(ExerciseUploaderContext)
  const [blob, setBlob] = useState(null)
  // REDUX
  const activeExercise = useSelector(exerciseSelector.activeExercise)
  const images = prop('images')(activeExercise)
  const exerciseId = prop('exerciseId')(activeExercise)

  const dispatch = useDispatch()

  const {
    isModalOpen,
    imageResult: imageReaderResult,
    imageFile: imageFiles,
    files: localImages,
  } = state

  const isImageLengthUnderMaximum = localImages && localImages.length < 1
  const isImageLengtMinimum = localImages && localImages.length > 0
  const isMounted = useRef(false)
  const onClose = () => {
    localDispatch({
      type: ExerciseUploaderTypes.TOGGLE_MODAL,
      data: false,
    })

    localDispatch({
      type: ExerciseUploaderTypes.SET_IMAGE_READER,
      data: {
        imageReaderResult: null,
        imageFiles: null,
      },
    })
  }

  const onSelectFile = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader()
      await reader.addEventListener('load', () => {
        localDispatch({
          type: ExerciseUploaderTypes.SET_IMAGE_READER,
          data: {
            imageResult: reader.result,
            imageFile: e.target.files[0],
          },
        })
      })
      await reader.readAsDataURL(e.target.files[0])
    }
  }

  useEffect(() => {
    if (imageFiles) {
      async function loadAsyncReader() {
        let reader = new FileReader()
        // Required Files
        await reader.readAsDataURL(imageFiles)
        localDispatch({
          type: ExerciseUploaderTypes.TOGGLE_MODAL,
          data: true,
        })
      }
      loadAsyncReader()
    }
  }, [imageFiles])

  const handleCallback = ({ action }) => {
    if (action === 'CLOSED') {
      localDispatch({
        type: ExerciseUploaderTypes.TOGGLE_MODAL,
        data: false,
      })
    }
  }

  useEscape(() =>
    localDispatch({
      type: ExerciseUploaderTypes.TOGGLE_MODAL,
      data: false,
    }),
  )

  const clearLocalAndSliceStates = () => {
    localDispatch({
      type: ExerciseUploaderTypes.CLEAR_IMAGES,
    })
    dispatch(clearImageIds())
  }

  useEffect(() => {
    clearLocalAndSliceStates()

    if (images && images[0]) {
      const localBlob = images.map((imageId) => {
        getBlobFromImageUrl(imageId)
      })
      setBlob(localBlob)
    }
    isMounted.current = false
  }, [exerciseId])

  // updates the localImages state with imageId and blob resposne
  const updateLocalAndActionCreatorImages = (imageId, blob) => {
    const generatedId = uuid()
    localDispatch({
      type: ExerciseUploaderTypes.ADD_IMAGE,
      data: { uid: generatedId, preview: blob },
    })
    localDispatch({
      type: ExerciseUploaderTypes.SET_IMAGE_READER,
      data: {
        imageReaderResult: null,
        imageFiles: null,
      },
    })
    dispatch(
      createExerciseImageSuccess({
        payload: { uid: generatedId, imageId: imageId },
      }),
    )
  }

  const getBlobFromImageUrl = async (imageId) => {
    const config = {
      url: `https://stagek.api.aima.fit/aima/exercise/download-image/${exerciseId}/${imageId}`,
      method: 'GET',
      headers: getHeaders(['AUTH']),
      responseType: 'blob',
    }
    const blob = await axios(config).then((response) => response.data)
    updateLocalAndActionCreatorImages(imageId, blob)
  }

  return (
    <UploaderFieldGroup>
      {isImageLengtMinimum && <ExerciseUploaderSize images={localImages} />}
      {isImageLengtMinimum && (
        <ExerciseUploaderImages
          localImages={localImages}
          callback={onSelectFile}
        />
      )}
      {isImageLengthUnderMaximum && (
        <ExerciseUploaderButton onChange={onSelectFile} />
      )}
      {isModalOpen && imageReaderResult && (
        <Portal selector="#image-cropper">
          <ClickOutside callback={onClose} mr={0}>
            <ExerciseUploaderModal callback={handleCallback}>
              <ExerciseUploaderCropper type="update" />
            </ExerciseUploaderModal>
          </ClickOutside>
          <UploaderOverlay />
        </Portal>
      )}
    </UploaderFieldGroup>
  )
}

export { ExerciseUploaderEditor }
