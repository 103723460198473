import styled, { css } from 'styled-components';

const TabButtonUnderlined = styled.button`
  background: none;
  border: none;
  line-height: 18px;
  font-size: 14px;
  font-weight: 500;
  padding: 7px 4px;
  outline: none;
  position: relative;
  cursor: pointer;
  color: #a9aeb9;
  &:after {
    content: none;
  }
  &:focus {
    outline: none;
  }
  ${props =>
    props.isActive &&
    css`
      background: transparent;
      line-height: 18px;
      color: #1d2a43;
      font-size: 14px;
      font-weight: 500;
      &:after {
        content: '';
        position: absolute;
        bottom: -2px;
        width: 100%;
        transform: translate(-50%, 0);
        height: 2px;
        background-color: #ff5a45;
        left: 50%;
      }
    `}
`;

const TabUnderlineList = styled.li`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  list-style: none;
  padding: 0;
  margin: 0;
  margin-left: 12px;
  &:first-child {
    margin-left: 0;
  }
  background-color: none;
`;

export { TabButtonUnderlined, TabUnderlineList };
