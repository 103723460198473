/* eslint-disable react/prop-types */
import React from 'react';

const IconPath = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <rect
        x="3.6001"
        y="6.6"
        width="14.4"
        height="8.9"
        rx="1.5"
        stroke="#6C20CC"
      />
      <rect x="1" y="4" width="15.4" height="9.9" rx="2" fill="#FF6E5C" />
      <line x1="1" y1="6.25" x2="16.4" y2="6.25" stroke="#6C20CC" />
      <rect
        x="5.9502"
        y="10.6"
        width="8.8"
        height="1.65"
        rx="0.825"
        fill="white"
      />
    </svg>
  );
};

export default IconPath;
