import React, { useEffect, useCallback, useState, useContext } from 'react'
import { useSelector } from '@hooks/useSelector'
import { useClickOutside as ClickOutside } from '@hooks/useClickOutside'
import { usePortal as Portal } from '@hooks/usePortal'
import { usePrevious } from '@hooks/usePrevious'
import { useEscape } from '@hooks/useEscape'

import { ExerciseUploaderButton } from '../ExerciseUploaderButton'
import { ExerciseUploaderModal } from '../ExerciseUploaderModal'
import { ExerciseUploaderSize } from '../ExerciseUploaderSize'
import { ExerciseUploaderImages } from '../ExerciseUploaderImages'
import { ExerciseUploaderCropper } from '../ExerciseUploaderCropper'
import {
  Context as ExerciseUploaderContext,
  Types as ExerciseUploaderTypes,
} from '../ExerciseUploaderProvider'

import { exerciseSelector } from '@state/exercise'

import {
  ExerciseUploaderOverlay,
  ExerciseUploaderFieldGroup,
} from './ExerciseUploaderCreator.styled'

const ExerciseUploaderCreator = () => {
  const [state, localDispatch] = useContext(ExerciseUploaderContext)
  const imageIds = useSelector(exerciseSelector.images)

  // LOCAL STATE DESTRUCTURE
  const {
    isModalOpen,
    imageResult: imageReaderResult,
    imageFile: imageFiles,
    files: images,
  } = state

  const [derivedImages, setDerivedImages] = useState(images)

  // REDUX
  const prevImages = usePrevious(images) || []
  const isImageLengthUnderMaximum = Boolean(images) && images.length < 1
  const isImageLengtMinimum = images && images.length > 0

  useEffect(() => {
    if (prevImages === images) return
    setDerivedImages(images)
  }, [images])

  const onClose = () => {
    localDispatch({
      type: ExerciseUploaderTypes.TOGGLE_MODAL,
      data: false,
    })

    localDispatch({
      type: ExerciseUploaderTypes.SET_IMAGE_READER,
      data: {
        imageReaderResult: null,
        imageFiles: null,
      },
    })
  }

  const onSelectFile = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader()
      await reader.addEventListener('load', () => {
        localDispatch({
          type: ExerciseUploaderTypes.SET_IMAGE_READER,
          data: {
            imageResult: reader.result,
            imageFile: e.target.files[0],
          },
        })
      })
      await reader.readAsDataURL(e.target.files[0])
    }
  }

  useEffect(() => {
    if (imageFiles) {
      async function loadAsyncReader() {
        let reader = new FileReader()
        // Required Files
        await reader.readAsDataURL(imageFiles)
        localDispatch({
          type: ExerciseUploaderTypes.TOGGLE_MODAL,
          data: true,
        })
      }
      loadAsyncReader()
    }
  }, [imageFiles])

  const handleCallback = ({ action }) => {
    if (action === 'CLOSED') {
      localDispatch({
        type: ExerciseUploaderTypes.TOGGLE_MODAL,
        data: false,
      })
    }
  }

  useEscape(() =>
    localDispatch({
      type: ExerciseUploaderTypes.TOGGLE_MODAL,
      data: false,
    }),
  )

  return (
    <ExerciseUploaderFieldGroup>
      {isImageLengtMinimum && <ExerciseUploaderSize images={images} />}
      {isImageLengtMinimum && (
        <ExerciseUploaderImages
          localImages={derivedImages}
          callback={onSelectFile}
          imageIds={imageIds}
        />
      )}
      {isImageLengthUnderMaximum && (
        <ExerciseUploaderButton onChange={onSelectFile} />
      )}
      {isModalOpen && imageReaderResult && (
        <Portal selector="#image-cropper">
          <ClickOutside callback={onClose} style={{ marginRight: '0px' }}>
            <ExerciseUploaderModal callback={handleCallback}>
              <ExerciseUploaderCropper type="create" />
            </ExerciseUploaderModal>
          </ClickOutside>
          <ExerciseUploaderOverlay />
        </Portal>
      )}
    </ExerciseUploaderFieldGroup>
  )
}

export { ExerciseUploaderCreator }
