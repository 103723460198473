import styled, { css } from 'styled-components'
import { colors, media } from '@common/Theme'

const CardContainer = styled.li`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background: white;
  border: 1px solid rgb(227, 229, 235);
  box-shadow: 0px 2px 5px 0px rgba(28, 17, 44, 0.1);
  box-sizing: border-box;
  border-radius: 6px;
  padding: 16px;
  min-width: 360px;
  flex: 1;
  width: 100%;
  &:first-child {
    margin-left: 0;
  }
  ${media.sm`
    max-width: 360px;
  `};
  ${(props) =>
    props.isActive &&
    css`
      border: 1px solid #1d2a43;
    `};
  .card {
    width: 100%;
    border-radius: 6px 6px;
  }
`

const CardHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-bottom: 16px;
`

const CardDetail = styled.div`
  display: flex;
  margin-top: 20px;
  width: 100%;
  box-sizing: border-box;
`

const CardText = styled.span.attrs({ className: 'text' })`
  color: #a9aeb9;
  font-size: 14px;
  font-weight: 400;
`

const CardControls = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
`

const CardTitle = styled.span`
  font-size: 20px;
  font-weight: 500;
  color: #1d2a43;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
`

const CardButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border: 1px solid #f0f0f0;
  border-radius: 6px;
  transition: all 0.2s ease-in-out;
  span {
    font-size: 14px;
    font-weight: 500;
    color: #a9aeb9;
  }
  &:hover {
    cursor: pointer;
    background: ${colors.orange};
    span {
      color: white;
    }
    svg.PLAY_FILLED {
      path {
        stroke: white;
        fill: white;
      }
    }
  }
`

const CardFooter = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  margin-top: 16px;
  position: relative;
  flex-direction: row;
  ul {
    margin: 0;
    padding: 0;
    display: flex;
    margin-left: 16px;
    gap: 24px;
  }
  li {
    color: #a9aeb9;
    font-size: 14px;
    font-weight: 400;
    display: list-item;
    list-style-type: circle;
    &::marker {
      color: transparent;
    }
  }
`

const Block = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  .text {
    margin-left: 8px;
  }
`

export {
  CardContainer,
  CardHeader,
  CardDetail,
  CardText,
  CardControls,
  CardTitle,
  CardButton,
  CardFooter,
  Block,
}
