import React, { useEffect } from 'react'
import { Main, Header, Content, Layout, Menu } from '@features/Layout'
import { prop, omit } from 'ramda'
import { plFn, ptFn, mtFn, mbFn, colors, media } from '@common/Theme'

import { workoutSelector, fetchWorkouts } from '@state/workout'
import { useSelector, useDispatch } from 'react-redux'
import { settingsSelector } from '@state/settings'
import { userSelector } from '@state/user'
import styled from 'styled-components'
import { Tabs } from '@common/Tabs'
import { useNavigate } from 'react-router-dom'
import { ImageRenderer } from '@components/ImageUploader'
import { Heading } from '@common/Heading'
import { Button } from '@common/Button'
import { Icon } from '@common/Icon'
import { getHighestRole } from '@utils/user'
import { Text } from '@common/Text'

const options = [
  {
    label: 'Profile',
    value: 'profile',
    content: null,
    route: '/profile',
    roles: ['PERSONALTRAINER'],
  },
]

const ProfileContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
`

const ProfileBanner = styled.div`
  .ImageElement {
    width: 100%;
    height: 250px;
    border-radius: 10px;
    ${media.sm`
    width: 100%;
    height: 400px;
    `};
  }
`

const ProfileImage = styled.div`
  min-width: 270px;
  max-width: 270px;
  position: absolute;

  left: 0;
  top: 0;
  width: 100%;
  ${media.sm`
  top: -90px;
  position: relative;
  top: unset;
  `};

  .ImageElement {
    position: absolute;

    border-radius: 50%;
    border: 4px solid white;
    left: 266px;
    transition: all 0.3s ease-in-out;

    width: 160px;
    height: 160px;
    display: none;

    transform: translate(-50%, -50%);

    ${media.sm`
    left: 32px;
    top: -64px;
    width: 220px;
    height: 220px;
    transform: none;
    display: block
    `};
  }
`

const ProfileRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
`

const ProfileColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const ProfileControls = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
`

const ProfileDetail = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  border-bottom: none;
  padding-bottom: 16px;
  ${media.sm`
  min-height: 170px;
  max-height: 170px;
  border-bottom: 1px solid rgb(227, 229, 235);
  `};
`

const ProfileGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 16px 0;
  /* margin-top: 48px; */
  border-bottom: 1px solid rgb(227, 229, 235);
  ${media.sm`
  margin-top: 0;
    border-bottom: none;
  `};
`

const Profile = () => {
  const navigate = useNavigate()
  const user = useSelector(userSelector.user) || {}
  const images = useSelector(userSelector.images)
  const email = prop('email')(user)
  const firstName = prop('firstName')(user) || ''
  const lastName = prop('lastName')(user) || ''

  const userRoles = useSelector(userSelector.roles)
  const role = getHighestRole(userRoles)

  return (
    <Layout variant="CUSTOM">
      <Menu />
      <Main className="Content">
        <Header config={{ isProfileEnabled: true }}>
          <Tabs
            options={options}
            active={'profile'}
            loading={false}
            showPanel={false}
          />
        </Header>
        <Content>
          <ProfileContent>
            <ProfileBanner>
              <ImageRenderer
                variant="banner"
                parentId={email}
                images={images}
                config={{
                  controlType: 'banner',
                }}
                kind="user"
              />
            </ProfileBanner>
            <ProfileDetail>
              <ProfileImage>
                <ImageRenderer
                  variant="thumbnail"
                  parentId={email}
                  images={images}
                  config={{
                    controlType: 'thumbnail',
                  }}
                  kind="user"
                />
              </ProfileImage>
              <ProfileGroup>
                <ProfileColumn>
                  <ProfileRow>
                    <Heading size={28} lineHeight={40} weight={700}>
                      {firstName} {lastName}
                    </Heading>
                    <Text size={16} lineHeight={32} weight={300}>
                      Email: <strong>{email}</strong>
                    </Text>
                    <Text size={16} lineHeight={32} weight={300}>
                      Status: <strong>{role?.roleLabel}</strong>
                    </Text>
                  </ProfileRow>
                </ProfileColumn>
                <ProfileColumn>
                  <ProfileControls>
                    <Button
                      variant="orange"
                      width="unset"
                      alone
                      onClick={() => navigate('/account/profile')}
                    >
                      <span>Edit Profile</span>
                      <Icon ml={5} name="EDIT" fill="white" />
                    </Button>
                  </ProfileControls>
                </ProfileColumn>
              </ProfileGroup>
            </ProfileDetail>
          </ProfileContent>
        </Content>
      </Main>
    </Layout>
  )
}

export { Profile }
