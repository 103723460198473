function filterOptionsByRole(options, roles) {
  // Check if options array or roles array is empty
  if (!options || !options.length || !roles || !roles.length) {
    return []
  }

  // Uppercase all roles
  const uppercasedRoles = roles.map((role) => role.toUpperCase())

  // Filter the options based on roles
  return options.filter((option) => {
    // Check if at least one role matches
    return uppercasedRoles.some((role) => option.roles.includes(role))
  })
}

const getHighestRole = (roles) => {
  if (!roles || !roles.length)
    return {
      message: 'You currently have no role allocated',
      roleLabel: 'No role allocated',
      roleId: 'none',
    }
  if (roles.includes('PersonalTrainer'))
    return {
      message: (
        <span>
          You are currently on the <strong>Trainer</strong> plan
        </span>
      ),
      roleLabel: 'Trainer',
      roleId: 'PersonalTrainer',
    }
  if (roles.includes('Client'))
    return {
      message: (
        <span>
          You are currently on the <strong>Client</strong> plan
        </span>
      ),
      roleLabel: 'Client',
      roleId: 'Client',
    }
  return { message: 'No role allocated', roleLabel: 'No role allocated' }
}

export { filterOptionsByRole, getHighestRole }
