import React, { useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

import { Tabs } from '@common/Tabs'
import { Main, Layout, Content, Header, Menu } from '@features/Layout'
import { prop } from 'ramda'
import { userSelector } from '@state/user'
import { useSelector } from 'react-redux'

import { filterOptionsByRole } from '@utils/user'
import initialOptions from './options'
import styled, { css } from 'styled-components'
import { media } from '@common/Theme'

const Account = () => {
  const [activeId, setActiveId] = useState('profile')
  const navigate = useNavigate()
  const match = useLocation()
  const userRoles = useSelector(userSelector.roles) || []

  const list = filterOptionsByRole(initialOptions, userRoles)

  const handleTabChange = (value) => {
    setActiveId(value)
    const lookup = list.find((route) => route.route.includes(value))
    navigate(lookup.route)
  }

  useEffect(() => {
    const matchedPath = match.pathname
    const lookup = list.find((tab) => matchedPath.includes(tab.value))
    if (!!lookup && prop('value')(lookup)) {
      setActiveId(lookup.value)
    }
  }, [match])

  return (
    <Layout variant="CUSTOM">
      <Menu />
      <Main className="Content">
        <Header config={{ isProfileEnabled: true }}>
          <Tabs
            callback={handleTabChange}
            options={list}
            active={activeId}
            loading={false}
            showPanel={false}
          />
        </Header>
        <Content>
          <Outlet />
        </Content>
      </Main>
    </Layout>
  )
}

export { Account }
