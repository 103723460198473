import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import { Block } from '@common/Block'
import { Text } from '@common/Text'
import { Spinner } from '@common/Spinner'
import { Button } from '@common/Button'
import { prop } from 'ramda'

const { Box } = Block

const SummaryContent = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  align-items: flex-start;
  flex-direction: column;
`

const Discount = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: row;
  padding: 0px 8px;
  border-radius: 6px;
  background: #f7f8fa;
`

const Input = styled.input.attrs({ className: 'field-input' })`
  height: 48px;
  appearance: none;
  border-radius: 4px;
  border: 1px solid transparent;
  background-color: transparent;
  font-size: 16px;
  font-weight: normal;
  line-height: 20px;
  box-sizing: border-box;
  padding: 14px 16px 14px 10px;
  display: block;
  width: 100%;
  background-image: none;
  box-shadow: none;
  font-weight: normal;
  color: #211551;
  font-size: 14px;
  transition: all 0.3s ease-in-out;
  &:hover {
    background-color: transparent;
  }
  &:focus {
    font-weight: normal;
    outline: none;
    border: 1px solid transparent;
    background-color: transparent;
    box-shadow: none;
  }
  &::placeholder {
    color: #9592a8;
    font-weight: normal;
  }
`

const SubTotal = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  color: #121b2d;
`

const Tax = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  color: #121b2d;
`

const SummaryDetail = styled.div`
  margin-left: auto;
`

const BreakdownPrice = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  color: #121b2d;
`

const BreakdownPlan = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  color: #a9aeb9;
`

const SummaryTotal = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  background: #f7f8fa;
  width: 100%;
  border-radius: 8px;
  justify-content: space-between;
  margin-top: 24px;
`

const TotalLabel = styled.span`
  font-weight: 500;
  font-size: 20px;
`

const TotalAmount = styled.span`
  font-weight: 500;
  font-size: 20px;
`

const SummaryRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 16px;
`

const SummaryBreakdown = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 8px;
`

const SubTotalLabel = styled.span`
  font-weight: 400;
  font-size: 14px;
`

const SubTotalAmount = styled.span`
  font-weight: 500;
  font-size: 14px;
`

const TaxLabel = styled.span`
  font-weight: 400;
  font-size: 14px;
`

const TaxAmount = styled.span`
  font-weight: 500;
  font-size: 14px;
`

const DiscountButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 12px;
  background: #121b2d;
  border-radius: 6px;
  border: none;
  outline: none;
  span {
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: white;
  }
`

const Label = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  color: #121b2d;
  margin-bottom: 12px;
`

const SummaryBorder = styled.div`
  border: 1px solid #e3e5eb;
  width: 100%;
  margin-top: 24px;
  margin-bottom: 24px;
`

const textStyle = {
  layout: {
    weight: 500,
    size: '16px',
    lineHeight: '100%',
    color: '#121b2d',
  },
}

const Summary = () => {
  const [value, setValue] = useState('')
  const [response, setResponse] = useState({})

  const loading = true

  const onChange = (e) => {
    const { value } = e.target
    setValue(value)
  }

  useEffect(() => {
    setResponse({
      tax: '1.23',
      subtotal: '18.00',
      total: '19.88',
      plan: '20',
    })
  }, [])

  const plan = prop('plan')(response)
  const subTotalAmount = prop('subtotal')(response)
  const taxAmount = prop('tax')(response)
  const totalAmount = prop('total')(response)

  return (
    <Box>
      <Text config={textStyle}>Summary</Text>
      <SummaryDetail>
        {plan && <BreakdownPrice>${plan}</BreakdownPrice>}
        {plan && <BreakdownPlan>{`/mo`}</BreakdownPlan>}
      </SummaryDetail>
      <SummaryBorder />
      <SummaryContent>
        <Label>Discount Code</Label>
        <Discount>
          <Input placeholder="Enter Code" value={value} onChange={onChange} />
          {loading && (
            <Spinner
              circle="transparent"
              path="#ff5a45"
              size={24}
              horizontalPull={`8px`}
              strokeWidth={2}
            />
          )}
          <DiscountButton>
            <span>Apply</span>
          </DiscountButton>
        </Discount>

        <SummaryBreakdown>
          <SummaryRow>
            <SubTotalLabel>Subtotal</SubTotalLabel>
            {subTotalAmount && (
              <SubTotalAmount>
                {`$`}
                {subTotalAmount}
              </SubTotalAmount>
            )}
          </SummaryRow>
          <SummaryRow>
            <TaxLabel>Tax</TaxLabel>
            {taxAmount && (
              <TaxAmount>
                {`$`}
                {taxAmount || 0}
              </TaxAmount>
            )}
          </SummaryRow>
        </SummaryBreakdown>

        <SummaryTotal>
          <TotalLabel>Total</TotalLabel>
          {totalAmount && <TotalAmount>{totalAmount}</TotalAmount>}
        </SummaryTotal>
        <Button mt={16} alone variant="orange">
          Subscribe to plan
        </Button>
      </SummaryContent>
    </Box>
  )
}

export { Summary }
