import React from 'react'
import styled from 'styled-components'

const Svg = styled.svg`
  transform: rotate(-90deg);
  transition: all 0.2s ease-in-out;
`

const TimerContainer = styled.div`
  display: inline-flex;
  justify-content: flex-start;
  flex-direction: row;
  position: relative;
  transform: rotateY(180deg) scale(1.1);
`

const Circle = styled.circle`
  transition: all 0.2s ease;
`

const svgTheme = {
  fillPos: '#282e32',
  fillNeg: '#282e32',
  activePos: '#3aeeb8',
  activeNeg: '#282e32',
}

const TaskCircle = ({ percentage, isComplete, size = 32, ...props }) => {
  const result = [(percentage * 31.4) / 100, '31.4'].join(',')
  return (
    <TimerContainer {...props}>
      <Svg height={size} width={size} viewBox="0 0 20 20">
        <Circle
          r={5}
          cx={10}
          cy={10}
          fill="transparent"
          strokeLinecap="round"
          stroke="#414a4f"
          strokeWidth={1.5}
        />
        <Circle
          r={5}
          cx={10}
          cy={10}
          fill="transparent"
          strokeLinecap="round"
          stroke={isComplete ? svgTheme.activeNeg : svgTheme.activePos}
          strokeWidth={1.5}
          strokeDasharray={result}
        />
      </Svg>
    </TimerContainer>
  )
}

export { TaskCircle }
