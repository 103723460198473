import React from 'react'
import styled, { css } from 'styled-components'
import {
  basicFunctions,
  basicStyles,
  basicText,
  pseudoStyles,
} from './buttonStyles'
import { mbFn, mtFn, mlFn, mrFn, widthFn } from '@common/Theme'

const OutlineStyle = styled.button`
  ${basicStyles};
  ${basicText};
  ${pseudoStyles}
  ${basicFunctions};
  ${mbFn};
  ${mtFn};
  ${mrFn};
  ${mlFn};
  color: #a9aeb9;
  border: 1px solid #e3e5eb;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  max-height: 48px;
  padding: 12px 16px;
  background: #f7f8fa;
  transition: all 0.2s ease-in-out;
  width: 100%;
  box-sizing: border-box;
  ${widthFn};

  ${(props) =>
    props.alone &&
    css`
      width: unset
      justify-content: center;
      align-items: center;
    `};
  span {
    color: #a9aeb9;
    line-height: 21px;
    width: 100%;
    font-weight: 500;
  }
  &:hover {
    background: #f7f8fa;
    box-shadow: 0px 2px 8px rgba(29, 42, 67, 0.2);
  }
`

const Outline = ({ style, children, ...props }) => (
  <OutlineStyle asBlue style={style} {...props}>
    {children}
  </OutlineStyle>
)

export { Outline }
