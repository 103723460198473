import { Service } from '@features/Auth/Service'

// const auth = Service()

export const authenticate = async () => {
  let data
  // await auth
  //   .handleAuthentication()
  //   .then((response) => {
  //     data = response
  //   })
  //   .catch((err) => {
  //     data = err
  //   })
  return data
}

export const authenticateSignin = async ({ email, password }) => {
  let data
  // await auth
  //   .signInWithCredentials({
  //     email,
  //     password,
  //   })
  //   .then((response) => {
  //     data = response
  //   })
  //   .catch((err) => {
  //     data = err
  //   })
  return data
}

export const authenticateSignup = async ({ email, password }) => {
  let data
  // await auth
  //   .signUpWithCredentials({
  //     email,
  //     password,
  //   })
  //   .then((response) => {
  //     data = response
  //   })
  //   .catch((err) => {
  //     data = err
  //   })
  return data
}
