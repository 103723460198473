import styled, { css } from 'styled-components'
import { colors } from '@common/Theme'

const BaseFieldLabel = css`
  line-height: 16px;
  font-size: 14px;
  font-weight: 500;
  font-family: 'IBM Plex Sans', sans-serif;
  color: #a9aeb9;
  display: block;
  margin: 0 0 8px 0;
`

const TextArea = styled.textarea`
  width: 100%;
  background: white;
  margin-bottom: 16px;
  border-radius: 4px;
  border: 1px solid #e3e5eb;
  background: white;
  font-size: 16px;
  font-weight: normal;
  line-height: 20px;
  padding: 14px 16px 14px 10px;
  display: block;
  width: 100%;
  box-shadow: none;
  color: #778092;
  transition: border 0.3s;
  &:focus {
    outline: none;
    border: 1px solid #ff5a45;
  }
`

const labelColorFn = ({ labelColor }) =>
  labelColor &&
  css`
    color: ${labelColor || '#1d2a43'};
  `

const FieldLabel = styled.span.attrs({ className: 'label' })`
  ${BaseFieldLabel};
  ${labelColorFn};
  ${(props) =>
    props.error &&
    css`
      color: ${colors.grapefruit};
    `}
`

const FieldContainer = styled.div`
  margin-top: 16px;
  width: 100%;
  ${(props) => props.extend};
`

export { FieldLabel, FieldContainer, TextArea }
