import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Formik, Form, Field } from 'formik'
import { string, object } from 'yup'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { useSelector } from '@hooks/useSelector'

import { InputField } from '@common/InputField'
import { Input } from '@common/Input'
import { Button } from '@common/Button'
import { Text } from '@common/Text'
import { Grid, Row, Col } from '@common/Grid'
import { settingsSelector, createPaymentMethodPending } from '@state/settings'

import { CreditCard } from './CreditCard'
import { Block } from '@common/Block'

const buttonStyle = {
  layout: {
    width: '100%',
    maxWidth: '180px',
    justifyContent: 'center',
  },
  text: { textAlign: 'center' },
}

const buttonSaveStyle = {
  layout: {
    width: '100%',
    maxWidth: '180px',
    justifyContent: 'center',
    marginLeft: '8px',
    minWidth: '140px',
  },
  text: { textAlign: 'center' },
}

const PaymentFooter = styled.div``
const PaymentUpdateContainer = styled.div`
  width: 100%;
  #card-number {
    color: #778092;
  }
  #card-expiry {
    border-right: 1px solid #e3e5eb;
    color: #778092;
    &::placeholder {
      color: #a9aeb9;
    }
  }
  .credit-card-input {
    color: #778092;
    &::placeholder {
      color: #a9aeb9;
    }
  }
`

const textStyle = {
  layout: {
    weight: 500,
    size: '16px',
    lineHeight: '100%',
    color: '#121b2d',
  },
}

const PaymentSchema = object().shape({
  firstName: string()
    .max(20, 'Your first name cannot be more than 20 characters in length')
    .min(3, 'Your first name does not meet our length requirements')
    .required('Please enter your first name'),
  lastName: string()
    .max(20, 'Your first name cannot be more than 20 characters in length')
    .min(3, 'Your last name does not meet our length requirements')
    .required('Please enter your last name'),
  country: string()
    .max(20, 'Your country cannot be more than 20 characters in length')
    .required('Please enter your country'),
  postalCode: string()
    .max(4, 'Your postcode cannot be more than 6 characters in length')
    .min(4, 'Your postcode does not look right')
    .required('Please enter your postcode'),
  cardNumber: string()
    .max(16, 'Your card does not look right.')
    .min(16, 'Your card does not look right')
    .required('Please enter your card number'),
  cardCVC: string()
    .max(3, 'Your card does not look right.')
    .min(3, 'Your card does not look right')
    .required('Please enter your CCV'),
})

const ComponentThing = ({ data }) => {
  return <div>Nothing</div>
}

const PaymentUpdate = ({ title, onCancel, isEdit, isDevMode }) => {
  const [errors, setErrors] = useState([])
  const [isLocalDevMode, setDevMode] = useState(isDevMode)
  const isLoading = useSelector(settingsSelector.loading)

  const dispatch = useDispatch()

  const closeError = (error) => {
    setErrors((state) => state.filter((item) => item !== error))
  }

  const initialValues = {
    firstName: '',
    lastName: '',
    postalCode: '',
    country: '',
    cardNumber: '',
    cardCVC: '',
    cardExpiryMonth: '',
    cardExpiryYear: '',
  }

  const handleSubmit = (value) => {
    const {
      firstName,
      lastName,
      postalCode,
      country,
      cardNumber,
      cardExpiryMonth,
      cardExpiryYear,
      cardCVC,
    } = value

    dispatch(
      createPaymentMethodPending({
        cardNumber,
        cardExpiryMonth,
        cardExpiryYear,
        cardCVC,
        firstName,
        lastName,
        country,
        postalCode,
      }),
    )
  }

  const onForce = ({ setFieldValue }) => {
    setFieldValue('firstName', 'John')
    setFieldValue('lastName', 'Cooliez')
    setFieldValue('country', 'AU')
    setFieldValue('postalCode', '2332')
    setFieldValue('cardNumber', '4242424242424242')
    setFieldValue('cardCVC', '323')
    setFieldValue('cardExpiryMonth', '10')
    setFieldValue('cardExpiryYear', '2024')
  }

  const collectCreditPayment = ({ setFieldValue, value }) => {
    const { cardNumber, cardCVC, cardExpiryMonth, cardExpiryYear } = value
    setFieldValue('cardNumber', cardNumber)
    setFieldValue('cardCVC', cardCVC)
    setFieldValue('cardExpiryMonth', cardExpiryMonth)
    setFieldValue('cardExpiryYear', cardExpiryYear)
  }

  const testView = (
    <Formik
      initialValues={initialValues}
      validationSchema={PaymentSchema}
      onSubmit={(values) => handleSubmit(values)}
      validateOnChange
    >
      {({ values, setFieldValue }) => (
        <Form style={{ width: '100%' }}>
          <Row>
            <Col xs={6}>
              <Input
                name="firstName"
                id="firstName"
                label="First name"
                variant="SETTINGS"
                mb={16}
                height={48}
                placeholder="First Name"
                value={values.firstName}
              />
              <Input
                name="lastName"
                id="lastName"
                label="Last name"
                variant="SETTINGS"
                mb={16}
                height={'48px'}
                placeholder="Last Name"
                value={values.lastName}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <Input
                name="cardNumber"
                id="cardNumber"
                label="Card number"
                variant="SETTINGS"
                mb={16}
                height={'48px'}
                placeholder="Card Number"
                value={values.cardNumber}
              />
            </Col>
            <Col xs={6}>
              <Input
                name="cardCVC"
                id="cardCVC"
                label="Card CCV"
                variant="SETTINGS"
                mb={16}
                height={'48px'}
                placeholder="Card CCV"
                value={values.cardCVC}
              />
            </Col>
          </Row>
          <Row mb={24}>
            <Col xs={6}>
              <Input
                name="cardExpiryMonth"
                id="cardExpiryMonth"
                label="Card expiry month"
                variant="SETTINGS"
                mb={16}
                height={'48px'}
                placeholder="MM"
                value={values.cardExpiryMonth}
              />
            </Col>
            <Col xs={6}>
              <Input
                name="cardExpiryYear"
                id="cardExpiryYear"
                label="Card expiry year"
                variant="SETTINGS"
                mb={16}
                height={'48px'}
                placeholder="YYYY"
                value={values.cardExpiryYear}
              />
            </Col>
          </Row>

          <div style={{ display: 'flex', marginLeft: 'auto', width: '100%' }}>
            <Button
              variant="OUTLINE"
              type="button"
              onClick={() => onForce({ setFieldValue })}
              config={buttonStyle}
              mr={8}
            >
              Force Update
            </Button>
            <Button
              variant="OUTLINE"
              type="button"
              onClick={() => setDevMode(false)}
              config={buttonStyle}
              mr={8}
            >
              Cancel
            </Button>
            <Button variant="orange" type="submit" config={buttonStyle} mr={8}>
              Force Save
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )

  return isLocalDevMode ? (
    testView
  ) : (
    <PaymentUpdateContainer>
      <Text config={textStyle}>{title}</Text>
      <Formik
        initialValues={initialValues}
        validationSchema={PaymentSchema}
        onSubmit={(values) => handleSubmit(values)}
        validateOnChange
      >
        {({ setFieldValue }) => (
          <Form>
            <Grid marginX={0} fluid>
              <Row>
                <Col xs={6}>
                  <Field
                    name="firstName"
                    component={InputField}
                    label="First Name"
                    variant="SETTINGS"
                    mb={16}
                    placeholder="First Name"
                  />
                </Col>
                <Col xs={6}>
                  <Field
                    name="lastName"
                    component={InputField}
                    label="Last Name"
                    mb={16}
                    variant="SETTINGS"
                    placeholder="Last Name"
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12}>
                  <CreditCard
                    callback={(value) =>
                      collectCreditPayment({ setFieldValue, value })
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={6}>
                  <Field
                    name="country"
                    component={InputField}
                    label="Country"
                    variant="SETTINGS"
                    mb={16}
                    placeholder="Enter Country"
                  />
                </Col>
                <Col xs={6}>
                  <Field
                    name="postalCode"
                    component={InputField}
                    label="Post Code"
                    mb={16}
                    variant="SETTINGS"
                    placeholder="Enter post code"
                  />
                </Col>
              </Row>
              <Row mt={24}>
                <Col xs={12} sm={8}>
                  <PaymentFooter>
                    <Text
                      weight={400}
                      color="#A9AEB9"
                      size={12}
                      lineHeight="18"
                    >
                      * You can cancel your subscription at any time,. Once
                      cancelled, your subscription will expire at the end of the
                      subscription period from which you started so you can use
                      this service until the next billing date
                    </Text>
                  </PaymentFooter>
                </Col>
                <Col xs={12} sm={4}>
                  <Block.Row
                    style={{
                      marginLeft: 'auto',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                    }}
                  >
                    {isEdit && (
                      <>
                        <Button
                          variant="OUTLINE"
                          type="button"
                          onClick={onCancel}
                          config={buttonStyle}
                        >
                          Cancel
                        </Button>
                      </>
                    )}
                    <Button
                      variant={`PRIMARY`}
                      type="submit"
                      mb={16}
                      mt={16}
                      config={buttonSaveStyle}
                    >
                      Confirm Card
                    </Button>
                  </Block.Row>
                </Col>
              </Row>
            </Grid>
          </Form>
        )}
      </Formik>
    </PaymentUpdateContainer>
  )
}

PaymentUpdate.defaultProps = {
  title: 'Payment Details',
  onCancel: () => {},
  isEdit: false,
  isDevMode: true,
}

PaymentUpdate.propTypes = {
  title: PropTypes.string,
  onCancel: PropTypes.func,
  isEdit: PropTypes.bool,
  isDevMode: PropTypes.bool,
}

export { PaymentUpdate }
