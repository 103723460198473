import {
  AUTH_FAILURE,
  AUTH_SIGNIN_FAILURE,
  AUTH_SIGNUP_FAILURE,
} from '../actionTypes';

export const authFailure = error => ({
  type: AUTH_FAILURE,
  payload: error,
});

export const authSigninFailure = error => ({
  type: AUTH_SIGNIN_FAILURE,
  payload: error,
});

export const authSignupFailure = error => ({
  type: AUTH_SIGNUP_FAILURE,
  payload: error,
});
