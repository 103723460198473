import React, { forwardRef, useEffect, useRef, useState } from 'react'
import { useOnClickOutside } from '@hooks/useOnClickOutside'
import { useDispatch } from 'react-redux'
import { useSelector } from '@hooks/useSelector'
import { prop } from 'ramda'
import {
  EditInputGroup,
  EditInputField,
  EditContainer,
  EditHeading,
} from './ControlName.styled'

import { updateWorkout, workoutSelector } from '@state/workout'
import { useEscape } from '@hooks/useEscape'
import { Icon } from '@common/Icon'

const InputField = forwardRef(
  ({ onChange, value, focus, onKeyPress, ...props }, ref) => (
    <EditInputGroup>
      <EditInputField
        type="text"
        ref={ref}
        onChange={onChange}
        value={value}
        onKeyPress={onKeyPress}
        focus={focus}
        {...props}
      />
    </EditInputGroup>
  ),
)

const keyCodes = {
  ENTER: 'Enter',
}

const ControlName = () => {
  const ref = useRef()
  const refClickOutside = useRef()
  const [isEditMode, setEditMode] = useState(false)
  const [value, setValue] = useState('')

  const dispatch = useDispatch()
  const activeWorkout = useSelector(workoutSelector.activeWorkout)
  const { workoutName = 'New Workout' } = activeWorkout || {}

  const handleValue = (value) => {
    const updatedWorkout = {
      ...activeWorkout,
      workoutName: value,
    }
    if (!prop('workoutId')(activeWorkout)) {
      console.warn('No workoutId found in [ControlName.js]')
      return
    }
    setValue(value)
    dispatch(updateWorkout(updatedWorkout))
  }

  const handleValidation = (value) => {
    if (!value || !value.length) {
      handleValue('New Workout')
      return
    }
    handleValue(value)
  }

  useOnClickOutside(refClickOutside, () => {
    if (isEditMode === false) return
    handleValidation(value)
    setEditMode(false)
  })

  useEffect(() => {
    onFocus()
    if (workoutName !== value) {
      setValue(workoutName)
    }
    // eslint-disable-next-line
  }, [isEditMode, workoutName])

  const onChange = (e) => {
    const { value } = e.target
    setValue(value)
  }

  const onFocus = () => {
    if (ref.current) {
      ref.current.focus()
    }
  }

  const handleFocus = () => {
    setEditMode(true)
    onFocus()
  }

  const onKeyPress = (e) => {
    const key = e.keyCode || e.charCode
    if (key === keyCodes.ENTER || key === 13) {
      setEditMode(false)
      handleValidation(value)
    }
  }

  useEscape(() => setEditMode(false))

  useEffect(() => {
    onFocus()
  }, [isEditMode])

  return (
    <EditContainer
      className="editContainer"
      tabindex={1}
      ref={refClickOutside}
      onClick={handleFocus}
    >
      <Icon name="EDIT" mr={5} size={20} />
      {isEditMode ? (
        <InputField
          value={value}
          ref={ref}
          onChange={onChange}
          maxLength={50}
          onFocus={onFocus}
          onKeyPress={onKeyPress}
          focus={isEditMode}
          className="editField"
          id={`edit-heading-1`}
        />
      ) : (
        <EditHeading className="editHeading">{value}</EditHeading>
      )}
    </EditContainer>
  )
}

export { ControlName }
