import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from '@common/Icon'
import { BackContainer, BackLabel } from './Back.styled'

const Back = ({ onPress = () => {}, mb, mt, label = '' }) => (
  <BackContainer onClick={onPress} mb={mb} mt={mt}>
    <Icon name="RETURN" stroke="#1D2A43" size={20} />
    <BackLabel>{label || 'Go Back'}</BackLabel>
  </BackContainer>
)

Back.propTypes = {
  onPress: PropTypes.func,
  label: PropTypes.string,
}

export { Back }
