import styled, { css } from 'styled-components'
import { allDefaultStyles } from './utils'
import { colors } from '@common/Theme'

const Copy = styled.button`
  ${allDefaultStyles};
  background: ${colors.purple};
  border: 1.5px solid ${colors.purple};
  background: transparent;
  span {
    color: ${colors.purple};
  }
  svg {
    path {
      stroke: ${colors.purple};
    }
    rect {
      stroke: ${colors.purple};
    }
  }
  svg.REFRESH {
    path {
      stroke: none;
      fill: ${colors.purple};
    }
    rect {
      stroke: none;
      fill: ${colors.purple};
    }
  }
  &:hover {
    background: #b03aee8c;
    span {
      color: #b681d2;
    }
    svg {
      path {
        stroke: #b681d2;
      }
      rect {
        stroke: #b681d2;
      }
    }
    svg.REFRESH {
      path {
        stroke: none;
        fill: ${colors.purple};
      }
      rect {
        stroke: none;
        fill: ${colors.purple};
      }
    }
  }
  ${(props) =>
    props.isLoading &&
    css`
      width: 115px;
    `};
  ${(props) => props.extend};
`

export { Copy }
