export const SETTINGS_GET_ACTIVE_SUBSCRIPTIONS_PENDING =
  'SETTINGS_GET_ACTIVE_SUBSCRIPTIONS_PENDING'
export const SETTINGS_GET_ACTIVE_SUBSCRIPTIONS_SUCCESS =
  'SETTINGS_GET_ACTIVE_SUBSCRIPTIONS_SUCCESS'
export const SETTINGS_GET_ACTIVE_SUBSCRIPTIONS_FAILURE =
  'SETTINGS_GET_ACTIVE_SUBSCRIPTIONS_FAILURE'

export const SETTINGS_GET_ACTIVE_PRICES_PENDING =
  'SETTINGS_GET_ACTIVE_PRICES_PENDING'
export const SETTINGS_GET_ACTIVE_PRICES_SUCCESS =
  'SETTINGS_GET_ACTIVE_PRICES_SUCCESS'
export const SETTINGS_GET_ACTIVE_PRICES_FAILURE =
  'SETTINGS_GET_ACTIVE_PRICES_FAILURE'

export const SETTINGS_UPDATE_QUOTE_PRICE_PENDING =
  'SETTINGS_UPDATE_QUOTE_PRICE_PENDING'
export const SETTINGS_UPDATE_QUOTE_PRICE_SUCCESS =
  'SETTINGS_UPDATE_QUOTE_PRICE_SUCCESS'
export const SETTINGS_UPDATE_QUOTE_PRICE_FAILURE =
  'SETTINGS_UPDATE_QUOTE_PRICE_FAILURE'

export const SETTINGS_UPDATE_QUOTE_QUANTITY_PENDING =
  'SETTINGS_UPDATE_QUOTE_QUANTITY_PENDING'
export const SETTINGS_UPDATE_QUOTE_QUANTITY_SUCCESS =
  'SETTINGS_UPDATE_QUOTE_QUANTITY_SUCCESS'
export const SETTINGS_UPDATE_QUOTE_QUANTITY_FAILURE =
  'SETTINGS_UPDATE_QUOTE_QUANTITY_FAILURE'

export const SETTINGS_GENERATE_DRAFT_QUOTE_PENDING =
  'SETTINGS_GENERATE_DRAFT_QUOTE_PENDING'
export const SETTINGS_GENERATE_DRAFT_QUOTE_SUCCESS =
  'SETTINGS_GENERATE_DRAFT_QUOTE_SUCCESS'
export const SETTINGS_GENERATE_DRAFT_QUOTE_FAILURE =
  'SETTINGS_GENERATE_DRAFT_QUOTE_FAILURE'

export const SETTINGS_CREATE_PAYMENT_METHOD_PENDING =
  'SETTINGS_CREATE_PAYMENT_METHOD_PENDING'
export const SETTINGS_CREATE_PAYMENT_METHOD_SUCCESS =
  'SETTINGS_CREATE_PAYMENT_METHOD_SUCCESS'
export const SETTINGS_CREATE_PAYMENT_METHOD_FAILURE =
  'SETTINGS_CREATE_PAYMENT_METHOD_FAILURE'

export const SETTINGS_GET_ALL_PAYMENT_METHODS_PENDING =
  'SETTINGS_GET_ALL_PAYMENT_METHODS_PENDING'
export const SETTINGS_GET_ALL_PAYMENT_METHODS_SUCCESS =
  'SETTINGS_GET_ALL_PAYMENT_METHODS_SUCCESS'
export const SETTINGS_GET_ALL_PAYMENT_METHODS_FAILURE =
  'SETTINGS_GET_ALL_PAYMENT_METHODS_FAILURE'

export const SETTINGS_UPDATE_QUOTE_APPLY_COUPON_PENDING =
  'SETTINGS_UPDATE_QUOTE_APPLY_COUPON_PENDING'
export const SETTINGS_UPDATE_QUOTE_APPLY_COUPON_SUCCESS =
  'SETTINGS_UPDATE_QUOTE_APPLY_COUPON_SUCCESS'
export const SETTINGS_UPDATE_QUOTE_APPLY_COUPON_FAILURE =
  'SETTINGS_GET_ALL_PAYMENT_METHODS_FAILURE'

export const SETTINGS_UPDATE_DEVMODE_PENDING = 'SETTINGS_UPDATE_DEVMODE_PENDING'
