/* eslint-disable react/prop-types */
import React from 'react'

const IconPath = (props) => {
  const { stroke = '#A9AEB9' } = props
  return (
    <svg width="34" height="34" viewBox="0 0 34 34" fill="none">
      <circle cx="17" cy="17" r="17" fill="#F1F2F5" />
      <path
        d="M6.46629 29.4495C6.46629 27.794 6.46629 13.9298 6.46629 7.20462"
        stroke="#1D2A43"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.3278 29.4495C26.3278 27.794 26.3278 13.9298 26.3278 7.20462"
        stroke="#1D2A43"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.08292 7.20462C5.94528 7.20462 21.5425 7.20462 29.1084 7.20462"
        stroke="#1D2A43"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.08294 27.0661C5.94801 27.0661 18.7509 27.0661 26.3278 27.0661"
        stroke="#1D2A43"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.157 27.0661H28.1709"
        stroke="#1D2A43"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.3278 5.27411L26.3278 3.28796"
        stroke="#1D2A43"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.46629 5.27411L6.46629 3.28796"
        stroke="#1D2A43"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="8.8576"
        y="10.1044"
        width="3.40823"
        height="3.40823"
        rx="0.5"
        stroke="#1D2A43"
      />
      <rect
        x="8.8576"
        y="15.7848"
        width="3.40823"
        height="3.40823"
        rx="0.5"
        stroke="#1D2A43"
      />
      <rect
        x="8.8576"
        y="21.4652"
        width="3.40823"
        height="3.40823"
        rx="0.5"
        stroke="#1D2A43"
      />
      <rect
        x="14.538"
        y="10.1044"
        width="3.40823"
        height="3.40823"
        rx="0.5"
        stroke="#1D2A43"
      />
      <rect
        x="14.538"
        y="15.7848"
        width="3.40823"
        height="3.40823"
        rx="0.5"
        stroke="#FF5A45"
      />
      <rect
        x="14.538"
        y="21.4652"
        width="3.40823"
        height="3.40823"
        rx="0.5"
        stroke="#FF5A45"
      />
      <rect
        x="20.2184"
        y="10.1044"
        width="3.40823"
        height="3.40823"
        rx="0.5"
        stroke="#FF5A45"
      />
      <rect
        x="20.2184"
        y="15.7848"
        width="3.40823"
        height="3.40823"
        rx="0.5"
        stroke="#1D2A43"
      />
      <rect
        x="20.2184"
        y="21.4652"
        width="3.40823"
        height="3.40823"
        rx="0.5"
        stroke="#1D2A43"
      />
      <path
        d="M2.19897 30.1744L6.94323 32.9135C7.30053 33.1198 7.7574 32.9973 7.96369 32.64C8.37626 31.9254 8.13142 31.0117 7.41683 30.5991L3.96645 28.607C3.25186 28.1945 2.33811 28.4393 1.92553 29.1539C1.71925 29.5112 1.84167 29.9681 2.19897 30.1744Z"
        fill="#FF5A45"
        stroke="#FF5A45"
      />
      <path
        d="M5.82063 28.6712L6.51266 29.0707C7.47043 29.6237 8.65476 29.6049 9.59448 29.0218L10.0564 28.7351C10.9131 28.2035 11.4455 27.277 11.4734 26.2691L11.6174 21.0722C11.6393 20.2836 10.782 19.7814 10.1048 20.1862L5.74773 22.7903C4.79765 23.3581 4.23742 24.403 4.29024 25.5085L4.32405 26.2163C4.37276 27.2359 4.9366 28.1608 5.82063 28.6712Z"
        fill="#FF5A45"
      />
      <path
        d="M11.1297 20.3291L8.18169 25.2901"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="8.05722"
        cy="25.5058"
        r="0.747028"
        transform="rotate(30 8.05722 25.5058)"
        fill="white"
      />
    </svg>
  )
}

export default IconPath
