import React, {
  useState,
  useCallback,
  useRef,
  useEffect,
  useContext,
} from 'react'
import { useDispatch } from 'react-redux'
// import ReactCrop from 'react-image-crop'
import { ErrorList } from '@common/ErrorList'
import { Loading } from '@common/Loading'
import { uuid } from '@utils/uuid'

import {
  Context as UploaderContext,
  Types as UploaderTypes,
} from '../ExerciseUploaderProvider'

import {
  UploaderContainer,
  UploaderWrapper,
  UploaderSpacer,
  UploaderCanvas,
} from './ExerciseUploaderCropper.styled'

import { ExerciseUploaderFooter } from './ExerciseUploaderFooter'
import {
  createExerciseImage,
  fetchExercisesFailure,
  updateExerciseImage,
} from '@state/exercise'

const IMAGE_ERROR = 'IMAGE_ERROR'
const IMAGE_SAVE_ERROR = 'IMAGE_SAVE_ERROR'
const IMAGE_PREVIEW_ERROR = 'IMAGE_PREVIEW_ERROR'

const errorOptions = [
  {
    name: IMAGE_ERROR,
    message: 'There was a problem with your upload.',
  },
  {
    name: IMAGE_SAVE_ERROR,
    message: 'Looks like you might need to try again.',
  },
  {
    name: IMAGE_PREVIEW_ERROR,
    message: 'Looks like we had a problem with that image, please try again.',
  },
]

const ExerciseUploaderCropper = ({ type }) => {
  const [state, localDispatch] = useContext(UploaderContext)

  const dispatch = useDispatch()

  const { imageResult: imageReaderResult, imageLoading } = state

  const [localCompletedCrop, setCompletedCrop] = useState(null)
  const [imageLoaded, setLoadedState] = useState(false)
  const imgRef = useRef(null)
  const [errors, setError] = useState([])
  const previewCanvasRef = useRef(null)
  const [crop, setCroppedImage] = useState({
    unit: 'px',
    width: 168,
    height: 160,
    // aspect: 16 / 9,
  })

  const onLoad = useCallback((img) => {
    imgRef.current = img
    setLoadedState(true)
  }, [])

  useEffect(() => {
    if (!localCompletedCrop || !previewCanvasRef.current || !imgRef.current) {
      return
    }

    const imageCurrent = imgRef.current
    const canvas = previewCanvasRef.current
    const completedCroppedImage = localCompletedCrop

    const scaleX = imageCurrent.naturalWidth / imageCurrent.width
    const scaleY = imageCurrent.naturalHeight / imageCurrent.height
    const ctx = canvas.getContext('2d')
    const pixelRatio = window.devicePixelRatio

    canvas.width = completedCroppedImage.width * pixelRatio * scaleX
    canvas.height = completedCroppedImage.height * pixelRatio * scaleY

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0)
    ctx.imageSmoothingQuality = 'high'

    ctx.drawImage(
      imageCurrent,
      completedCroppedImage.x * scaleX,
      completedCroppedImage.y * scaleY,
      completedCroppedImage.width * scaleX,
      completedCroppedImage.height * scaleY,
      0,
      0,
      completedCroppedImage.width * scaleX,
      completedCroppedImage.height * scaleY,
    )
    // eslint-disable-next-line
    ;() => {
      setLoadedState(false)
    }
  }, [localCompletedCrop])

  useEffect(() => {
    if (!focus) return
    document.addEventListener('keydown', handleSave, false)
    return () => {
      document.removeEventListener('keydown', handleSave, false)
    }
    // eslint-disable-next-line
  }, [focus])

  const handleSave = async () => {
    if (!previewCanvasRef || !previewCanvasRef.current) {
      setError([IMAGE_ERROR])
      return
    }
    if (!localCompletedCrop) {
      setError([IMAGE_SAVE_ERROR])
      return
    }

    await previewCanvasRef.current.toBlob(
      (blob) => {
        const generatedId = uuid()
        localDispatch({
          type: UploaderTypes.ADD_IMAGE,
          data: { uid: generatedId, preview: blob },
        })
        localDispatch({
          type: UploaderTypes.SET_IMAGE_READER,
          data: {
            imageReaderResult: null,
            imageFiles: null,
          },
        })
        if (type.toUpperCase() === 'UPDATE') {
          dispatch(updateExerciseImage({ uid: generatedId, rawImage: blob }))
        } else {
          dispatch(createExerciseImage({ uid: generatedId, rawImage: blob }))
        }
      },
      'image/jpeg',
      0.5,
    )

    localDispatch({
      type: UploaderTypes.TOGGLE_MODAL,
      data: false,
    })
  }

  const onCancel = () => {
    localDispatch({
      type: UploaderTypes.TOGGLE_MODAL,
      data: false,
    })
  }

  const renderCropper = ({ imageReaderResult = null, crop = null }) => {
    if (!imageReaderResult || !crop) return null
    return (
      <UploaderSpacer>
        {/* <ReactCrop
          src={imageReaderResult}
          onChange={setCroppedImage}
          onComplete={setCompletedCrop}
          onImageLoaded={onLoad}
        >
          <img className="cropper" src={imageReaderResult} />
        </ReactCrop> */}
      </UploaderSpacer>
    )
  }

  return (
    <>
      <UploaderWrapper>
        <UploaderContainer>
          {imageLoading && <Loading />}
          {renderCropper({ imageReaderResult, crop })}
          <UploaderCanvas
            ref={previewCanvasRef}
            width={Math.round(
              localCompletedCrop?.width ? localCompletedCrop.width : 0,
            )}
            height={Math.round(
              localCompletedCrop?.height ? localCompletedCrop.height : 0,
            )}
          />
          <ErrorList haystack={errorOptions} needles={errors} />
        </UploaderContainer>
      </UploaderWrapper>
      {imageLoaded && (
        <ExerciseUploaderFooter onSave={handleSave} onCancel={onCancel} />
      )}
    </>
  )
}

export { ExerciseUploaderCropper }
