import { useState, useEffect } from 'react'

const useDelayResize = (delay = 300) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => {
      const timeoutId = setTimeout(() => {
        setWindowWidth(window.innerWidth)
      }, delay)

      return () => clearTimeout(timeoutId)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [delay])

  return windowWidth
}

export { useDelayResize }
