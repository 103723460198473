import React, { useContext, useEffect } from 'react'

import { LayoutContext } from '../LayoutProvider'
import { MenuHeader } from './MenuHeader'
import { MenuFooter } from './MenuFooter'
import { MenuBody } from './MenuBody'
import { MenuMobile } from './MenuMobile'

import { MenuContainer, MenuStyled, MenuContent } from './Menu.styled'
import { useDisableBodyScroll } from '@hooks/useDisableBodyScroll'

const Menu = () => {
  const [state, setState] = useContext(LayoutContext)
  const { menuExpanded } = state

  useDisableBodyScroll(menuExpanded)

  return (
    <>
      <MenuMobile />
      <MenuContainer
        isExpanded={menuExpanded}
        className={menuExpanded ? 'Menu isExpanded' : 'Menu'}
      >
        <MenuStyled>
          <MenuContent>
            <MenuHeader />
            <MenuBody />
            <MenuFooter />
          </MenuContent>
        </MenuStyled>
      </MenuContainer>
    </>
  )
}

export { Menu }
