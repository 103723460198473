import styled, { css, keyframes } from 'styled-components'
import {
  mbFn,
  mtFn,
  mlFn,
  mrFn,
  plFn,
  prFn,
  colors,
  media,
} from '@common/Theme'

const pulseCircle = keyframes`
  0% {
    
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }

  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.2);
    opacity: 0;
  }
`

const options = {
  // about: {
  //   id: 'about',
  //   value: 'about',
  //   route: '/about',
  //   label: 'About',
  //   active: true,
  //   icon: 'DASHBOARD',
  //   roles: ['CLIENT', 'PERSONALTRAINER'],
  // },
  // updates: {
  //   id: 'updates',
  //   value: 'updates',
  //   route: '/updates',
  //   label: 'updates',
  //   active: true,
  //   icon: 'DASHBOARD',
  //   roles: ['CLIENT', 'PERSONALTRAINER'],
  // },
}

const Navbar = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  .TabContainer {
    display: none;
    ${media.sm`
    display: flex;
    `};
  }
  .Controls {
    margin-left: auto;
    justify-content: flex-end;
    button.login-button {
      span {
        font-size: 18px;
      }
    }
  }
`
const Wrapper = styled.div`
  &.wrapper {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }

  .footer,
  .navbar,
  .content {
    padding: 20px;
    text-align: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
`
const Content = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1 1 0%;
  a {
    /* text-decoration: none; */
    color: ${colors.orange};
    span.link {
      font-weight: 500;
      color: ${colors.orange};
      cursor: pointer;
    }
  }
  background-color: whitesmoke;
  position: relative;
  button.arrow-button {
    position: absolute;
    left: 50%;
    bottom: 50px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border: 1.5px solid black;
    min-height: 60px;
    transform: translateX(-50%);
  }
`

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-sizing: border-box;
  border-radius: 8px;
  transition: all 0.3s ease-in-out;
  height: 38px;
  width: 38px;
  border: 1px solid #e3e5eb;
  div.logo-small {
    display: flex;
  }
  div.logo-full {
    display: none;
  }
  ${media.sm`
    height: unset;
    width: unset;
    border: 1px solid transparent;
    div.logo-full {
      display: flex;
    }
    div.logo-small {
      display: none;
    }
  `};
`

const FloatingBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  margin-bottom: 48px;
  margin-top: 48px;
`

const CardGroup = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  gap: 16px;
  ${media.sm`
  flex-direction: row;
  `};
`
const Card = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: white;
  background: white;
  border-radius: 12px;
  overflow: hidden;
  max-width: 480px;
  border: 1px solid #e3e5eb;
  &.Trainer {
    border: 1px solid ${colors.black};
  }
  .Header {
    padding: 8px;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
    background: ${colors.orange};
    &.Trainer {
      background: ${colors.black};
    }
  }
  .Content {
    padding: 24px 32px 24px 32px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    p {
      span.period {
        position: relative;
        top: -2px;
        font-size: 18px;
        font-weight: 300;
      }
    }
  }
  .Row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 24px;
  }
  .CardIcon {
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-bottom: 16px;
    &:after {
      content: '';
      position: absolute;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      border: 1px solid black;
      left: 50%;
      transform: translateX(50%);
      top: 50%;
      animation: ${pulseCircle} 1500ms ease-in 0s infinite alternate;
    }
  }
  .Footer {
    padding: 24px 32px 24px 32px;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
`

const Footer = styled.footer``

export {
  options,
  Navbar,
  Wrapper,
  Content,
  LogoContainer,
  FloatingBlock,
  CardGroup,
  Card,
  Footer,
}
