import { useState, useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'

const types = {
  PENDING: 'PENDING',
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE',
}

const useAuth = () => {
  const { getAccessTokenSilently, isAuthenticated, loginWithRedirect } =
    useAuth0()

  const [authStatus, setAuthStatus] = useState(null)

  useEffect(() => {
    const checkAuthSession = async () => {
      if (isAuthenticated) {
        try {
          await getAccessTokenSilently({ cacheMode: 'default' })
          setAuthStatus(types.SUCCESS)
        } catch (error) {
          console.log('Error refreshing access token:', error)
          setAuthStatus(types.FAILURE)
          // Redirect the user to the login page
          loginWithRedirect()
        }
      }
    }
    setAuthStatus(types.PENDING)
    checkAuthSession()
  }, [getAccessTokenSilently, isAuthenticated, loginWithRedirect])

  return {
    authStatus,
    types,
    isAuthenticated,
  }
}

export { useAuth }
