import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from '@common/Button'
import { Block } from '@common/Block'
import { Text } from '@common/Text'
import { Heading } from '@common/Heading'
import { Image } from '@common/Image'
import { useBackgroundColor } from '@hooks/useBackgroundColor'
import { useAuth0 } from '@auth0/auth0-react'

const isNext = true
const baseUrl = isNext ? '' : './public/'

const imageConfig = (type) =>
  ({
    INVITE: `${baseUrl}assets/icons/invite.svg`,
  }[type || 'INVITE'])

const Validate = ({ config }) => {
  const { isDevMode = false } = config || {}
  const navigate = useNavigate()
  const setBackgroundColor = useBackgroundColor('white')

  const { loginWithRedirect } = useAuth0()

  useEffect(() => {
    setBackgroundColor('#F7F8FA')
    return () => setBackgroundColor('transparent')
  }, [])

  return (
    <Block.Center isEnabled={!isDevMode}>
      <Block.Container column shadow>
        <Image
          src={imageConfig('INVITE')}
          width="100px"
          height="100px"
          border={`50%`}
          bgSize={'100%'}
        />
        <Heading mb={16} variant="PAGE">
          Invite on the way!
        </Heading>

        <Text
          element="p"
          ellipses
          size={`16px`}
          weight={300}
          horizontalPush={`6px`}
          lineHeight={`26px`}
          color="#606A7E"
        >
          {`We have sent an invite to your email. Please check your inbox and follow the instructions to complete your registration.`}
        </Text>

        <br />
        <Button
          variant="orange"
          mb={16}
          type="button"
          onClick={() => loginWithRedirect()}
          alone
        >
          <span>Login</span>
        </Button>
      </Block.Container>
    </Block.Center>
  )
}

export { Validate }
