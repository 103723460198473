import React, { useState, useRef, createRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Container,
  Group,
  IconContainer,
  CustomButton,
  Label,
  SelectedItemContainer,
} from './MultiSelect.styled'

import { Icon } from '@common/Icon'
import { curry, map, assoc, prop } from 'ramda'
import { alter } from '@utils/ramda'
import { Checkmark } from './Checkmark'
const alterAll = curry((state, items) => map(assoc('active', state), items))

const SelectedItem = ({
  item,
  onSelect = () => {},
  trimCount,
  showCheckbox,
  isCopied,
  labelKey,
  disabled,
  children,
  ...props
}) => {
  const mightTrim = (count) => (str) =>
    str && str.length > count ? str.slice(0, count) : str
  const isActive = item.active
  const appendedClassName = isActive ? 'active' : 'in-active'

  const label =
    !labelKey || !labelKey.length ? prop('label')(item) : prop(labelKey)(item)

  const renderIcon = () => {
    if (!showCheckbox) return null
    return isActive ? (
      <IconContainer
        className={`IconContainer ${appendedClassName}`}
        color="blue"
      >
        <Icon name="CHECKBOX_FILLED" size={20} />
      </IconContainer>
    ) : (
      <IconContainer className={`IconContainer ${appendedClassName}`}>
        <Icon name="CHECKBOX_OUTLINE" viewBox="-1 -1 20 20" size={20} />
      </IconContainer>
    )
  }

  const handleSelect = (item) => {
    if (!!disabled) return
    onSelect(item)
  }

  const renderLabel = () => {
    if (!label || !label.length) return null
    if (!!trimCount && trimCount > 0) {
      return mightTrim(trimCount)(label)
    }
    return label || 'Empty'
  }

  return (
    <SelectedItemContainer
      onClick={() => handleSelect(item)}
      showCheckbox={showCheckbox}
      isCopied={isCopied}
      className={`SelectedItemContainer ${appendedClassName} ${
        disabled ? 'disabled' : ''
      }`}
      isActive={isActive}
      {...props}
    >
      <span className={`SelectedItemLabel ${appendedClassName}`}>
        {renderLabel()}
      </span>
      {renderIcon()}
      {children}
    </SelectedItemContainer>
  )
}

SelectedItem.defaultProps = {
  trimCount: 50,
}

const MultiSelect = ({
  options: rawOptions = [],
  callback = () => {},
  label,
  name,
  config,
  ...props
}) => {
  const {
    isToggleAllEnabled,
    initialiseAll,
    labelKey,
    showCheckbox,
    isCopyVariant,
    singleSelect,
  } = config

  const [isAllInitialised, setInitialiseAll] = useState(initialiseAll)
  const [copiedIndex, setCopiedIndex] = useState(null)
  const options = rawOptions

  const textAreaRefs = useRef([])

  textAreaRefs.current = options.map((_, index) =>
    textAreaRefs.current[index] ? textAreaRefs.current[index] : createRef(),
  )

  const activeState = () => {
    if (!isAllInitialised) return false
    const foundNegative = options.filter((f) => !f.active)
    if (foundNegative.length > 0) {
      return false
    }

    return true
  }

  const toggleSelectAll = () => {
    if (activeState()) {
      setInitialiseAll(false)
      const disableAllItems = alterAll(false, options)
      callback({ name, value: disableAllItems })
      return
    }
    setInitialiseAll(true)
    const enableAllItems = alterAll(true, options)
    callback({ name, value: enableAllItems })
  }

  const handleSinglSelect = (item) => {
    const disableAllItems = alterAll(false, options)
    const newData = alter(true, item.id, disableAllItems)
    callback({ name, value: newData })
  }

  useEffect(() => {
    if (isCopyVariant && !!copiedIndex) {
      setTimeout(() => {
        setCopiedIndex(null)
      }, 800)
    }
  }, [copiedIndex])

  const onLocalSelect = (item, index) => {
    if (isCopyVariant && textAreaRefs && textAreaRefs?.current[index]) {
      textAreaRefs.current[index]?.current.select()
      document.execCommand('copy')
      setCopiedIndex(index)
    }
    if (Boolean(singleSelect)) {
      return handleSinglSelect(item, index)
    }
    // const isFound = options.find((f) => {
    //   const result = f.id === item.id || f.sequence === item.sequence
    //   return result
    // })
    // if (!!isFound && !!isFound.active) {
    //   const newData = alter(false, item.id, options)
    //   callback({ name, value: newData })
    //   return
    // }
    const newData = alter(!item.active, item.id, options)
    callback({ name, value: newData })
  }

  const renderItems = options.map((item, index) => (
    <SelectedItem
      key={index}
      item={item}
      trimCount={200}
      labelKey={labelKey}
      isActive={item.active}
      disabled={item.disabled}
      isCopied={copiedIndex === index}
      onSelect={(value) => onLocalSelect(value, index)}
      showCheckbox={showCheckbox}
    >
      {isCopyVariant && (
        <textarea
          readOnly
          ref={textAreaRefs.current[index]}
          value={item.label}
        />
      )}
    </SelectedItem>
  ))

  return (
    <Container className="MultiSelect__Container" {...props}>
      {!isToggleAllEnabled && !!label && <Label>{label}</Label>}
      {isToggleAllEnabled && !Boolean(singleSelect) && options.length > 1 && (
        <CustomButton
          classname="MultiSelect__Toggle"
          config={config}
          onClick={toggleSelectAll}
        >
          <Checkmark isActive={activeState()}>{`Select all`}</Checkmark>
        </CustomButton>
      )}
      {options && options.length > 0 && (
        <Group className="MultiSelect__Group">{renderItems}</Group>
      )}
    </Container>
  )
}

/**
 * Returns an array after accepting an object and converting it to an array
 *
 * @param options The array of items in which to select from
 * @returns {function} The callback incuding all selected items with an udpated object
 */

MultiSelect.defaultProps = {
  config: {
    isToggleAllEnabled: false,
    initialiseAll: false,
    showCheckbox: true,
    isCopyVariant: false,
  },
}

MultiSelect.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  callback: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.any,
      sequence: PropTypes.number,
      disabled: PropTypes.bool,
    }),
  ),
  callback: PropTypes.func,
  config: PropTypes.shape({
    labelKey: PropTypes.string,
    isToggleAllEnabled: PropTypes.bool,
    initialiseAll: PropTypes.bool,
    showCheckbox: PropTypes.bool,
    isCopyVariant: PropTypes.bool,
  }),
}

export { MultiSelect }
