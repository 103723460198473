import React, { useEffect, useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { prop } from 'ramda'
import {
  workoutSelector,
  fetchWorkouts,
  deleteWorkout,
  fetchWorkoutsFailure,
  setActiveWorkoutSuccess,
} from '@state/workout'

import { LayoutContext, LayoutTypes } from '@features/Layout'
import { DefaultState } from '@common/DefaultState'
import { Loading } from '@common/Loading'

import { WorkoutItem } from './WorkoutItem/WorkoutItem'
import { WorkoutSearch } from './WorkoutSearch'
import { WorkoutGroup } from './WorkoutBody.styled'
import { WorkoutControlBar } from './WorkoutControlBar'

import { getByVariousKeys } from '@utils/regular'

const WorkoutBody = () => {
  const dispatch = useDispatch()
  // SELECTORS
  const workouts = useSelector(workoutSelector.workouts)
  const activeWorkout = useSelector(workoutSelector.activeWorkout)
  // const filteredWorkouts = useSelector(workoutSelector.filteredWorkouts)
  const isLoading = useSelector(workoutSelector.loading)

  const [state, localDispatch] = useContext(LayoutContext)
  const { bladeExpanded } = state

  const navigate = useNavigate()

  useEffect(() => {
    dispatch(fetchWorkouts())
    // fetch the workouts each time the blade is expanded or closed
  }, [bladeExpanded])

  const onOpenCreator = () => {
    localDispatch({ type: LayoutTypes.OPEN_BLADE })
  }

  const onSelect = (e, item) => {
    e.stopPropagation()
    const id = getByVariousKeys(item)

    dispatch(setActiveWorkoutSuccess(item))
    if (!id) return
    navigate(`/library/workout/${id}`)
  }

  const onDelete = (e, item) => {
    const id = getByVariousKeys(item)
    e.stopPropagation()
    e.preventDefault()
    if (id) dispatch(deleteWorkout(id))
  }

  const renderWorkouts = (workouts) => {
    if (!workouts || !workouts.length) {
      return <DefaultState onClick={onOpenCreator} variant="workout" />
    }
    return (
      <WorkoutGroup>
        {workouts.map((item, index) => (
          <WorkoutItem
            isActive={
              !!prop('workoutId')(item) &&
              prop('workoutId')(item) === prop('workoutId')(activeWorkout)
            }
            key={!!prop('workoutId')(item) ? prop('workoutId')(item) : index}
            onSelect={(e) => onSelect(e, item)}
            onDelete={(e) => onDelete(e, item)}
            {...item}
          />
        ))}
      </WorkoutGroup>
    )
  }

  return (
    <>
      {/* <WorkoutControlBar isExpanded={bladeExpanded}>
        <WorkoutSearch />
      </WorkoutControlBar> */}
      {isLoading && (
        <Loading
          delay={2000}
          method={() => dispatch(fetchWorkoutsFailure())}
          position="fixed"
          hasOverlay
        />
      )}
      {renderWorkouts(workouts)}
    </>
  )
}

export { WorkoutBody }
