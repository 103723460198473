import styled from 'styled-components';

const ExerciseBuilderContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 6px;
  height: 100%;
  padding-left: 16px;
  padding-bottom: 16px;
`;

export { ExerciseBuilderContainer };
