import { propEq, assoc, when, find, map, curry } from 'ramda'

const randomLabels = [
  'Awesome',
  'Fantastic',
  'Epic',
  'Cool',
  'Amazing',
  'King Jesus',
  'Psalm 100',
  'Psalm 139',
  'Psalm 23',
  'Psalm 91',
  'Psalm 27',
  'Psalm 46',
  'Psalm 37',
  'Psalm 1',
  'Name of God',
  'The Lord is my Shepherd',
  'The Lord is my Light',
  'The Lord is my Salvation',
  'The Lord is my Rock',
  'The Lord is my Redeemer',
  'The Lord is my Healer',
  'The Lord is my Banner',
  'The Lord is my Provider',
  'The Lord is my Protector',
  'The Lord is my Shield',
  'The Lord is my Refuge',
  'The Lord is my Strength',
  'The Lord is my Deliverer',
  'The Lord is my Saviour',
  'The Lord is my King',
  'The Lord is my Master',
  'The Lord is my Creator',
  'The Lord is my Father',
  'The Lord is my God',
  'The Lord is my Helper',
  'The Lord is my Comforter',
  'The Lord is my Guide',
  'The Lord is my Teacher',
  'The Lord is my Counselor',
  'The Lord is my Intercessor',
  'The Lord is my Mediator',
  'The Lord is my Advocate',
  'The Lord is my High Priest',
  'The Lord is my Prophet',
  'The Lord is my Priest',
  'The Lord is my Sacrifice',
  'The Lord is my Lamb',
  'The Lord is my Lion',
  'The Lord is my Judge',
  'Jesus is Lord',
  'Until now, you have not asked for anything in my name. Ask and you will receive, and your joy will be complete.',
  'The Lord is my Light and my Salvation',
  'The Lord is my Shepherd, I shall not want',
  'The Lord reigns, let the earth be glad let the distant shores rejoice clouds and thick darkness surround him righteousness and justice are the foundation of his throne',
  'The Lord Reigns',
]

const allItemsActive = (items = []) =>
  items.every((item) => {
    const isCompleted = item.status.toUpperCase() === 'COMPLETED'
    const isSkipped = item.status.toUpperCase() === 'SKIPPED'
    return isCompleted || isSkipped
  })

const generateLabel = (optionProp) => {
  const options = !!optionProp || randomLabels
  const randomIndex = Math.floor(Math.random() * options.length)
  const randomLabel = options[randomIndex]

  const now = new Date()
  const hours = now.getHours().toString().padStart(2, '0') // Ensure two digits
  const minutes = now.getMinutes().toString().padStart(2, '0') // Ensure two digits

  const labeledString = `${randomLabel} ${hours}:${minutes}`

  return labeledString
}

/** Alter
 * @param {state} Boolean
 * @param {id} String
 * @param {items} Array
 * @returns {Array}
 */
const alterTimerStatus = curry((prop, newValue, items) =>
  map(when(propEq('id', prop), assoc('timerStatus', newValue)), items),
)

/** Alter
 * @param {state} Boolean
 * @param {id} String
 * @param {items} Array
 * @returns {Array}
 */
const alterTime = curry((prop, newValue, items) =>
  map(when(propEq('id', prop), assoc('seconds', newValue)), items),
)
const alterRemainingTime = curry((prop, newValue, items) =>
  map(when(propEq('id', prop), assoc('remainingSeconds', newValue)), items),
)

const alterActive = curry((prop, newValue, items) =>
  map(when(propEq('id', prop), assoc('active', newValue)), items),
)

const alterStatus = curry((prop, newValue, items) =>
  map(when(propEq('id', prop), assoc('status', newValue)), items),
)

const alterAllStatus = curry((prop, items) => map(assoc('status', prop), items))

function alterAllInProgress(items) {
  return items.map((item) => {
    if (item.status === 'IN_PROGRESS') {
      return { ...item, status: 'IS_PENDING' }
    }
    return item
  })
}
function alterAllIsPending(items) {
  return items.map((item) => {
    if (item.status === 'IS_PENDING') {
      return { ...item, status: 'SKIPPED' }
    }
    return item
  })
}

const getById =
  (id) =>
  (items = []) =>
    find(propEq('id', id))(items)

export {
  generateLabel,
  alterTimerStatus,
  alterAllIsPending,
  alterTime,
  alterActive,
  alterRemainingTime,
  alterAllInProgress,
  alterStatus,
  getById,
  allItemsActive,
  alterAllStatus,
}
