import { generateData } from '@features/Workout/Builder/AdvancedDrag/utils/generateData'
import { createSelector } from 'reselect'

// Define an input selector to get the exercise state

// // Create a selector to get the exercises array from the exercise state
// export const exercises = createSelector(
//   [getExerciseState],
//   (exercise) => exercise.exercises,
// )

const getExerciseState = (state) => state.exercise

// Create selector to compute activities based on exercise state
export const getActivities = createSelector([getExerciseState], (exercise) => {
  // Perform computation based on exercise state
  // For example:
  // return generateData(exercise?.exercises || [])
  return exercise?.builderExercises || []
})

export const exercises = (state) => state.exercise.exercises
export const activeExercise = (state) => state.exercise.activeExercise
export const images = (state) => state.exercise.images
export const builderExercises = (state) => state.exercise.builderExercises
export const loading = (state) => state.exercise.loading
export const filteredExercises = (state) => state.exercise.filteredExercises

// const getExercise = (state) => state.exercise; // Get the exercise state from your Redux store

// export const getActivities = createSelector([getExerciseState], (exercise) =>
//   generateData(exercise.exercises),
// )

export const exerciseSelector = {
  exercises,
  activeExercise,
  builderExercises,
  images,
  loading,
  filteredExercises,
}
