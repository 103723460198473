import styled, { css } from 'styled-components';

const listStyle = css`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const layoutStyle = css`
  border-radius: 0px;
  cursor: pointer;
  line-height: 19px;
  font-weight: 500;
  font-size: 14px;
  max-width: 100%;
  white-space: nowrap;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 8px 12px;
  outline: none;
`;

const Primary = styled.li`
  ${listStyle};
  ${layoutStyle};
  box-shadow: none;
  color: #121b2d;
  border-radius: 6px;
  background: transparent;
  text-transform: capitalize;
  border: 1px solid transparent;
  background: #edf0f6;
  margin-left: 8px;
  border-radius: 6px;
  overflow: hidden;
  &:first-child {
    margin-left: 0;
  }
  ${props =>
    props.isActive &&
    css`
      background: white;
    `}
`;

const Secondary = styled.li`
  ${layoutStyle};
  color: #a9aeb9;
  border-radius: 6px;
  background: transparent;
  text-transform: capitalize;
  border: 1px solid transparent;
  background: #f1f2f6;
  user-select: none;
  font-size: 14px;
  position: relative;
  ${props =>
    props.isActive &&
    css`
      background: white;
      color: #1d2a43;
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.03),
        0px 1.2963px 2.92824px rgba(0, 0, 0, 0.0227778),
        0px 0.77037px 1.59259px rgba(0, 0, 0, 0.0182222),
        0px 0.4px 0.8125px rgba(0, 0, 0, 0.015),
        0px 0.162963px 0.407407px rgba(0, 0, 0, 0.0117778),
        0px 0.037037px 0.196759px rgba(0, 0, 0, 0.00722222);
    `}
`;

export { Primary, Secondary };
