import React from 'react'
import styled, { css } from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { Button } from '@common/Button'
import { Icon } from '@common/Icon'

const SystemButton = styled.div`
  bottom: 24px;
  right: 24px;
  button {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  span {
    font-size: 16px;
    display: none;
    font-weight: 500;
    line-height: 20px;
    color: #121b2d;
  }
  .isExpanded & {
    span {
      display: inline-block;
    }
  }
`

const System = () => {
  const navigate = useNavigate()
  return (
    <SystemButton>
      <Button
        onClick={() => navigate('/system')}
        variant="MENU"
        config={{ layout: { padding: '12px', marginTop: '8px' } }}
      >
        <span className="label">System</span>
        <Icon name="SECTION" size={20} />
      </Button>
    </SystemButton>
  )
}

export { System }
