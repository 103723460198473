import React, { useEffect, useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
// STATE
import {
  exerciseSelector,
  fetchExercises,
  fetchExercisesFailure,
  removeExercise,
  setActiveExercise,
} from '@state/exercise'
import { fetchEquipment, fetchMuscles } from '@state/master'
import { LayoutContext, LayoutTypes } from '@features/Layout'

import { ExerciseControlBar } from './ExerciseControlBar'
import { DefaultState } from '@common/DefaultState'

import { ExerciseItem } from './ExerciseItem'
import { ExerciseSearch } from './ExerciseSearch'
import { ExerciseGroup } from './ExerciseBody.styled'
import { prop } from 'ramda'
import { Loading } from '@common/Loading'

const ExerciseBody = () => {
  const dispatch = useDispatch()
  // SELECTORS
  const exercises = useSelector(exerciseSelector.exercises)
  const filteredExerciseList = useSelector(exerciseSelector.filteredExercises)

  const activeExercise = useSelector(exerciseSelector.activeExercise)
  const isLoading = useSelector(exerciseSelector.loading)
  const [state, localDispatch] = useContext(LayoutContext)
  const { bladeExpanded } = state

  useEffect(() => {
    dispatch(fetchExercises())
  }, [])

  useEffect(() => {
    if (!bladeExpanded) return
    dispatch(fetchMuscles())
    dispatch(fetchEquipment())
  }, [bladeExpanded])

  const onSelect = (e, item) => {
    e.stopPropagation()
    if (!bladeExpanded) localDispatch({ type: LayoutTypes.OPEN_BLADE })

    console.log({ item })

    if (
      prop('exerciseId')(item) === prop('exerciseId')(activeExercise) &&
      prop('exerciseId')(activeExercise) !== null
    ) {
      dispatch(setActiveExercise(null))
      return
    }
    if (!prop('exerciseId')(item)) {
      dispatch(setActiveExercise(null))
      return
    }
    if (prop('exerciseId')(item) !== prop('exerciseId')(activeExercise))
      dispatch(setActiveExercise(item))
  }

  const onDelete = (e, item) => {
    e.stopPropagation()
    if (exercises && exercises.length === 1) {
      dispatch(setActiveExercise(null))
    }

    dispatch(removeExercise(item.exerciseId))
  }

  const onOpenCreator = () => {
    localDispatch({ type: LayoutTypes.OPEN_BLADE })
  }

  const getExerciseList = () => {
    if (!exercises || !exercises.length) return []
    return filteredExerciseList
  }

  // DERIVED VALUES
  const exerciseListDerived = getExerciseList()

  const renderExercises = (list) => {
    if (!list || !list.length)
      return <DefaultState onClick={onOpenCreator} variant="exercise" />

    return (
      <ExerciseGroup>
        {list.map((item) => (
          <ExerciseItem
            key={item.exerciseId}
            isActive={
              !!prop('exerciseId')(activeExercise) &&
              prop('exerciseId')(item) === prop('exerciseId')(activeExercise)
            }
            onSelect={(e) => onSelect(e, item)}
            onDelete={(e) => onDelete(e, item)}
            exerciseName={item.exerciseName}
            isBladeExpanded={bladeExpanded}
            exerciseId={item.exerciseId}
          />
        ))}
      </ExerciseGroup>
    )
  }

  return (
    <>
      <ExerciseControlBar isExpanded={bladeExpanded}>
        <ExerciseSearch />
      </ExerciseControlBar>
      {isLoading && (
        <Loading
          delay={2000}
          method={() => dispatch(fetchExercisesFailure())}
        />
      )}
      {!isLoading && renderExercises(exerciseListDerived)}
    </>
  )
}

export { ExerciseBody }
