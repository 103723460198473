const getConfig = () => {
  if (!('process' in window)) {
    window.process = {}
  }

  const spa = {
    returnTo: process.env.REACT_APP_AUTH_RETURN_TO || '',
    clientId: process.env.REACT_APP_AUTH_CLIENT_ID || '',
    domain: process.env.REACT_APP_AUTH_DOMAIN || '',
    audience: process.env.REACT_APP_AUTH_AUDIENCE || '',
    redirectUri: process.env.REACT_APP_AUTH_REDIRECT_URI || '',
  }

  const { returnTo, clientId, domain, audience, redirectUri } = spa

  const AUTH_CONFIG = {
    returnTo,
    clientId,
    domain,
    audience,
    redirectUri,
    responseType: 'token id_token',
    scope: 'openid profile email',
    realm: 'Username-Password-Authentication',
  }

  const localVars = {
    mock: process ? process.env.REACT_APP_MOCK : '',
  }

  return {
    AUTH_CONFIG,
    localVars,
  }
}

const { AUTH_CONFIG, localVars } = getConfig()

export { AUTH_CONFIG, localVars }
