const getValidImages = (images, variant) => {
  if (!images || !images.length) return []
  if (!variant || !variant.length) return images

  const upperVariant = variant.toUpperCase()
  // rewrite to allow an include list or variants
  const imageItem = images.find(
    (item) => !!item.variant && item.variant.toUpperCase() === upperVariant,
  )

  // If a banner item is found, return it in an array, otherwise return an empty array
  return !!imageItem ? [imageItem] : []
}
export { getValidImages }
