import styled from 'styled-components'
import { allDefaultStyles } from './utils'
import { colors } from '@common/Theme'

const Grey = styled.button`
  ${allDefaultStyles};
  background: #202428;
  border: 1.5px solid #343b44;

  span {
    color: grey;
  }
  svg.SETTINGS {
    path {
      stroke: white;
    }
  }
  svg.RESEND {
    path {
      fill: #ee3a3a;
    }
    line {
      fill: #ee3a3a;
    }
  }
  svg.HISTORY {
    path {
      stroke: white;
    }
    line {
      fill: white;
    }
  }
  svg.TRASH {
    path {
      stroke: ${colors.red};
    }
    line {
      stroke: ${colors.red};
    }
  }
  &:hover {
    background: rgb(23 25 28);
    span {
      font-size: 16px;
      color: white;
    }
    svg.SETTINGS {
      path {
        stroke: white;
      }
    }
    svg.TRASH {
      path {
        stroke: rgb(252, 115, 115);
      }
      line {
        stroke: rgb(252, 115, 115);
      }
    }
    svg.RESEND {
      path {
        fill: #fc7373;
      }
      line {
        fill: #fc7373;
      }
    }
  }
  ${(props) => props.extend};
`

export { Grey }
