import { loadState } from '@utils/storage'
import { omit } from 'ramda'

const getHeaders = (matchers = ['AUTH', 'CORS']) => {
  const token = loadState('token')

  if (!token) {
    console.warn('Token not found ::')
    return
  }

  const options = {
    AUTH: { Authorization: `Bearer ${token}` },
    IMAGE: { 'Content-type': 'multipart/form-data' },
    JSON: { 'Content-Type': 'application/json' },
    CORS: { 'Access-Control-Allow-Origin': '*' },
  }

  const getMatchers = (options, matchers) => {
    return Object.entries(options).reduce((prev, [key, value]) => {
      if (matchers.includes(key)) {
        return { ...prev, ...value }
      }
      return prev
    }, {})
  }

  return getMatchers(options, matchers)
}

const getConfig =
  ({ type, params, body }) =>
  (config) => {
    const url = params
      ? `${config[type].endpoint}/${params}`
      : config[type].endpoint
    const payload = {
      url,
      method: config[type].method,
      headers: getHeaders(config[type].headers),
      data: body,
    }
    return body ? payload : omit(['data'])(payload)
  }

export { getHeaders, getConfig }
