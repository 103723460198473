export default [
  {
    stripePriceId: 'price_1L57BDCTOd88lFo5vTF7p5CL',
    stripeProductId: 'prod_LmgYTVY0kxAP71',
    amount: 0,
    label: {
      interval: 'year',
      usageType: 'licensed',
      lastResponse: null,
      intervalCount: 1,
      rawJsonObject: null,
      aggregateUsage: null,
      trialPeriodDays: null,
    },
    description: 'Aima Yearly Plan',
    active: true,
    tiers: [
      {
        upTo: 3,
        flatAmount: 24000,
        unitAmount: null,
        lastResponse: null,
        rawJsonObject: null,
        flatAmountDecimal: 24000,
        unitAmountDecimal: null,
      },
      {
        upTo: null,
        flatAmount: null,
        unitAmount: 3600,
        lastResponse: null,
        rawJsonObject: null,
        flatAmountDecimal: null,
        unitAmountDecimal: 3600,
      },
    ],
    taxBehavior: 'inclusive',
    tiersMode: 'graduated',
    type: 'recurring',
    currency: 'aud',
    isDefault: false,
    createdAt: '2022-05-30T11:54:41.275721',
    updatedAt: '2022-05-30T11:54:41.275721',
  },
  {
    stripePriceId: 'price_1L57BECTOd88lFo5ZsYt2QFV',
    stripeProductId: 'prod_LmgYTVY0kxAP71',
    amount: 0,
    label: {
      interval: 'month',
      usageType: 'licensed',
      lastResponse: null,
      intervalCount: 1,
      rawJsonObject: null,
      aggregateUsage: null,
      trialPeriodDays: null,
    },
    description: 'Aima Monthly Plan',
    active: true,
    tiers: [
      {
        upTo: 3,
        flatAmount: 2000,
        unitAmount: null,
        lastResponse: null,
        rawJsonObject: null,
        flatAmountDecimal: 2000,
        unitAmountDecimal: null,
      },
      {
        upTo: null,
        flatAmount: null,
        unitAmount: 300,
        lastResponse: null,
        rawJsonObject: null,
        flatAmountDecimal: null,
        unitAmountDecimal: 300,
      },
    ],
    taxBehavior: 'inclusive',
    tiersMode: 'graduated',
    type: 'recurring',
    currency: 'aud',
    isDefault: true,
    createdAt: '2022-05-30T11:54:41.557592',
    updatedAt: '2022-05-30T11:54:41.557592',
  },
];
