export const CONTAINER = 'CONTAINER';
export const SECTION = 'SECTION';
export const GROUP = 'GROUP';
export const ACTIVITY = 'ACTIVITY';
export const SUPERSET = 'SUPERSET';
export const EXERCISE = 'EXERCISE';
export const SURVEY = 'SURVEY';
export const REST = 'REST';

export const ADD_SURVEY = 'ADD_SURVEY';
export const ADD_CONTAINER = 'ADD_CONTAINER';
export const ADD_WORKOUT = 'ADD_WORKOUT';
export const ADD_SECTION = 'ADD_SECTION';
export const ADD_GROUP = 'ADD_GROUP';
export const ADD_ACTIVITY = 'ADD_ACTIVITY';
export const ADD_REST = 'ADD_REST';
