import axios from 'axios'
import { getConfig } from './headers'

const api = (props) => async (config) => {
  let data
  await axios(getConfig(props)(config))
    .then((response) => {
      data = response
    })
    .catch((err) => {
      data = err
    })
  return data
}

export { api }
