import styled from 'styled-components'

import {
  colorFn,
  widthFn,
  preventSelectFn,
  linkFn,
  weightFn,
  centerFn,
  lineHeightFn,
  mtFn,
  mbFn,
  mlFn,
  mrFn,
  fontSizeFn,
  alignFn,
} from '@common/Theme'

const TextLink = styled.a`
  font-size: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  margin: 0;
  text-transform: none;
  line-height: 20px;
  user-select: none;
  ${preventSelectFn};
  ${lineHeightFn};
  ${fontSizeFn};
  ${weightFn};
  ${widthFn};
  ${colorFn};
  ${linkFn};
  ${centerFn};
  ${alignFn};
  ${mlFn};
  ${mrFn};
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`

const TextParagraph = styled.p`
  font-size: 16px;
  line-height: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  margin: 0;
  text-transform: none;
  ${preventSelectFn};
  ${lineHeightFn};
  ${fontSizeFn};
  ${weightFn};
  ${widthFn};
  ${colorFn};
  ${linkFn};
  ${centerFn};
  ${alignFn};

  ${mtFn};
  ${mbFn};
  ${mlFn};
  ${mrFn};
`

const TextSpan = styled.span`
  font-size: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  margin: 0;
  text-transform: none;
  line-height: 20px;
  user-select: none;
  ${preventSelectFn};
  ${lineHeightFn};
  ${fontSizeFn};
  ${weightFn};
  ${widthFn};
  ${colorFn};
  ${linkFn};
  ${centerFn};

  ${mtFn};
  ${mbFn};
  ${mlFn};
  ${mrFn};
`

export { TextLink, TextParagraph, TextSpan }
