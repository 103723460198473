import React from 'react'
import PropTypes from 'prop-types'
import { prop } from 'ramda'

import { Heading } from '@common/Heading'
import { Button } from '@common/Button'
import { Icon } from '@common/Icon'
import { useAuth0 } from '@auth0/auth0-react'
import { AUTH_CONFIG } from '@features/Auth/Service'

import {
  Container,
  Content,
  Footer,
  ModalBlock,
  ModalClose,
} from '../Modal.styled'
import styled from 'styled-components'
import { clearState } from '@utils/storage'
// import Auth from '@features/Auth'

const Controls = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
  border-top: 1px solid #e3e5eb;
  padding-top: 16px;
`

// const auth = Auth.Service()

const LogoutContent = ({ callback = () => {}, level }) => {
  const { isAuthenticated, error, user, logout, loginWithRedirect } = useAuth0()
  // PHILIP
  const onLogout = () => {
    clearState('token')
    // logout({
    //   logoutParams: {
    //     returnTo: AUTH_CONFIG.returnTo,
    //   },
    // })
    logout()
    callback({ action: 'CLOSE' })
  }
  const onLogin = () => {
    loginWithRedirect()
    callback({ action: 'CLOSE' })
  }

  const handleCallback = () => callback({ action: 'CLOSE' })

  return (
    <Container level={level}>
      <ModalBlock direction="row" justifyContent="flex-start">
        <Heading
          config={{
            text: {
              color: '#000000',
              fontSize: '24px',
              textAlign: 'left',
              lineHeight: '32px',
              margin: '0',
            },
          }}
          variant="SECONDARY"
        >
          Are you sure?
        </Heading>
        <ModalClose
          onClick={() => callback({ action: 'CLOSE' })}
          style={{ position: 'absolute', right: '24px', top: '24px' }}
        >
          <Icon name="CLOSE" size={20} />
        </ModalClose>
      </ModalBlock>

      <Content>
        <p>
          Make sure you have saved your work before you logout. Press the
          "Cancel" button below to go back and save your work.
        </p>
      </Content>

      <Footer>
        <Controls>
          <Button
            variant="outline"
            type="button"
            alone
            onClick={handleCallback}
            label="Cancel"
          >
            <Icon name="CANCEL" ml={5} />
          </Button>
          {isAuthenticated && (
            <Button
              variant="orange"
              type="button"
              alone
              width="unset"
              onClick={() => onLogout()}
              label="Logout"
            >
              <Icon name="LEAVE" ml={5} stroke="white" />
            </Button>
          )}
          {!isAuthenticated && (
            <Button
              variant="orange"
              type="button"
              alone
              width="unset"
              onClick={onLogin}
              label="Log in"
            >
              <Icon name="LEAVE" ml={5} stroke="white" />
            </Button>
          )}
        </Controls>
      </Footer>
    </Container>
  )
}

LogoutContent.defaultProps = {
  style: {},
  dev: false,
  level: 2,

  onClick: () => {},
}

LogoutContent.propTypes = {
  data: PropTypes.object,
  style: PropTypes.object,
  dev: PropTypes.bool,
  level: PropTypes.number,
  onClick: PropTypes.func,
}

export default LogoutContent
