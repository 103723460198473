export default {
  stripeQuoteId: 'qt_1L52S0CTOd88lFo58XGpetx0',
  currency: 'aud',
  collectionMethod: 'charge_automatically',
  status: 'draft',
  amountTotal: 2000,
  amountSubTotal: 2000,
  quantity: 3,
  stripeCreatedAt: '2022-05-30T12:21:40',
  expiresAt: '2022-06-29T12:21:40',
  computed: {
    lastResponse: null,
    rawJsonObject: null,
    recurring: {
      lastResponse: null,
      rawJsonObject: null,
      amountSubtotal: 2000,
      amountTotal: 2000,
      interval: 'month',
      intervalCount: 1,
      totalDetails: {
        lastResponse: null,
        rawJsonObject: null,
        amountDiscount: 0,
        amountShipping: 0,
        amountTax: 0,
        breakdown: null,
      },
    },
    upfront: {
      lastResponse: null,
      rawJsonObject: null,
      amountSubtotal: 0,
      amountTotal: 0,
      lineItems: null,
      totalDetails: {
        lastResponse: null,
        rawJsonObject: null,
        amountDiscount: 0,
        amountShipping: 0,
        amountTax: 0,
        breakdown: null,
      },
    },
  },
  statusTransition: {
    lastResponse: null,
    rawJsonObject: null,
    acceptedAt: null,
    canceledAt: null,
    finalizedAt: null,
  },
  subscriptionData: {
    lastResponse: null,
    rawJsonObject: null,
    effectiveDate: null,
    trialPeriodDays: 8,
  },
  totalDetails: {
    lastResponse: null,
    rawJsonObject: null,
    amountDiscount: 0,
    amountShipping: 0,
    amountTax: 0,
    breakdown: null,
  },
  priceInfo: {
    stripePriceId: 'price_1L3GOICTOd88lFo54hWU6es7',
    stripeProductId: 'prod_Ljx4bgjysAPjTg',
    amount: 0,
    label: {
      interval: 'month',
      usageType: 'licensed',
      lastResponse: null,
      intervalCount: 1,
      rawJsonObject: null,
      aggregateUsage: null,
      trialPeriodDays: null,
    },
    description: 'Monthly Plan',
    active: true,
    tiers: [
      {
        upTo: 3,
        flatAmount: 2000,
        unitAmount: null,
        lastResponse: null,
        rawJsonObject: null,
        flatAmountDecimal: 2000,
        unitAmountDecimal: null,
      },
      {
        upTo: null,
        flatAmount: null,
        unitAmount: 300,
        lastResponse: null,
        rawJsonObject: null,
        flatAmountDecimal: null,
        unitAmountDecimal: 300,
      },
    ],
    taxBehavior: 'inclusive',
    tiersMode: 'graduated',
    type: 'recurring',
    currency: 'aud',
    isDefault: true,
    createdAt: '2022-05-25T14:50:32.141183',
    updatedAt: '2022-05-25T14:50:32.141183',
  },
  createdBy: {
    email: 'support@prostaid.health',
    stripeCustomerId: 'cus_LlA9RYOvQPQDq1',
    roles: ['PersonalTrainer'],
    createdAt: '2022-05-26T15:51:27.579485',
    updatedAt: '2022-05-26T15:56:41.277154',
    isActive: true,
    isDeleted: false,
    stripeSetupIntentObject: {
      setupIntentId: 'seti_1L3dsECTOd88lFo5OESS02BH',
      paymentMethodId: 'pm_1L3drmCTOd88lFo5qUN9f7iL',
      setupIntentSecret:
        'seti_1L3dsECTOd88lFo5OESS02BH_secret_LlACdyfZ5qFf7V9YbeRsCXbgxb1PNOw',
    },
  },
};
