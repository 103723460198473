/* eslint-disable no-underscore-dangle */
import React from 'react'
import PropTypes from 'prop-types'
import { prop } from 'ramda'
import { TextParagraph, TextSpan, TextLink } from './Text.styled'
import { toUpper, keys, includes } from 'ramda'

const Text = ({ as = 'P', config = {}, children, ...props }) => {
  const layoutStyle = prop('layout')(config)

  const configMap = {
    P: TextParagraph,
    SPAN: TextSpan,
    LINK: TextLink,
  }

  if (!includes(toUpper(as))(keys(configMap))) {
    return <TextParagraph {...props}>{children}</TextParagraph>
  }

  const Component = configMap[toUpper(as)]
  return (
    <Component {...props} style={layoutStyle}>
      {children}
    </Component>
  )
}

Text.defaultProps = {
  as: 'p',
}

Text.propTypes = {
  as: PropTypes.string,
}

export { Text }
