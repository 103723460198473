import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { prop, toUpper } from 'ramda'
import styled, { css } from 'styled-components'
import { Icon } from '@common/Icon'
import {
  colors,
  widthFn,
  bgColorFn,
  media,
  mrFn,
  mlFn,
  mbFn,
  mtFn,
} from '@common/Theme'

const PasswordReveal = styled.button`
  outline: none;
  margin: 0;
  padding: 0;
  border: none;
  background: white;
  position: relative;
  width: 48px;
  min-width: 48px;
  height: 48px;
  flex-basis: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const baseStyles = css`
  -webkit-appearance: none;
  box-sizing: border-box;
  background-image: none;
  transition: all 0.3s ease-in-out;
`

const variantPlain = ({ variant }) => {
  if (variant === 'plain') {
    return css`
      .icon {
        stroke: black;
        fill: black;
      }
      text-indent: 24px;
      background-color: #edf0f6;
      border-radius: 4px;
      box-shadow: none;
      border: 1px solid #e3e5eb;
      &:hover {
        background-color: #e2e7f1;
        border: 1px solid #e2e7f1;
      }
      &:focus {
        background-color: transparent;
        border: 1px solid #e2e7f1;
        box-shadow: none;
      }
      &:active {
        box-shadow: none;
        border: 1px solid transparent;
      }
    `
  }
}

const variantWhite = ({ variant }) => {
  if (variant === 'white') {
    return css`
      .icon {
        stroke: black;
        fill: black;
      }
      text-indent: 20px;
      background-color: white;
      border-radius: 4px;
      box-shadow: none;
      border: 1px solid #e3e5eb;
      &:hover {
        background-color: #e3e5eb;
        border: 1px solid #e3e5eb;
      }
      &:focus {
        background-color: transparent;
        border: 1px solid #e2e7f1;
        box-shadow: none;
      }
      &:active {
        box-shadow: none;
        border: 1px solid transparent;
      }
    `
  }
}

const variantLight = css`
  text-indent: 8px;
  background-color: #f7f8fa;
  border-radius: 4px;
  box-shadow: none;
  border: 1px solid #f7f8fa;
  &:hover {
    background-color: #e3e5eb;
    border: 1px solid #e3e5eb;
  }
  &:focus {
    background-color: transparent;
    border: 1px solid #e2e7f1;
    box-shadow: none;
  }
  &:active {
    box-shadow: none;
    border: 1px solid transparent;
  }
`

const errorFn = ({ error }) =>
  error &&
  css`
    border: 1px solid ${colors.grapefruit};
  `

const disabledFn = ({ disabled }) =>
  disabled &&
  css`
    border: solid 1px transparent;
    background: #f5f5f8;
    cursor: not-allowed;
    color: #d6d5e4;
    &:focus {
      border: solid 1px transparent;
    }
    &:hover {
      background: #f5f5f8;
    }
  `

const heightFn = (props) => {
  const { height = 0 } = props
  if (height > 0) {
    return css`
      height: ${height};
    `
  }
}

const mediaStyles = css`
  &::focus {
    font-size: 16px;
  }
  ${media.sm`
      &::focus {
      font-size: 14px;
    }
  `}
  ${media.md`
      &::focus {
      font-size: 14px;
    }
  `}
  ${media.lg`
      &::focus {
      font-size: 14px;
    }
  `}
`

const resetAutoFill = css`
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
`

const FieldInput = styled.input.attrs({ className: 'field-input' })`
  ${heightFn};
  ${mediaStyles};
  ${baseStyles};

  ${mtFn};
  ${mbFn};
  ${mlFn};
  ${mrFn};
  ${resetAutoFill};
  height: 48px;
  border-radius: 4px;
  border: 1px solid transparent;
  background: #f2f3f4;
  font-size: 16px;
  font-weight: normal;
  line-height: 20px;
  padding: 14px 16px 14px 10px;
  display: block;
  width: 100%;
  box-shadow: none;
  color: #211551;
  &:focus {
    font-weight: normal;
    outline: none;
    border: 1px solid #ff5a45;
    background-color: white;
    box-shadow: 0 0 0 2px #f7d8d2;
    ${(props) =>
      props.bgColor &&
      css`
        background-color: white;
      `}
    ${(props) =>
      props.error &&
      css`
        border: 1px solid ${colors.orange};
      `}
  }
  &:hover {
    background: #e2e5ef;
  }
  ${variantPlain};
  ${variantWhite};
  ${bgColorFn};
  ${widthFn};
  ${errorFn};
  ${disabledFn};
  &::placeholder {
    color: #9592a8;
    font-weight: normal;
  }
`

const InputFieldOutline = styled.input.attrs({
  className: 'InputField-outline',
})`
  ${heightFn};
  ${mediaStyles};
  ${baseStyles};

  ${mtFn};
  ${mbFn};
  ${mlFn};
  ${mrFn};
  height: 48px;
  border-radius: 4px;
  border: 1px solid #e3e5eb;
  background: white;
  font-size: 16px;
  font-weight: normal;
  line-height: 20px;
  padding: 14px 16px 14px 10px;
  display: block;
  width: 100%;
  box-shadow: none;
  color: #778092;
  &:focus {
    font-weight: normal;
    outline: none;
    border: 1px solid #ff5a45;
    background-color: white;
    ${(props) =>
      props.bgColor &&
      css`
        background-color: white;
      `}
    ${(props) =>
      props.error &&
      css`
        border: 1px solid ${colors.orange};
      `}
  }
  &:hover {
    background: white;
  }
  ${variantPlain};
  ${variantWhite};
  ${bgColorFn};
  ${widthFn};
  ${errorFn};
  ${disabledFn};
  &::placeholder {
    color: #778092;
    font-weight: normal;
  }
`

const FieldPassword = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  flex-grow: 1;
  ${mbFn};
  .input {
    width: 100%;
  }
`

const InputFieldLight = styled.input.attrs({
  className: 'InputField-light',
})`
  ${heightFn};
  ${mediaStyles};
  ${baseStyles};

  height: 48px;
  border-radius: 4px;
  border: 1px solid #e3e5eb;
  background: white;
  font-size: 16px;
  font-weight: normal;
  line-height: 20px;
  padding: 14px 16px 14px 10px;
  display: block;
  width: 100%;
  box-shadow: none;
  color: #778092;
  &:focus {
    font-weight: normal;
    outline: none;
    border: 1px solid #ff5a45;
    background-color: white;
    ${(props) =>
      props.bgColor &&
      css`
        background-color: white;
      `}
    ${(props) =>
      props.error &&
      css`
        border: 1px solid ${colors.orange};
      `}
  }
  &:hover {
    background: white;
  }
  ${variantLight};
  ${bgColorFn};
  ${widthFn};
  ${errorFn};
  ${disabledFn};
  &::placeholder {
    color: #778092;
    font-weight: normal;
  }
`

const Input = forwardRef(
  ({ placeholder, style, variant, config, isPassword, ...props }, ref) => {
    const matchedLabelStyles = prop('layout')(config) || {}

    const [revealPassword, setRevealPassword] = React.useState(
      props.type === 'password' ? false : true,
    )

    const handleToggle = (e) => {
      e.preventDefault()
      setRevealPassword(!revealPassword)
    }

    const varaintToUpper = toUpper(variant)
    if (varaintToUpper === 'OUTLINE')
      return (
        <InputFieldOutline
          placeholder={placeholder}
          style={matchedLabelStyles}
          ref={ref}
          autocomplete="off"
          {...props}
        />
      )
    if (varaintToUpper === 'SETTINGS')
      return (
        <InputFieldLight
          placeholder={placeholder}
          style={matchedLabelStyles}
          autocomplete="off"
          ref={ref}
          {...props}
        />
      )
    return isPassword ? (
      <FieldPassword mb={props.mb}>
        <FieldInput
          placeholder={placeholder}
          style={matchedLabelStyles}
          ref={ref}
          {...props}
          type={revealPassword ? 'text' : 'password'}
          mb={0}
        />
        <PasswordReveal onClick={handleToggle}>
          <Icon
            name={revealPassword ? 'EYE_OPEN' : 'EYE_CLOSED'}
            fill={colors.black}
            size={20}
          />
        </PasswordReveal>
      </FieldPassword>
    ) : (
      <FieldInput
        placeholder={placeholder}
        style={matchedLabelStyles}
        ref={ref}
        {...props}
      />
    )
  },
)

Input.defaultProps = {
  placeholder: '',
  style: {},
  config: {},
  variant: 'primary',
}

Input.propTypes = {
  placeholder: PropTypes.string,
  style: PropTypes.object,
  config: PropTypes.object,
  variant: PropTypes.string,
}

export { Input }
