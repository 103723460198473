function hasNoDuplicates(arr) {
  // Create a Set from the array to remove duplicates
  const uniqueValues = new Set(arr)

  // If the size of the Set is equal to the length of the array, there are no duplicates
  return uniqueValues.size === arr.length
}

function validateItems(items) {
  // Check if items is an array
  if (!Array.isArray(items)) {
    return false
  }

  // Check if items array has exactly one or two elements
  if (items.length < 1 || items.length > 2) {
    return false
  }

  // Check if each item in the array is either 'Client' or 'PersonalTrainer'
  const validRoles = ['Client', 'PersonalTrainer']
  const filteredRoles = items.filter((item) => validRoles.includes(item))

  const result =
    !!filteredRoles &&
    !!filteredRoles.length &&
    !!hasNoDuplicates(filteredRoles)

  // Check if filteredRoles contains exactly one occurrence of each role
  if (!result) {
    console.warn('Invalid roles:', items)
    return result
  }

  return result
}

export { validateItems }
