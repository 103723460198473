import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { path, pipe, replace, toLower, isNil, complement } from 'ramda'

import { TextArea } from '@common/TextArea'
import { ErrorList } from '@common/ErrorList'
import { Label } from '@common/Label'

const getIn = (original, fieldName) => path(fieldName.split('.'), original)

const htmlId = pipe(replace(/\s+/g, '-'), toLower)

const isPresent = complement(isNil)

const FieldContainer = styled.div`
  margin-top: 16px;
  width: 100%;
`

const TextAreaField = ({
  id,
  field,
  form,
  label,
  placeholder,
  disabled,
  labelColor,
  closeError = () => {},
  ...props
}) => {
  const { errors, touched, submitCount } = form
  const fieldError = getIn(errors, field.name)
  const fieldTouched = getIn(touched, field.name)

  return (
    <FieldContainer>
      {label && (
        <Label
          labelColor={labelColor}
          error={submitCount > 0 && fieldTouched && fieldError}
        >
          {label}
        </Label>
      )}
      <TextArea
        id={id || htmlId(field.name)}
        type="text"
        error={submitCount > 0 && fieldTouched && isPresent(fieldError)}
        disabled={disabled}
        placeholder={placeholder}
        autocomplete="off"
        {...field}
        {...props}
      />
      {submitCount > 0 && fieldTouched && fieldError && (
        <ErrorList
          haystack={[{ name: 'error', message: fieldError }]}
          needles={['error']}
          mb={16}
          callback={closeError}
        />
      )}
    </FieldContainer>
  )
}

TextAreaField.defaultProps = {
  labelColor: '#121B2D',
}

TextAreaField.propTypes = {
  id: PropTypes.string,
  field: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    name: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  label: PropTypes.string.isRequired,
  form: PropTypes.shape({
    errors: PropTypes.object,
    touched: PropTypes.object,
    submitCount: PropTypes.number,
  }).isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
}

export { TextAreaField }
