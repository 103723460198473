import {
  WORKOUTS_CREATE_PENDING,
  WORKOUTS_FETCH_PENDING,
  WORKOUTS_UPDATE_PENDING,
  WORKOUTS_DELETE_PENDING,
  WORKOUTS_BY_ID_PENDING,
  WORKOUTS_DELETE_IMAGE_PENDING,
  WORKOUTS_CREATE_IMAGE_PENDING,
  WORKOUTS_UPDATE_IMAGE_PENDING,
  WORKOUTS_UPDATE_LISTEN_PENDING,
  // WORKOUTS_DELETE_UPDATE_IMAGE_PENDING,
} from '../actionTypes'

export const fetchWorkouts = () => ({
  type: WORKOUTS_FETCH_PENDING,
})

export const updateWorkout = (payload) => ({
  type: WORKOUTS_UPDATE_PENDING,
  payload,
})

export const updateWorkoutListen = (payload) => ({
  type: WORKOUTS_UPDATE_LISTEN_PENDING,
  payload,
})

export const createWorkout = (payload) => ({
  type: WORKOUTS_CREATE_PENDING,
  payload,
})

export const deleteWorkout = (payload) => ({
  type: WORKOUTS_DELETE_PENDING,
  payload,
})

export const getWorkoutById = (payload) => ({
  type: WORKOUTS_BY_ID_PENDING,
  payload,
})

export const createWorkoutImage = (payload) => ({
  type: WORKOUTS_CREATE_IMAGE_PENDING,
  payload,
})

export const updateWorkoutImage = (payload) => ({
  type: WORKOUTS_UPDATE_IMAGE_PENDING,
  payload: payload,
})

export const deleteWorkoutImage = (payload) => ({
  type: WORKOUTS_DELETE_IMAGE_PENDING,
  payload,
})
