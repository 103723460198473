import styled, { css } from 'styled-components'

const AdvancedDropableGroup = styled.div`
  padding: grid;
  background: #3d3c40;
  border: 1px dashed transparent;
  transition: all 0.1s ease-in-out;
  border-radius: 4px;
  margin: 0;
  ${(props) =>
    props.isDraggingOver &&
    css`
      border: 1px dashed white;
    `}
`

const SectionDroppableContainer = styled.div`
  transition: all 0.3s ease-in-out;
  flex: 1;
  ${(props) =>
    props.isDraggingOver &&
    css`
      background-color: transparent;
    `}
`

const AdvancedDragContainer = styled.div`
  margin-top: 24px;
  width: 100%;
`

const AdvancedSectionHeader = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  position: relative;
  h2 {
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-weight: 600;
  }
`

const AdvancedDraggableItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px;
  user-select: none;
  display: inline-flex;
  padding: 16px 16px;
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid #59585c;

  &:first-child {
    margin-top: 0;
  }
  ${(props) =>
    props.isDragging &&
    css`
      background: lightgreen;
    `}
`

const AdvancedDraggableItemHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 0px;
  position: relative;
  h3 {
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-weight: 600;
    margin-left: 4px;
  }
  .secondary {
    left: 0px;
    top: 0px;
  }
`

const ConceptLabel = styled.div`
  width: 100%;
  padding-top: 4px;
  padding-bottom: 4px;
`

const ConceptGroup = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  border: none;
`

const ConceptItem = styled.li`
  list-style: none;
  padding: 16px;
  background: #aeaeae;
  border-radius: 4px;
  border: 2px solid black;
  margin-top: 4px;
  color: black;
  ${(props) =>
    props.isActive &&
    css`
      background: yellow;
      &:hover {
        background: yellow;
      }
    `}
`

export {
  AdvancedDropableGroup,
  SectionDroppableContainer,
  AdvancedDragContainer,
  AdvancedDraggableItem,
  AdvancedDraggableItemHeader,
  AdvancedSectionHeader,
  ConceptLabel,
  ConceptGroup,
  ConceptItem,
}
