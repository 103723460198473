import styled, { css } from 'styled-components'
import { media } from '@common/Theme'

const ellipses = css`
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const WorkoutItemContainer = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  list-style: none;
  padding: 12px 16px 12px 12px;
  width: 100%;
  background: #ffffff;
  /* border: 1px solid transparent; */
  border: 1px solid rgb(227, 229, 235);
  box-shadow: 0px 2px 4px rgba(28, 17, 44, 0.04),
    0px 5px 12px rgba(28, 17, 44, 0.03);
  border-radius: 8px;
  margin: 8px 0px;
  user-select: none;
  cursor: pointer;
  box-sizing: border-box;
  transition: 0.1s ease-in-out;
  &:hover {
    border: 1px solid #a9aeb9;
    box-shadow: 0px 2px 4px rgba(28, 17, 44, 0.04),
      0px 5px 12px rgba(28, 17, 44, 0.03);
  }

  &:hover {
    div.ExerciseItem {
      opacity: 1;
    }
  }
`

const WorkoutRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: ${(props) => props.width || '100%'};
  box-sizing: border-box;
  position: relative;
`

const WorkoutName = styled.h4`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  margin: 0;
  padding: 0;
  color: ${(props) => props.color || '#1D2A43'};
  width: ${(props) => props.width || '100%'};
  ${ellipses};
  line-height: 30px;
`

const WorkoutImage = styled.div`
  ${(props) =>
    props.imageSrc &&
    css`
      width: 40px;
      height: 40px;
      min-width: 40px;
      min-height: 40px;
      border-radius: 6px;
      margin-right: 10px;
      background-position: center center;
      background-size: cover;
      background-image: ${`url(${props.imageSrc})`};
      background-repeat: no-repeat;
    `};
`

const FromIconContainer = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  margin-left: 15px;
  border: 1px solid transparent;
  padding: 0;
  height: 20px;
  width: 20px;
  padding: 0;
`

const WorkoutControls = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  min-width: 56px;
  visibility: ${(props) => props.visibility || 'visible'};
  .DeleteButton {
    display: none;
  }
  ${media.md`
  .DeleteButton {
    display: flex;
  }
`}
  &.controls {
    transition: 0.25s ease-in-out;
    ${(props) =>
      props.isActive &&
      css`
        display: none;
      `}
    ${media.md`
        ${(props) =>
          props.isActive &&
          css`
            display: flex;
          `}
    `}
  }

  button {
    &:first-child {
      margin-left: 0;
    }
  }
`

export {
  WorkoutItemContainer,
  WorkoutControls,
  WorkoutRowContainer,
  FromIconContainer,
  WorkoutName,
  WorkoutImage,
}
