import React from 'react'
import { LayoutProvider } from '../LayoutProvider'
import { Container } from './Layout.styled'
import { includes, toUpper, keys } from 'ramda'

import { Menu } from '../Menu'
import { Main } from '../Main'
import { Heading } from '@common/Heading'

const DefaultLayout = ({ children, title, className }) => (
  <LayoutProvider>
    <Container className="Layout">
      <Menu />
      <Main>
        <div id="menu" />
        <Heading variant="page" mb={16} size={26}>
          {title}
        </Heading>
        {children}
      </Main>
    </Container>
    <div id="modal" />
  </LayoutProvider>
)

const CustomLayout = ({ children }) => (
  <LayoutProvider>
    <div id="menu" />
    <Container className="Layout">{children}</Container>
    <div id="modal" />
  </LayoutProvider>
)

const AccountLayout = ({ children, extend }) => (
  <LayoutProvider>
    <div id="menu" />
    <Container className="Layout" extend={extend}>
      {children}
    </Container>
    <div id="modal" />
  </LayoutProvider>
)

const config = {
  DEFAULT: DefaultLayout,
  CUSTOM: CustomLayout,
  ACCOUNT: AccountLayout,
}

const Layout = ({ children, variant, ...props }) => {
  if (!includes(toUpper(variant), keys(config))) {
    return (
      <div>
        {`Please provide an appropriate key for the <Modal /> component.`}
      </div>
    )
  }

  const Component = config[toUpper(variant)]
  return (
    <Component className="Layout" {...props}>
      {children}
    </Component>
  )
}

Layout.defaultProps = {
  variant: 'CUSTOM',
  title: '',
}

export { Layout }
