import styled, { css } from 'styled-components'
import {
  basicFunctions,
  basicStyles,
  basicText,
  pseudoStyles,
} from './buttonStyles'
import { mtFn, mbFn, mrFn, mlFn, widthFn } from '@common/Theme'

const Orange = styled.button`
  ${basicStyles};
  ${basicText};
  ${pseudoStyles}
  ${basicFunctions};
  ${mtFn};
  ${mbFn};
  ${mrFn};
  ${mlFn};
  padding: 12px 16px;
  width: 100%;
  background: #ff5a45;
  border-radius: 8px;
  transition: all 0.2s ease-in-out;
  align-items: center;
  justify-content: center;
  ${widthFn};

  ${(props) =>
    props.alone &&
    css`
      width: unset
      justify-content: center;
      align-items: center;
    `};

  svg.SVG {
    min-width: 20px;
  }

  span {
    color: white;
    font-size: 14px;
    font-weight: 500;
    width: 100%;
    line-height: 21px;
  }
  &:hover {
    background: #ff5a45;
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 8px,
      rgba(209, 48, 28, 0.06) 0px 5px 12px;
  }

  ${(props) =>
    props.disabled &&
    css`
      background: #f7f8fa;
      span {
        color: #a9aeb9;
      }
      border: 1px solid #e3e5eb;
      cursor: not-allowed;
      &:hover {
        background: #f7f8fa;
        box-shadow: none;
      }
    `};
  ${(props) => props.extend};
`

export { Orange }
