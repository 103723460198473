import {
  USER_FETCH_ME_PENDING,
  USER_UPDATE_PENDING,
  PREFERENCES_FETCH_PENDING,
  PREFERENCES_SAVE_BOOKMARK_PENDING,
  PREFERENCES_REMOVE_BOOKMARK_PENDING,
  USER_CREATE_IMAGE_PENDING,
  USER_VALIDATE_CHANNEL_PENDING,
  USER_RESET_VALIDATE_CHANNEL_PENDING,
} from '../actionTypes'

// user/validate-channel-name/amithsai1234
export const validateChannel = (payload) => ({
  type: USER_VALIDATE_CHANNEL_PENDING,
  payload,
})

export const resetChannelValidation = (payload) => ({
  type: USER_RESET_VALIDATE_CHANNEL_PENDING,
  payload,
})

export const fetchPreferences = () => ({
  type: PREFERENCES_FETCH_PENDING,
})

export const createUserImage = (payload) => ({
  type: USER_CREATE_IMAGE_PENDING,
  payload,
})

export const fetchMe = () => ({
  type: USER_FETCH_ME_PENDING,
})

export const updateUser = (payload) => ({
  type: USER_UPDATE_PENDING,
  payload,
})

export const saveExerciseBookmark = () => ({
  type: PREFERENCES_SAVE_BOOKMARK_PENDING,
})

export const removeExerciseBookmark = () => ({
  type: PREFERENCES_REMOVE_BOOKMARK_PENDING,
})
